import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SectionPageProps } from "../../pages/Default.page";
import {
  IPermissionGroup,
  deletePermissiongroup,
  reselectLocalPermissiongroup,
  updatePermissiongroup,
  Permissions,
  selectPermissiongroup
} from "../../slices/permissiongroups.slice";
import useAccessControl from "../../hooks/useAccessControl"
import { userdata } from "../../slices/auth.slice";

const usePermissiongroups = ({
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar?: boolean;
  disable?: boolean;
  setDisable?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  interface sectionDetailsObject {
    [key: string]: SectionPageProps;
  }
  type onChangeEvent = React.SyntheticEvent<HTMLElement, Event>;
  type onChangeValue =
    | string
    | number
    | boolean
    | (string | number | boolean)[]
    | undefined;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { selectedPermissiongroup, selectedLocalPermissiongroup } =
    useAppSelector((state) => state.permissiongroups);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showReactiveModal, setShowReactiveModal] = useState<boolean>(false);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

  const { canAccess } = useAccessControl();

  const canEdit = useMemo(() =>
    canAccess(Permissions.UPDATE_PERMISSIONS_GROUPS),
    [canAccess]
  );

  const permissiongroupCallback = useCallback(() => {
    if (
      selectedPermissiongroup !== null &&
      typeof selectedPermissiongroup?._id !== "undefined"
    ) {
      dispatch(reselectLocalPermissiongroup(selectedPermissiongroup));
    }
  }, [selectedPermissiongroup]);

  const sectionDetails: sectionDetailsObject = {
    infos: {
      id: "permissiongroup-info",
      icon: "las la-info-circle",
      title: "Informações",
      description: "Edite as informações",
    },
    content: {
      id: "permissiongroup-content",
      icon: "las la-shield-alt ",
      title: "Permissões",
      description: "Configure as permissões do grupo",
    },
  };

  const header: {
    icon?: string;
    title?: string;
    created?: string;
    updated?: string;
    deleted?: string;
  } = {
    created: selectedPermissiongroup?.created_at,
    updated: selectedPermissiongroup?.updated_at,
    deleted: selectedPermissiongroup?.deleted_at,
    title: selectedPermissiongroup?.name,
    icon: "las la-user-shield",
  };

  const back = () => {
    navigate("/settings/permissiongroups");
  };

  const remove = async () => {
    if (setDisable) setDisable(true);
    setShowDeleteModal(false);
    if (selectedPermissiongroup?._id) {
      const { payload } = await dispatch(
        deletePermissiongroup([selectedPermissiongroup._id])
      );
      if (setDisable) setDisable(false);
      if (
        payload !== null &&
        typeof payload !== "undefined" &&
        typeof payload?.message !== "string"
      ) {
        navigate("/settings/permissiongroups");
      }
    }
  };

  const reactive = async () => {
    if (setDisable) setDisable(true);
    setShowReactiveModal(false);
    const _permissionGroup = {
      ...selectedPermissiongroup,
      active: true,
      deleted: false,
      deleted_by: null,
      deleted_at: "",
    } as IPermissionGroup;
    const { payload } = await dispatch(
      updatePermissiongroup({
        _permissionGroup
      })
    );
    if (setDisable) setDisable(false);
    if (typeof payload._id !== "undefined") {
      navigate(-1);
    }
  };

  const save = async () => {
    if (setDisable) setDisable(true);

    const _permissionGroup = {
      ...selectedPermissiongroup,
      _id: selectedPermissiongroup?._id,
      name: selectedLocalPermissiongroup?.name,
      role: selectedLocalPermissiongroup?.role,
      permissions: selectedLocalPermissiongroup?.permissions,
      default: selectedLocalPermissiongroup?.default,
      active: selectedLocalPermissiongroup?.active,
    } as IPermissionGroup;

    const { payload } = await dispatch(
      updatePermissiongroup({ _permissionGroup })
    );
    await Promise.all([
      dispatch(selectPermissiongroup({ _id: payload._id })),
      dispatch(userdata())
    ])
    if (setDisable) setDisable(false);
  };

  const updatePermissiongroupData = ({
    field,
    value,
  }: {
    field: string;
    value:
    | undefined
    | null
    | string
    | boolean
    | number
    | string[]
    | {
      _id: string;
      name: string;
    }
    | {
      _id: string;
      name: string;
    }[];
  }) => {
    if (selectedPermissiongroup) {
      dispatch(
        reselectLocalPermissiongroup({
          ...selectedPermissiongroup,
          [field]: value,
        })
      );
    } else {
      dispatch(reselectLocalPermissiongroup(null));
    }
  };

  const infos = {
    active: {
      disabled: selectedPermissiongroup?.deleted,
    },
    disabled: selectedPermissiongroup?.deleted,
    readonly: selectedPermissiongroup?.deleted || !canEdit,
    onChange: {
      name: async (e: onChangeEvent, { value }: { value: onChangeValue }) => {
        if (typeof value === "string") {
          updatePermissiongroupData({
            field: "name",
            value,
          });
        }
      },
      role: async (e: onChangeEvent, { value }: { value: onChangeValue }) => {
        if (typeof value === "string") {
          updatePermissiongroupData({
            field: "role",
            value,
          });
        }
      },
      active: async (e: onChangeEvent, { value }: { value: onChangeValue }) => {
        try {
          if (typeof value === "boolean") {
            updatePermissiongroupData({
              field: "active",
              value,
            });
          }
        } catch (error) {
          // console.log(error);
        }
      },
    },
  };

  const permissions = {
    id: "permissiongroup-permissions",
    initialValue: selectedPermissiongroup?.permissions,
    onChange: updatePermissiongroupData,
    readOnly: !canEdit,
  };

  const footer = {
    disabled:
      disable ||
      selectedPermissiongroup?.deleted ||
      typeof selectedPermissiongroup?.name === "undefined" ||
      selectedPermissiongroup?.permissions?.length === 0 ||
      selectedPermissiongroup?.name?.length < 2,
    deleted: selectedPermissiongroup?.deleted,
    back,
    reactive: canAccess(Permissions.DELETE_PERMISSIONS_GROUPS) ? reactive : undefined,
    remove: canAccess(Permissions.DELETE_PERMISSIONS_GROUPS) ? remove : undefined,
    save: canEdit ? save : undefined,
    toggleSidebar: toggleSidebar || false,
    modaltitle: selectedPermissiongroup?.deleted
      ? "Deseja reativar este grupo de permissão?"
      : "Deseja remover este grupo de permissão?",
    showDeleteModal,
    setShowDeleteModal,
    showReactiveModal,
    setShowReactiveModal,
  };

  return {
    permissiongroupCallback,
    header,
    footer,
    infos,
    permissions,
    sectionDetails,
  };
};

export default usePermissiongroups;
