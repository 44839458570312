import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import LoadingDots from "../shared/components/LoadingDots.component";
import DividerInfoPages from "../shared/components/DividerInfoPages.component";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";
import Section from "../shared/components/Section.component";
import SectionElement from "../shared/components/SectionElement.component";
import {
  ExternalAppType,
  selectAgideskApp,
  updateExternalapps,
  reselectExternalappAgidesk,
  deleteExternalapps,
  getAgideskServices,
  IExternalAppAgidesk,
} from "../slices/externalapps.slice";
import { getSettings } from "../slices/settings.slice";
import useAccessControl from "../hooks/useAccessControl";
import { Permissions } from "../slices/permissiongroups.slice";

const AgideskPage = ({
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { canAccess } = useAccessControl();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoadingExternalapps, selectedExternalAppAgidesk } = useAppSelector(
    (state) => state.externalapps
  );

  const [, setReloadForced] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showReactiveModal, setShowReactiveModal] = useState<boolean>(false);

  useEffect(() => {
    const getId = () => {
      if (params && typeof params._id !== "undefined") return params._id;
      return location.pathname.split("/").slice(-1)[0];
    };

    (async () => {
      await dispatch(
        selectAgideskApp({
          _id: getId(),
        })
      );
      setReloadForced(false);
      await dispatch(getSettings({}));
    })();
  }, []);

  useEffect(() => {
    const handleClickScrollImport = () => {
      const element = document.getElementById("agidesk-import");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    const handleClickScrollLogin = () => {
      const element = document.getElementById("agidesk-login");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    const handleClickScrollTicket = () => {
      const element = document.getElementById("agidesk-ticket");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    const handleClickScrollTicketFinish = () => {
      const element = document.getElementById("agidesk-chat-finish");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    const handleClickScrollTicketFinishInactive = () => {
      const element = document.getElementById("agidesk-chat-finishinactive");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    switch (location.hash) {
      case "#agidesk-login":
        handleClickScrollLogin();
        break;
      case "#agidesk-ticket":
        handleClickScrollTicket();
        break;
      case "#agidesk-chat-finish":
        handleClickScrollTicketFinish();
        break;
      case "#agidesk-chat-finishinactive":
        handleClickScrollTicketFinishInactive();
        break;
      case "#agidesk-import":
        handleClickScrollImport();
        break;
      default:
        break;
    }
  }, [location]);

  const goBack = () => {
    navigate("/settings/integration/agidesk");
  };

  const goRemove = async () => {
    setDisable(true);
    setShowDeleteModal(false);
    if (selectedExternalAppAgidesk?._id) {
      const { payload } = await dispatch(
        deleteExternalapps([selectedExternalAppAgidesk._id])
      );
      setDisable(false);
      if (
        payload !== null &&
        typeof payload !== "undefined" &&
        typeof payload?.message !== "string"
      ) {
        navigate("/settings/integration/agidesk");
      }
    }
  };

  const goReactive = async () => {
    setDisable(true);
    setShowReactiveModal(false);
    const _externalapp = {
      ...selectedExternalAppAgidesk,
      type: ExternalAppType.AGIDESK,
      deleted: false,
      active: true,
      deleted_by: null,
      deleted_at: "",
    };
    const { payload } = await dispatch(updateExternalapps({ _externalapp }));
    setDisable(false);
    if (typeof payload._id !== "undefined") {
      navigate(-1);
    }
  };

  const goSaveAgidesk = async () => {
    setDisable(true);
    const _externalapp: IExternalAppAgidesk = {
      ...selectedExternalAppAgidesk,
      _id: selectedExternalAppAgidesk?._id,
      active: selectedExternalAppAgidesk?.active,
      type: ExternalAppType.AGIDESK,
      fields: {
        ...selectedExternalAppAgidesk.fields,
        name: selectedExternalAppAgidesk?.fields?.name,
        url: selectedExternalAppAgidesk?.fields?.url,
        slug: selectedExternalAppAgidesk?.fields?.slug,
        email: selectedExternalAppAgidesk?.fields?.email,
        appkey: selectedExternalAppAgidesk?.fields?.appkey,
      },
    };
    const { payload } = await dispatch(
      updateExternalapps({ _externalapp, _validate: true })
    );
    if (typeof payload.error === "undefined") {
      dispatch(
        reselectExternalappAgidesk({
          ...selectedExternalAppAgidesk,
          updated_at: payload.updated_at,
          updated_by: payload.updated_by,
        })
      );
      const action = await dispatch(
        getAgideskServices({
          _services: {
            skip: 0,
            limit: 1,
            filter: "",
            externalappid: selectedExternalAppAgidesk?._id || "",
          },
          _validate: true,
        })
      );
      if (typeof action.payload.error === "undefined") {
        const _action = await dispatch(
          updateExternalapps({
            _externalapp: {
              ..._externalapp,
              fields: {
                ..._externalapp.fields,
                validate: true,
              },
            },
            _validate: true,
          })
        );
        if (
          selectedExternalAppAgidesk?.fields?.name &&
          selectedExternalAppAgidesk?.fields?.slug &&
          selectedExternalAppAgidesk?.fields?.url &&
          selectedExternalAppAgidesk?.fields?.email &&
          selectedExternalAppAgidesk?.fields?.appkey
        ) {
          dispatch(
            reselectExternalappAgidesk({
              ...selectedExternalAppAgidesk,
              fields: {
                ...selectedExternalAppAgidesk.fields,
                validate: true,
              },
              updated_at: _action.payload.updated_at,
              updated_by: _action.payload.updated_by,
            })
          );
        }
        navigate(`${location.pathname}#agidesk-login`);
      } else {
        dispatch(
          reselectExternalappAgidesk({
            ...selectedExternalAppAgidesk,
            fields: {
              ...selectedExternalAppAgidesk.fields,
              validate: false,
            },
          })
        );
      }
    }
    setDisable(false);
  };

  const goSave = async () => {
    setDisable(true);
    const _externalapp = {
      _id: selectedExternalAppAgidesk?._id,
      active: selectedExternalAppAgidesk?.active,
      type: ExternalAppType.AGIDESK,
      fields: {
        name: selectedExternalAppAgidesk?.fields?.name,
        url: selectedExternalAppAgidesk?.fields?.url,
        slug: selectedExternalAppAgidesk?.fields?.slug,
        email: selectedExternalAppAgidesk?.fields?.email,
        appkey: selectedExternalAppAgidesk?.fields?.appkey,
        enablechat: selectedExternalAppAgidesk?.fields?.enablechat,
        displaytitlechat: selectedExternalAppAgidesk?.fields?.displaytitlechat,
        enablelogin: selectedExternalAppAgidesk?.fields?.enablelogin,
        displaytitlelogin:
          selectedExternalAppAgidesk?.fields?.displaytitlelogin,
        servicerestrict: selectedExternalAppAgidesk?.fields?.servicerestrict,
        ticketinfo: selectedExternalAppAgidesk?.fields?.ticketinfo,
        ticketfinishinfo: selectedExternalAppAgidesk?.fields?.ticketfinishinfo,
        ticketfinishinactiveinfo:
          selectedExternalAppAgidesk?.fields?.ticketfinishinactiveinfo, // TODO
        ticketfinish: selectedExternalAppAgidesk?.fields?.ticketfinish,
        ticketfinishinactive:
          selectedExternalAppAgidesk?.fields?.ticketfinishinactive,
        ticketfinishservice:
          selectedExternalAppAgidesk?.fields?.ticketfinishservice,
        ticketfinishinactiveservice:
          selectedExternalAppAgidesk?.fields?.ticketfinishinactiveservice,
        ticketfinishteam:
          selectedExternalAppAgidesk?.fields?.ticketfinishteam?.map((rel) => {
            const _r = {
              ...rel,
              team: typeof rel?.team === "string" ? rel?.team : rel?.team?._id,
            };
            return _r;
          }),
        ticketfinishinactiveteam:
          selectedExternalAppAgidesk?.fields?.ticketfinishinactiveteam?.map(
            (rel) => {
              const _r = {
                ...rel,
                team:
                  typeof rel?.team === "string" ? rel?.team : rel?.team?._id,
              };
              return _r;
            }
          ),
        category: selectedExternalAppAgidesk?.fields?.category,
        topic: selectedExternalAppAgidesk?.fields?.topic,
        service: selectedExternalAppAgidesk?.fields?.service,
        validate: true,
      },
    };

    const invalidExtApp = {
      _id: selectedExternalAppAgidesk?._id,
      active: selectedExternalAppAgidesk?.active,
      type: ExternalAppType.AGIDESK,
      fields: {
        ...selectedExternalAppAgidesk.fields,
        category: [],
        topic: [],
        service: [],
        validate: false,
        servicerestrict: false,
        enablechat: false,
        displaytitlechat: "",
        enablelogin: false,
        displaytitlelogin: "",
        ticketinfo: {
          title: "",
          subject: "",
          includechat: false,
          internal: false,
          openingsolved: false,
        },
        ticketfinishinfo: {
          title: "",
          includechat: false,
          internal: false,
          openingsolved: false,
        },
        ticketfinishinactiveinfo: {
          title: "",
          includechat: false,
          internal: false,
          openingsolved: false,
        },
        ticketfinish: false,
        ticketfinishinactive: false,
        ticketfinishservice: {},
        ticketfinishteam: [],
        ticketfinishinactiveservice: {},
        ticketfinishinactiveteam: [],
      },
    };

    const { payload } = await dispatch(updateExternalapps({ _externalapp }));
    const action = await dispatch(
      getAgideskServices({
        _services: {
          skip: 0,
          limit: 1,
          filter: "",
          externalappid: selectedExternalAppAgidesk?._id || "",
        },
        _validate: true,
        _validateError: true,
      })
    );
    if (typeof action.payload.error === "undefined") {
      dispatch(
        reselectExternalappAgidesk({
          ...payload,
          updated_at: payload.updated_at,
          updated_by: payload.updated_by,
        })
      );
    } else {
      const _error = await dispatch(
        updateExternalapps({
          _externalapp: { ...invalidExtApp },
          _validate: true,
        })
      );
      dispatch(
        reselectExternalappAgidesk({
          ..._error.payload,
          updated_at: payload.updated_at,
          updated_by: payload.updated_by,
        })
      );
    }
    setDisable(false);
  };

  if (isLoadingExternalapps)
    return (
      <LoadingDots
        className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
        large
      />
    );
  return (
    <div className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-4 sm:px-8">
      <HeaderPageInfo
        created={selectedExternalAppAgidesk?.created_at}
        updated={selectedExternalAppAgidesk?.updated_at}
        deleted={selectedExternalAppAgidesk?.deleted_at}
        title={selectedExternalAppAgidesk?.fields?.name || "-"}
        imported={false}
        icon="agidesk"
      />
      <Section
        icon="las la-info-circle"
        title="Informações do Agidesk"
        description="Preencha os dados necessários para completar a integração"
        component={
          <SectionElement
            instance={selectedExternalAppAgidesk || null}
            type="agidesk-info"
            save={goSaveAgidesk}
          />
        }
      />
      {selectedExternalAppAgidesk?.fields?.appkey?.length > 0 &&
      selectedExternalAppAgidesk?.fields?.email?.length > 0 &&
      selectedExternalAppAgidesk?.fields?.name?.length > 0 &&
      selectedExternalAppAgidesk?.fields?.url?.length > 0 &&
      selectedExternalAppAgidesk.fields.validate ? (
        <>
          <DividerInfoPages />
          <Section
            id="agidesk-login"
            icon="las la-sign-in-alt"
            title="Permissão de autenticação"
            description="Habilite a opção de login com o Agidesk podendo personalizar um título"
            component={
              <SectionElement
                instance={selectedExternalAppAgidesk || null}
                type="agidesk-login"
              />
            }
          />
          <DividerInfoPages />
          <Section
            id="agidesk-ticket"
            icon="las la-life-ring"
            title="Criação de atendimentos"
            description="Selecione os serviços disponíveis para abertura de atendimento, e também, pré-defina o título, a descrição e a inclusão da conversa"
            component={
              <SectionElement
                instance={selectedExternalAppAgidesk || null}
                type="agidesk-ticket"
              />
            }
          />
          <DividerInfoPages />
          <Section
            id="agidesk-chat-finish"
            icon="las la-stopwatch"
            title="Encerramento de conversas"
            description="Configure como a integração deve se comportar nos momentos que uma conversa é encerrada. 
                          <br/> Atendimentos que não tiverem agentes responsáveis serão criados utilizando seu usuário padrão."
            component={
              <SectionElement
                instance={selectedExternalAppAgidesk || null}
                type="agidesk-chat-finish"
              />
            }
          />
          <DividerInfoPages />
          <Section
            id="agidesk-import"
            icon="las la-cloud-download-alt"
            title="Importação de equipes e agentes"
            description="Tragas as equipes e agentes do Agidesk para dentro do Agitalks"
            component={
              <SectionElement
                instance={selectedExternalAppAgidesk || null}
                type="agidesk-import"
                showModal={showModal}
                setShowModal={setShowModal}
              />
            }
          />
        </>
      ) : null}
      <div className="mb-20" />
      <FooterPageInfo
        disabled={
          disable ||
          selectedExternalAppAgidesk.deleted ||
          typeof selectedExternalAppAgidesk?.fields?.name === "undefined" ||
          typeof selectedExternalAppAgidesk?.fields?.appkey === "undefined" ||
          typeof selectedExternalAppAgidesk?.fields?.email === "undefined" ||
          typeof selectedExternalAppAgidesk?.fields?.slug === "undefined" ||
          typeof selectedExternalAppAgidesk?.fields?.url === "undefined" ||
          (selectedExternalAppAgidesk?.fields?.servicerestrict &&
            (!selectedExternalAppAgidesk?.fields?.service ||
              selectedExternalAppAgidesk?.fields?.service.length === 0)) ||
          selectedExternalAppAgidesk?.fields?.name.length < 2 ||
          selectedExternalAppAgidesk?.fields?.appkey.length === 0 ||
          selectedExternalAppAgidesk?.fields?.email.length === 0 ||
          selectedExternalAppAgidesk?.fields?.slug.length === 0 ||
          selectedExternalAppAgidesk?.fields?.url.length === 0 ||
          (selectedExternalAppAgidesk?.fields?.ticketfinish &&
            (typeof selectedExternalAppAgidesk?.fields?.ticketfinishservice
              ?.id === "undefined" ||
              !selectedExternalAppAgidesk?.fields?.ticketfinishservice.id ||
              typeof selectedExternalAppAgidesk?.fields?.ticketfinishinfo
                ?.title === "undefined" ||
              selectedExternalAppAgidesk?.fields?.ticketfinishinfo?.title
                .length === 0)) ||
          (selectedExternalAppAgidesk?.fields?.ticketfinishinactive &&
            (typeof selectedExternalAppAgidesk?.fields
              ?.ticketfinishinactiveservice?.id === "undefined" ||
              !selectedExternalAppAgidesk?.fields?.ticketfinishinactiveservice
                .id ||
              typeof selectedExternalAppAgidesk?.fields
                ?.ticketfinishinactiveinfo?.title === "undefined" ||
              selectedExternalAppAgidesk?.fields?.ticketfinishinactiveinfo
                ?.title.length === 0))
        }
        deleted={selectedExternalAppAgidesk?.deleted}
        toggleSidebar={toggleSidebar}
        back={goBack}
        title={
          selectedExternalAppAgidesk?.deleted
            ? "Deseja reativar esta integração?"
            : "Deseja remover esta integração?"
        }
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        showReactiveModal={showReactiveModal}
        setShowReactiveModal={setShowReactiveModal}
        reactive={
          canAccess(Permissions.DELETE_INTEGRATIONS_AGIDESK)
            ? goReactive
            : undefined
        }
        remove={
          canAccess(Permissions.DELETE_INTEGRATIONS_AGIDESK)
            ? goRemove
            : undefined
        }
        save={
          canAccess(Permissions.UPDATE_INTEGRATIONS_AGIDESK)
            ? goSave
            : undefined
        }
      />
    </div>
  );
};

export default AgideskPage;
