import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PrivateRoute } from ".";
import Conversation from "../features/chats/components/Conversation.component";
import { useAppSelector } from "../hooks/redux/hooks";
import { AuthLayoutPage } from "../pages";
import { UserType } from "../slices/users.slice";
import LoadingDots from "../shared/components/LoadingDots.component";
import Button from "../shared/components/Buttons/Button.component";

const WidgetEmbeddedRoute = ({
  toggleOpenClose,
  widgetType,
  disable,
  setDisable,
}: {
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  toggleOpenClose: boolean;
  widgetType: UserType;
}) => {
  const [toggleList, setToggleList] = useState<string>("mine");

  const { isAuthenticated, isLoadingAuth } = useAppSelector(
    (state) => state.auth
  );
  const { selectedChat } = useAppSelector((state) => state.chats);

  if (isLoadingAuth) {
    return (
      <div
        className={`shadow-md bg-white top-0 bottom-0 right-0 h-full rounded-[3px] overflow-x-hidden fixed 
    ${
      !toggleOpenClose && widgetType === UserType.AGENT
        ? "hidden"
        : "flex flex-col w-full"
    }`}
      >
        <LoadingDots
          medium
          className="flex justify-center items-center flex-1"
        />
      </div>
    );
  }

  const errorScreen = () => {
    return (
      <AuthLayoutPage widgetType={widgetType}>
        <div className="flex flex-col items-center relative">
          <p className="text-[20px] font-semibold text-center">
            Ops! Algo inesperado aconteceu e perdemos a comunicação.
            <br />
            Tente novamente!
          </p>
          <Button
            minWidth
            extraClass="m-[25px]"
            label="Tentar novamente"
            onClick={() => null}
            icon="las la-arrow-right"
          />
        </div>
      </AuthLayoutPage>
    );
  };

  return (
    <div
      className={`shadow-md bg-white top-0 bottom-0 right-0 h-full w-[500px] rounded-[3px] ${
        widgetType === UserType.EMBEDDED ? "" : "mr-[32px]"
      } overflow-x-hidden fixed flex flex-col`}
    >
      {widgetType === UserType.EMBEDDED ? (
        <Routes>
          <Route
            path="/company"
            element={
              isAuthenticated && typeof selectedChat?._id !== "undefined" ? (
                <Navigate to={`/chat/${selectedChat._id}`} />
              ) : (
                errorScreen()
              )
            }
          />
          <Route element={<PrivateRoute />}>
            <Route
              path="/"
              element={
                isAuthenticated && typeof selectedChat?._id !== "undefined" ? (
                  <Navigate to={`/chat/${selectedChat._id}`} />
                ) : (
                  errorScreen()
                )
              }
            />
            <Route
              path="/chat/:_id"
              element={
                <Conversation
                  disable={disable}
                  setDisable={setDisable}
                  widgetType={widgetType}
                  toggleList={toggleList}
                  setToggleList={setToggleList}
                />
              }
            />
          </Route>
        </Routes>
      ) : (
        errorScreen()
      )}
    </div>
  );
};

export default WidgetEmbeddedRoute;
