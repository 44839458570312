import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import { teamsChatsAssignment } from "../../../slices/teams.slice";
import { IUser, UserStatus } from "../../../slices/users.slice";
import {
  changeStatus,
  logout,
  refreshLogout,
  selectTenant,
  signin,
} from "../../../slices/auth.slice";
import { clearRecents } from "../../../slices/chats.slice";

interface IUserProfile {
  user: IUser | null | undefined;
  isOnline: boolean;
  hasAvatar: boolean;
  updateStatus: () => Promise<void>;
  handleLogout: () => Promise<void>;
}

const useUserProfile = ({ type }: { type?: string }): IUserProfile => {
  const dispatch = useAppDispatch();
  const { user, tenantID } = useAppSelector((state) => state.auth);
  const { recentChats } = useAppSelector((state) => state.chats);
  const hasAvatar = Boolean(user?.avatar?.path && user?.avatar?.name);
  const isOnline = user?.status === UserStatus.ONLINE;

  const updateStatus = async () => {
    if (user?._id) {
      dispatch(
        changeStatus({
          status:
            user?.status === UserStatus.OFFLINE
              ? UserStatus.ONLINE
              : UserStatus.OFFLINE,
          origin: user?._id,
          manual: true,
        })
      );

      if (user?.status === UserStatus.OFFLINE) {
        await dispatch(teamsChatsAssignment());
      }
    }
  };

  const handleLogout = async () => {
    const _tenant = tenantID;
    if (recentChats.length > 0) window.parent.postMessage("toggleRecent", "*");
    await dispatch(
      changeStatus({ status: UserStatus.OFFLINE, origin: user?._id })
    );
    dispatch(clearRecents());
    if (!type || !["inbox-widget", "contact-widget"].includes(type)) {
      await dispatch(logout());
      dispatch(refreshLogout());
      localStorage.clear();
    } else {
      if (type === "inbox-widget") {
        await dispatch(logout());
      }
      dispatch(refreshLogout());
      localStorage.clear();
      if (typeof _tenant !== "undefined" && _tenant.length > 0) {
        dispatch(selectTenant(_tenant));
        await dispatch(signin({ tenant: _tenant }));
      }
    }
  };
  // const handleLogout = async () => {
  //   dispatch(changeStatus({ status: UserStatus.OFFLINE, origin: user?._id }));
  //   await dispatch(logout());
  //   dispatch(refreshLogout());
  //   localStorage.clear();
  // };

  return {
    user,
    isOnline,
    hasAvatar,
    updateStatus,
    handleLogout,
  };
};

export default useUserProfile;
