/* eslint-disable react/require-default-props */
import React from "react";
import { twMerge } from "tailwind-merge";

export interface BadgeProps {
  label: string;
  variant:
    | "critical"
    | "primary"
    | "neutral"
    | "success"
    | "warning"
    | "info"
    | "violet";
  style: "solid" | "soft";
  size?: "default" | "small";
  disabled?: boolean;
  title?: string;
  icon?: string;
  extraClass?: string;
}

const Badge = ({
  label,
  variant,
  style,
  size,
  icon,
  title,
  disabled,
  extraClass,
}: BadgeProps) => {
  return (
    <div
      id="badge"
      title={title ?? label}
      className={twMerge(
        "inline-flex min-w-[16px] gap-1 shrink-0 items-center justify-center py-0.5 rounded-full",
        extraClass ||
          (size === "small"
            ? "h-4 px-1 text-25 leading-[11px]"
            : "h-[26px] px-2.5 text-50 leading-5"),
        extraClass || variant,
        disabled && "disabled",
        extraClass || style
      )}
    >
      {icon && <i className={twMerge(icon, "size-3")} />}
      <span className="text-center font-bold overflow-ellipsis line-clamp-1 leading-trim-both text-edge-cap">
        {label}
      </span>
    </div>
  );
};

export default Badge;
