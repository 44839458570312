/* eslint-disable no-shadow */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { usersService } from "../services";
import { IFile } from "../shared/models/interfaces/file.interface";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import { Teams } from "./teams.slice";
import { Customers } from "./customers.slice";
import customToast from "../shared/utils/customToast";
import { IToastType } from "../shared/models/types/Toast.type";
import { SettingCompany } from "./settings.slice";
import { ITag } from "./tags.slice";
import { IFiltersDropdownInstances } from "../shared/components/@types/searchBarFilter.types";
import { Permissions } from "./permissiongroups.slice";

export enum ImportSource {
  AGENT = "AGENT",
  CONTACT = "CONTACT",
  CUSTOMER = "CUSTOMER",
  TEAM = "TEAM",
}

export enum UserType {
  AGENT = "agent",
  CONTACT = "contact",
  NONE = "",
  EMBEDDED = "embedded",
}

export enum UserStatus {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
}

export enum RoleUser {
  ADMINISTRATOR = "ADMINISTRATOR",
  SUPERVISOR = "SUPERVISOR",
  AGENT = "AGENT",
  CONTACT = "CONTACT",
}
export interface ILoginUser {
  tenant: string;
  email: string;
  password: string;
  widget?: string;
}

export interface IRegisterFormField {
  tenant: string;
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export type RegisterUser = Omit<IRegisterFormField, "confirmPassword">;

export interface ILoginAgideskUser {
  tenant: string;
  username: string;
  password: string;
  externalapp: string | undefined;
}

export interface IOnboardingUser {
  _id: string;
  tenant: string;
  name: string;
  email: string;
  password: string;
  roles: RoleUser[];
}
export interface IChangeUser {
  _id?: string;
  avatar?: IFile;
  name?: string;
  phone?: string;
  tags?: ITag[];
  updated_at?: string;
  code?: string;
  tenant?: string;
  spam?: boolean;
  chatfilters?: {
    tips?: string[];
    filters?: IFiltersDropdownInstances;
    filtersOptions?: any;
    sort?: {
      backlog?: number;
      mine?: number;
      teams?: number;
      search?: number;
      history?: number;
    };
  };
}

export interface IChangeStatusUser {
  _id?: string;
  status?: UserStatus;
  updated_at?: string;
  tenant?: string;
}

export interface IUserProfile {
  externalapp?: string;
  defaulttemplate?: string;
  externalappnumber?: string;
}
export interface IUser {
  _id?: string;
  tenant?: string;
  deleted?: boolean;
  company?: SettingCompany;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
  name?: string;
  email?: string;
  active?: boolean;
  status?: UserStatus;
  avatar?: IFile;
  phone?: string;
  onboarding?: string;
  roles?: RoleUser[];
  permissions?: Permissions[];
  externalcode?: string;
  teams?: Teams;
  customers?: Customers;
  profile?: IUserProfile;
  tags?: ITag[];
  spam?: boolean;
  code?: string;
  chatfilters?: {
    tips?: string[];
    filters?: IFiltersDropdownInstances;
    filtersOptions?: any;
    sort?: {
      backlog?: number;
      mine?: number;
      teams?: number;
      history?: number;
      search?: number;
    };
  };
  permissiongroups?: string[]
}

type _updateUser = Omit<IUser, "avatar" | "teams" | "customers">;
export interface IUpdateUser extends _updateUser {
  avatar?: string;
  teams?: string[];
  customers?: string[];
}

export interface ISender extends IUser {
  color?: string;
}

export type Users = IUser[];

interface UsersState extends AsyncState {
  isLoadingUsers: boolean;
  isLoadingDropdownUsers: boolean;
  isLoadingContacts: boolean;
  isLoadingDropdownContacts: boolean;
  isCreatingUsers: boolean;
  isCreatingContacts: boolean;
  isRemoving: boolean;
  isSpamming: boolean;
  users: Users;
  contacts: Users;
  totalUsers: number;
  totalContacts: number;
  selectedUser: IUser | null;
  selectedLocalUser: IUser | null;
  selectedContact: IUser | null;
  selectedLocalContact: IUser | null;
}

const initialState: UsersState = {
  users: [],
  contacts: [],
  totalUsers: 0,
  totalContacts: 0,
  selectedUser: null,
  selectedContact: null,
  selectedLocalUser: null,
  selectedLocalContact: null,
  isLoadingContacts: false,
  isLoadingDropdownContacts: false,
  isLoadingUsers: false,
  isLoadingDropdownUsers: false,
  isCreatingContacts: false,
  isCreatingUsers: false,
  isRemoving: false,
  isSpamming: false,
  isSuccess: false,
  isError: false,
};

export interface INewUser {
  avatar?: string;
  status?: UserStatus;
  name: string;
  email: string;
  password: string;
  phone: string;
  roles: string[];
  sendinvite: boolean;
  tenant: string;
  widget?: boolean;
  code?: string;
}

export interface IFilterUser {
  skip: number;
  limit: number;
  filter: string;
  deleted: boolean;
  spam?: boolean;
  allStatus?: boolean;
  historySearch?: boolean;
}

export type INewContact = Omit<INewUser, "password">;

export const createUser = createAsyncThunk(
  "users/create",
  async (newUser: INewUser, thunkAPI) => {
    try {
      return await usersService.createAgent({
        ...newUser,
        phone: newUser?.phone?.replace(/[^0-9.]/g, ""),
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/delete",
  async (ids: string[], thunkAPI) => {
    try {
      return await usersService.deleteUsers({ ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteContact = createAsyncThunk(
  "contacts/delete",
  async (ids: string[], thunkAPI) => {
    try {
      return await usersService.deleteUsers({ ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface SpamProps {
  ids: string[];
  spam: boolean;
}

export const spamContact = createAsyncThunk(
  "contacts/spam",
  async (spamProps: SpamProps, thunkAPI) => {
    try {
      return await usersService.spamContacts(spamProps);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createContact = createAsyncThunk(
  "contacts/create",
  async (newContact: INewContact, thunkAPI) => {
    try {
      return await usersService.createContact({
        ...newContact,
        phone: newContact?.phone?.replace(/[^0-9.]/g, ""),
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSearchUsers = createAsyncThunk(
  "users/search",
  async (filterAgent: IFilterUser, thunkAPI) => {
    try {
      return await usersService.searchAgents(filterAgent);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSearchFilterUsers = createAsyncThunk(
  "usersfilter/search",
  async (_ids: any, thunkAPI) => {
    try {
      return await usersService.postAgentsByTeamIds({ _ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSearchContacts = createAsyncThunk(
  "contacts/search",
  async (filterContact: IFilterUser, thunkAPI) => {
    try {
      return await usersService.searchContacts(filterContact);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const importContacts = createAsyncThunk(
  "contacts/import",
  async (
    {
      file,
      source,
      updateexists,
    }: {
      file: File;
      source?: ImportSource;
      updateexists?: boolean;
    },
    thunkAPI
  ) => {
    try {
      return await usersService.importContacts({ file, source, updateexists });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/update",
  async (_user: any, thunkAPI) => {
    try {
      return await usersService.update({ _user });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const selectUser = createAsyncThunk(
  "users/selectUser",
  async (
    { _id, isContact }: { _id: string; isContact?: boolean },
    thunkAPI
  ) => {
    try {
      return await usersService.user({ _id });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getManyUsers = createAsyncThunk(
  "users/many",
  async ({ _ids }: { _ids: string }, thunkAPI) => {
    try {
      return await usersService.getManyUsers({ _ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const getAgideskUsers = createAsyncThunk(
//   "teams/sync",
//   async (_groupLimit: IGroupLimit, thunkAPI) => {
//     try {
//       return await usersService.syncUsers(_groupLimit);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
    selectTotalAgents(state, action: PayloadAction<number>) {
      state.totalUsers = action.payload || 0;
    },
    selectTotalContacts(state, action: PayloadAction<number>) {
      state.totalContacts = action.payload || 0;
    },
    selectAgents(state, action: PayloadAction<IUser[]>) {
      state.users = action.payload || [];
    },
    selectContacts(state, action: PayloadAction<IUser[]>) {
      state.contacts = action.payload || [];
    },
    reselectAgent(state, action: PayloadAction<IUser | null>) {
      state.selectedUser = action.payload;
    },
    reselectContact(state, action: PayloadAction<IUser | null>) {
      state.selectedContact = action.payload;
    },
    reselectLocalAgent(state, action: PayloadAction<IUser | null>) {
      state.selectedLocalUser = action.payload;
    },
    reselectLocalContact(state, action: PayloadAction<IUser | null>) {
      state.selectedLocalContact = action.payload;
    },
    changeUser(state, action: PayloadAction<IChangeUser>) {
      const _userToBeUpdated: IChangeUser = action.payload;
      const newState = state.users.map((user: IUser) => {
        if (user._id === _userToBeUpdated._id) {
          return {
            ...user,
            chatfilters: _userToBeUpdated.chatfilters || {
              filters: {
                campaigns: [],
                period: [],
                users: [],
                customers: [],
                teams: [],
                types: [],
                status: [],
                externalapps: [],
                content: [],
                surveys: [],
                groupchat: [],
                campaignchat: [],
                contacttags: [],
                contacts: [],
                tags: [],
              },
              filterOptions: {
                campaigns: [],
                period: [],
                users: [],
                customers: [],
                teams: [],
                types: [],
                status: [],
                externalapps: [],
                content: [],
                surveys: [],
                groupchat: [],
                campaignchat: [],
                contacttags: [],
                contacts: [],
                tags: [],
              },
              tips: [],
              sort: {
                backlog: 1,
                history: 1,
                teams: -1,
                mine: -1,
                search: -1,
              },
            },
            spam: _userToBeUpdated?.spam,
            tags: _userToBeUpdated?.tags || undefined,
            code: _userToBeUpdated?.code || undefined,
            avatar: _userToBeUpdated.avatar,
            name: _userToBeUpdated.name || "",
            phone: _userToBeUpdated.phone,
            updated_at: _userToBeUpdated.updated_at,
          };
        }
        return user;
      });
      state.users = newState;
    },
    changeStatusUser(state, action: PayloadAction<IChangeStatusUser>) {
      const _userToBeUpdated: IChangeStatusUser = action.payload;
      const newState = state.users.map((user: IUser) => {
        if (user._id === _userToBeUpdated._id) {
          return {
            ...user,
            status: _userToBeUpdated.status || UserStatus.OFFLINE,
            updated_at: _userToBeUpdated.updated_at,
          };
        }
        return user;
      });
      state.users = newState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSearchFilterUsers.pending, (state) => {
        state.isLoadingUsers = true;
        state.isLoadingDropdownUsers = true;
      })
      .addCase(getSearchFilterUsers.fulfilled, (state) => {
        state.isSuccess = true;
        state.isLoadingUsers = false;
        state.isLoadingDropdownUsers = false;
      })
      .addCase(getSearchFilterUsers.rejected, (state) => {
        state.isError = true;
        state.isLoadingUsers = false;
        state.isLoadingDropdownUsers = false;
      })
      // USERS
      .addCase(getSearchUsers.pending, (state, action) => {
        if (action.meta.arg.limit === 10) {
          state.isLoadingUsers = true;
        }
        state.isLoadingUsers = true;
        state.isLoadingDropdownUsers = true;
      })
      .addCase(getSearchUsers.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.users = action.payload.results || [];
        // if (state.totalUsers <= action.payload.count)
        state.totalUsers = action.payload.count;
        state.isLoadingUsers = false;
        state.isLoadingDropdownUsers = false;
      })
      .addCase(getSearchUsers.rejected, (state) => {
        state.isError = true;
        // state.users = [];
        state.isLoadingUsers = false;
        state.isLoadingDropdownUsers = false;
      })
      .addCase(getSearchContacts.pending, (state, action) => {
        if (action.meta.arg.limit === 10) {
          state.isLoadingContacts = true;
        }
        state.isLoadingDropdownContacts = true;
      })
      .addCase(getSearchContacts.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (
          typeof action.meta.arg.historySearch === "undefined" ||
          !action.meta.arg.historySearch
        ) {
          state.contacts = action.payload.results || [];
          // if (state.totalContacts <= action.payload.count)
          state.totalContacts = action.payload.count;
        }
        state.isLoadingContacts = false;
        state.isLoadingDropdownContacts = false;
      })
      .addCase(getSearchContacts.rejected, (state, action) => {
        state.isError = true;
        if (
          typeof action.meta.arg.historySearch === "undefined" ||
          !action.meta.arg.historySearch
        ) {
          state.contacts = [];
        }
        state.isLoadingContacts = false;
        state.isLoadingDropdownContacts = false;
      })
      .addCase(importContacts.pending, (state) => {
        state.isLoadingContacts = true;
      })
      .addCase(importContacts.fulfilled, (state, action) => {
        if (typeof action?.payload?.data !== "undefined") {
          state.isSuccess = true;
        } else {
          state.isError = true;
        }
        state.isLoadingContacts = false;
      })
      .addCase(importContacts.rejected, (state) => {
        state.isError = true;
        state.isLoadingContacts = false;
      })
      // SHOW USER
      .addCase(selectUser.pending, (state, action) => {
        if (action.meta.arg.isContact) {
          state.isLoadingContacts = true;
        } else {
          state.isLoadingUsers = true;
        }
      })
      .addCase(selectUser.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (action.meta.arg.isContact) {
          state.selectedContact = action.payload || null;
          state.selectedLocalContact = action.payload || null;
          state.isLoadingContacts = false;
        } else {
          state.selectedUser = action.payload || null;
          state.isLoadingUsers = false;
        }
      })
      .addCase(selectUser.rejected, (state, action) => {
        state.isError = true;
        if (action.meta.arg.isContact) {
          state.selectedContact = null;
          state.isLoadingContacts = false;
        } else {
          state.selectedUser = null;
          state.isLoadingUsers = false;
        }
      })
      // UPDATE USER
      .addCase(updateUser.pending, () => {
        // state.isLoadingUsers = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        if (
          action?.payload !== null &&
          typeof action?.payload?._id !== "undefined"
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Alteração salva com sucesso!`,
          });
        } else {
          if (
            typeof action.payload.message === "string" &&
            action.payload.message.includes("duplicate")
          ) {
            customToast({
              type: IToastType.ERROR,
              message: `Opa! Já existe um usuário com este e-mail!`,
            });
          } else if (
            typeof action.payload.message === "string" &&
            action.payload.message.toLowerCase().includes("phone")
          ) {
            customToast({
              type: IToastType.ERROR,
              message: `Opa! Já existe um usuário com este telefone!`,
            });
          } else if (
            typeof action.payload.message === "string" &&
            action.payload.message.toLowerCase().includes("code")
          ) {
            customToast({
              type: IToastType.ERROR,
              message: `Opa! Já existe um contato com este CPF!`,
            });
          } else if (
            typeof action.payload?.message === "string" &&
            action.payload.message.includes(
              "been set as default user on general settings"
            )
          ) {
            customToast({
              type: IToastType.WARNING,
              message: `Agente definido para ações anônimas!`,
            });
          } else {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado! Tente novamente.`,
            });
          }
          state.isError = true;
          // state.selectedUser = null;
        }
        state.isLoadingUsers = false;
      })
      .addCase(updateUser.rejected, (state) => {
        state.isError = true;
        state.selectedUser = null;
        state.isLoadingUsers = false;
      })
      // .addCase(getAgideskUsers.pending, (state) => {
      //   state.isLoadingUsers = true;
      // })
      // .addCase(getAgideskUsers.fulfilled, (state, action) => {
      //   state.isSuccess = true;
      //   state.users = action.payload.results || [];
      //   state.isLoadingUsers = false;
      // })
      // .addCase(getAgideskUsers.rejected, (state) => {
      //   state.isError = true;
      //   state.users = [];
      //   state.isLoadingUsers = false;
      // })
      .addCase(createUser.pending, (state) => {
        state.isCreatingUsers = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          !["undefined", "string"].includes(typeof action.payload)
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Agente criado com sucesso!`,
          });
          const newState = state.users;
          newState.push(action.payload);
          state.users = newState;
        } else {
          if (
            typeof action.payload === "string" &&
            action.payload.includes("duplicate")
          ) {
            customToast({
              type: IToastType.ERROR,
              message: `Opa! Já existe um agente com este e-mail!`,
            });
          } else if (
            typeof action.payload.message === "string" &&
            action.payload.message.toLowerCase().includes("code")
          ) {
            customToast({
              type: IToastType.ERROR,
              message: `Opa! Já existe um contato com este CPF!`,
            });
          } else {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado! Tente novamente!`,
            });
          }
          state.isError = true;
        }
        state.isCreatingUsers = false;
      })
      .addCase(createUser.rejected, (state) => {
        state.isError = true;
        state.isCreatingUsers = false;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isRemoving = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          typeof action.payload !== "undefined" &&
          typeof action.payload?.message !== "string"
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Remoção realizada com sucesso!`,
          });
        } else {
          state.isError = true;
          if (
            typeof action.payload?.message === "string" &&
            action.payload.message.includes(
              "been set as default user on general settings"
            )
          ) {
            customToast({
              type: IToastType.WARNING,
              message: `Agente definido para ações anônimas!`,
            });
          } else {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado!`,
            });
          }
        }
        state.isRemoving = false;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.isError = true;
        state.isRemoving = false;
      })
      .addCase(spamContact.pending, (state) => {
        state.isSpamming = true;
      })
      .addCase(spamContact.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          typeof action.payload !== "undefined" &&
          typeof action.payload?.message !== "string" &&
          action.payload.length > 0 &&
          typeof action.payload[0]._id !== "undefined"
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Ação realizada com sucesso!`,
          });
        } else {
          state.isError = true;
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado!`,
          });
        }
        state.isSpamming = false;
      })
      .addCase(spamContact.rejected, (state) => {
        state.isError = true;
        state.isSpamming = false;
      })
      .addCase(deleteContact.pending, (state) => {
        state.isRemoving = true;
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          typeof action.payload !== "undefined" &&
          typeof action.payload?.message !== "string"
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Remoção realizada com sucesso!`,
          });
        } else {
          state.isError = true;
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado!`,
          });
        }
        state.isRemoving = false;
      })
      .addCase(deleteContact.rejected, (state) => {
        state.isError = true;
        state.isRemoving = false;
      })
      .addCase(createContact.pending, (state) => {
        state.isCreatingContacts = true;
      })
      .addCase(createContact.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          !["undefined", "string"].includes(typeof action.payload)
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Contato criado com sucesso!`,
          });
          const newState = state.contacts;
          newState.push(action.payload);
          state.contacts = newState;
        } else {
          if (
            typeof action.payload === "string" &&
            action.payload.includes("duplicate")
          ) {
            customToast({
              type: IToastType.ERROR,
              message: `Opa! Já existe um contato com este e-mail!`,
            });
          } else if (
            typeof action.payload === "string" &&
            action.payload.toLowerCase().includes("phone")
          ) {
            customToast({
              type: IToastType.ERROR,
              message: `Opa! Já existe um contato com este telefone!`,
            });
          } else if (
            typeof action.payload === "string" &&
            action.payload.toLowerCase().includes("code")
          ) {
            customToast({
              type: IToastType.ERROR,
              message: `Opa! Já existe um contato com este CPF!`,
            });
          } else {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado! Tente novamente!`,
            });
          }
          state.isError = true;
        }
        state.isCreatingContacts = false;
      })
      .addCase(createContact.rejected, (state) => {
        state.isError = true;
        state.isCreatingContacts = false;
      });
  },
});

export default usersSlice.reducer;
export const {
  changeStatusUser,
  changeUser,
  logout,
  reselectAgent,
  reselectContact,
  selectAgents,
  selectContacts,
  selectTotalAgents,
  selectTotalContacts,
  reselectLocalAgent,
  reselectLocalContact,
} = usersSlice.actions;
