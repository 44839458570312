import React from "react";
import ReactInputMask from "react-input-mask";
import BadgeStatus, { BadgeStatusProps } from "./BadgeStatus.component";
import handleMaskLength from "../../utils/handleMaskLength";

export interface BaseIntegrationCardProps {
  variant: "integrations" | "accounts" | "services";
  onClick?: VoidFunction;
}
export interface TemplatesIntegrationCardProps {
  variant: "templates";
  type?: "whatsapp" | "agibot" | "botmaker";
  title: string;
  description: string;
  badge: BadgeStatusProps;
  phone: string;
  onClick?: VoidFunction;
}
export type IntegrationCardProps =
  | BaseIntegrationCardProps
  | TemplatesIntegrationCardProps;

const IntegrationCard = ({
  variant,
  onClick,
  ...props
}: IntegrationCardProps) => {
  if (variant === "templates") {
    const { type, title, description, badge, phone } =
      props as TemplatesIntegrationCardProps;
    return (
      <div
        role="presentation"
        onClick={onClick}
        id="IntegrationCard"
        className="group w-full max-w-[316px] cursor-pointer flex flex-col items-start rounded-2 border border-subtle hover:border-primary bg-default"
      >
        <div id="header" className="flex flex-col p-5 gap-4 items-start">
          {/* eslint-disable-next-line no-nested-ternary */}
          {!type ? (
            <i className="las la-question text-250 text-center" />
          ) : type === "agibot" || type === "botmaker" ? (
            <i className="las la-robot fg-primary text-250 text-center" />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_4306_5270)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.2173 0C5.71102 0 0.435997 5.23423 0.435997 11.6916C0.435997 13.9001 1.05366 15.9657 2.12616 17.7283L0 24L6.52246 21.9277C8.20992 22.853 10.1517 23.3818 12.2173 23.3818C18.725 23.3818 24 18.1475 24 11.6902C24 5.23289 18.725 0 12.2173 0ZM12.2173 21.4229C10.2257 21.4229 8.37006 20.8301 6.82119 19.814L3.05198 21.0117L4.27654 17.3971C3.10177 15.7921 2.40875 13.8213 2.40875 11.6902C2.41009 6.3238 6.80908 1.95749 12.2173 1.95749C17.6256 1.95749 22.0259 6.3238 22.0259 11.6902C22.0259 17.0566 17.6256 21.4229 12.2173 21.4229ZM17.7413 14.3474C17.4452 14.1872 15.9946 13.41 15.7228 13.3005C15.451 13.1911 15.2518 13.1336 15.0405 13.4247C14.8293 13.7158 14.2264 14.3661 14.0447 14.5584C13.8617 14.7506 13.6854 14.7693 13.3894 14.6078C13.0947 14.4475 12.1379 14.0964 11.0264 13.0375C10.1625 12.2136 9.59596 11.2135 9.43179 10.9091C9.26762 10.6033 9.43179 10.4484 9.58654 10.3056C9.72649 10.176 9.90008 9.96773 10.0575 9.79949C10.2136 9.63124 10.2688 9.5084 10.3765 9.31345C10.4841 9.1185 10.441 8.94225 10.3711 8.79003C10.3011 8.63781 9.75475 7.14766 9.52733 6.54011C9.29992 5.9339 9.04558 6.02337 8.8693 6.01669C8.69437 6.01135 8.49386 5.97797 8.29336 5.97129C8.09285 5.96328 7.76451 6.02604 7.47788 6.31445C7.19125 6.60287 6.38654 7.29587 6.33137 8.76466C6.2762 10.2321 7.30025 11.6916 7.44289 11.8958C7.58553 12.1001 9.39815 15.2834 12.4098 16.592C15.4227 17.9005 15.4375 17.5013 15.9906 17.4719C16.5436 17.4425 17.7965 16.8163 18.0737 16.1286C18.3509 15.441 18.3724 14.8441 18.3024 14.7173C18.2325 14.5904 18.0347 14.5076 17.74 14.3474H17.7413Z"
                  fill="#46A540"
                  stroke="none"
                />
              </g>
              <defs>
                <clipPath id="clip0_4306_5270">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}

          <div className="flex flex-col gap-3 self-stretch items-start">
            <h3 className="self-stretch fg-default group-hover:fg-primary leading-trim-both text-edge-cap text-150 font-bold leading-6">
              {title}
            </h3>
            <span className="self-stretch fg-weak leading-trim-both text-edge-cap text-50 leading-5">
              {description}
            </span>
          </div>
        </div>

        <div
          id="footer"
          className="pt-2 px-5 pb-3 flex justify-between items-center self-stretch bg-slate-50 border-slate-200 border-t-0 rounded-b-2"
        >
          <div id="ID" className="flex gap-2 text-100 items-center fg-weak">
            <i className="las la-check-square" />
            <ReactInputMask
              readOnly
              maskPlaceholder={null}
              className="leading-trim-both text-edge-cap text-50 leading-5"
              style={{ background: "transparent", cursor: "pointer" }}
              mask={handleMaskLength({
                value: phone,
              })}
              value={phone}
            />
            {/* <span className="leading-trim-both text-edge-cap text-50 leading-5">
              {phone}
            </span> */}
          </div>
          <BadgeStatus {...badge} />
        </div>
      </div>
    );
  }
  // TODO
  return <div>IntegrationCard</div>;
};

export default IntegrationCard;
