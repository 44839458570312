import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import {
  SettingOfficehours,
  deleteOfficehour,
  reselectOfficehour,
  selectOfficehour,
  updateOfficehour,
} from "../slices/officehours.slice";
import LoadingDots from "../shared/components/LoadingDots.component";
import DividerInfoPages from "../shared/components/DividerInfoPages.component";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";
import RelationModal from "../shared/components/Modals/RelationModal.component";
import { SidebarComponent } from "../shared/components/Sidebars";
import CreateModal from "../shared/components/Modals/CreateModal.component";
import Section from "../shared/components/Section.component";
import SectionElement from "../shared/components/SectionElement.component";
import { getSettings } from "../slices/settings.slice";
import { cellObject } from "../shared/components/Tables/TableBody.component";
import ConfirmationModal from "../shared/components/Modals/ConfirmationModal.component";
import { ITeam } from "../slices/teams.slice";
import useAccessControl from "../hooks/useAccessControl";
import { Permissions } from "../slices/permissiongroups.slice";

const OfficehourPage = ({
  icon,
  title,
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  icon: string;
  title: string;
}) => {
  const { canAccess } = useAccessControl();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoadingOfficehours, selectedOfficehour } = useAppSelector(
    (state) => state.officehours
  );

  const [, setReloadForced] = useState<boolean>(true);
  const [showModalTeams, setShowModalTeams] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteHoursModal, setShowDeleteHoursModal] =
    useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showReactiveModal, setShowReactiveModal] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const [officehoursName, setOfficehoursName] = useState<string>("");
  const [officehoursStartTime, setOfficehoursStartTime] = useState<string>("");
  const [officehoursFinishTime, setOfficehoursFinishTime] =
    useState<string>("");

  const [selectedRow, setSelectedRow] = useState<cellObject[]>([]);

  const deletedItems = useRef<string[]>([]);

  useEffect(() => {
    const getId = () => {
      if (params && typeof params._id !== "undefined") return params._id;
      return location.pathname.split("/").slice(-1)[0];
    };

    (async () => {
      await dispatch(selectOfficehour({ _id: getId() }));
      setReloadForced(false);
      await dispatch(getSettings({}));
      setOfficehoursName("");
      setOfficehoursStartTime("");
      setOfficehoursFinishTime("");
    })();
  }, []);

  const handleAction = async () => {
    try {
      setDisable(true);
      if (selectedOfficehour) {
        const _officehours: SettingOfficehours[] | undefined =
          selectedOfficehour?.officehours;

        const _sendValue = _officehours?.filter(
          (officehour: SettingOfficehours) =>
            !deletedItems.current.includes(officehour?._id || "")
        );
        const _officehour = {
          ...selectedOfficehour,
          _id: selectedOfficehour?._id,
          name: selectedOfficehour?.name,
          active: selectedOfficehour?.active,
          teams: selectedOfficehour?.teams?.map((_t: ITeam) => {
            if (typeof _t === "string") return _t;
            return _t._id;
          }),
          enablelink: selectedOfficehour?.enablelink,
          officehours: _sendValue,
          default: selectedOfficehour?.default,
        };
        await dispatch(updateOfficehour({ _officehour }));
        dispatch(
          reselectOfficehour({
            ...selectedOfficehour,
            officehours: _sendValue,
          })
        );
      }
      setDisable(false);
      setShowDeleteHoursModal(false);
      document
        .querySelectorAll(".dropdown-officehour .clear")
        .forEach((el: any) => el.click());
    } catch (error) {
      // console.log(error);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const goRemove = async () => {
    setDisable(true);
    setShowDeleteModal(false);
    if (selectedOfficehour?._id) {
      const { payload } = await dispatch(
        deleteOfficehour([selectedOfficehour._id])
      );
      setDisable(false);
      if (
        payload !== null &&
        typeof payload !== "undefined" &&
        typeof payload?.message !== "string"
      ) {
        navigate("/settings/officehours");
      }
    }
  };

  const goReactive = async () => {
    setDisable(true);
    setShowReactiveModal(false);
    const _officehour = {
      ...selectedOfficehour,
      teams: selectedOfficehour?.teams?.map((_t: any) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
      active: true,
      deleted: false,
      deleted_by: null,
      deleted_at: "",
    };
    const { payload } = await dispatch(updateOfficehour({ _officehour }));
    setDisable(false);
    if (typeof payload._id !== "undefined") {
      navigate(-1);
    }
  };

  const goSave = async () => {
    setDisable(true);
    const _officehour = {
      ...selectedOfficehour,
      _id: selectedOfficehour?._id,
      name: selectedOfficehour?.name,
      active: selectedOfficehour?.active,
      teams: selectedOfficehour?.teams?.map((_t: ITeam) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
      enablelink: selectedOfficehour?.enablelink,
      officehours: selectedOfficehour?.officehours,
      default: selectedOfficehour?.default,
    };
    const { payload } = await dispatch(updateOfficehour({ _officehour }));
    dispatch(
      reselectOfficehour({
        ...selectedOfficehour,
        updated_at: payload.updated_at,
        updated_by: payload.updated_by,
      })
    );
    setDisable(false);
  };

  const getContent = () => {
    if (location.hash.includes("#teams"))
      return (
        <CreateModal
          title={title}
          icon={icon}
          setShowModal={setShowModalTeams}
          setVisible={setVisible}
        />
      );
    return (
      <CreateModal
        title="Novo horário de atendimento"
        icon="las la-calendar"
        setShowModal={setShowModal}
        setVisible={setVisible}
        setReloadForced={setReloadForced}
        officehoursName={officehoursName}
        setOfficehoursName={setOfficehoursName}
        officehoursStartTime={officehoursStartTime}
        setOfficehoursStartTime={setOfficehoursStartTime}
        officehoursFinishTime={officehoursFinishTime}
        setOfficehoursFinishTime={setOfficehoursFinishTime}
      />
    );
  };

  if (isLoadingOfficehours)
    return (
      <LoadingDots
        className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
        large
      />
    );
  return (
    <div className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-4 sm:px-8">
      <SidebarComponent
        visible={visible}
        setVisible={setVisible}
        setReloadForced={setReloadForced}
        content={getContent()}
      />
      <Sidebar.Pusher>
        <HeaderPageInfo
          created={selectedOfficehour?.created_at}
          updated={selectedOfficehour?.updated_at}
          deleted={selectedOfficehour?.deleted_at}
          title={selectedOfficehour?.name || ""}
          imported={false}
          icon="las la-calendar-check"
        />
        <Section
          icon="las la-info-circle"
          title="Informações"
          description="Edite as informações exibidas"
          component={
            <SectionElement
              instance={selectedOfficehour}
              type="officehour-info"
            />
          }
        />
        <DividerInfoPages />
        <Section
          icon="las la-user-friends"
          title="Equipes que possuem este horário"
          description={`Relacione as equipes desejadas.\n\n<b>Atenção:</b> Cada equipe possui um único horário de atendimento.\nAo selecionar uma equipe que já possui um horário de atendimento, ele será substituído pelo mais recente.`}
          component={
            <SectionElement
              instance={selectedOfficehour}
              type="officehour-teams"
              setShowModal={setShowModalTeams}
              setVisible={setVisible}
            />
          }
        />
        <DividerInfoPages />
        <Section
          icon="las la-calendar"
          title="Horários de atendimento"
          description="Em que momentos as equipes estarão aptas a atender os contatos."
          component={
            <SectionElement
              instance={selectedOfficehour?.officehours || []}
              type="officehours"
              deletedItems={deletedItems}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              setVisible={setVisible}
              setShowModal={setShowModal}
              setShowDeleteModal={setShowDeleteHoursModal}
            />
          }
        />
        <div className="mb-20" />
        <FooterPageInfo
          disabled={
            disable ||
            selectedOfficehour?.deleted ||
            typeof selectedOfficehour?.name === "undefined" ||
            selectedOfficehour?.name?.length < 2
          }
          deleted={selectedOfficehour?.deleted}
          toggleSidebar={toggleSidebar}
          back={goBack}
          title={
            selectedOfficehour?.deleted
              ? "Deseja reativar este horário?"
              : "Deseja remover este horário?"
          }
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          showReactiveModal={showReactiveModal}
          setShowReactiveModal={setShowReactiveModal}
          reactive={canAccess(Permissions.DELETE_OFFICE_HOURS) ? goReactive : undefined}
          remove={canAccess(Permissions.DELETE_OFFICE_HOURS) ? goRemove : undefined}
          save={canAccess(Permissions.UPDATE_OFFICE_HOURS) ? goSave : undefined}
        />
        {/* MODALS */}
        {showModalTeams ? (
          <RelationModal
            title="Relacionar equipes"
            icon="las la-user-friends"
            setShowModal={setShowModalTeams}
          />
        ) : null}
        {showModal ? (
          <RelationModal
            title="Horário de atendimento"
            icon="las la-calendar"
            setShowModal={setShowModal}
            selectedRow={selectedRow[0]}
          />
        ) : null}
        {showDeleteHoursModal ? (
          <ConfirmationModal
            disabled={disable}
            action={handleAction}
            setShowModal={setShowDeleteHoursModal}
            title="Deseja remover os items selecionados?"
          />
        ) : null}
      </Sidebar.Pusher>
    </div>
  );
};

export default OfficehourPage;
