import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux/hooks";
import {
  addToRecents,
  closeChat,
  IChat,
  openChat,
  removeFromRecents,
} from "../../../../slices/chats.slice";
import ListHeader from "./ListHeader.component";
import ChatCard from "../ChatCard.component";
import { resetWaitingMessages } from "../../../../slices/messages.slice";
import { getNotificationsAmount } from "../../../../shared/utils/getChatNotificationsByUser";

const RecentChatsList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.auth);
  const { recentChats, selectedChat } = useAppSelector((state) => state.chats);

  const handleClick = async (
    e: React.MouseEvent<HTMLDivElement>,
    chat: IChat
  ) => {
    e.stopPropagation();
    const users = chat?.users || null;
    if (users) {
      dispatch(removeFromRecents(chat));
      if (selectedChat?._id) {
        dispatch(addToRecents(selectedChat));
        dispatch(closeChat());
      }
      dispatch(resetWaitingMessages());
      dispatch(openChat(chat));
      navigate(`/chat/${chat._id}`);
    }
  };

  return (
    <div
      className={`w-[200px] right-[479px] top-[1px] absolute flex flex-col ${
        !recentChats ? "hidden" : ""
      }`}
    >
      <ListHeader />
      {recentChats?.map((chat) => {
        if (user)
          return (
            <div
              className="overflow-x-hidden flex-1 relative p-[2px]"
              aria-hidden
              key={chat?._id}
              onClick={(e) => {
                if (chat._id === selectedChat?._id) return;
                handleClick(e, chat);
              }}
            >
              <ChatCard
                chat={chat}
                recents
                notifications={getNotificationsAmount(chat, user!)}
              />
              <div className="w-[190px] flex justify-center items-center self-center border-t border-gray-ddd my-1" />
            </div>
          );
        return null;
      })}
    </div>
  );
};

export default memo(RecentChatsList);
