/* eslint-disable react/require-default-props */
import React from "react";

export interface HeaderCardStepProps {
  icon?: string;
  text?: string;
  onClick?: VoidFunction;
  state: "enabled" | "selected";
}

const HeaderCardStep = ({
  onClick,
  icon,
  text,
  state = "enabled",
}: HeaderCardStepProps) => {
  return (
    <div
      role="presentation"
      onClick={onClick}
      id="headerCardStep"
      className={`group relative cursor-pointer flex flex-col w-[130px] py-4 px-5 justify-center items-center gap-3.5 rounded-2 border ${
        state === "enabled"
          ? "border-subtle hover:border-primary bg-default"
          : "border-primary hover:border-[#EA580C] bg-orange-50 hover:bg-orange-100"
      }`}
    >
      {icon !== "ban" && state === "selected" && (
        <i className="hidden las la-times fg-primary absolute group-hover:flex right-[9px] top-2 text-100-to-fix group-hover:items-center group-hover:justify-center" />
      )}
      {icon && (
        <i
          className={`las la-${icon} ${
            state === "selected" ? "fg-primary" : "fg-default"
          } group-hover:fg-primary text-250 flex items-center justify-center`}
        />
      )}
      {text && (
        <span
          className={`self-stretch ${
            state === "selected" ? "fg-primary" : "fg-default"
          } group-hover:fg-primary text-center leading-trim-both text-edge-cap font-text-md`}
        >
          {text}
        </span>
      )}
    </div>
  );
};

export default HeaderCardStep;
