import React, { useState } from "react";
import DefaultModal from "../Modals/Default.modal";
import useTemplates from "../../../hooks/features/useTemplates";
import IntegrationCard from "./IntegrationCard.component";
import { useAppSelector } from "../../../hooks/redux/hooks";
import { capitalizeFirstLetter } from "../../../hooks/useFormatText";

const SendTemplateModal = ({
  setShowModal,
}: {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const { selectedLocalTemplate } = useAppSelector((state) => state.templates);
  const { footer } = useTemplates({
    disable: disabled,
    setDisable: setDisabled,
  });

  const categoryLabel: { [key: string]: string } = {
    marketing: "Marketing",
    auth: "Autenticação",
    utility: "Utilidade",
  };

  return (
    <DefaultModal
      noForm
      header={{ title: "Enviar o template para revisão" }}
      content={
        <div className="w-full flex flex-col items-center justify-center gap-6 py-8">
          <span className="leading-trim-both text-edge-cap text-25 leading-5 text-center fg-default mx-6">
            Este template ficará em análise pela Meta, e a validação pode levar
            em torno de 3 dias úteis. Após enviado, o mesmo não poderá ser mais
            editado.
          </span>
          <IntegrationCard
            badge={{ state: "in-review" }}
            title={
              capitalizeFirstLetter(
                selectedLocalTemplate?.selectedConfig?.displayName?.replace(
                  /_/g,
                  " "
                ) || ""
              ) || "Sem nome"
            }
            type={
              selectedLocalTemplate?.selectedConfig?.externalappType?.toLocaleLowerCase() as any
            }
            variant="templates"
            phone={
              selectedLocalTemplate?.selectedConfig?.externalappNumber?.split(
                "|%@g1%|"
              )?.[1] ?? "-"
            }
            description={
              categoryLabel[selectedLocalTemplate?.selectedCategory as string]
            }
          />
        </div>
      }
      buttons={{
        cancelAction: () => {
          setShowModal(false);
          setDisabled(false);
        },
        submitAction: async () => {
          setShowModal(false);
          await footer.save();
        },
        submitLabel: "Enviar para revisão",
        noIcon: true,
      }}
    />
  );
};

export default SendTemplateModal;
