interface FileInfo {
  name: string;
  mimetype: string;
  extension: string;
  path: string;
  size: number;
}

async function getFileInfoFromURL(url: string) {
  try {
    const response = await fetch(url, { method: "HEAD" });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const contentDisposition = response.headers.get("Content-Disposition");
    const contentType = response.headers.get("Content-Type");
    const contentLength = response.headers.get("Content-Length");

    if (!contentType || !contentLength) {
      throw new Error("Could not retrieve necessary headers.");
    }

    const nameMatch = contentDisposition?.match(/filename="([^"]*)"/);
    const name = nameMatch
      ? nameMatch[1]
      : url.substring(url.lastIndexOf("/") + 1);

    const extensionMatch = name.match(/\.([^.]*)$/);
    const extension = extensionMatch ? extensionMatch[1] : "";

    const fileInfo = {
      name,
      mimetype: contentType,
      extension,
      path: url.substring(0, url.lastIndexOf("/") + 1),
      size: parseInt(contentLength, 10),
    };

    return fileInfo;
  } catch (error) {
    console.error("Error getting file info:", error);
    return null;
  }
}

async function downloadFileFromURL(url: string, filename?: string) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();

    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = filename || url.substring(url.lastIndexOf("/") + 1);
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(a.href);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
}

export { getFileInfoFromURL, downloadFileFromURL }; // Named exports
