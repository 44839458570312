import React from "react";
import Badge, { BadgeProps } from "../Badge.component";

export interface BadgeStatusProps {
  state:
    | "active"
    | "inactive"
    | "update"
    | "active-pending"
    | "active-high"
    | "active-medium"
    | "active-low"
    | "rejected"
    | "paused"
    | "in-review"
    | "disabled"
    | "appeal-requested"
    | "draft";
}

export interface BadgeStatusObject {
  [key: string]: BadgeProps;
}

export const types: BadgeStatusObject = {
  active: {
    label: "Ativo",
    variant: "success",
    style: "soft",
    icon: "las la-check-circle",
  },
  inactive: {
    label: "Inativo",
    variant: "neutral",
    style: "soft",
    icon: "las la-minus-circle",
  },
  update: {
    label: "Atualizar",
    variant: "warning",
    style: "soft",
    icon: "las la-sync",
  },
  rejected: {
    label: "Rejeitado",
    variant: "critical",
    style: "soft",
    icon: "las la-times-circle",
  },
  draft: {
    label: "Rascunho",
    variant: "warning",
    style: "soft",
    icon: "las la-edit",
  },
  "active-pending": {
    label: "Ativo - Qualidade pendente",
    variant: "success",
    style: "soft",
    icon: "las la-check-circle",
  },
  "active-high": {
    label: "Ativo - Qualidade alta",
    variant: "success",
    style: "solid",
    icon: "las la-check-circle",
  },
  "active-medium": {
    label: "Ativo - Qualidade média",
    variant: "success",
    style: "solid",
    icon: "las la-check-circle",
  },
  "active-low": {
    label: "Ativo - Qualidade baixa",
    variant: "success",
    style: "soft",
    icon: "las la-check-circle",
  },
  paused: {
    label: "Pausado",
    variant: "neutral",
    style: "soft",
    icon: "las la-pause-circle",
  },
  "in-review": {
    label: "Em análise",
    variant: "warning",
    style: "soft",
    icon: "las la-circle",
  },
  disabled: {
    label: "Desabilitado",
    variant: "neutral",
    style: "soft",
    icon: "las la-minus-circle",
  },
  "appeal-requested": {
    label: "Em apelação",
    variant: "warning",
    style: "soft",
    icon: "las la-exclamation-circle",
  },
};

const BadgeStatus = ({ state }: BadgeStatusProps) => {
  return <Badge {...types[state]} />;
};

export default BadgeStatus;
