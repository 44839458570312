/* eslint-disable react/require-default-props */
import React from "react";
import { twMerge } from "tailwind-merge";

export interface ActionLinkProps {
  label: string;
  variant: "primary" | "default" | "subtle" | "link";
  state: "default" | "disabled";
  size?: "default" | "medium";
  leadingIcon?: string;
  trailingIcon?: string;
}

const ActionLink = ({
  label,
  variant,
  state,
  size,
  leadingIcon,
  trailingIcon,
}: ActionLinkProps) => {
  return (
    <div
      id="ActionLink"
      title={label}
      className={twMerge(
        "inline-flex items-center justify-center gap-1 w-fit",
        "leading-trim-both text-edge-cap leading-6",
        variant === "primary" &&
          `${
            state === "disabled"
              ? "fg-primary--disabled"
              : "cursor-pointer fg-primary hover:fg-primary--hover"
          }`,
        variant === "default" &&
          `${
            state === "disabled"
              ? "fg-weak"
              : "cursor-pointer fg-default hover:fg-primary"
          }`,
        variant === "subtle" &&
          `${
            state === "disabled"
              ? "fg-weaker"
              : "cursor-pointer fg-weak hover:fg-primary"
          }`,
        variant === "link" &&
          `${
            state === "disabled"
              ? "text-sky-400"
              : "cursor-pointer text-sky-600 hover:text-sky-700"
          }`
      )}
    >
      {leadingIcon && (
        <i
          className={twMerge(
            leadingIcon,
            size === "medium" ? "text-100" : "text-50"
          )}
        />
      )}
      <span
        className={`${
          size === "medium" ? "text-100" : "text-50"
        } text-center overflow-ellipsis line-clamp-1 leading-trim-both text-edge-cap`}
      >
        {label}
      </span>
      {trailingIcon && (
        <i
          className={twMerge(
            trailingIcon,
            size === "medium" ? "text-100" : "text-50"
          )}
        />
      )}
    </div>
  );
};

export default ActionLink;
