import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { reselectLocalTemplate } from "../../slices/templates.slice";
import customToast from "../utils/customToast";
import { IToastType } from "../models/types/Toast.type";

const RtfEditor = ({
  id,
  initialValue,
  disabled,
  height,
  externalCss,
  label,
  onChange,
  templates,
  vars,
  setVars,
}: {
  id: string;
  initialValue?: string;
  height?: string;
  externalCss?: string;
  label?: string;
  disabled?: boolean;
  onChange?: any;
  templates?: boolean;
  vars?: { name: string; example: string }[];
  setVars?: React.Dispatch<
    React.SetStateAction<
      { name: string; oldName?: string; example: string; id: number }[]
    >
  >;
}) => {
  const location = useLocation();
  const charLimit = 1024;
  const { selectedLocalTemplate } = useAppSelector((state) => state.templates);
  const dispatch = useAppDispatch();

  const toolbar = `${
    location.pathname.includes("template") || location.pathname.includes("user")
      ? `${
          templates
            ? // ? "charcount | formatselect | emoticons bold italic strikethrough | removeformat | addvar"
              "formatselect | emoticons bold italic strikethrough | removeformat | addvar"
            : "formatselect | emoticons bold italic strikethrough | removeformat | agitalksVars"
        } `
      : "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat | emoticons | charcount"
  }`;

  const addVariable = (editor: any) => {
    if (setVars) {
      setVars((prevVars) => {
        const newVars = [
          ...prevVars,
          {
            name: "",
            oldName: `{{${prevVars.length + 1}}}`,
            example: "",
            id: prevVars.length || 0,
          },
        ];
        editor.insertContent(`{{${newVars.length}}}`);
        dispatch(
          reselectLocalTemplate({
            ...selectedLocalTemplate,
            selectedContent: {
              ...selectedLocalTemplate?.selectedContent,
              vars: newVars,
            },
          })
        );
        return newVars;
      });
    }
  };

  return (
    <div
      id="rtf-editor-area"
      className={`${externalCss} ${
        !templates ? "flex-1 pb-5" : "flex-1 w-full"
      }`}
    >
      {label && (
        <div className="field required">
          <label>{label}</label>
        </div>
      )}
      <div className="-my-[8px]">
        <Editor
          id={`${id}-rtf-editor`}
          tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
          value={initialValue}
          disabled={disabled}
          init={{
            setup: (editor) => {
              if (templates) {
                editor.on("keyup change", () => {
                  const content = editor.getContent({ format: "text" });
                  if (content.length > charLimit) {
                    editor.setContent(content.substring(0, charLimit));
                    customToast({
                      type: IToastType.WARNING,
                      message:
                        "O texto atingiu o limite máximo de caracteres (1024).",
                    });
                  }
                });
              }
              if (templates) {
                editor.ui.registry.addButton("addvar", {
                  text: "{  } Adicionar variável",
                  onAction: () => addVariable(editor),
                });
              } else {
                editor.ui.registry.addMenuButton("agitalksVars", {
                  text: "Variáveis do sistema",
                  fetch: (callback) => {
                    const items: {
                      type: "menuitem";
                      text: string;
                      onAction(): void;
                    }[] = [
                      {
                        type: "menuitem",
                        text: "Nome do contato {{contact.name}}",
                        onAction: () =>
                          editor.insertContent("{{contact.name}}"),
                      },
                      {
                        type: "menuitem",
                        text: "Nome do cliente {{contact.customers}}",
                        onAction: () =>
                          editor.insertContent("{{contact.customers}}"),
                      },
                      {
                        type: "menuitem",
                        text: "Telefone do contato {{contact.phone}}",
                        onAction: () =>
                          editor.insertContent("{{contact.phone}}"),
                      },
                      {
                        type: "menuitem",
                        text: "Nome do agente {{user.name}}",
                        onAction: () => editor.insertContent("{{user.name}}"),
                      },
                      {
                        type: "menuitem",
                        text: "E-mail do agente {{user.email}}",
                        onAction: () => editor.insertContent("{{user.email}}"),
                      },
                      {
                        type: "menuitem",
                        text: "Equipe {{chat.team.name}}",
                        onAction: () =>
                          editor.insertContent("{{chat.team.name}}"),
                      },
                      {
                        type: "menuitem",
                        text: "Empresa {{company.name}}",
                        onAction: () =>
                          editor.insertContent("{{company.name}}"),
                      },
                    ];
                    callback(items);
                  },
                });
              }
            },
            promotion: false,
            branding: false,
            height,
            language: "pt_BR",
            language_url: "/editor/langs/pt_BR.js",
            menubar: false,
            toolbar_location: "bottom",
            toolbar_class: "custom-toolbar",
            statusbar: false,
            placeholder: "Digite seu texto aqui...",
            menu: {
              file: {
                title: "File",
                items: "newdocument restoredraft print | preview ",
              },
              edit: {
                title: "Edit",
                items:
                  "undo redo | cut copy paste pastetext | selectall | searchreplace",
              },
              view: {
                title: "View",
                items:
                  "code | visualaid visualchars visualblocks | spellchecker | preview fullscreen",
              },
              insert: {
                title: "Insert",
                items:
                  "image link media inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime",
              },
              format: {
                title: "Format",
                items:
                  "bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat",
              },
              tools: {
                title: "Tools",
                items: "spellchecker spellcheckerlanguage | code wordcount",
              },
              table: {
                title: "Table",
                items: "inserttable | cell row column | tableprops deletetable",
              },
              help: { title: "Help", items: "help" },
            },
            plugins: [
              "preview",
              "searchreplace",
              "autolink",
              "directionality",
              "visualblocks",
              "visualchars",
              "fullscreen",
              "image",
              "link",
              "table",
              "charmap",
              "pagebreak",
              "nonbreaking",
              "anchor",
              "insertdatetime",
              "advlist",
              "lists",
              "emoticons",
            ],
            toolbar,
            image_advtab: true,
          }}
          onEditorChange={onChange}
          onBlur={onChange}
          onKeyUp={onChange}
          // onChange={onChange}
        />
      </div>
    </div>
  );
};

RtfEditor.defaultProps = {
  externalCss: "",
  disabled: false,
  templates: false,
  initialValue: undefined,
  vars: undefined,
  setVars: undefined,
  label: "",
  onChange: () => null,
  height: "450px",
};

export default RtfEditor;
