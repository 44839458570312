/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import SearchBox from "../../../shared/components/SearchBox.component";
import {
  addToRecents,
  ChatStatus,
  ChatType,
  closeChat,
  IChat,
  IFilterChat,
  IFilterProps,
  openChat,
  QuickFiltersID,
  removeFromRecents,
  selectBacklogChats,
  selectMineChats,
  selectSearchChats,
  selectTeamsChats,
  selectTotalBacklogChats,
  selectTotalMineChats,
  selectTotalSearchChats,
  selectTotalTeamsChats,
} from "../../../slices/chats.slice";
import { UserType } from "../../../slices/users.slice";
import ChatCard from "./ChatCard.component";
import LoadingDots from "../../../shared/components/LoadingDots.component";
import useOnScreen from "../../../hooks/useOnScreen";
import NotificationBadge from "./NotificationBadge.component";
import LinkButton from "../../../shared/components/Buttons/LinkButton.component";
import { chatsService, messagesService } from "../../../services";
import removeDuplicates from "../../../shared/utils/removeDuplicates";
import { updateTenant } from "../../../services/instances";
import {
  disableChatCard,
  IMessage,
  resetWaitingMessages,
} from "../../../slices/messages.slice";
import useLGPD from "../../../hooks/useLGPD";
import {
  IChatSearchFilters,
  IFiltersDropdownInstances,
} from "../../../shared/components/@types/searchBarFilter.types";
import { InboxProps } from "./@types/Inbox.types";
import TipTagsComponent from "./TipTags.component";
import ChatSortComponent from "./ChatSort.component";
import MessageCardListComponent from "./MessageCardList.component";
import { getNotificationsAmount } from "../../../shared/utils/getChatNotificationsByUser";

export const countSelectedFilters = ({
  searchFiltersSelected,
}: {
  searchFiltersSelected: any;
}) => {
  let selectedFilters = 0;
  if (typeof searchFiltersSelected !== "undefined") {
    for (const instance of Object.keys(searchFiltersSelected)) {
      const instanceValues =
        searchFiltersSelected[instance as keyof IChatSearchFilters];
      if (
        typeof instanceValues !== "undefined" &&
        (instanceValues !== "" ||
          (Array.isArray(instanceValues) && instanceValues.length > 0))
      ) {
        selectedFilters +=
          typeof instanceValues === "string" ||
          typeof instanceValues === "boolean"
            ? instanceValues
              ? 1
              : 0
            : instanceValues.length;
      }
    }
  }
  return selectedFilters;
};

const Inbox = ({
  clearFilters,
  searchFiltersSelected,
  searchFiltersOptions,
  showFilter,
  setShowFilter,
  widgetType,
  setShow,
  toggleList,
  skipSearch,
  setSkipSearch,
  setToggleList,
  setShowHistory,
}: InboxProps) => {
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [skipBacklog, setSkipBacklog] = useState<number>(0);
  const [skipMine, setSkipMine] = useState<number>(0);
  const [skipTeams, setSkipTeams] = useState<number>(0);
  const [skipMessages, setSkipMessages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMoreBacklog, setHasMoreBacklog] = useState(false);
  const [hasMoreMine, setHasMoreMine] = useState(false);
  const [hasMoreTeams, setHasMoreTeams] = useState(false);
  const [hasMoreSearch, setHasMoreSearch] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const [renderDataBacklog, setRenderDataBacklog] = useState<IChat[]>([]);
  const [renderDataMine, setRenderDataMine] = useState<IChat[]>([]);
  const [renderDataTeams, setRenderDataTeams] = useState<IChat[]>([]);
  const [renderDataSearch, setRenderDataSearch] = useState<IChat[]>([]);
  const [renderMessagesSearch, setRenderMessagesSearch] = useState<IMessage[]>(
    []
  );

  const { user, isWidgetAgent, isWidgetEmbedded, tenantID } = useAppSelector(
    (state) => state.auth
  );
  const [selectedTips, setSelectedTips] = useState<string[]>(
    user?.chatfilters?.tips ?? []
  );

  const [selectedSort, setSelectedSort] = useState<number>(
    toggleList === "backlog" ? 1 : -1
  );

  const scrollRef = useRef<null | HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { chatdisable } = useAppSelector((state) => state.messages);
  const { showCode } = useLGPD();
  const {
    selectedChat,
    backlogChats,
    totalBacklogChats,
    mineChats,
    totalMineChats,
    teamsChats,
    totalTeamsChats,
    searchChats,
    totalSearchChats,
  } = useAppSelector((state) => state.chats);

  const getHasMore = () => {
    if (toggleList === "backlog") return hasMoreBacklog;
    if (toggleList === "mine") return hasMoreMine;
    if (toggleList === "search") return hasMoreSearch;
    return hasMoreTeams;
  };

  const getSetSkip = () => {
    if (toggleList === "backlog") return setSkipBacklog;
    if (toggleList === "mine") return setSkipMine;
    if (toggleList === "search") return setSkipSearch;
    return setSkipTeams;
  };

  const [listRef] = useOnScreen({
    hasMore: getHasMore(),
    isLoading,
    setSkip: getSetSkip(),
  });

  const [messageListRef] = useOnScreen({
    hasMore: hasMoreMessages,
    isLoading,
    setSkip: setSkipMessages,
  });

  const handleOpenChatClick = async (chat: IChat) => {
    const users = chat?.users || null;
    if (users) {
      dispatch(removeFromRecents(chat));
      if (selectedChat?._id && widgetType !== UserType.CONTACT) {
        dispatch(addToRecents(selectedChat));
        dispatch(closeChat());
      }
      dispatch(disableChatCard({ disable: true, chat: chat?._id }));
      navigate(`/chat/${chat._id}`);
      if (setShow) {
        setShow(false);
      }
      dispatch(resetWaitingMessages());
      dispatch(openChat(chat));
    }
  };

  const getFilter = () => {
    const _name = searchKeyword.length > 0 ? searchKeyword : undefined;
    let _period = "";
    let _type: string[] = [];

    const _groupchat =
      typeof searchFiltersSelected?.groupchat !== "undefined" &&
      searchFiltersSelected.groupchat.length > 0
        ? searchFiltersSelected.groupchat[0]
        : false;
    const _campaignchat =
      typeof searchFiltersSelected?.campaignchat !== "undefined" &&
      searchFiltersSelected.campaignchat.length > 0
        ? searchFiltersSelected.campaignchat[0]
        : false;
    const _externalappitem =
      typeof searchFiltersSelected?.externalappitem !== "undefined" &&
      searchFiltersSelected.externalappitem.length > 0
        ? searchFiltersSelected.externalappitem[0]
        : false;
    const _readFilter =
      typeof searchFiltersSelected?.read !== "undefined" &&
      searchFiltersSelected.read.length > 0
        ? searchFiltersSelected.read[0]
        : false;
    if (toggleList === "search") {
      return {
        ...searchFiltersSelected,
        content: _name,
        groupchat: _groupchat,
        externalappitem: _externalappitem,
        read: _readFilter,
        campaignchat: _campaignchat,
      } as unknown as IFilterProps;
    }
    let _status = [];
    let _read;
    if (toggleList === "backlog") _status.push(ChatStatus.WAIT);
    if (toggleList !== "backlog") _status.push(ChatStatus.ACTIVE);
    if (toggleList === "mine") _status.push(ChatStatus.WAITREPLY);

    if (toggleList === "backlog" && selectedTips.length > 0) {
      selectedTips.forEach((_tip) => {
        if (_tip === "NOTREAD") {
          _read = "false";
        } else {
          _period = selectedTips.filter((_t) =>
            [
              QuickFiltersID.TODAY,
              QuickFiltersID.YESTERDAY,
              QuickFiltersID.THISWEEK,
            ].includes(_t as QuickFiltersID)
          )[0];
        }
      });
    }

    if (toggleList === "mine" && selectedTips.length > 0) {
      selectedTips.forEach((_tip) => {
        if (_tip === "NOTREAD") {
          _read = "false";
        }
        if (_tip === "TRANSFERRED") {
          _status = ["TRANSFERRED"];
        } else if (_tip === ChatType.INTERNAL) {
          _type.push(ChatType.INTERNAL);
        } else if (_tip === ChatType.EXTERNAL) {
          _type.push(ChatType.EXTERNAL);
          _type.push(ChatType.BOTMAKER);
          _type.push(ChatType.WHATSAPP);
          _type.push("WIDGET");
        }
      });
      // if (!selectedTips.includes("TRANSFERRED")) {
      _status.push(ChatStatus.ACTIVE);
      _status.push(ChatStatus.WAITREPLY);
      // }
    }

    if (toggleList === "teams" && selectedTips.length > 0) {
      selectedTips.forEach((_tip) => {
        if (_tip === "NOTREAD") {
          _read = "false";
        } else if (_tip === "today-teams") {
          _period = QuickFiltersID.TODAY;
        } else if (_tip === ChatStatus.ACTIVE) {
          _status.push(ChatStatus.ACTIVE);
        } else if (_tip === ChatStatus.FINISH) {
          _status.push(ChatStatus.FINISH);
        }
      });
    }

    _status = removeDuplicates(_status);
    _type = removeDuplicates(_type);

    return {
      name: _name,
      status: _status,
      period: _period || undefined,
      type: _type.length > 0 ? _type : undefined,
      grouptype: toggleList === "mine" ? "me" : toggleList,
      read: _read,
    };
  };

  const conditions = {
    backlog: () => toggleList === "backlog" || searchKeyword.length > 0,
    mine: () => toggleList === "mine" || searchKeyword.length > 0,
    teams: () => toggleList === "teams" || searchKeyword.length > 0,
    search: () => toggleList === "search" || searchKeyword.length > 0,
  };

  const formatFilters = () => {
    const currentFilters = { ...searchFiltersSelected };

    const filterProps = {
      groupchat:
        typeof currentFilters?.groupchat !== "undefined" &&
        currentFilters.groupchat.length > 0
          ? currentFilters.groupchat[0]
          : undefined,
      content:
        typeof searchKeyword !== "undefined" && searchKeyword.length > 0
          ? searchKeyword
          : typeof currentFilters.content !== "undefined" &&
            currentFilters.content.length > 0
          ? currentFilters.content[0]
          : undefined,
      users:
        typeof currentFilters.users !== "undefined" &&
        currentFilters.users.length > 0
          ? currentFilters.users
          : undefined,
      team:
        typeof currentFilters.teams !== "undefined" &&
        currentFilters.teams.length > 0
          ? currentFilters.teams
          : undefined,
      customer:
        typeof currentFilters.customers !== "undefined" &&
        currentFilters.customers.length > 0
          ? currentFilters.customers
          : undefined,
      status:
        typeof currentFilters.status !== "undefined" &&
        currentFilters.status.length > 0
          ? currentFilters.status
          : undefined,
      type:
        typeof currentFilters.types !== "undefined" &&
        currentFilters.types.length > 0
          ? currentFilters.types
          : undefined,
      period:
        typeof currentFilters.period !== "undefined" &&
        // Array.isArray(currentFilters.period) &&
        currentFilters.period !== null
          ? currentFilters.period
          : undefined,
      externalapp:
        typeof currentFilters.externalapps !== "undefined" &&
        currentFilters.externalapps.length > 0
          ? currentFilters.externalapps
          : undefined,
      contacttags:
        typeof currentFilters.contacttags !== "undefined" &&
        currentFilters.contacttags.length > 0
          ? currentFilters.contacttags
          : undefined,
      contacts:
        typeof currentFilters.contacts !== "undefined" &&
        currentFilters.contacts.length > 0
          ? currentFilters.contacts
          : undefined,
      tags:
        typeof currentFilters.tags !== "undefined" &&
        currentFilters.tags.length > 0
          ? currentFilters.tags
          : undefined,
      campaigns:
        typeof currentFilters.campaigns !== "undefined" &&
        currentFilters.campaigns.length > 0
          ? currentFilters.campaigns
          : undefined,
      campaignchat:
        typeof currentFilters?.campaignchat !== "undefined" &&
        currentFilters.campaignchat.length > 0
          ? currentFilters.campaignchat[0]
          : undefined,
      externalappitem:
        typeof currentFilters?.externalappitem !== "undefined" &&
        currentFilters.externalappitem.length > 0
          ? currentFilters.externalappitem[0]
          : undefined,
      read:
        typeof currentFilters?.read !== "undefined" &&
        currentFilters.read.length > 0
          ? currentFilters.read[0]
          : undefined,
    } as IFilterProps;

    const _transferred =
      typeof currentFilters.status !== "undefined" &&
      currentFilters.status.length > 0 &&
      typeof currentFilters.status !== "undefined" &&
      currentFilters.status.indexOf("TRANSFERRED") > 0;

    if (_transferred) {
      filterProps.transferred = _transferred;
    }

    const _types = filterProps.type;
    const _widgetType = "WIDGET" as ChatType;

    if (typeof _types !== "undefined") {
      if (
        _types.includes(ChatType.EXTERNAL) &&
        !(
          _types.includes(ChatType.BOTMAKER) ||
          _types.includes(ChatType.WHATSAPP) ||
          _types.includes(_widgetType)
        )
      ) {
        _types.push(ChatType.BOTMAKER, ChatType.WHATSAPP, _widgetType);
      }

      if (
        _types.includes(_widgetType) &&
        !(
          _types.includes(ChatType.BOTMAKER) ||
          _types.includes(ChatType.WHATSAPP)
        )
      ) {
        _types.push(ChatType.EXTERNAL);
      }

      filterProps.type = _types as string[];
    }

    const filter = filterProps as IFilterChat;

    Object.keys(filter).forEach((key) => {
      const currentValue = JSON.stringify(filter[key as keyof IFilterChat]);
      if (
        currentValue === "[]" ||
        currentValue === undefined ||
        currentValue === ""
      ) {
        delete filter[key as keyof IFilterChat];
      }
    });

    return filter;
  };

  useEffect(() => {
    // setSearchKeyword("");
    setShowFilter(false);
  }, [selectedChat?._id]);

  useEffect(() => {
    // setSelectedSort(toggleList === "backlog" ? 1 : -1);
    setSearchKeyword("");
    setSelectedTips(user?.chatfilters?.tips ?? []);
    setShowFilter(false);
    setRenderMessagesSearch([]);
  }, [toggleList]);

  useEffect(() => {
    if (totalBacklogChats > 10) {
      setSkipBacklog(0);
      setHasMoreBacklog(true);
    }
    if (totalMineChats > 10) {
      setSkipMine(0);
      setHasMoreMine(true);
    }
    if (totalTeamsChats > 10) {
      setSkipTeams(0);
      setHasMoreTeams(true);
    }
    if (totalSearchChats > 10) {
      setSkipSearch(0);
      setHasMoreSearch(true);
    }
  }, [selectedSort, selectedTips]);

  useEffect(() => {
    if (toggleList === "backlog") {
      if (skipBacklog + 10 >= totalBacklogChats) {
        setHasMoreBacklog(false);
      } else {
        setHasMoreBacklog(true);
      }
    }
    const _backlog = backlogChats;
    if (typeof _backlog !== "undefined" && _backlog.length > 0) {
      setRenderDataBacklog(
        [..._backlog]?.sort((a: IChat, b: IChat) =>
          (b?.created_at || "") > (a?.created_at || "")
            ? -1
            : (a?.created_at || "") > (b?.created_at || "")
            ? 1
            : 0
        )
      );
    } else {
      setRenderDataBacklog([]);
      setIsLoading(false);
    }
  }, [backlogChats]);

  useEffect(() => {
    if (toggleList === "mine") {
      if (skipMine + 10 >= totalMineChats) {
        setHasMoreMine(false);
      } else {
        setHasMoreMine(true);
      }
    }
    const _mine = mineChats;
    if (typeof _mine !== "undefined" && _mine.length > 0) {
      setRenderDataMine(_mine);
    } else {
      setRenderDataMine([]);
      setIsLoading(false);
    }
  }, [mineChats]);

  useEffect(() => {
    if (renderDataTeams.length > 0 && toggleList === "teams") {
      if (skipTeams + 10 >= totalTeamsChats) {
        setHasMoreTeams(false);
      } else {
        setHasMoreTeams(true);
      }
    }
    const _teams = teamsChats;
    if (typeof _teams !== "undefined" && _teams.length > 0) {
      setRenderDataTeams(_teams);
    } else {
      setRenderDataTeams([]);
      setIsLoading(false);
    }
  }, [teamsChats]);

  const getSort = (chats: IChat[], backlog?: boolean) => {
    if (backlog && selectedSort === 1) {
      return chats
        ?.filter((_chat: IChat) => _chat?.lastmessage)
        ?.sort((a: IChat, b: IChat) =>
          (b?.created_at || "") > (a?.created_at || "")
            ? -1
            : (a?.created_at || "") > (b?.created_at || "")
            ? 1
            : 0
        );
    }
    if (backlog && selectedSort === -1) {
      return chats
        ?.filter((_chat: IChat) => _chat?.lastmessage)
        ?.sort((a: IChat, b: IChat) =>
          (b?.created_at || "") < (a?.created_at || "")
            ? -1
            : (a?.created_at || "") < (b?.created_at || "")
            ? 1
            : 0
        );
    }

    if (selectedSort === -1) {
      return chats
        ?.filter((_chat: IChat) => _chat?.lastmessage)
        ?.sort((a: IChat, b: IChat) =>
          (b?.lastmessage?.created_at || b?.updated_at || "") >
          (a?.lastmessage?.created_at || a?.updated_at || "")
            ? 1
            : (a?.lastmessage?.created_at || a?.updated_at || "") >
              (b?.lastmessage?.created_at || b?.updated_at || "")
            ? -1
            : 0
        );
    }
    return chats
      ?.filter((_chat: IChat) => _chat?.lastmessage)
      ?.sort((a: IChat, b: IChat) =>
        (b?.lastmessage?.created_at || b?.updated_at || "") <
        (a?.lastmessage?.created_at || a?.updated_at || "")
          ? 1
          : (a?.lastmessage?.created_at || a?.updated_at || "") <
            (b?.lastmessage?.created_at || b?.updated_at || "")
          ? -1
          : 0
      );
  };

  useEffect(() => {
    if (renderDataSearch.length > 0 && toggleList === "search") {
      if (skipSearch + 10 >= totalSearchChats) {
        setHasMoreSearch(false);
      } else {
        setHasMoreSearch(true);
      }
    }
    const _search = searchChats;
    if (typeof _search !== "undefined" && _search.length > 0) {
      setRenderDataSearch(_search);
    } else {
      setRenderDataSearch([]);
      const filters = formatFilters();
      (async () => {
        const payload = await chatsService.advancedSearch({
          skip: skipSearch,
          limit: 10,
          filter: filters,
          code: showCode,
          sort: selectedSort,
        });
        let _results: IChat[] = [];
        if (
          typeof payload?.results !== "undefined" &&
          payload?.results?.length > 0
        ) {
          _results = getSort(payload?.results as IChat[]);
          setRenderDataSearch(_results);
          dispatch(selectSearchChats(_results));
          if (typeof payload?.count !== "undefined") {
            dispatch(selectTotalSearchChats(payload?.count));
          }
        }
      })();
      setIsLoading(false);
    }
  }, [searchChats]);

  useEffect(() => {
    (async () => {
      const filters = formatFilters();
      if (toggleList === "search" && Object.keys(filters).length === 0) {
        setToggleList("mine");
        return false;
      }
      if (toggleList === "search" && Object.keys(filters).length > 0) {
        if (
          (isWidgetAgent || isWidgetEmbedded) &&
          typeof tenantID !== "undefined" &&
          tenantID.length > 0
        ) {
          updateTenant(tenantID, widgetType);
        }
        const payload = await chatsService.advancedSearch({
          skip: skipSearch,
          limit: 10,
          filter: filters,
          code: showCode,
          sort: selectedSort,
        });
        let _results: IChat[] = [];
        if (
          typeof payload?.results !== "undefined" &&
          payload?.results?.length > 0
        ) {
          _results = getSort(payload?.results as IChat[]);
        }
        setIsLoading(false);
        if (skipSearch + 10 >= payload?.count) {
          setHasMoreSearch(false);
        } else {
          setHasMoreSearch(true);
        }
        if (skipSearch > 0) {
          setRenderDataSearch(renderDataSearch.concat(_results));
          dispatch(selectSearchChats(renderDataSearch.concat(_results)));
        } else {
          setRenderDataSearch(_results);
          dispatch(selectSearchChats(_results));
        }
        if (typeof payload?.count !== "undefined") {
          dispatch(selectTotalSearchChats(payload?.count));
        }
      }
    })();
  }, [
    searchFiltersSelected,
    skipSearch,
    searchKeyword,
    selectedSort,
    selectedTips,
  ]);

  useEffect(() => {
    (async () => {
      if (conditions.backlog() && toggleList === "backlog") {
        if (
          (isWidgetAgent || isWidgetEmbedded) &&
          typeof tenantID !== "undefined" &&
          tenantID.length > 0
        ) {
          updateTenant(tenantID, widgetType);
        }
        const payload = await chatsService.search({
          skip: skipBacklog,
          limit: 10,
          filter: getFilter(),
          code: showCode,
          sort: selectedSort,
        });
        let _results: IChat[] = [];

        if (
          typeof payload?.results !== "undefined" &&
          payload?.results?.length > 0
        ) {
          _results = payload.results.map((chat: IChat) => {
            if (!chat.notifications) {
              chat.notifications = [];
            }
            const userNotificationIndex = chat.notifications.findIndex(
              (notification) => notification?.user === user?._id
            );
            if (userNotificationIndex !== -1) {
              chat.notifications[userNotificationIndex].amount = 1;
            } else {
              chat.notifications.push({ user: user?._id as string, amount: 1 });
            }
            return chat;
          });
          _results = getSort(_results as IChat[], true);
        }
        setIsLoading(false);
        if (skipBacklog + 10 >= payload?.count) {
          setHasMoreBacklog(false);
        } else {
          setHasMoreBacklog(true);
        }
        if (skipBacklog > 0) {
          setRenderDataBacklog(renderDataBacklog.concat(_results));
          dispatch(selectBacklogChats(renderDataBacklog.concat(_results)));
        } else {
          setRenderDataBacklog(_results);
          dispatch(selectBacklogChats(_results));
        }
        if (typeof payload?.count !== "undefined") {
          dispatch(selectTotalBacklogChats(payload?.count));
        }
      }
    })();
  }, [skipBacklog, searchKeyword, selectedSort, selectedTips, toggleList]);

  function sortMessagesByDate(messages: IMessage[]) {
    const sortedMessages =
      selectedSort === -1
        ? messages.sort((a: any, b: any) => {
            return a.created_at?.localeCompare(b.created_at ?? "");
          })
        : messages;

    return sortedMessages;
  }

  useEffect(() => {
    (async () => {
      if (searchKeyword.length > 0) {
        const response = await messagesService.content({
          skip: skipMessages,
          limit: 10,
          message: searchKeyword,
        });

        let _results: IMessage[] = [];

        if (
          typeof response?.results !== "undefined" &&
          response?.results?.length > 0
        ) {
          _results = sortMessagesByDate(response?.results as IMessage[]);
        }

        setIsLoading(false);
        if (skipMessages + 10 >= response?.count) {
          setHasMoreMessages(false);
        } else {
          setHasMoreMessages(true);
        }
        if (skipMessages > 0) {
          setRenderMessagesSearch(renderMessagesSearch.concat(_results));
        } else {
          setRenderMessagesSearch(_results);
        }
      }
    })();
  }, [searchKeyword, skipMessages, selectedSort]);

  useEffect(() => {
    (async () => {
      if (conditions.mine() && toggleList === "mine") {
        if (
          (isWidgetAgent || isWidgetEmbedded) &&
          typeof tenantID !== "undefined" &&
          tenantID.length > 0
        ) {
          updateTenant(tenantID, widgetType);
        }

        const payload = await chatsService.search({
          skip: skipMine,
          limit: 10,
          filter: getFilter(),
          code: showCode,
          sort: selectedSort,
        });
        let _results: IChat[] = [];
        if (
          typeof payload?.results !== "undefined" &&
          payload?.results?.length > 0
        ) {
          _results = payload.results.map((chat: IChat) =>
            chat.notifications?.length &&
            !chat.notifications?.some(
              (notification) => notification?.user === user?._id
            )
              ? {
                  ...chat,
                  notifications: [
                    ...chat.notifications,
                    {
                      user: user?._id,
                      amount: 1,
                    },
                  ],
                }
              : chat
          );
          _results = getSort(payload?.results as IChat[]);
        }
        setIsLoading(false);
        if (skipMine + 10 >= payload?.count) {
          setHasMoreMine(false);
        } else {
          setHasMoreMine(true);
        }
        if (skipMine > 0) {
          setRenderDataMine(renderDataMine.concat(_results));
          dispatch(selectMineChats(renderDataMine.concat(_results)));
        } else {
          setRenderDataMine(_results);
          dispatch(selectMineChats(_results));
        }
        if (typeof payload?.count !== "undefined") {
          dispatch(selectTotalMineChats(payload?.count));
        }
      }
    })();
  }, [skipMine, searchKeyword, selectedSort, selectedTips, toggleList]);

  useEffect(() => {
    (async () => {
      if (conditions.teams() && toggleList === "teams") {
        const payload = await chatsService.search({
          skip: skipTeams,
          limit: 10,
          filter: getFilter(),
          code: showCode,
          sort: selectedSort,
        });
        let _results: IChat[] = [];
        if (
          typeof payload.results !== "undefined" &&
          payload.results.length > 0
        ) {
          _results = getSort(payload?.results as IChat[]);
        }
        setIsLoading(false);
        if (skipTeams + 10 >= payload?.count) {
          setHasMoreTeams(false);
        } else {
          setHasMoreTeams(true);
        }
        if (skipTeams > 0) {
          setRenderDataTeams(renderDataTeams.concat(_results));
          dispatch(selectTeamsChats(renderDataTeams.concat(_results)));
        } else {
          setRenderDataTeams(_results);
          dispatch(selectTeamsChats(_results));
        }
        if (typeof payload?.count !== "undefined") {
          dispatch(selectTotalTeamsChats(payload?.count));
        }
      }
    })();
  }, [skipTeams, searchKeyword, selectedSort, selectedTips, toggleList]);

  useEffect(() => {
    scrollRef.current?.scrollTo(0, 0);
  });

  interface noChatsObject {
    [key: string]: string;
  }

  const noChats: noChatsObject = {
    mine: "Você não possui conversas.",
    teams: "A equipe não possui conversas.",
    backlog: "Nenhuma conversa sem responsável.",
    research: "Nenhuma conversa encontrada.",
  };

  const emptyList = (content: noChatsObject[string]) => {
    return (
      <div className="flex relative mt-[20px]">
        <h2 className="my-0 mx-auto mt-[50px]">{content}</h2>
      </div>
    );
  };

  const listBehavior = ({
    list,
    messages,
  }: {
    list: IChat[];
    messages?: IMessage[];
  }) => {
    return (
      <ul
        className={`overflow-x-hidden overflow-y-scroll flex-1 relative pl-4 pr-2 ${
          widgetType === UserType.AGENT || widgetType === UserType.EMBEDDED
            ? "widget"
            : ""
        }`}
      >
        {list.length > 0 && searchKeyword.length > 0 && (
          <h1 className="mb-4 font-bold">Conversas</h1>
        )}

        <li>
          <div ref={scrollRef} />
        </li>
        {list.map((chat, index) => {
          if (user) {
            return (
              <li
                ref={index === list.length - 1 ? listRef : null}
                aria-hidden
                key={chat?._id}
                onClick={() => {
                  if (chat._id === selectedChat?._id) return;
                  if (!chatdisable) {
                    handleOpenChatClick(chat);
                  }
                }}
              >
                <ChatCard
                  disabledClick={chatdisable}
                  chat={chat}
                  setShow={setShow}
                  notifications={
                    !["teams"].includes(toggleList)
                      ? getNotificationsAmount(chat, user!)
                      : 0
                  }
                />
                <div className="w-full border-t border-gray-ddd my-1 -ml-1 mr-1" />
              </li>
            );
          }
          return null;
        })}

        {messages && messages.length > 0 && (
          <h1 className="mb-4 mt-5 font-bold">Mensagens</h1>
        )}

        {messages?.map((message, index) => {
          if (user) {
            return (
              <li
                ref={index === messages.length - 1 ? messageListRef : null}
                aria-hidden
                key={message?._id}
                onClick={() => {
                  if (message.chat._id === selectedChat?._id) return;
                  if (!chatdisable) {
                    handleOpenChatClick(message?.chat);
                  }
                }}
              >
                <MessageCardListComponent
                  message={message}
                  keyWord={searchKeyword}
                  selectedChat={selectedChat}
                />
                <div className="w-full border-t border-gray-ddd my-1 -ml-1 mr-1" />
              </li>
            );
          }
          return null;
        })}
      </ul>
    );
  };

  const getListLengthCondition = () => {
    if (renderMessagesSearch.length > 0) {
      return renderMessagesSearch.length > 0;
    }

    if (typeof renderDataSearch !== "undefined" && toggleList === "search") {
      return renderDataSearch.length > 0;
    }
    if (toggleList === "backlog") {
      return renderDataBacklog.length > 0;
    }
    if (toggleList === "mine") {
      return renderDataMine.length > 0;
    }

    return renderDataTeams.length > 0;
  };

  const getListCondition = () => {
    if (toggleList === "search") {
      return removeDuplicates(renderDataSearch, "_id") as IChat[];
    }
    if (toggleList === "backlog") {
      return removeDuplicates(renderDataBacklog, "_id");
    }
    if (toggleList === "mine") {
      return removeDuplicates(renderDataMine, "_id");
    }

    return removeDuplicates(renderDataTeams, "_id");
  };

  const getList = () => {
    if (getListLengthCondition()) {
      return listBehavior({
        list: getListCondition(),
        messages: renderMessagesSearch,
      });
    }
    return emptyList(
      toggleList === "search" ||
        searchKeyword.length > 0 ||
        selectedTips.length > 0
        ? noChats.research
        : noChats[toggleList]
    );
  };
  const showAppliedFilters = () => {
    if (
      countSelectedFilters({ searchFiltersSelected }) <= 0 ||
      toggleList !== "search"
    ) {
      return null;
    }

    const showFilters: string[] = [];

    if (
      typeof searchFiltersSelected !== "undefined" &&
      typeof searchFiltersOptions !== "undefined"
    ) {
      for (const instance of Object.keys(searchFiltersSelected)) {
        const instanceValues =
          typeof searchFiltersSelected[
            instance as keyof IFiltersDropdownInstances
          ] !== "undefined"
            ? instance === "period"
              ? [
                  searchFiltersSelected[
                    instance as keyof IFiltersDropdownInstances
                  ],
                ]
              : searchFiltersSelected[
                  instance as keyof IFiltersDropdownInstances
                ]
            : [];

        const instanceOptions =
          typeof searchFiltersOptions[
            instance as keyof IFiltersDropdownInstances
          ] !== "undefined"
            ? searchFiltersOptions[instance as keyof IFiltersDropdownInstances]
            : [];

        if (
          typeof instanceValues !== "undefined" &&
          instanceValues.length > 0
        ) {
          if (
            Array.isArray(instanceValues) &&
            typeof instanceValues === "object"
          ) {
            if (instance === "content") {
              showFilters.push(`${instanceValues.join(", ")}`);
            }
            if (["groupchat"].includes(instance) && instanceValues[0]) {
              showFilters.unshift(`Exibir somente conversas de grupo`);
            } else if (
              ["campaignchat"].includes(instance) &&
              instanceValues[0]
            ) {
              showFilters.unshift(`Exibir somente conversas de campanha`);
            } else if (["read"].includes(instance) && instanceValues[0]) {
              showFilters.unshift(`Exibir somente conversas não lidas`);
            } else if (
              ["externalappitem"].includes(instance) &&
              instanceValues[0]
            ) {
              showFilters.unshift(
                `Exibir somente conversas com tickets vinculados`
              );
            } else {
              const items =
                typeof instanceOptions === "object" &&
                instanceOptions.length > 0
                  ? instanceValues.map((value) => {
                      if (instance === "content") return value;
                      const result = instanceOptions.find(
                        (item: any) => item.key === value
                      );
                      if (
                        typeof value !== "undefined" &&
                        typeof value !== "boolean"
                      )
                        return result?.text;
                    })
                  : [];

              showFilters.push(`${removeDuplicates(items).join(", ")}`);
            }
          }
        } else if (
          Array.isArray(instanceOptions) &&
          instanceOptions.length > 0
        ) {
          const result = instanceOptions.find(
            (item: any) => item.key === instanceValues
          );
          if (typeof result?.text !== "undefined") {
            // showFilters.push(`${getDropdownLabel(instance)} : ${result?.text}`)
            showFilters.push(`${result?.text}`);
          }
        }
      }
    }
    const _filters: string = showFilters
      .filter((_filter) => _filter && _filter.length > 0)
      .join("; ");
    if (!_filters) return null;
    return (
      <div className="ml-3 pb-3 text-[12px] text-break">
        {typeof searchFiltersSelected !== "undefined" ? (
          <div className="flex">
            <h2 className="font-semibold mr-2">Filtrando por:</h2>
            <h2>{`${
              _filters.length > 200
                ? _filters.substring(0, 200).concat("...")
                : _filters
            }`}</h2>
          </div>
        ) : null}
        <div className="w-full">
          <LinkButton
            label="Limpar"
            leftIcon="las la-eraser"
            onClick={
              typeof clearFilters !== "undefined" ? clearFilters : () => null
            }
            extraClass="text-[12px] !gap-1"
          />
        </div>
      </div>
    );
  };

  const getLoadingCondition = () =>
    isLoading &&
    ((renderDataBacklog.length === 0 && toggleList === "backlog") ||
      (renderDataMine.length === 0 && toggleList === "mine") ||
      (renderDataSearch?.length === 0 && toggleList === "search") ||
      (renderDataTeams.length === 0 && toggleList === "teams"));

  const getFilterTips = () => {
    switch (toggleList) {
      case "backlog":
      case "mine":
      case "teams":
        return (
          <TipTagsComponent
            disabled={false}
            type={toggleList}
            selectedTips={selectedTips}
            setSelectedTips={setSelectedTips}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="flex gap-1 p-4 pt-0">
        <SearchBox
          placeholder="Pesquisar conversas..."
          _escFunction={() => {
            if (toggleList === "backlog") {
              setSkipBacklog(0);
            } else if (toggleList === "mine") {
              setSkipMine(0);
            } else if (toggleList === "search") {
              setSkipSearch(0);
            } else {
              setSkipTeams(0);
            }
            setSearchKeyword("");
            setRenderMessagesSearch([]);
            setSkipMessages(0);
          }}
          extraClass="w-full"
          keyword={searchKeyword}
          listFilter={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (toggleList === "backlog") {
              setSkipBacklog(0);
            } else if (toggleList === "mine") {
              setSkipMine(0);
            } else if (toggleList === "search") {
              setSkipSearch(0);
            } else {
              setSkipTeams(0);
            }
            setSkipMessages(0);
            setSearchKeyword(e.target.value);
          }}
        />

        <div className="rounded-none max-h-[70px] flex items-center justify-start ">
          {typeof setShowHistory !== "undefined" && toggleList !== "search" ? (
            <div
              role="presentation"
              onClick={() => {
                setShowHistory((prevState) => !prevState);
              }}
            >
              <i
                className={`border border-gray-ccc p-2 cursor-pointer text-gray-bench hover:text-gray-bench-alphaA0 las ${
                  showFilter ? "la-times" : "la-history"
                } rounded-2 text-[16px] relative`}
                title="Exibir histórico"
                aria-label="Exibir histórico"
              />
            </div>
          ) : null}
        </div>

        <div className="rounded-none max-h-[70px] flex items-center justify-start ">
          {setShowFilter ? (
            <>
              <div
                role="presentation"
                onClick={() => {
                  if (
                    typeof searchFiltersSelected !== "undefined" &&
                    countSelectedFilters({ searchFiltersSelected }) > 0 &&
                    toggleList !== "search"
                  ) {
                    setToggleList("search");
                    setShowFilter(false);
                  } else {
                    setShowFilter((prevState) => !prevState);
                  }
                }}
              >
                <i
                  className={`border border-gray-ccc p-2 cursor-pointer text-gray-bench hover:text-gray-bench-alphaA0 las ${
                    showFilter ? "la-times" : "la-filter"
                  } rounded-2 text-[16px] relative`}
                  title="Exibir filtros"
                  aria-label="Exibir filtros"
                />
              </div>
              {typeof searchFiltersSelected !== "undefined" &&
              countSelectedFilters({ searchFiltersSelected }) > 0 &&
              toggleList === "search" ? (
                <div
                  role="presentation"
                  className="absolute ml-5 mt-5"
                  onClick={() => {
                    setShowFilter((prevState) => !prevState);
                  }}
                >
                  <NotificationBadge
                    title="filtro(s)"
                    badge={countSelectedFilters({ searchFiltersSelected })}
                  />
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </div>

      <div
        className={`px-4 pb-2 w-full flex ${
          toggleList === "search" ? "justify-end" : "justify-between"
        }`}
      >
        {getFilterTips()}

        <ChatSortComponent
          disabled={!getListLengthCondition()}
          selectedSort={selectedSort}
          setSelectedSort={setSelectedSort}
          toggleList={toggleList as any}
        />
      </div>

      <div className="pr-0 w-full">{showAppliedFilters()}</div>

      {getLoadingCondition() ? (
        <LoadingDots className="flex flex-1 justify-center items-center" />
      ) : (
        <>{getList()}</>
      )}
    </>
  );
};

Inbox.defaultProps = {
  widgetType: UserType.NONE,
};

export default Inbox;
