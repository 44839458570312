/* eslint-disable no-case-declarations */
import { useAppDispatch } from "./redux/hooks";
import { messagesService } from "../services";
import { IChat, updateChat } from "../slices/chats.slice";
import {
  INewMessage,
  InternalMessageType,
  addMessage,
  createMessage,
  sendsocket,
} from "../slices/messages.slice";

const useSendInternalMessage = () => {
  const dispatch = useAppDispatch();

  const sendInternalMessage = async ({
    type,
    message,
    responseAction,
  }: {
    type: InternalMessageType;
    message: {
      chat: IChat;
      content: string;
    };
    responseAction?: VoidFunction;
  }) => {
    const newMessage: INewMessage = {
      chat: message.chat,
      content: message.content,
      files: [],
      parent: null,
      internal: true,
      read: !message?.chat?.groupchat,
    };
    let _return = null;
    switch (type) {
      case InternalMessageType.WIDGET_CONTACT:
        await messagesService.create(newMessage);
        break;
      case InternalMessageType.START_CHAT:
        break;
      case InternalMessageType.SUSPEND_CHAT:
        const { payload } = await dispatch(createMessage(newMessage));
        _return = payload;
        break;
      case InternalMessageType.USER_TRANSFER:
        const userTransferPayload = await messagesService.create(newMessage);
        _return = userTransferPayload;
        break;
      case InternalMessageType.TEAM_TRANSFER:
        const teamTransferPayload = await messagesService.create(newMessage);
        _return = teamTransferPayload;
        break;
      default:
        const _payload = await messagesService.create(newMessage);
        if (_payload !== null && message.chat !== null) {
          dispatch(addMessage(_payload));
          dispatch(
            sendsocket({
              chat: message.chat,
              message: _payload,
            })
          );
          if (responseAction) responseAction();
          _return = _payload;
          const _chat = {
            _id: message?.chat._id,
            status: message?.chat?.status,
            users: message?.chat?.users?.map((_user) => _user?._id),
            lastmessage: _payload,
          };
          await dispatch(updateChat(_chat));
        }
        break;
    }
    return _return;
  };

  return { sendInternalMessage };
};
export default useSendInternalMessage;
