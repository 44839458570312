import React from "react";
import Button from "./Buttons/Button.component";

const RelatedButtons = ({
  onClickCreation,
  onClickRelation,
  disabled,
}: {
  onClickCreation?: VoidFunction;
  onClickRelation: VoidFunction;
  disabled?: boolean;
}) => {
  return (
    <div className="flex mt-6">
      {onClickCreation ? (
        <>
          <Button
            minWidth
            label="Criar"
            onClick={onClickCreation}
            icon="las la-plus"
            disabled={disabled}
          />
          <div className="mr-4" />
        </>
      ) : null}
      <Button
        disabled={disabled}
        minWidth
        label="Relacionar"
        onClick={onClickRelation}
        icon="las la-link"
        inverted
      />
    </div>
  );
};

RelatedButtons.defaultProps = {
  onClickCreation: undefined,
  disabled: false,
};

export default RelatedButtons;
