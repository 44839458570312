import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import LoadingDots from "../shared/components/LoadingDots.component";
import DividerInfoPages from "../shared/components/DividerInfoPages.component";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";
import RelationModal from "../shared/components/Modals/RelationModal.component";
import { update, reselectUser, userdata } from "../slices/auth.slice";
import { IUser } from "../slices/users.slice";
import { SidebarComponent } from "../shared/components/Sidebars";
import CreateModal from "../shared/components/Modals/CreateModal.component";
import Section from "../shared/components/Section.component";
import SectionElement from "../shared/components/SectionElement.component";

const ProfilePage = ({
  icon,
  title,
  widget,
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  icon: string;
  widget?: boolean;
  title: string;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoadingAuth, user } = useAppSelector((state) => state.auth);

  const [, setReloadForced] = useState<boolean>(true);
  const [showModalTeams, setShowModalTeams] = useState<boolean>(false);
  // const [showModalCustomers, setShowModalCustomers] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      await dispatch(userdata());
      setReloadForced(false);
    })();
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const goSave = async () => {
    setDisable(true);
    delete user?.permissiongroups;
    delete user?.permissions;
    const _updateUser = {
      ...user,
      _id: user?._id,
      avatar: user?.avatar?._id,
      name: user?.name,
      active: user?.active,
      phone: user?.phone,
      teams: user?.teams?.map((_t) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
      // customers: user?.customers?.map((_t) => {
      //   if (typeof _t === "string") return _t;
      //   return _t._id;
      // }),
    };
    const { payload } = await dispatch(update({ _updateUser }));
    const _payload = payload as IUser;
    dispatch(
      reselectUser({
        ...user,
        updated_at: _payload.updated_at,
        updated_by: _payload.updated_by,
      })
    );
    setDisable(false);
  };

  const getContent = () => {
    if (location.hash.includes("#teams"))
      return (
        <CreateModal
          title={title}
          icon={icon}
          profile
          setShowModal={setShowModalTeams}
          setVisible={setVisible}
        />
      );
    // if (location.hash.includes("#customers"))
    //   return (
    //     <CreateModal
    //       title="Novo cliente"
    //       icon="las la-building"
    //       profile
    //       setShowModal={setShowModalCustomers}
    //       setVisible={setVisible}
    //     />
    //   );
    return (
      <CreateModal
        title="Alterar senha"
        icon="las la-key"
        profile
        setShowModal={setShowModalTeams}
        setVisible={setVisible}
      />
    );
  };

  if (isLoadingAuth)
    return (
      <LoadingDots
        className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
        large={!widget}
      />
    );
  return (
    <div
      className={`${
        !widget ? "mt-10 px-8 py-4" : "px-4 mt-6"
      } container items-center mx-auto lg:px-44 mt-10 md:px-12 my-auto`}
    >
      <SidebarComponent
        visible={visible}
        setVisible={setVisible}
        content={getContent()}
      />
      <Sidebar.Pusher>
        {!widget ? (
          <HeaderPageInfo
            created={user?.created_at}
            updated={user?.updated_at}
            deleted={user?.deleted_at}
            title={user?.name || "-"}
            imported={!!user?.externalcode}
            icon="las la-user"
          />
        ) : (
          <h1 className="mt-4 flex items-center justify-center font-semibold">
            <i className="las la-user text-4xl" />
            <p className="text-2xl ml-3">Meu perfil</p>
          </h1>
        )}
        <Section
          widget={widget}
          icon="las la-info-circle"
          title="Informações"
          description="Edite as informações exibidas"
          component={
            <SectionElement
              instance={user}
              type={widget ? "profile-info-widget" : "profile-info"}
              widget={widget}
              setVisible={setVisible}
            />
          }
        />
        {!widget ? <DividerInfoPages /> : null}
        {!widget ? (
          <Section
            widget={widget}
            icon="las la-user-friends"
            title="Equipes vinculadas ao seu agente"
            description="Relacione as equipes desejadas"
            component={
              <SectionElement
                instance={user}
                type="profile-teams"
                setShowModal={setShowModalTeams}
                setVisible={setVisible}
              />
            }
          />
        ) : null}
        {/* {!widget ? <DividerInfoPages /> : null} */}
        {/* {!widget ? (
          <Section
            widget={widget}
            icon="las la-building"
            title="Clientes vinculados ao seu contato"
            description="Relacione os clientes desejados"
            component={
              <SectionElement
                instance={user}
                type="profile-customers"
                setShowModal={setShowModalCustomers}
                setVisible={setVisible}
              />
            }
          />
        ) : null} */}
        {!widget ? <div className="mb-20" /> : null}
        {!widget ? (
          <FooterPageInfo
            disabled={disable}
            toggleSidebar={toggleSidebar}
            back={goBack}
            remove={goBack}
            save={goSave}
          />
        ) : null}
        {/* MODALS */}
        {showModalTeams ? (
          <RelationModal
            title="Relacionar equipes"
            icon="profile-teams"
            setShowModal={setShowModalTeams}
          />
        ) : null}
        {/* {showModalCustomers ? (
          <RelationModal
            title="Relacionar clientes"
            icon="profile-customers"
            setShowModal={setShowModalCustomers}
          />
        ) : null} */}
      </Sidebar.Pusher>
    </div>
  );
};

ProfilePage.defaultProps = {
  widget: false,
};

export default ProfilePage;
