import React from "react";
import { DropdownProps, SemanticWIDTHS } from "semantic-ui-react";
import Dropdown from "../Dropdown.component";

const ButtonTypeDropdown = ({
  id,
  className,
  disabled,
  onChange,
  width,
  fluid,
  value,
}: {
  id?: string;
  width?: SemanticWIDTHS;
  className?: string;
  disabled?: boolean;
  value?: string;
  onChange?:
    | ((
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: DropdownProps
      ) => void)
    | undefined;
  fluid?: boolean;
}) => {
  return (
    <div className="flex flex-col gap-2.5">
      <label>Tipo do botão</label>
      <Dropdown
        id={id || "dropdown-button-type"}
        className={className || "min-w-[150px] w-full md:w-full"}
        disabled={disabled || false}
        placeholder="Selecione o tipo"
        fluid={fluid}
        width={width}
        selection
        value={value}
        onChange={onChange}
        options={[
          {
            key: "QUICK_REPLY",
            text: "Resposta rápida",
            value: "QUICK_REPLY",
          },
          {
            key: "PHONE_NUMBER",
            text: "Ligar",
            value: "PHONE_NUMBER",
          },
          {
            key: "URL",
            text: "Acessar URL",
            value: "URL",
          },
        ]}
      />
    </div>
  );
};

ButtonTypeDropdown.defaultProps = {
  id: undefined,
  value: undefined,
  className: undefined,
  disabled: undefined,
  onChange: undefined,
  fluid: undefined,
  width: undefined,
};

export default ButtonTypeDropdown;
