import React from "react";
import {
  IQuickFilter,
  QuickFiltersID,
  selectQuickFilters,
} from "../../../slices/chats.slice";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import { update, userdata } from "../../../slices/auth.slice";
import { ITeam } from "../../../slices/teams.slice";
import { ITag } from "../../../slices/tags.slice";
import { ICustomer } from "../../../slices/customers.slice";

export const QuickFilters = {
  TODAY: { _id: QuickFiltersID.TODAY, name: "Hoje" },
  TODAYTEAMS: { _id: QuickFiltersID.TODAYTEAMS, name: "Hoje" },
  YESTERDAY: { _id: QuickFiltersID.YESTERDAY, name: "Ontem" },
  THISWEEK: { _id: QuickFiltersID.THISWEEK, name: "Esta semana" },
  ACTIVE: { _id: QuickFiltersID.ACTIVE, name: "Em atendimento" },
  FINISH: { _id: QuickFiltersID.FINISH, name: "Encerrado" },
  TRANSFERRED: { _id: QuickFiltersID.TRANSFERRED, name: "Transferido" },
  INTERNAL: { _id: QuickFiltersID.INTERNAL, name: "Interno" },
  EXTERNAL: { _id: QuickFiltersID.EXTERNAL, name: "Externo" },
  NOTREAD: { _id: QuickFiltersID.NOTREAD, name: "Não lidas" },
};

const TipTagsComponent = ({
  type,
  selectedTips,
  setSelectedTips,
  disabled,
}: {
  type: "backlog" | "mine" | "teams" | "history";
  selectedTips: string[];
  setSelectedTips: React.Dispatch<React.SetStateAction<string[]>>;
  disabled: boolean;
}) => {
  const { user } = useAppSelector((state) => state.auth);
  const { quickFilters, quickFiltersID } = useAppSelector(
    (state) => state.chats
  );
  const dispatch = useAppDispatch();

  const _backlogHistoryTips = [
    QuickFilters.TODAY,
    QuickFilters.YESTERDAY,
    QuickFilters.THISWEEK,
    QuickFilters.NOTREAD,
  ];

  const _mineTips = [
    QuickFilters.INTERNAL,
    QuickFilters.EXTERNAL,
    QuickFilters.TRANSFERRED,
    QuickFilters.NOTREAD,
  ];

  const _teamsTips = [
    QuickFilters.ACTIVE,
    QuickFilters.FINISH,
    QuickFilters.TODAYTEAMS,
    QuickFilters.NOTREAD,
  ];

  let _tips = _backlogHistoryTips;

  if (type === "mine") {
    _tips = _mineTips;
  } else if (type === "teams") {
    _tips = _teamsTips;
  }

  const handleTipClick = (_tip: IQuickFilter) => {
    let newSelectedLocalTips: string[] = [];
    let newSelectedGlobalTips: IQuickFilter[] = [];

    const defaultRemoveCase = (prevSelectedTips?: string[]) => {
      if (typeof prevSelectedTips !== "undefined") {
        newSelectedLocalTips = prevSelectedTips.filter(
          (tip) => tip !== _tip._id
        );
      } else {
        newSelectedGlobalTips = quickFilters.filter(
          (tip) => tip._id !== _tip._id
        );
      }
    };

    const uniqueSelectionCase = (prevSelectedTips?: string[]) => {
      if (typeof prevSelectedTips !== "undefined") {
        if (
          _tip._id === "today" &&
          (prevSelectedTips.includes("yesterday") ||
            prevSelectedTips.includes("thisweek"))
        ) {
          newSelectedLocalTips = newSelectedLocalTips.filter(
            (tip) => tip !== "yesterday" && tip !== "thisweek"
          );
        } else if (
          _tip._id === "yesterday" &&
          (prevSelectedTips.includes("today") ||
            prevSelectedTips.includes("thisweek"))
        ) {
          newSelectedLocalTips = newSelectedLocalTips.filter(
            (tip) => tip !== "today" && tip !== "thisweek"
          );
        } else if (
          _tip._id === "thisweek" &&
          (prevSelectedTips.includes("today") ||
            prevSelectedTips.includes("yesterday"))
        ) {
          newSelectedLocalTips = newSelectedLocalTips.filter(
            (tip) => tip !== "today" && tip !== "yesterday"
          );
        } else if (
          _tip._id === "INTERNAL" &&
          (prevSelectedTips.includes("EXTERNAL") ||
            prevSelectedTips.includes("TRANSFERRED"))
        ) {
          newSelectedLocalTips = newSelectedLocalTips.filter(
            (tip) => tip !== "EXTERNAL" && tip !== "TRANSFERRED"
          );
        } else if (
          (_tip._id === "EXTERNAL" || _tip._id === "TRANSFERRED") &&
          prevSelectedTips.includes("INTERNAL")
        ) {
          newSelectedLocalTips = newSelectedLocalTips.filter(
            (tip) => tip !== "INTERNAL"
          );
        }
      } else if (
        _tip._id === QuickFiltersID.TODAY &&
        (quickFiltersID.includes(QuickFiltersID.YESTERDAY) ||
          quickFiltersID.includes(QuickFiltersID.THISWEEK))
      ) {
        newSelectedGlobalTips = quickFilters.filter(
          (tip) =>
            tip._id !== QuickFiltersID.YESTERDAY &&
            tip._id !== QuickFiltersID.THISWEEK
        );
      } else if (
        _tip._id === QuickFiltersID.YESTERDAY &&
        (quickFiltersID.includes(QuickFiltersID.TODAY) ||
          quickFiltersID.includes(QuickFiltersID.THISWEEK))
      ) {
        newSelectedGlobalTips = quickFilters.filter(
          (tip) =>
            tip._id !== QuickFiltersID.TODAY &&
            tip._id !== QuickFiltersID.THISWEEK
        );
      } else if (
        _tip._id === QuickFiltersID.THISWEEK &&
        (quickFiltersID.includes(QuickFiltersID.TODAY) ||
          quickFiltersID.includes(QuickFiltersID.YESTERDAY))
      ) {
        newSelectedGlobalTips = quickFilters.filter(
          (tip) =>
            tip._id !== QuickFiltersID.TODAY &&
            tip._id !== QuickFiltersID.YESTERDAY
        );
      } else if (
        _tip._id === QuickFiltersID.INTERNAL &&
        (quickFiltersID.includes(QuickFiltersID.EXTERNAL) ||
          quickFiltersID.includes(QuickFiltersID.TRANSFERRED))
      ) {
        newSelectedGlobalTips = quickFilters.filter(
          (tip) =>
            tip._id !== QuickFiltersID.EXTERNAL &&
            tip._id !== QuickFiltersID.TRANSFERRED
        );
      } else if (
        (_tip._id === QuickFiltersID.EXTERNAL ||
          _tip._id === QuickFiltersID.TRANSFERRED) &&
        quickFiltersID.includes(QuickFiltersID.INTERNAL)
      ) {
        newSelectedGlobalTips = quickFilters.filter(
          (tip) => tip._id !== QuickFiltersID.INTERNAL
        );
      }
    };

    if (!disabled) {
      if (quickFiltersID.includes(_tip._id)) {
        defaultRemoveCase();
      } else {
        newSelectedGlobalTips = [...quickFilters, _tip];
        uniqueSelectionCase();
      }

      dispatch(selectQuickFilters(newSelectedGlobalTips));
      setSelectedTips((prevSelectedTips) => {
        if (prevSelectedTips.includes(_tip._id)) {
          defaultRemoveCase(prevSelectedTips);
        } else {
          newSelectedLocalTips = [...prevSelectedTips, _tip._id];
          uniqueSelectionCase(prevSelectedTips);
        }
        (async () => {
          await dispatch(
            update({
              _updateUser: {
                _id: user?._id,
                chatfilters: {
                  ...user?.chatfilters,
                  tips: newSelectedLocalTips,
                },
              },
              noToast: true,
            })
          );
          await dispatch(userdata());
        })();
        return newSelectedLocalTips;
      });
    }
  };

  return (
    <div className="flex gap-1">
      {_tips.map((_tip) => (
        <div
          role="presentation"
          onClick={() => handleTipClick(_tip)}
          key={_tip._id}
          className={`filter-tags 
            ${selectedTips.includes(_tip._id) ? "selected" : ""}
            ${disabled ? "disabled" : ""}
          `}
        >
          <span>{_tip.name}</span>
        </div>
      ))}
    </div>
  );
};

export default TipTagsComponent;
