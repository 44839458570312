import React from "react";

const NotificationBadge = ({
  badge,
  title,
  extraBadge,
}: {
  title?: string;
  badge: number;
  extraBadge?: boolean;
}) => {
  const _badge = badge >= 10 ? "9+" : badge;
  const _extraBadge = typeof extraBadge === "boolean" && !extraBadge ? " " : "";
  const _title = title || `nova(s)`;

  return (
    <div
      title={!_extraBadge ? `${_badge} ${_title}` : "Conversa não lida"}
      className="flex items-center justify-center text-[9px] w-[16px] h-[16px] bg-red rounded-full cursor-pointer text-white text-center"
    >
      {_badge || _extraBadge}
    </div>
  );
};

NotificationBadge.defaultProps = {
  // badge: undefined,
  title: undefined,
  extraBadge: undefined,
};

export default NotificationBadge;
