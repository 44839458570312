import React from "react";
import Button from "../Buttons/Button.component";

const ActionButtonsModal = ({
  cancelAction,
  submitAction,
  disabled,
  submitLabel,
  submitIcon,
  cancelLabel,
  cancelIcon,
  sidebar,
  noIcon,
}: {
  cancelAction?: VoidFunction;
  submitAction?: VoidFunction;
  disabled?: boolean;
  submitLabel?: string;
  submitIcon?: string;
  cancelLabel?: string;
  cancelIcon?: string;
  sidebar?: boolean;
  noIcon?: boolean;
}) => {
  return (
    <div
      className={`flex mx-auto items-center ${
        sidebar ? "justify-between" : "justify-center"
      } w-full mt-2`}
    >
      {cancelAction && (
        <Button
          minWidth
          label={cancelLabel || "Cancelar"}
          icon={noIcon ? undefined : cancelIcon || "las la-arrow-left"}
          onClick={cancelAction}
          inverted
        />
      )}
      <div className="mr-4" />
      {submitAction && (
        <Button
          minWidth
          label={submitLabel || "Confirmar"}
          icon={noIcon ? undefined : submitIcon || "las la-save"}
          onClick={submitAction}
          disabled={disabled}
        />
      )}
    </div>
  );
};

ActionButtonsModal.defaultProps = {
  submitLabel: undefined,
  submitIcon: undefined,
  cancelLabel: undefined,
  cancelIcon: undefined,
  disabled: false,
  sidebar: false,
  cancelAction: undefined,
  submitAction: undefined,
  noIcon: false,
};

export default ActionButtonsModal;
