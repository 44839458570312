// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { RoleUser } from "../slices/users.slice";
import { useAppSelector } from "./redux/hooks";

const useUserIsContact = () => {
  const { user } = useAppSelector((state) => state.auth);

  const isContact = (user?.roles?.length === 1 && user?.roles?.includes(RoleUser.CONTACT)) || false;

  return { isContact };
};

export default useUserIsContact;
