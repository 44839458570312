import React from "react";
import { Form } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import { reselectLocalTemplate } from "../../../slices/templates.slice";

const FooterFormGroup = () => {
  const dispatch = useAppDispatch();
  const { selectedLocalTemplate } = useAppSelector((state) => state.templates);

  return (
    <Form
      id="FooterFormGroup"
      className="flex flex-col items-start gap-9 w-full"
    >
      <Form.TextArea
        fluid="true"
        className="w-full"
        id="template-footer"
        style={{ resize: "none" }}
        label="Digite o texto do rodapé da sua mensangem"
        placeholder="Digite o texto"
        maxLength={60}
        type="text"
        defaultValue={selectedLocalTemplate?.selectedFooter}
        onChange={(e, { value }) => {
          dispatch(
            reselectLocalTemplate({
              ...selectedLocalTemplate,
              selectedFooter: value?.toString(),
            })
          );
        }}
      />
    </Form>
  );
};

export default FooterFormGroup;
