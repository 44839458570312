import React, { useState } from "react";
import DropdownComponent from "../Dropdown.component";
import { handleMaskRegex } from "../../utils/handleMaskLength";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import { ddOptions } from "../../models/interfaces/dropdownOptions.interface";
import { externalappsService } from "../../../services";
import {
  ExternalAppType,
  IExternalAppBotmaker,
  IExternalAppWhatsApp,
  selectExternalappsBotmaker,
  selectExternalappsWhatsApp,
  selectTotalExternalappsBotmaker,
  selectTotalExternalappsWhatsApp,
} from "../../../slices/externalapps.slice";

const IntegrationsDropdown = ({
  externalapp,
  setExternalapp,
}: {
  externalapp: string;
  setExternalapp: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [optionsExternalapp, setOptionsExternalapp] = useState<ddOptions[]>(
    externalapp
      ? [
          {
            key: externalapp.split("|%@g1%|")[0],
            text: externalapp.split("|%@g1%|")[2],
            value: externalapp,
            content: (
              <div className="flex">
                <h1 className="mr-2">{externalapp.split("|%@g1%|")[2]}</h1>
                {typeof externalapp.split("|%@g1%|")[1] !== "undefined" ? (
                  <h1 className="text-gray-text">
                    ({handleMaskRegex(externalapp.split("|%@g1%|")[1])})
                  </h1>
                ) : null}
              </div>
            ),
          },
        ]
      : []
  );
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);

  const onOpen = async () => {
    setIsloading(true);
    const payload = await externalappsService.searchExternalAppsToChat({
      teams:
        user?.teams?.map((_t) => {
          if (typeof _t === "string") return _t;
          return _t._id || "";
        }) ?? [],
      types: [ExternalAppType.WHATSAPP, ExternalAppType.BOTMAKER],
    });
    const _whatsapp: IExternalAppWhatsApp[] = [];
    const _bot: IExternalAppBotmaker[] = [];
    payload.forEach((_extApp: IExternalAppWhatsApp | IExternalAppBotmaker) => {
      if (_extApp.type === ExternalAppType.WHATSAPP) {
        _whatsapp.push(_extApp as IExternalAppWhatsApp);
      } else if (_extApp.type === ExternalAppType.BOTMAKER) {
        _bot.push(_extApp as IExternalAppBotmaker);
      }
    });
    dispatch(selectExternalappsWhatsApp(_whatsapp));
    dispatch(selectTotalExternalappsWhatsApp(_whatsapp.length));
    dispatch(selectExternalappsBotmaker(_bot));
    dispatch(selectTotalExternalappsBotmaker(_bot.length));

    _bot.forEach((_extapp) => {
      const _listPhones = _extapp.fields.externalphones;
      if (typeof _listPhones !== "undefined" && _listPhones.length > 0) {
        _listPhones.forEach((_externalapp) => {
          setOptionsExternalapp((prevOptions) => [
            ...prevOptions,
            {
              key: _externalapp.phone,
              text: _externalapp.name,
              value: `${_extapp._id}|%@g1%|${_externalapp.phone}|%@g1%|${_externalapp.name}`,
              content: (
                <div className="flex">
                  <h1 className="mr-2">{_externalapp?.name}</h1>
                  {typeof _externalapp.phone !== "undefined" ? (
                    <h1 className="text-gray-text">
                      ({handleMaskRegex(_externalapp.phone)})
                    </h1>
                  ) : null}
                </div>
              ),
            },
          ]);
        });
      }
    });
    setIsloading(false);
  };

  return (
    <DropdownComponent
      id="dropdown-integrations"
      fluid
      loading={isLoading}
      required
      search
      selection
      clearable
      value={externalapp}
      className={`${externalapp ? "selected" : ""}`}
      placeholder="Integrações"
      onOpen={onOpen}
      onChange={async (e, { value }) => {
        setExternalapp(value ? (value as string) : "");
      }}
      options={optionsExternalapp}
    />
  );
};

export default IntegrationsDropdown;
