/* eslint-disable react/require-default-props */
import React, { useState } from "react";
import ExternalappTypesDropdown from "../Dropdowns/ExternalappTypes.dropdown.component";
import DropdownComponent from "../Dropdown.component";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import { reselectLocalTemplate } from "../../../slices/templates.slice";
import {
  ExternalAppType,
  ExternalAppTypeTemplates,
  IExternalAppBotmaker,
  IExternalAppWhatsApp,
} from "../../../slices/externalapps.slice";
import { ddOptions } from "../../models/interfaces/dropdownOptions.interface";
import { externalappsService } from "../../../services";
import { handleMaskRegex } from "../../utils/handleMaskLength";
import PopupComponent from "../Popup.component";

const InputSelectAccount = () => {
  const dispatch = useAppDispatch();
  const { selectedLocalTemplate } = useAppSelector((state) => state.templates);
  const { user } = useAppSelector((state) => state.auth);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [optionsExternalapp, setOptionsExternalapp] = useState<ddOptions[]>(
    selectedLocalTemplate?.selectedConfig?.externalappNumber
      ? [
          {
            key: selectedLocalTemplate?.selectedConfig?.externalappNumber?.split(
              "|%@g1%|"
            )?.[1],
            text: selectedLocalTemplate?.selectedConfig?.externalappNumber?.split(
              "|%@g1%|"
            )?.[2],
            value: selectedLocalTemplate?.selectedConfig?.externalappNumber,
            content: (
              <div className="flex">
                <h1 className="mr-2">
                  {
                    selectedLocalTemplate?.selectedConfig?.externalappNumber?.split(
                      "|%@g1%|"
                    )?.[2]
                  }
                </h1>
                {selectedLocalTemplate?.selectedConfig?.externalappNumber?.split(
                  "|%@g1%|"
                )?.[1] ? (
                  <h1 className="text-gray-text">
                    (
                    {handleMaskRegex(
                      selectedLocalTemplate?.selectedConfig?.externalappNumber?.split(
                        "|%@g1%|"
                      )?.[1]
                    )}
                    )
                  </h1>
                ) : null}
              </div>
            ),
          },
        ]
      : []
  );

  const onOpen = async () => {
    setIsloading(true);
    const payload = await externalappsService.searchExternalAppsToChat({
      teams:
        user?.teams?.map((_t) => {
          if (typeof _t === "string") return _t;
          return _t._id || "";
        }) ?? [],
      types: selectedLocalTemplate?.selectedConfig?.externalappType
        ? [selectedLocalTemplate?.selectedConfig?.externalappType as any]
        : [],
    });
    const _whatsapp: IExternalAppWhatsApp[] = [];
    const _bot: IExternalAppBotmaker[] = [];
    payload.forEach((_extApp: IExternalAppWhatsApp | IExternalAppBotmaker) => {
      if (_extApp.type === ExternalAppType.WHATSAPP) {
        _whatsapp.push(_extApp as IExternalAppWhatsApp);
      } else if (_extApp.type === ExternalAppType.BOTMAKER) {
        _bot.push(_extApp as IExternalAppBotmaker);
      }
    });

    _bot.forEach((_extapp) => {
      const _listPhones = _extapp.fields.externalphones;
      if (typeof _listPhones !== "undefined" && _listPhones.length > 0) {
        _listPhones.forEach((_externalapp) => {
          setOptionsExternalapp((prevOptions) => [
            ...prevOptions,
            {
              key: _externalapp.phone,
              text: _externalapp.name,
              value: `${_extapp._id}|%@g1%|${_externalapp.phone}|%@g1%|${_externalapp.name}`,
              content: (
                <div className="flex">
                  <h1 className="mr-2">{_externalapp?.name}</h1>
                  {typeof _externalapp.phone !== "undefined" ? (
                    <h1 className="text-gray-text">
                      ({handleMaskRegex(_externalapp.phone)})
                    </h1>
                  ) : null}
                </div>
              ),
            },
          ]);
        });
      }
    });
    setIsloading(false);
  };

  const getTitle = () => {
    if (!selectedLocalTemplate?.selectedConfig?.externalappType) {
      return "Integração";
    }
    if (
      selectedLocalTemplate?.selectedConfig?.externalappType ===
      ExternalAppTypeTemplates.BOTMAKER
    ) {
      return "Integração do Agibot";
    }
    return "Integração do WhatsApp";
  };

  return (
    <div className="flex flex-col gap-2.5 pl-2">
      <PopupComponent
        className="flex items-center mb-1"
        label={getTitle()}
        content={
          <h1 className="font-semibold text-[12px]">
            Cada template de mensagem só pode ser utilizado por uma integração
            (Whatsapp ou Agibot).
            <br />
            <br />
            Selecione em qual das suas integrações ativas você deseja utilizar
            esse template.
          </h1>
        }
      />
      <div className="flex">
        <ExternalappTypesDropdown
          className={
            selectedLocalTemplate?.selectedConfig?.externalappType
              ? "!w-[150px] selected"
              : "!w-[150px]"
          }
          defaultValue={selectedLocalTemplate?.selectedConfig?.externalappType}
          onChange={(e, { value }) => {
            setOptionsExternalapp([]);
            dispatch(
              reselectLocalTemplate({
                ...selectedLocalTemplate,
                selectedConfig: {
                  ...selectedLocalTemplate?.selectedConfig,
                  externalappType: value as ExternalAppTypeTemplates,
                },
              })
            );
          }}
          fluid
        />
        <DropdownComponent
          loading={isLoading}
          className={`!rounded-l-none !w-[300px] ${
            selectedLocalTemplate?.selectedConfig?.externalappNumber
              ? "selected"
              : ""
          }`}
          id="dropdown-externalappnumber"
          placeholder="Selecione o número"
          // required
          fluid
          search
          selection
          onOpen={onOpen}
          defaultValue={
            selectedLocalTemplate?.selectedConfig?.externalappNumber
          }
          onChange={async (e, { value }) => {
            dispatch(
              reselectLocalTemplate({
                ...selectedLocalTemplate,
                selectedConfig: {
                  ...selectedLocalTemplate?.selectedConfig,
                  externalappNumber: value as string,
                },
              })
            );
          }}
          options={optionsExternalapp}
          clearable
        />
      </div>
    </div>
  );
};

export default InputSelectAccount;
