/* eslint-disable import/prefer-default-export */
import { IChat } from "../../slices/chats.slice";

const updateNotificationsByUser = (
  chat: IChat,
  user: string,
  clear?: boolean,
  transfer?: boolean
) => {
  try {
    let userExists = false;

    const newNotificationsCount = chat?.notifications?.length
      ? chat.notifications?.map((notification: any) => {
          if (notification.user.toString() === user) {
            if (clear) {
              notification.amount = 0;
            } else {
              notification.amount += 1;
            }
            userExists = true;
          } else if (transfer) {
            if (clear) {
              notification.amount = 0;
            } else {
              notification.amount += 1;
            }
          }
          return notification;
        })
      : [];

    if (!userExists && user) {
      newNotificationsCount.push({
        user,
        amount: clear ? 0 : 1,
      });
    }

    const newChat = { ...chat, notifications: newNotificationsCount };
    return newChat;
  } catch (error) {
    // console.log({ error });
    return chat;
  }
};

export { updateNotificationsByUser };
