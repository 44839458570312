import instances from "./instances";

const create = async (tenant: string) => {
  try {
    const response = await instances.instanceAppKey.post(tenant);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const appkeys = async (tenant: string) => {
  try {
    const response = await instances.instanceAppKey.get(tenant);
    if (response.data.length) return response.data[0];
    return await create(tenant);
  } catch (error) {
    return null;
  }
};

const ssokeys = async (tenant: string) => {
  try {
    const response = await instances.instanceAppKey.get(tenant);
    if (response.data.length) return response.data[0];
    return await create(tenant);
  } catch (error) {
    return null;
  }
};

const refresh = async (tenant: string) => {
  try {
    const response = await instances.instanceAppKey.post(tenant)
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const refreshsso = async (tenant: string) => {
  try {    
    const response = await instances.instanceAppKey.post("updatesso", tenant)
    console.log(response.data)
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const appkeyService = {
  appkeys,
  refresh,
  create,
  refreshsso,
  ssokeys
};

export default appkeyService;
