import React from "react";
import { DropdownProps, SemanticWIDTHS } from "semantic-ui-react";
import Dropdown from "../Dropdown.component";

const LanguageDropdown = ({
  id,
  className,
  disabled,
  onChange,
  width,
  fluid,
  label,
  defaultValue,
}: {
  label: boolean;
  id?: string;
  width?: SemanticWIDTHS;
  className?: string;
  disabled?: boolean;
  defaultValue?: string;
  onChange?:
    | ((
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: DropdownProps
      ) => void)
    | undefined;
  fluid?: boolean;
}) => {
  return (
    <div className="flex flex-col gap-2.5">
      {label && <label>Idioma</label>}
      <Dropdown
        id={id || "dropdown-template-language"}
        className={className || "w-full md:w-full"}
        disabled={disabled || false}
        placeholder="false"
        fluid={fluid}
        width={width}
        selection
        defaultValue={defaultValue}
        onChange={onChange}
        options={[
          {
            key: 0,
            text: "Português (BR)",
            value: "pt_BR",
            // icon: "right floated small circle green icon",
          },
        ]}
      />
    </div>
  );
};

LanguageDropdown.defaultProps = {
  id: undefined,
  className: undefined,
  disabled: undefined,
  defaultValue: undefined,
  onChange: undefined,
  fluid: undefined,
  width: undefined,
};

export default LanguageDropdown;
