/* eslint-disable react/require-default-props */
import React from "react";
import { TemplateStepsType } from "../../../slices/templates.slice";

export interface FormHeaderProps {
  type?: TemplateStepsType | "disabled";
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
}

const FormHeader = ({ type, title, description }: FormHeaderProps) => {
  return (
    <div
      id="FormHeader"
      className="flex p-0 flex-col items-start gap-4 self-stretch"
    >
      <h2
        className={`self-stretch font-bold text-200 leading-trim-both text-edge-cap ${
          type === "disabled" ? "fg-weak" : "fg-default"
        }`}
      >
        {title}
      </h2>
      {description && (
        <span className="self-stretch text-100-to-fix leading-trim-both text-edge-cap fg-weak">
          {description}
        </span>
      )}
    </div>
  );
};

export default FormHeader;
