import React from "react";

const LinkButton = ({
  onClick,
  disabled,
  label,
  icon,
  leftIcon,
  extraClass,
}: {
  onClick: (e?: any) => void;
  disabled?: boolean;
  label: string;
  icon?: string;
  leftIcon?: string;
  extraClass?: string;
}) => {
  return (
    <button
      className={`new-link ${
        extraClass || "text-base"
      } border-none flex items-center ${
        disabled ? "" : "cursor-pointer"
      } no-underline gap-2`}
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      {leftIcon ? <i className={`${leftIcon}`} /> : null}
      <p>{label}</p>
      {icon ? <i className={`${icon}`} /> : null}
    </button>
  );
};

LinkButton.defaultProps = {
  disabled: false,
  leftIcon: undefined,
  icon: undefined,
  extraClass: undefined,
};

export default LinkButton;
