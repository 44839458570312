/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import {
  reselectLocalTemplate,
  reselectTemplate,
  selectTemplate,
} from "../slices/templates.slice";
import useGetIdPage from "../hooks/useGetIdPage";
import useTemplates from "../hooks/features/useTemplates";
import DefaultPage from "./Default.page";
import WizardMenu from "../shared/components/Templates/WizardMenu.component";
import WhatsAppMobilePreview from "../shared/components/Templates/WhatsAppMobilePreview.component";
import WizardListItem from "../shared/components/Templates/WizardListItem.component";
import StepForm from "../shared/components/Templates/StepForm.component";
import { capitalizeFirstLetter } from "../hooks/useFormatText";
import SendTemplateModal from "../shared/components/Templates/SendTemplate.modal";

const TemplatePage = ({
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { getId } = useGetIdPage();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoadingTemplates, isSendingTemplate, selectedLocalTemplate } =
    useAppSelector((state) => state.templates);
  const { header, footer, getPreviewType, getStateWizardItem } = useTemplates({
    disable,
    setDisable,
    toggleSidebar,
  });
  const [showSendTemplateModal, setShowSendTemplateModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      location.pathname.includes(`/settings/template/`) &&
      location.pathname !== "/settings/template/agibot"
    ) {
      const _id = getId();
      (async () => {
        dispatch(reselectTemplate(null));
        dispatch(reselectLocalTemplate(null));
        if (_id) {
          const { payload } = await dispatch(selectTemplate({ _id }));

          if (payload?.step) navigate(`#${payload?.step}`);
        }
      })();
    } else if (
      location.pathname === "/settings/template/agibot" &&
      selectedLocalTemplate === null
    ) {
      footer.back();
    }
  }, []);

  useEffect(() => {
    const handleClickScrollSelectedStep = (type: string) => {
      const element = document.getElementById(type);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    const hash = location.hash.slice(1);
    handleClickScrollSelectedStep(`${hash}-StepForm`);
    if (window.location.hash) {
      setTimeout(() => {
        window.location.hash = "";
        navigate(location.pathname);
      }, 350);
    }
  }, [location.hash]);

  return (
    <div className="container items-center my-auto mx-auto mt-10 !py-0 !px-0">
      <DefaultPage
        isLoading={
          isSendingTemplate ||
          (isLoadingTemplates && selectedLocalTemplate === null)
        }
        header={{
          ...header,
          title: selectedLocalTemplate?.selectedConfig?.displayName
            ? capitalizeFirstLetter(
                selectedLocalTemplate?.selectedConfig?.displayName?.replace(
                  /_/g,
                  " "
                )
              )
            : "Escolha um nome para o template",
        }}
        content={
          <div className="inline-flex items-start gap-7">
            <div className="w-full max-w-[216px] mr-2">
              {selectedLocalTemplate?.selectedCategory === "auth" ? (
                <WizardMenu>
                  <WizardListItem
                    state={getStateWizardItem("category")}
                    number
                    value="1"
                    label="Categoria"
                    onClick={() => {
                      dispatch(
                        reselectLocalTemplate({
                          ...selectedLocalTemplate,
                          selectedStep: "category",
                        })
                      );
                      navigate("#category");
                    }}
                  />
                  <WizardListItem
                    state={getStateWizardItem("config")}
                    number
                    value="2"
                    onClick={() => {
                      dispatch(
                        reselectLocalTemplate({
                          ...selectedLocalTemplate,
                          selectedStep: "config",
                        })
                      );
                      navigate("#config");
                    }}
                    label="Configuração"
                  />
                  <WizardListItem
                    state={getStateWizardItem("content")}
                    number
                    value="3"
                    onClick={() => {
                      dispatch(
                        reselectLocalTemplate({
                          ...selectedLocalTemplate,
                          selectedStep: "content",
                        })
                      );
                      navigate("#content");
                    }}
                    label="Conteúdo"
                  />
                  <WizardListItem
                    state={getStateWizardItem("buttons")}
                    number
                    value="4"
                    onClick={() => {
                      dispatch(
                        reselectLocalTemplate({
                          ...selectedLocalTemplate,
                          selectedStep: "buttons",
                        })
                      );
                      navigate("#buttons");
                    }}
                    label="Botões de ação"
                  />
                </WizardMenu>
              ) : (
                <WizardMenu>
                  <WizardListItem
                    state={getStateWizardItem("category")}
                    number
                    value="1"
                    label="Categoria"
                    onClick={() => {
                      dispatch(
                        reselectLocalTemplate({
                          ...selectedLocalTemplate,
                          selectedStep: "category",
                        })
                      );
                      navigate("#category");
                    }}
                  />
                  <WizardListItem
                    state={getStateWizardItem("config")}
                    number
                    value="2"
                    onClick={() => {
                      dispatch(
                        reselectLocalTemplate({
                          ...selectedLocalTemplate,
                          selectedStep: "config",
                        })
                      );
                      navigate("#config");
                    }}
                    label="Configuração"
                  />
                  <WizardListItem
                    state={getStateWizardItem("header")}
                    number
                    value="3"
                    onClick={() => {
                      dispatch(
                        reselectLocalTemplate({
                          ...selectedLocalTemplate,
                          selectedStep: "header",
                        })
                      );
                      navigate("#header");
                    }}
                    label="Cabeçalho"
                  />
                  <WizardListItem
                    state={getStateWizardItem("content")}
                    number
                    value="4"
                    onClick={() => {
                      dispatch(
                        reselectLocalTemplate({
                          ...selectedLocalTemplate,
                          selectedStep: "content",
                        })
                      );
                      navigate("#content");
                    }}
                    label="Conteúdo"
                  />
                  <WizardListItem
                    state={getStateWizardItem("footer")}
                    number
                    value="5"
                    onClick={() => {
                      dispatch(
                        reselectLocalTemplate({
                          ...selectedLocalTemplate,
                          selectedStep: "footer",
                        })
                      );
                      navigate("#footer");
                    }}
                    label="Rodapé"
                  />
                  <WizardListItem
                    state={getStateWizardItem("buttons")}
                    number
                    value="6"
                    onClick={() => {
                      dispatch(
                        reselectLocalTemplate({
                          ...selectedLocalTemplate,
                          selectedStep: "buttons",
                        })
                      );
                      navigate("#buttons");
                    }}
                    label="Botões de ação"
                  />
                </WizardMenu>
              )}
            </div>
            <div
              id="content"
              className="flex w-full flex-col flex-1 overflow-y-auto h-[calc(100vh-10rem)] pb-20 gap-y-8"
            >
              <StepForm type="category" />
              {getStateWizardItem("category") !== "disabled" && (
                <StepForm
                  type={
                    getStateWizardItem("config") !== "disabled"
                      ? "config"
                      : "disabled"
                  }
                  title="Configuração"
                />
              )}
              {selectedLocalTemplate?.selectedCategory !== "auth" &&
                getStateWizardItem("config") !== "disabled" && (
                  <StepForm
                    type={
                      getStateWizardItem("header") !== "disabled"
                        ? "header"
                        : "disabled"
                    }
                    title="Cabeçalho"
                  />
                )}
              {getStateWizardItem("header") !== "disabled" && (
                <StepForm
                  type={
                    getStateWizardItem("content") !== "disabled"
                      ? "content"
                      : "disabled"
                  }
                  title="Conteúdo"
                />
              )}
              {selectedLocalTemplate?.selectedCategory !== "auth" &&
                getStateWizardItem("content") !== "disabled" && (
                  <StepForm
                    type={
                      getStateWizardItem("footer") !== "disabled"
                        ? "footer"
                        : "disabled"
                    }
                    title="Rodapé"
                  />
                )}
              {getStateWizardItem("content") !== "disabled" && (
                <StepForm
                  type={
                    getStateWizardItem("buttons") !== "disabled"
                      ? "buttons"
                      : "disabled"
                  }
                  title="Botões de ação"
                />
              )}
            </div>
            <div className="w-[280px]">
              <WhatsAppMobilePreview
                type={getPreviewType()}
                buttons={
                  selectedLocalTemplate?.selectedCategory === "auth" ||
                  (selectedLocalTemplate?.selectedButtons &&
                    selectedLocalTemplate?.selectedButtons?.length > 0)
                }
              />
            </div>
          </div>
        }
        footer={{
          ...footer,
          save: () => {
            if (setShowSendTemplateModal) setShowSendTemplateModal(true);
          },
        }}
      />
      {showSendTemplateModal && (
        <SendTemplateModal setShowModal={setShowSendTemplateModal} />
      )}
    </div>
  );
};

export default TemplatePage;
