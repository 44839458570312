/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-duplicates */
import React, { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cell, Row, TableBodyPropGetter, TableBodyProps } from "react-table";
import { Table } from "semantic-ui-react";
import { format, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import ReactInputMask from "react-input-mask";
import { Customers } from "../../../slices/customers.slice";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import { Teams } from "../../../slices/teams.slice";
import useAbrevName from "../../../hooks/useAbrevName";
import { IFile } from "../../models/interfaces/file.interface";
import {
  ExternalAppType,
  ExternalAppTypeTemplates,
} from "../../../slices/externalapps.slice";
import handleMaskLength, {
  handleMaskRegex,
} from "../../utils/handleMaskLength";
import NoAvatar from "../NoAvatar.component";
import Avatar from "../Avatar.component";
import { IUser, RoleUser } from "../../../slices/users.slice";
import { getRatingElement } from "../SurveyElements.component";
import { IRatingResultData, ISurvey } from "../../../slices/surveys.slice";
import { ChatStatus, IChat } from "../../../slices/chats.slice";
import Tag from "../../../features/chats/components/Tag.component";
import { ITarget } from "../../../slices/campaigns.slice";
import BadgeStatus from "../Templates/BadgeStatus.component";
import { capitalizeFirstLetter } from "../../../hooks/useFormatText";
import {
  reselectLocalTemplate,
  reselectTemplate,
} from "../../../slices/templates.slice";

export type cellObject = {
  category: string;
  id: string;
  title: string;
  _id: string;
  active: boolean;
  deleted: boolean;
  avatar: IFile;
  email: string;
  name: string;
  phone: string;
  tenant: string;
  updated_at: string;
  teams: Teams;
  weekday: string;
  start: string;
  finish: string;
  customers: Customers;
  survey: ISurvey;
  target: ITarget;
  type: string;
  externalapp_name: string;
  externalapp_phone: string;
  externalapp_type: string;
  config: {
    name: string;
    externalappType: ExternalAppTypeTemplates;
    externalappNumber: string;
  };
  fields: {
    name: string;
    defaultteam: Teams;
    displayphone: string;
  };
  user: IUser;
  result: object[];
  chat: IChat;
  created_at: string;
  finished_at: string;
  expire_at: string;
  scheduledate: string;
  status: number | string;
  statusmessage: string;
  default: boolean;
};

const TableBody = ({
  type,
  getTableBodyProps,
  prepareRow,
  page,
  selectedRow,
  setSelectedRow,
  setShowModal,
  selection,
}: {
  type: string;
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<object> | undefined
  ) => TableBodyProps;
  prepareRow: (row: Row<object>) => void;
  page: Row<object>[];
  selectedRow: cellObject[];
  setSelectedRow?: React.Dispatch<React.SetStateAction<cellObject[]>>;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  selection?: boolean;
}) => {
  const URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedOfficehour } = useAppSelector((state) => state.officehours);

  // selection = typeof selection !== "undefined" ? selection : true;

  const getType = async ({
    _id,
    _original,
  }: {
    _id: string;
    _original?: any;
  }) => {
    switch (type) {
      case "user":
        navigate(`/settings/user/${_id}`);
        break;
      case "chattag":
        // dispatch(selectHoliday({ _id }));
        // if (setShowModal) setShowModal(true);
        navigate(`/settings/tags/chattag/${_id}`);
        break;
      case "contacttag":
        // dispatch(selectHoliday({ _id }));
        // if (setShowModal) setShowModal(true);
        navigate(`/settings/tags/contacttag/${_id}`);
        break;
      case "holiday":
        // dispatch(selectHoliday({ _id }));
        // if (setShowModal) setShowModal(true);
        navigate(`/settings/holiday/${_id}`);
        break;
      case "offlinemessage":
        // dispatch(selectHoliday({ _id }));
        // if (setShowModal) setShowModal(true);
        navigate(`/settings/offlinemessage/${_id}`);
        break;
      case "officehour":
        // dispatch(selectOfficehour({ _id }));
        navigate(`/settings/officehour/${_id}`);
        break;
      case "agidesk":
        // dispatch(selectExternalapp({ _id }));
        navigate(`/settings/integration/agidesk/${_id}`);
        break;
      case "whatsapp":
        // dispatch(selectExternalapp({ _id }));
        navigate(`/settings/integration/whatsapp/${_id}`);
        break;
      case "botmaker":
        // dispatch(selectExternalapp({ _id }));
        navigate(`/settings/integration/agibot/${_id}`);
        break;
      case "vtex":
        // dispatch(selectExternalapp({ _id }));
        navigate(`/settings/integration/vtex/${_id}`);
        break;
      case "team":
        // await dispatch(selectTeam({ _id }));
        navigate(`/settings/team/${_id}`);
        break;
      case "customer":
        // await dispatch(selectCustomer({ _id }));
        navigate(`/settings/customer/${_id}`);
        break;
      case "target":
      case "contact":
        navigate(`/settings/contact/${_id}`);
        break;
      case "survey":
        navigate(`/settings/survey/${_id}`);
        break;
      case "commenttemplate":
        navigate(`/settings/commenttemplate/${_id}`);
        break;
      case "template":
        if (
          // _type?.toLocaleLowerCase() === "agibot" &&
          _original?.status?.toLowerCase() !== "draft"
        ) {
          dispatch(reselectTemplate(_original));
          dispatch(reselectLocalTemplate(_original));
          navigate(`/settings/template/agibot`);
        }
        // else if (
        //   _type?.toLocaleLowerCase() === "whatsapp" &&
        //   _item?.status?.toLowerCase() !== "draft"
        // ) {
        //   navigate(`/settings/template/whatsapp`);
        // }
        else {
          navigate(`/settings/template/${_id}`);
        }
        break;
      case "permissiongroup":
        navigate(`/settings/permissiongroup/${_id}`);
        break;
      case "campaign":
        navigate(`/settings/campaign/${_id}`);
        break;
      default:
        break;
    }
  };

  const surveyrating = ["surveyrating"].includes(type);

  const renderCell = (cell: Cell<object, unknown>, _original?: any) => {
    let _cell = null;
    const original = cell.row.original as cellObject;

    if (cell.column.id === "selection" && surveyrating) return false;

    const _teams = ["whatsapp", "botmaker"].includes(type)
      ? original?.fields?.defaultteam
      : original?.teams;

    const surveyRatingColumns = `w-full min-h-[80px]`;

    const getDayLabel = () => {
      switch (original?.weekday) {
        case "monday":
          return "Segunda-feira";
        case "tuesday":
          return "Terça-feira";
        case "wednesday":
          return "Quarta-feira";
        case "thursday":
          return "Quinta-feira";
        case "friday":
          return "Sexta-feira";
        case "saturday":
          return "Sábado";
        case "sunday":
          return "Domingo";
        default:
          return "";
      }
    };

    const classNameTemplatesCell =
      "overflow-hidden fg-default leading-trim text-edge-cap overflow-ellipsis font-body text-50 font-normal not-italic leading-[20px] flex-1";

    switch (cell.column.Header) {
      case "Dia da semana":
        _cell = (
          <Table.Cell {...cell.getCellProps()}>
            <div
              className="h-full flex items-center"
              title={original?.name}
              role="presentation"
              onClick={() => {
                if (setShowModal && setSelectedRow) {
                  if (!original?._id) {
                    const originalArray: cellObject[] = [original].map(
                      (_row) => ({ ..._row, _id: cell.row.id })
                    );
                    setSelectedRow(originalArray);
                  } else {
                    setSelectedRow([original]);
                  }
                  if (
                    !selectedOfficehour?.deleted &&
                    selectedOfficehour?.active
                  )
                    setShowModal(true);
                }
              }}
            >
              <p className="text-[14px]">{getDayLabel()}</p>
            </div>
          </Table.Cell>
        );
        break;
      // case "Equipe":
      //   _cell = (
      //     <Table.Cell {...cell.getCellProps()}>
      //       <div
      //         className="h-full flex items-center justify-start"
      //         title={original?.title}
      //         role="presentation"
      //         // onClick={() => {
      //         //   console.log(original);
      //         // }}
      //       >
      //         <div
      //           key={original?.id}
      //           title={original.title}
      //           className="w-8 h-8 bg-agitalks flex items-center justify-center rounded-full border border-agitalks shadow -ml-[5px] mr-[10px]"
      //         >
      //           <p className="text-[14px] text-white">
      //             {useAbrevName(original?.title)}
      //           </p>
      //         </div>
      //         <div className="ml-2">
      //           <p className="text-[14px]">{original?.title}</p>
      //         </div>
      //       </div>
      //     </Table.Cell>
      //   );
      //   break;
      case "Alvo":
        const __original = original.target;
        _cell = (
          <Table.Cell {...cell.getCellProps()}>
            <div
              className="h-full flex items-center justify-start"
              title={__original?.name}
              role="presentation"
              onClick={() => {
                // console.log(__original);
                getType({ _id: __original?._id as string });
              }}
            >
              <div className="flex items-center w-full">
                <div className="w-[28px]">
                  <div className="w-[28px] h-[28px]">
                    {__original?.avatar &&
                    __original?.avatar?.path &&
                    __original?.avatar?.name ? (
                      <Avatar
                        path={__original?.avatar?.path}
                        name={__original?.avatar?.name}
                        title={__original?.name}
                      />
                    ) : (
                      <NoAvatar
                        displaytext={__original?.name || "--"}
                        elementClass="p-0"
                        labelClass="text-[11px]"
                      />
                    )}
                  </div>
                </div>
                <div className="flex flex-col w-[calc(100%-35px)] ml-[14px] text-left">
                  <p className="text-[12px] font-semibold">
                    {__original?.name}
                  </p>
                  <p className="text-[12px] text-agitalks">
                    {__original?.email}
                  </p>
                </div>
              </div>
            </div>
          </Table.Cell>
        );
        break;
      case "Nome do modelo":
        const _templateName =
          _original?.config?.displayName ?? _original?.name ?? "(Sem nome)";
        _cell = (
          <Table.Cell
            {...cell.getCellProps()}
            className="!pl-6"
            onClick={() => {
              getType({ _id: original?._id, _original });
            }}
            title={capitalizeFirstLetter(_templateName?.replace(/_/g, " "))}
          >
            <div
              className="h-full flex items-center justify-start"
              title={capitalizeFirstLetter(_templateName?.replace(/_/g, " "))}
              role="presentation"
              onClick={() => {
                getType({ _id: original?._id, _original });
              }}
            >
              <p className={classNameTemplatesCell}>
                {capitalizeFirstLetter(_templateName?.replace(/_/g, " "))}
              </p>
            </div>
          </Table.Cell>
        );
        break;
      case "Categoria":
        const categories: any = {
          MARKETING: "Marketing",
          AUTH: "Autenticação",
          UTILITY: "Utilidades",
        };
        _cell = (
          <Table.Cell
            {...cell.getCellProps()}
            onClick={() => {
              getType({ _id: original?._id, _original });
            }}
            title={categories[original?.category?.toLocaleUpperCase()] || "-"}
          >
            <div
              className="h-full flex items-center justify-start"
              title={categories[original?.category?.toLocaleUpperCase()] || "-"}
              role="presentation"
              onClick={() => {
                getType({ _id: original?._id, _original });
              }}
            >
              <p className={classNameTemplatesCell}>
                {categories[original?.category?.toLocaleUpperCase()] || "-"}
              </p>
            </div>
          </Table.Cell>
        );
        break;
      case "Integração":
        const _type =
          original?.externalapp_type ?? original?.config?.externalappType;
        const _name =
          original?.externalapp_name ??
          original?.config?.externalappNumber?.split("|%@g1%|")?.[2];
        const _phone =
          original?.externalapp_phone ??
          original?.config?.externalappNumber?.split("|%@g1%|")?.[1];
        _cell = (
          <Table.Cell
            {...cell.getCellProps()}
            onClick={() => {
              getType({ _id: original?._id, _original });
            }}
            title={
              _type === "agibot" || _type === "BOTMAKER" ? "Agibot" : "WhatsApp"
            }
          >
            <div
              className={`${classNameTemplatesCell} !fg-weak h-full flex items-center justify-start gap-4 w-full`}
              title={
                _type === "agibot" || _type === "BOTMAKER"
                  ? "Agibot"
                  : "WhatsApp"
              }
              role="presentation"
              onClick={() => {
                getType({ _id: original?._id, _original });
              }}
            >
              {/* eslint-disable-next-line no-nested-ternary */}
              {!_type ? (
                <i className="las la-question text-250 text-center" />
              ) : _type === "agibot" || _type === "BOTMAKER" ? (
                <i className="las la-robot fg-primary text-250 text-center" />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_4306_5270)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.2173 0C5.71102 0 0.435997 5.23423 0.435997 11.6916C0.435997 13.9001 1.05366 15.9657 2.12616 17.7283L0 24L6.52246 21.9277C8.20992 22.853 10.1517 23.3818 12.2173 23.3818C18.725 23.3818 24 18.1475 24 11.6902C24 5.23289 18.725 0 12.2173 0ZM12.2173 21.4229C10.2257 21.4229 8.37006 20.8301 6.82119 19.814L3.05198 21.0117L4.27654 17.3971C3.10177 15.7921 2.40875 13.8213 2.40875 11.6902C2.41009 6.3238 6.80908 1.95749 12.2173 1.95749C17.6256 1.95749 22.0259 6.3238 22.0259 11.6902C22.0259 17.0566 17.6256 21.4229 12.2173 21.4229ZM17.7413 14.3474C17.4452 14.1872 15.9946 13.41 15.7228 13.3005C15.451 13.1911 15.2518 13.1336 15.0405 13.4247C14.8293 13.7158 14.2264 14.3661 14.0447 14.5584C13.8617 14.7506 13.6854 14.7693 13.3894 14.6078C13.0947 14.4475 12.1379 14.0964 11.0264 13.0375C10.1625 12.2136 9.59596 11.2135 9.43179 10.9091C9.26762 10.6033 9.43179 10.4484 9.58654 10.3056C9.72649 10.176 9.90008 9.96773 10.0575 9.79949C10.2136 9.63124 10.2688 9.5084 10.3765 9.31345C10.4841 9.1185 10.441 8.94225 10.3711 8.79003C10.3011 8.63781 9.75475 7.14766 9.52733 6.54011C9.29992 5.9339 9.04558 6.02337 8.8693 6.01669C8.69437 6.01135 8.49386 5.97797 8.29336 5.97129C8.09285 5.96328 7.76451 6.02604 7.47788 6.31445C7.19125 6.60287 6.38654 7.29587 6.33137 8.76466C6.2762 10.2321 7.30025 11.6916 7.44289 11.8958C7.58553 12.1001 9.39815 15.2834 12.4098 16.592C15.4227 17.9005 15.4375 17.5013 15.9906 17.4719C16.5436 17.4425 17.7965 16.8163 18.0737 16.1286C18.3509 15.441 18.3724 14.8441 18.3024 14.7173C18.2325 14.5904 18.0347 14.5076 17.74 14.3474H17.7413Z"
                      fill="#46A540"
                      stroke="none"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4306_5270">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              )}
              <div className="flex flex-col !w-fit">
                <p className="!fg-default">{_name || "-"}</p>
                {_phone ? (
                  <ReactInputMask
                    readOnly
                    maskPlaceholder={null}
                    style={{ background: "transparent", cursor: "pointer" }}
                    mask={handleMaskLength({
                      value: _phone,
                    })}
                    value={_phone}
                  />
                ) : (
                  "-"
                )}
              </div>
            </div>
          </Table.Cell>
        );
        break;
      case "Nome":
        if (["user", "contact"].includes(type)) {
          _cell = (
            <Table.Cell
              {...cell.getCellProps()}
              onClick={() => {
                getType({ _id: original?._id });
              }}
              title={original?.name}
            >
              <div
                className="h-full flex items-center justify-start"
                role="presentation"
                onClick={() => {
                  getType({ _id: original?._id });
                }}
              >
                <div className="flex items-center w-full">
                  <div className="w-[42px]">
                    <div className="w-[42px] h-[42px]">
                      {original?.avatar &&
                      original?.avatar?.path &&
                      original?.avatar?.name ? (
                        <Avatar
                          path={original?.avatar?.path}
                          name={original?.avatar?.name}
                          title={original?.name}
                        />
                      ) : (
                        // <div className="w-full h-full rounded-full flex items-center justify-center bg-gray-333 border-gray-333">
                        //   <img
                        //     className="avatar"
                        //     alt={original?.name}
                        //     src={`${URL}/api/files/${original?.avatar.path}/${original?.avatar.name}`}
                        //   />
                        // </div>
                        <NoAvatar
                          displaytext={original?.name || "--"}
                          elementClass="p-0"
                          labelClass="text-[14px]"
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col w-[calc(100%-35px)] ml-[14px] text-left">
                    <p className="text-[16px] font-semibold">
                      {cell.render("Cell")}
                    </p>
                    <p className="text-[14px] text-agitalks">
                      {original?.email}
                    </p>
                  </div>
                </div>
              </div>
            </Table.Cell>
          );
        } else {
          _cell = (
            <Table.Cell
              {...cell.getCellProps()}
              onClick={() => {
                getType({ _id: original?._id });
              }}
              title={
                [
                  ExternalAppType.AGIDESK.toLowerCase(),
                  ExternalAppType.WHATSAPP.toLowerCase(),
                  ExternalAppType.BOTMAKER.toLowerCase(),
                  ExternalAppType.VTEX.toLowerCase(),
                ].includes(type)
                  ? original?.fields?.name
                  : `${original?.name} ${original?.default ? "(Padrão)" : ""}`
              }
            >
              <div
                className="h-full flex items-center justify-start"
                title={
                  [
                    ExternalAppType.AGIDESK.toLowerCase(),
                    ExternalAppType.WHATSAPP.toLowerCase(),
                    ExternalAppType.BOTMAKER.toLowerCase(),
                    ExternalAppType.VTEX.toLowerCase(),
                  ].includes(type)
                    ? original?.fields?.name
                    : `${original?.name} ${original?.default ? "(Padrão)" : ""}`
                }
                role="presentation"
                onClick={() => {
                  // console.log(original);
                  getType({ _id: original?._id });
                }}
              >
                <div className="flex items-center w-full">
                  <div className="w-[32px]">
                    <div className="w-[32px] h-[32px]">
                      {original?.avatar ? (
                        <img
                          className="avatar"
                          alt={
                            [
                              ExternalAppType.AGIDESK.toLowerCase(),
                              ExternalAppType.WHATSAPP.toLowerCase(),
                              ExternalAppType.BOTMAKER.toLowerCase(),
                              ExternalAppType.VTEX.toLowerCase(),
                            ].includes(type)
                              ? original?.fields?.name
                              : original?.name
                          }
                          src={`${URL}/api/files/${original?.avatar.path}/${original?.avatar.name}`}
                        />
                      ) : (
                        <div
                          key={original?._id}
                          title={
                            [
                              ExternalAppType.AGIDESK.toLowerCase(),
                              ExternalAppType.WHATSAPP.toLowerCase(),
                              ExternalAppType.BOTMAKER.toLowerCase(),
                              ExternalAppType.VTEX.toLowerCase(),
                            ].includes(type)
                              ? original?.fields?.name
                              : original?.name
                          }
                          className="w-full h-full bg-agitalks flex items-center justify-center rounded-full border border-agitalks shadow -ml-[5px] mr-[10px]"
                        >
                          <p className="text-[12px] text-white">
                            {useAbrevName(
                              [
                                ExternalAppType.AGIDESK.toLowerCase(),
                                ExternalAppType.WHATSAPP.toLowerCase(),
                                ExternalAppType.BOTMAKER.toLowerCase(),
                                ExternalAppType.VTEX.toLowerCase(),
                              ].includes(type)
                                ? original?.fields?.name
                                : original?.name
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col w-[calc(100%-35px)] ml-[14px] text-left">
                    <div className="flex items-center">
                      <p className="text-[14px] font-semibold word-wrap-2 max-w-[90%]">
                        {[
                          ExternalAppType.AGIDESK.toLowerCase(),
                          ExternalAppType.WHATSAPP.toLowerCase(),
                          ExternalAppType.BOTMAKER.toLowerCase(),
                          ExternalAppType.VTEX.toLowerCase(),
                        ].includes(type)
                          ? original?.fields?.name
                          : `${original.name}`}
                      </p>
                      {original?.default ? (
                        <div className="ml-2">
                          <Tag tagType="default" title="Padrão" />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </Table.Cell>
          );
        }
        break;
      case "Situação":
        let _props: {
          content?: ReactElement;
          classname?: string;
          onclick?: VoidFunction;
        } = {};

        if (["user", "contact"].includes(type)) {
          _props = {
            classname: `flex items-center w-full h-full`,
            onclick: () => {
              getType({ _id: original?._id });
            },
            content: (
              <>
                <div
                  className={`h-2.5 w-2.5 rounded-full ${
                    !original?.deleted ? "bg-green" : "bg-red"
                  } mr-2`}
                />
                <p className="text-[14px]">
                  {!original?.deleted ? "Online" : "Offline"}
                </p>
              </>
            ),
          };
        } else if (["template"].includes(type)) {
          _props = {
            classname: `flex items-center`,
            onclick: () => {
              getType({ _id: original?._id, _original });
            },
            content: (
              <BadgeStatus
                state={(original?.status as any)?.toLocaleLowerCase()}
              />
            ),
          };
        } else if (["campaignbatch"].includes(type)) {
          interface targetObject {
            [key: string]: {
              status: string;
              icon: string;
            };
          }
          const _target: targetObject = {
            SUCCESS: {
              status: "Enviado",
              icon: "text-green las la-check-double",
            },
            PENDING: {
              status: "Pendente",
              icon: "text-agidesk las la-hourglass-end",
            },
            BUSY: {
              status: "Contato ocupado",
              icon: "text-agidesk las la-clock",
            },
            CANCELED: {
              status: "Cancelado",
              icon: "text-red las la-ban",
            },
          };
          const { status, icon } = _target[original?.status];
          // original?.status === "SUCCESS" ? "Enviado" : "Pendente";
          // const icon =
          //   original?.status === "SUCCESS"
          //     ? "text-green las la-check-double"
          //     : "text-agidesk las la-hourglass-end";
          _props = {
            classname: `flex items-center`,
            content: (
              <>
                <i className={`${icon} mx-2`} />
                <p className="text-[14px]">{status}</p>
              </>
            ),
          };
        } else if (surveyrating) {
          const currentDate = new Date().getTime();
          const expired = currentDate > new Date(original.expire_at).getTime();
          const rated = original.status === 2;
          _props = {
            classname: `flex items-center ${surveyRatingColumns} `,
            content: (
              <>
                <i
                  className={` ${
                    rated
                      ? "las la-check-double text-green"
                      : expired
                      ? "las la-exclamation text-red"
                      : "las la-hourglass-end text-agidesk"
                  } mr-2`}
                />
                <p className="text-[14px]">
                  {rated ? "Respondida" : expired ? "Expirada" : "Pendente"}
                </p>
              </>
            ),
          };
        } else {
          _props = {
            classname: `flex items-center w-full h-full`,
            onclick: () => {
              getType({ _id: original?._id });
            },
            content: (
              <>
                <div
                  className={`h-2.5 w-2.5 rounded-full ${
                    !original?.deleted ? "bg-green" : "bg-red"
                  } mr-2`}
                />
                <p className="text-[14px]">
                  {!original?.deleted ? "Ativo" : "Inativo"}
                </p>
              </>
            ),
          };
        }

        _cell = (
          <Table.Cell {...cell.getCellProps()} onClick={_props?.onclick}>
            <div
              title="Situação"
              role="presentation"
              onClick={_props?.onclick}
              className={_props.classname}
            >
              {_props.content}
            </div>
          </Table.Cell>
        );
        break;
      case "Equipes":
        _cell = (
          <Table.Cell
            {...cell.getCellProps()}
            onClick={() => {
              getType({ _id: original?._id });
            }}
            // textAlign="center"
          >
            <div
              className="flex items-center w-full h-full"
              title="Equipes"
              role="presentation"
              onClick={() => {
                getType({ _id: original?._id });
              }}
            >
              <div className="flex flex-col w-full">
                {_teams && typeof _teams !== "undefined" && _teams.length > 0
                  ? _teams?.map((team, index: number) => {
                      if (index > 3) return null;
                      if (index === 3) {
                        return (
                          <div
                            key={team._id}
                            className="h-full w-full flex items-center justify-center mt-1"
                          >
                            <p className="text-[14px] font-semibold text-agitalks">
                              E mais {+_teams.length - index}
                            </p>
                          </div>
                        );
                      }
                      return (
                        <div
                          key={team._id}
                          className="h-full flex items-center justify-start mt-1"
                        >
                          <div className="flex items-center w-full">
                            <div className="w-[32px]">
                              <div className="w-[32px] h-[32px]">
                                <div
                                  title={team.name}
                                  className="w-full h-full bg-agitalks flex items-center justify-center rounded-full border border-agitalks shadow"
                                >
                                  <p className="text-[12px] text-white">
                                    {useAbrevName(team.name || "")}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col w-[calc(100%-35px)] ml-4 text-left">
                              <p className="text-[14px] font-semibold">
                                {team.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : "-"}
              </div>
            </div>
          </Table.Cell>
        );
        break;
      case "Clientes":
        _cell = (
          <Table.Cell
            {...cell.getCellProps()}
            onClick={() => {
              getType({ _id: original?._id });
            }}
            // textAlign="center"
          >
            <div
              className="flex items-center w-full h-full"
              title="Clientes"
              role="presentation"
              onClick={() => {
                getType({ _id: original?._id });
              }}
            >
              <div className="flex flex-col w-full">
                {original?.customers &&
                typeof original?.customers !== "undefined" &&
                original?.customers.length > 0
                  ? original?.customers?.map((customer, index) => {
                      if (index > 3) return null;
                      if (index === 3) {
                        return (
                          <div
                            key={customer._id}
                            className="h-full flex items-center justify-center mt-1"
                          >
                            <p className="text-[14px] font-semibold text-agitalks">
                              E mais {+original.customers.length - index}
                            </p>
                          </div>
                        );
                      }
                      return (
                        <div
                          key={customer._id}
                          className="h-full flex items-center justify-start mt-1"
                        >
                          <div className="flex items-center w-full">
                            <div className="w-[32px]">
                              <div className="w-[32px] h-[32px]">
                                <div
                                  title={customer.name || customer.fullname}
                                  className="w-full h-full bg-agitalks flex items-center justify-center rounded-full border border-agitalks shadow"
                                >
                                  <p className="text-[12px] text-white">
                                    {useAbrevName(customer.name || "")}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col w-[calc(100%-35px)] ml-4 text-left">
                              <p className="text-[14px] font-semibold word-wrap-2">
                                {customer.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : "-"}
              </div>
            </div>
          </Table.Cell>
        );
        break;
      case "Telefone":
        _cell = (
          <Table.Cell
            {...cell.getCellProps()}
            onClick={() => {
              getType({ _id: original?._id });
            }}
          >
            <div
              className="w-full h-full"
              title="Telefone"
              role="presentation"
              onClick={() => {
                getType({ _id: original?._id });
              }}
            >
              <div className="flex flex-col w-full pr-6">
                <p className="text-[14px] text-right">
                  {typeof original?.target?.phone !== "undefined"
                    ? handleMaskRegex(original?.target?.phone)
                    : "-"}
                </p>
              </div>
            </div>
          </Table.Cell>
        );
        break;
      case "Número":
        _cell = (
          <Table.Cell
            {...cell.getCellProps()}
            onClick={() => {
              getType({ _id: original?._id });
            }}
          >
            <div
              className="w-full h-full"
              title="Número"
              role="presentation"
              onClick={() => {
                getType({ _id: original?._id });
              }}
            >
              {original?.fields?.displayphone ? (
                <ReactInputMask
                  readOnly
                  maskPlaceholder={null}
                  style={{ background: "transparent", cursor: "pointer" }}
                  mask={handleMaskLength({
                    value: original?.fields.displayphone,
                  })}
                  value={original?.fields.displayphone}
                />
              ) : (
                <p className="text-[14px] text-center">-</p>
              )}
            </div>
          </Table.Cell>
        );
        break;
      case "Mensagem":
        interface targetObject {
          [key: string]: string;
        }
        const _target: targetObject = {
          SUCCESS: "Envio realizado com sucesso.",
          SUCCESSWAIT:
            "Envio realizado com sucesso. Aguardando retorno do contato.",
          BUSY: "Já existe uma conversa ativa com esse alvo. Campanha não enviada.",
          CANCELED: "Envio cancelado pelo usuário.",
          GENERIC: "Ocorreu um erro (ID: CODE). Campanha não enviada.",
        };
        const getCodeMessage = () => {
          // interface errorsObject {
          //   [key: string]: string;
          // }
          // const _limitationIntegrityErrors: errorsObject = {
          //   // limitation
          //   "4": "4 - Muitas chamadas de API",
          //   "80007": "80007 - Problemas com limite de volume",
          //   "130429": "130429 - Limite de volume atingido",
          //   "131048": "131048 - Limite de taxa de spam atingido",
          //   "131056":
          //     "131056 - Limite de volume de emparelhamento atingido (conta comercial, conta de consumidor)",
          //   "133016":
          //     "133016 - Limite de taxa de registro/exclusão de contas excedido",
          //   // integrity
          //   "368": "368 - Bloqueado temporariamente por violações de políticas",
          //   "131031": "131031 - Conta bloqueada",
          // };
          // if (
          //   Object.keys(_limitationIntegrityErrors).includes(
          //     original.statusmessage
          //   )
          // ) {
          //   return _target.GENERIC.replace(
          //     "CODE",
          //     _limitationIntegrityErrors[original.statusmessage]
          //   );
          // }
          const _limitationErrors = [
            "4",
            "80007",
            "130429",
            "131048",
            "131056",
            "133016",
          ];
          const _integrityErrors = ["368", "131031"];

          if (_limitationErrors.includes(original.statusmessage)) {
            return _target.GENERIC.replace(
              "CODE",
              `${original.statusmessage} - Limite de volume atingido`
            );
          }
          if (_integrityErrors.includes(original.statusmessage)) {
            return _target.GENERIC.replace(
              "CODE",
              `${original.statusmessage} - Conta bloqueada`
            );
          }
          return _target.GENERIC.replace("CODE", original.statusmessage);
        };

        const _msg =
          original.status === "PENDING" &&
          typeof original.statusmessage !== "undefined"
            ? getCodeMessage()
            : original?.statusmessage === "PENDING"
            ? _target[original?.statusmessage]
            : original.status === "SUCCESS" &&
              typeof original?.chat?.status !== "undefined" &&
              original?.chat?.status === ChatStatus.WAITCAMPAIGN
            ? _target.SUCCESSWAIT
            : _target[original?.status];

        _cell = (
          <Table.Cell
            {...cell.getCellProps()}
            onClick={() => {
              getType({ _id: original?._id });
            }}
            // textAlign={typeof _msg !== "undefined" ? "left" : "center"}
          >
            <div
              className="w-full h-full"
              title="Mensagem"
              role="presentation"
              onClick={() => {
                getType({ _id: original?._id });
              }}
            >
              {_msg || "-"}
            </div>
          </Table.Cell>
        );
        break;
      case "Atualização":
      case "Pesquisa":
        const dateField = (date: string) => {
          return date
            ? !surveyrating
              ? format(parseISO(date), "dd/MM/yyyy", {
                  locale: ptBR,
                })
              : format(parseISO(date), "dd/MM/yyyy, 'às' HH:mm", {
                  locale: ptBR,
                })
            : "-";
        };
        _cell = (
          <Table.Cell
            {...cell.getCellProps()}
            onClick={() => {
              getType({ _id: original?._id, _original });
            }}
            // textAlign={`${surveyrating ? "left" : "center"}`}
          >
            <div
              className={`${
                surveyrating
                  ? `${surveyRatingColumns} flex flex-col items-left`
                  : ""
              } w-full h-full`}
              title={`${
                original?.updated_at
                  ? format(
                      parseISO(original?.updated_at),
                      "dd/MM/yyyy, 'às' HH:mm",
                      {
                        locale: ptBR,
                      }
                    )
                  : "-"
              }`}
              role="presentation"
              onClick={() => {
                getType({ _id: original?._id, _original });
              }}
            >
              {surveyrating ? (
                <>
                  <p className="text-[14px] font-semibold">
                    {original?.survey?.name}
                  </p>
                  <div
                    className="text-[13px] flex items-center"
                    title="Enviado em:"
                  >
                    <i className="lar la-paper-plane text-[13px] mr-2" />
                    <p>{dateField(original?.created_at)}</p>
                  </div>
                  <div
                    className="text-[13px] flex items-center"
                    title="Respondido em:"
                  >
                    <i className="las la-check-double text-[13px] mr-2" />
                    <p>{dateField(original?.finished_at)}</p>
                  </div>
                  <div
                    className="text-[13px] flex items-center"
                    title="Expira em:"
                  >
                    <i className="las la-exclamation text-[13px] mr-2" />
                    <p>{dateField(original?.expire_at)}</p>
                  </div>
                </>
              ) : (
                <p className="text-[13px]">{dateField(original?.updated_at)}</p>
              )}
            </div>
          </Table.Cell>
        );
        break;
      case "Envio":
        const sendData =
          typeof original?.chat?.created_at !== "undefined"
            ? original.chat?.created_at
            : (original?.status === "SUCCESS" ||
                original?.status === "CANCELED" ||
                typeof original.statusmessage !== "undefined") &&
              typeof original?.chat?._id === "undefined"
            ? original?.updated_at
            : "";
        const createDate = (date: string) => {
          return date
            ? format(parseISO(date), "dd/MM/yyyy, 'às' HH:mm", {
                locale: ptBR,
              })
            : "-";
        };
        _cell = (
          <Table.Cell
            {...cell.getCellProps()}
            onClick={() => {
              getType({ _id: original?._id });
            }}
            // textAlign="center"
          >
            <div
              className="w-full h-full"
              title={`${cell.column.Header}`}
              role="presentation"
              onClick={() => {
                getType({ _id: original?._id });
              }}
            >
              <p className="text-[13px]">{createDate(sendData)}</p>
            </div>
          </Table.Cell>
        );
        break;
      case "Avaliação":
        if (surveyrating) {
          const currentDate = new Date().getTime();
          const expired = currentDate > new Date(original.expire_at).getTime();

          const rated = original.status === 2;

          const _ratingData =
            typeof original.result !== "undefined" && original.result.length > 0
              ? original.result[0]
              : [];
          const resultElement = getRatingElement(
            _ratingData as IRatingResultData
          );
          _cell = (
            <Table.Cell {...cell.getCellProps()}>
              <div
                title="Avaliado como"
                role="presentation"
                // onClick={() => {
                // }}
                className={`${surveyRatingColumns} justify-center flex flex-col items-center`}
              >
                <div className="text-[14px] text-center">
                  {rated ? resultElement : expired ? "(Sem resposta)" : "-"}
                </div>
              </div>
            </Table.Cell>
          );
        }
        break;
      case "Dados do contato":
      case "Contato":
        if (surveyrating || ["campaignbatch"].includes(type)) {
          const customers =
            (surveyrating
              ? original?.user?.customers
              : typeof original?.customers !== "undefined"
              ? original.customers
              : []) || [];

          const customerName =
            (typeof customers !== "undefined" && customers.length <= 0) ||
            typeof customers[0]?.name === "undefined"
              ? "-"
              : `${customers[0]?.name}${
                  customers.length > 1
                    ? ` (e mais ${customers.length - 1})`
                    : ""
                }`;
          const name = surveyrating ? original?.user?.name : original?.name;
          const email = surveyrating ? original?.user?.email : original?.email;
          const phone = surveyrating ? original?.user?.phone : original?.phone;
          _cell = (
            <Table.Cell {...cell.getCellProps()}>
              <div
                title="Dados do contato"
                className={`${surveyRatingColumns} flex flex-col items-left w-full`}
              >
                <div className="flex flex-col text-[12px]">
                  <p className="text-[14px] font-semibold">{name}</p>
                  <p className="text-agitalks font-semibold">
                    <i className="las la-building mr-2" />
                    {customerName}
                  </p>
                  <p className="text-agitalks">{email}</p>
                  <p className="text-agitalks">{handleMaskRegex(phone)}</p>
                </div>
              </div>
            </Table.Cell>
          );
        }
        break;
      case "Agente/Equipe":
      case "Agente":
        if (surveyrating || ["campaignbatch"].includes(type)) {
          const agent =
            original?.chat?.users?.filter(
              (_user) => !_user.roles?.includes(RoleUser.CONTACT)
            )[0] || null;
          const agentName =
            agent && typeof agent.name !== "undefined" ? agent.name : "-";
          const agentEmail =
            agent && typeof agent.email !== "undefined" ? agent.email : "-";
          const teamName =
            typeof original?.chat?.team !== "undefined" &&
            original?.chat?.team !== null
              ? original?.chat?.team.name
              : "-";

          _cell = (
            <Table.Cell {...cell.getCellProps()}>
              <div
                title="Dados do agente"
                className={`${surveyRatingColumns} ${
                  agentName === "-" ? "justify-center text-center" : ""
                } flex flex-col items-left w-full`}
              >
                {agentName !== "-" ? (
                  <div className="flex flex-col">
                    <p className="text-[14px] font-semibold" title="Agente">
                      {agentName}
                    </p>
                    {teamName ? (
                      <p
                        className="text-agitalks text-[12px] font-semibold"
                        title="Equipe"
                      >
                        <i className="las la-user-friends mr-2" />
                        {teamName}
                      </p>
                    ) : (
                      ""
                    )}
                    <p className=" text-agitalks text-[12px]">{agentEmail}</p>
                  </div>
                ) : (
                  "-"
                )}
              </div>
            </Table.Cell>
          );
        }
        break;

      default:
        _cell = (
          <Table.Cell
            className="text-[14px]"
            {...cell.getCellProps({})}
            // textAlign={typeof original.id !== "undefined" ? "left" : "center"}
            // textAlign="center"
            onClick={() => {
              if (setShowModal && setSelectedRow) {
                if (!original?._id) {
                  const originalArray: cellObject[] = [original].map(
                    (_row) => ({ ..._row, _id: cell.row.id })
                  );
                  setSelectedRow(originalArray);
                } else {
                  setSelectedRow([original]);
                }
                if (!selectedOfficehour?.deleted && selectedOfficehour?.active)
                  setShowModal(true);
              }
            }}
          >
            {cell.render("Cell")}
          </Table.Cell>
        );
        break;
    }
    return _cell;
  };

  useEffect(() => {
    selectedRow.map((rowSelect) => {
      return rowSelect._id;
    });
  }, [selectedRow]);

  const customClass: React.HTMLProps<HTMLElement>["className"] =
    "!min-h-[60px] xl:!h-[60px] p-0 gap-0 border-b-1 border-b-slate-100 bg-base-white";
  return (
    <Table.Body {...getTableBodyProps({})}>
      {page.map((row) => {
        prepareRow(row);
        const _original = row.original as cellObject;
        const getBorder = () => {
          if (surveyrating || type === "target") return "";
          if (["template"].includes(type) && _original?.status) {
            switch (_original?.status as string) {
              case "active":
              case "active-pending":
              case "active-low":
              case "active-high":
              case "active-medium":
                return "border-l-4 border-l-emerald-500";
              case "inactive":
              case "paused":
              case "disabled":
                return "border-l-4 border-l-slate-500";
              case "update":
              case "in-review":
              case "appeal-requested":
              case "draft":
                return "border-l-4 border-l-amber-500";
              case "rejected":
                return "border-l-4 border-l-red-500";
              default:
                return "";
            }
          }
          if (["campaignbatch"].includes(type))
            return _original?.status === "SUCCESS"
              ? " border-l-4 border-l-green"
              : "border-l-4 border-l-agidesk";
          if (_original?.deleted) return "border-l-4 border-l-agitalks-alpha30";
          if (_original?.active) return "border-l-4 border-l-green";
          return "border-l-4 border-l-red";
        };
        const pointer =
          !surveyrating || !["campaignbatch"].includes(type)
            ? "cursor-pointer"
            : "";
        return (
          <Table.Row
            {...row.getRowProps({
              className: `${pointer} ${customClass} ${getBorder()}`,
            })}
          >
            {row?.cells?.map((cell) => {
              if (cell.column.id === "selection" && !selection) return false;

              return cell.column.id === "selection" ? (
                <Table.Cell
                  {...cell.getCellProps({})}
                  textAlign="center"
                  className={`${pointer} !flex-1 !self-stretch ${getBorder()}`}
                >
                  {cell.render("Cell")}
                </Table.Cell>
              ) : (
                renderCell(cell, _original)
              );
            })}
          </Table.Row>
        );
      })}
    </Table.Body>
  );
};

TableBody.defaultProps = {
  setShowModal: null,
  setSelectedRow: null,
  selection: true,
};

export default TableBody;
