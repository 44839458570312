import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import { permissiongroupsService } from "../services";
import { IUser, RoleUser } from "./users.slice";
import customToast from "../shared/utils/customToast";
import { IToastType } from "../shared/models/types/Toast.type";
import { ITeam } from "./teams.slice";

export enum Permissions {
  VIEW_PERMISSIONS_GROUPS = "VIEW_PERMISSIONS_GROUPS",
  CREATE_PERMISSIONS_GROUPS = "CREATE_PERMISSIONS_GROUPS",
  UPDATE_PERMISSIONS_GROUPS = "UPDATE_PERMISSIONS_GROUPS",
  DELETE_PERMISSIONS_GROUPS = "DELETE_PERMISSIONS_GROUPS",

  VIEW_GENERAL_SETTINGS = "VIEW_GENERAL_SETTINGS",
  UPDATE_GENERAL_SETTINGS = "UPDATE_GENERAL_SETTINGS",

  VIEW_CHATS = "VIEW_CHATS",

  VIEW_WAITING_LINE = "VIEW_WAITING_LINE",
  UPDATE_WAITING_LINE = "UPDATE_WAITING_LINE",
  START_SERVICE_FROM_WAITING_LINE = "START_SERVICE_FROM_WAITING_LINE",
  CANCEL_WAITING_LINE = "CANCEL_WAITING_LINE",

  VIEW_MY_CHATS = "VIEW_MY_CHATS",
  CREATE_MY_CHATS = "CREATE_MY_CHATS",
  UPDATE_MY_CHATS = "UPDATE_MY_CHATS",
  DELETE_MY_CHATS = "DELETE_MY_CHATS",
  TRANSFER_CHATS = "TRANSFER_CHATS",
  CANCEL_CHAT = "CANCEL_CHAT",
  CREATE_CHAT_GROUP = "CREATE_CHAT_GROUP",

  VIEW_TEAM_CHATS = "VIEW_TEAM_CHATS",
  UPDATE_TEAM_CHATS = "UPDATE_TEAM_CHATS",
  DELETE_TEAM_CHATS = "DELETE_TEAM_CHATS",
  TRANSFER_TEAM_CHATS = "TRANSFER_TEAM_CHATS",
  CANCEL_TEAM_CHATS = "CANCEL_TEAM_CHATS",

  VIEW_CHATS_HISTORY = "VIEW_CHATS_HISTORY",
  UPDATE_CHATS_HISTORY = "UPDATE_CHATS_HISTORY",

  VIEW_AGENTS = "VIEW_AGENTS",
  CREATE_AGENTS = "CREATE_AGENTS",
  UPDATE_AGENTS = "UPDATE_AGENTS",
  DELETE_AGENTS = "DELETE_AGENTS",

  VIEW_TEAMS = "VIEW_TEAMS",
  CREATE_TEAMS = "CREATE_TEAMS",
  UPDATE_TEAMS = "UPDATE_TEAMS",
  DELETE_TEAMS = "DELETE_TEAMS",

  VIEW_CONTACTS = "VIEW_CONTACTS",
  CREATE_CONTACTS = "CREATE_CONTACTS",
  UPDATE_CONTACTS = "UPDATE_CONTACTS",
  DELETE_CONTACTS = "DELETE_CONTACTS",
  MARK_CONTACTS_AS_SPAM = "MARK_CONTACTS_AS_SPAM",

  VIEW_CUSTOMERS = "VIEW_CUSTOMERS",
  CREATE_CUSTOMERS = "CREATE_CUSTOMERS",
  UPDATE_CUSTOMERS = "UPDATE_CUSTOMERS",
  DELETE_CUSTOMERS = "DELETE_CUSTOMERS",

  VIEW_DASHBOARDS = "VIEW_DASHBOARDS",

  VIEW_SETTINGS = "VIEW_SETTINGS",

  VIEW_OFFICE_HOURS = "VIEW_OFFICE_HOURS",
  CREATE_OFFICE_HOURS = "CREATE_OFFICE_HOURS",
  UPDATE_OFFICE_HOURS = "UPDATE_OFFICE_HOURS",
  DELETE_OFFICE_HOURS = "DELETE_OFFICE_HOURS",
  SET_DEFAULT_OFFICE_HOURS = "SET_DEFAULT_OFFICE_HOURS",

  VIEW_HOLLIDAYS = "VIEW_HOLLIDAYS",
  CREATE_HOLLIDAYS = "CREATE_HOLLIDAYS",
  UPDATE_HOLLIDAYS = "UPDATE_HOLLIDAYS",
  DELETE_HOLLIDAYS = "DELETE_HOLLIDAYS",

  VIEW_OFFILINE_MESSAGES = "VIEW_OFFILINE_MESSAGES",
  CREATE_OFFILINE_MESSAGES = "CREATE_OFFILINE_MESSAGES",
  UPDATE_OFFILINE_MESSAGES = "UPDATE_OFFILINE_MESSAGES",
  DELETE_OFFILINE_MESSAGES = "DELETE_OFFILINE_MESSAGES",

  VIEW_COMMENT_TEMPLATES = "VIEW_COMMENT_TEMPLATES",
  CREATE_COMMENT_TEMPLATES = "CREATE_COMMENT_TEMPLATES",
  UPDATE_COMMENT_TEMPLATES = "UPDATE_COMMENT_TEMPLATES",
  DELETE_COMMENT_TEMPLATES = "DELETE_COMMENT_TEMPLATES",

  VIEW_SURVEYS = "VIEW_SURVEYS",
  CREATE_SURVEYS = "CREATE_SURVEYS",
  UPDATE_SURVEYS = "UPDATE_SURVEYS",
  DELETE_SURVEYS = "DELETE_SURVEYS",

  VIEW_SURVEYS_RESULTS = "VIEW_SURVEYS_RESULTS",

  VIEW_CAMPAIGNS = "VIEW_CAMPAIGNS",
  CREATE_CAMPAIGNS = "CREATE_CAMPAIGNS",
  UPDATE_CAMPAIGNS = "UPDATE_CAMPAIGNS",
  DELETE_CAMPAIGNS = "DELETE_CAMPAIGNS",

  START_CAMPAIGNS = "START_CAMPAIGNS",

  VIEW_CHAT_TAGS = "VIEW_CHAT_TAGS",
  CREATE_CHAT_TAGS = "CREATE_CHAT_TAGS",
  UPDATE_CHAT_TAGS = "UPDATE_CHAT_TAGS",
  DELETE_CHAT_TAGS = "DELETE_CHAT_TAGS",

  VIEW_CONTACT_TAGS = "VIEW_CONTACT_TAGS",
  CREATE_CONTACT_TAGS = "CREATE_CONTACT_TAGS",
  UPDATE_CONTACT_TAGS = "UPDATE_CONTACT_TAGS",
  DELETE_CONTACT_TAGS = "DELETE_CONTACT_TAGS",

  VIEW_INTEGRATIONS = "VIEW_INTEGRATIONS",

  VIEW_INTEGRATIONS_AGIBOT = "VIEW_INTEGRATIONS_AGIBOT",
  CREATE_INTEGRATIONS_AGIBOT = "CREATE_INTEGRATIONS_AGIBOT",
  UPDATE_INTEGRATIONS_AGIBOT = "UPDATE_INTEGRATIONS_AGIBOT",
  DELETE_INTEGRATIONS_AGIBOT = "DELETE_INTEGRATIONS_AGIBOT",

  VIEW_INTEGRATIONS_VTEX = "VIEW_INTEGRATIONS_VTEX",
  CREATE_INTEGRATIONS_VTEX = "CREATE_INTEGRATIONS_VTEX",
  UPDATE_INTEGRATIONS_VTEX = "UPDATE_INTEGRATIONS_VTEX",
  DELETE_INTEGRATIONS_VTEX = "DELETE_INTEGRATIONS_VTEX",

  VIEW_INTEGRATIONS_WHATSAPP = "VIEW_INTEGRATIONS_WHATSAPP",
  CREATE_INTEGRATIONS_WHATSAPP = "CREATE_INTEGRATIONS_WHATSAPP",
  UPDATE_INTEGRATIONS_WHATSAPP = "VIEW_INTEGRATIONS_WHATSAPP",
  DELETE_INTEGRATIONS_WHATSAPP = "DELETE_INTEGRATIONS_WHATSAPP",

  VIEW_INTEGRATIONS_AGIDESK = "VIEW_INTEGRATIONS_AGIDESK",
  CREATE_INTEGRATIONS_AGIDESK = "CREATE_INTEGRATIONS_AGIDESK",
  UPDATE_INTEGRATIONS_AGIDESK = "UPDATE_INTEGRATIONS_AGIDESK",
  DELETE_INTEGRATIONS_AGIDESK = "DELETE_INTEGRATIONS_AGIDESK",
}

export const RoleUserPermissions = {
  ADMINISTRATOR: [
    Permissions.VIEW_PERMISSIONS_GROUPS,
    Permissions.CREATE_PERMISSIONS_GROUPS,
    Permissions.UPDATE_PERMISSIONS_GROUPS,
    Permissions.DELETE_PERMISSIONS_GROUPS,

    Permissions.VIEW_GENERAL_SETTINGS,
    Permissions.UPDATE_GENERAL_SETTINGS,

    Permissions.VIEW_CHATS,

    Permissions.VIEW_WAITING_LINE,
    Permissions.UPDATE_WAITING_LINE,
    Permissions.START_SERVICE_FROM_WAITING_LINE,
    Permissions.CANCEL_WAITING_LINE,

    Permissions.VIEW_MY_CHATS,
    Permissions.CREATE_MY_CHATS,
    Permissions.UPDATE_MY_CHATS,
    Permissions.DELETE_MY_CHATS,
    Permissions.TRANSFER_CHATS,
    Permissions.CANCEL_CHAT,
    Permissions.CREATE_CHAT_GROUP,

    Permissions.VIEW_TEAM_CHATS,
    Permissions.UPDATE_TEAM_CHATS,
    Permissions.DELETE_TEAM_CHATS,
    Permissions.TRANSFER_TEAM_CHATS,
    Permissions.CANCEL_TEAM_CHATS,

    Permissions.VIEW_CHATS_HISTORY,
    Permissions.UPDATE_CHATS_HISTORY,

    Permissions.VIEW_AGENTS,
    Permissions.CREATE_AGENTS,
    Permissions.UPDATE_AGENTS,
    Permissions.DELETE_AGENTS,

    Permissions.VIEW_TEAMS,
    Permissions.CREATE_TEAMS,
    Permissions.UPDATE_TEAMS,
    Permissions.DELETE_TEAMS,

    Permissions.VIEW_CONTACTS,
    Permissions.CREATE_CONTACTS,
    Permissions.UPDATE_CONTACTS,
    Permissions.DELETE_CONTACTS,
    Permissions.MARK_CONTACTS_AS_SPAM,

    Permissions.VIEW_CUSTOMERS,
    Permissions.CREATE_CUSTOMERS,
    Permissions.UPDATE_CUSTOMERS,
    Permissions.DELETE_CUSTOMERS,

    Permissions.VIEW_DASHBOARDS,

    Permissions.VIEW_SETTINGS,

    Permissions.VIEW_OFFICE_HOURS,
    Permissions.CREATE_OFFICE_HOURS,
    Permissions.UPDATE_OFFICE_HOURS,
    Permissions.DELETE_OFFICE_HOURS,
    Permissions.SET_DEFAULT_OFFICE_HOURS,

    Permissions.VIEW_HOLLIDAYS,
    Permissions.CREATE_HOLLIDAYS,
    Permissions.UPDATE_HOLLIDAYS,
    Permissions.DELETE_HOLLIDAYS,

    Permissions.VIEW_OFFILINE_MESSAGES,
    Permissions.CREATE_OFFILINE_MESSAGES,
    Permissions.UPDATE_OFFILINE_MESSAGES,
    Permissions.DELETE_OFFILINE_MESSAGES,

    Permissions.VIEW_COMMENT_TEMPLATES,
    Permissions.CREATE_COMMENT_TEMPLATES,
    Permissions.UPDATE_COMMENT_TEMPLATES,
    Permissions.DELETE_COMMENT_TEMPLATES,

    Permissions.VIEW_SURVEYS,
    Permissions.CREATE_SURVEYS,
    Permissions.UPDATE_SURVEYS,
    Permissions.DELETE_SURVEYS,

    Permissions.VIEW_SURVEYS_RESULTS,

    Permissions.VIEW_CAMPAIGNS,
    Permissions.CREATE_CAMPAIGNS,
    Permissions.UPDATE_CAMPAIGNS,
    Permissions.DELETE_CAMPAIGNS,

    Permissions.START_CAMPAIGNS,

    Permissions.VIEW_CHAT_TAGS,
    Permissions.CREATE_CHAT_TAGS,
    Permissions.UPDATE_CHAT_TAGS,
    Permissions.DELETE_CHAT_TAGS,

    Permissions.VIEW_CONTACT_TAGS,
    Permissions.CREATE_CONTACT_TAGS,
    Permissions.UPDATE_CONTACT_TAGS,
    Permissions.DELETE_CONTACT_TAGS,

    Permissions.VIEW_INTEGRATIONS,

    Permissions.VIEW_INTEGRATIONS_AGIBOT,
    Permissions.CREATE_INTEGRATIONS_AGIBOT,
    Permissions.UPDATE_INTEGRATIONS_AGIBOT,
    Permissions.DELETE_INTEGRATIONS_AGIBOT,

    Permissions.VIEW_INTEGRATIONS_VTEX,
    Permissions.CREATE_INTEGRATIONS_VTEX,
    Permissions.UPDATE_INTEGRATIONS_VTEX,
    Permissions.DELETE_INTEGRATIONS_VTEX,

    Permissions.VIEW_INTEGRATIONS_WHATSAPP,
    Permissions.CREATE_INTEGRATIONS_WHATSAPP,
    Permissions.VIEW_INTEGRATIONS_WHATSAPP,
    Permissions.DELETE_INTEGRATIONS_WHATSAPP,

    Permissions.VIEW_INTEGRATIONS_AGIDESK,
    Permissions.CREATE_INTEGRATIONS_AGIDESK,
    Permissions.UPDATE_INTEGRATIONS_AGIDESK,
    Permissions.DELETE_INTEGRATIONS_AGIDESK,
  ],
  SUPERVISOR: [
    Permissions.VIEW_CHATS,

    Permissions.VIEW_WAITING_LINE,
    Permissions.UPDATE_WAITING_LINE,
    Permissions.START_SERVICE_FROM_WAITING_LINE,
    Permissions.CANCEL_WAITING_LINE,

    Permissions.VIEW_MY_CHATS,
    Permissions.CREATE_MY_CHATS,
    Permissions.UPDATE_MY_CHATS,
    Permissions.TRANSFER_CHATS,
    Permissions.CANCEL_CHAT,
    Permissions.CREATE_CHAT_GROUP,

    Permissions.VIEW_TEAM_CHATS,
    Permissions.UPDATE_TEAM_CHATS,
    Permissions.TRANSFER_TEAM_CHATS,
    Permissions.CANCEL_TEAM_CHATS,

    Permissions.VIEW_CHATS_HISTORY,
    Permissions.UPDATE_CHATS_HISTORY,

    Permissions.VIEW_AGENTS,
    Permissions.CREATE_AGENTS,
    Permissions.UPDATE_AGENTS,

    Permissions.VIEW_TEAMS,
    Permissions.CREATE_TEAMS,
    Permissions.UPDATE_TEAMS,

    Permissions.VIEW_CONTACTS,
    Permissions.CREATE_CONTACTS,
    Permissions.UPDATE_CONTACTS,
    Permissions.MARK_CONTACTS_AS_SPAM,

    Permissions.VIEW_CUSTOMERS,
    Permissions.CREATE_CUSTOMERS,
    Permissions.UPDATE_CUSTOMERS,

    Permissions.VIEW_DASHBOARDS,

    Permissions.VIEW_SETTINGS,

    Permissions.VIEW_OFFICE_HOURS,
    Permissions.CREATE_OFFICE_HOURS,
    Permissions.UPDATE_OFFICE_HOURS,
    Permissions.SET_DEFAULT_OFFICE_HOURS,

    Permissions.VIEW_HOLLIDAYS,
    Permissions.CREATE_HOLLIDAYS,
    Permissions.UPDATE_HOLLIDAYS,

    Permissions.VIEW_OFFILINE_MESSAGES,
    Permissions.CREATE_OFFILINE_MESSAGES,
    Permissions.UPDATE_OFFILINE_MESSAGES,

    Permissions.VIEW_COMMENT_TEMPLATES,
    Permissions.CREATE_COMMENT_TEMPLATES,
    Permissions.UPDATE_COMMENT_TEMPLATES,

    Permissions.VIEW_SURVEYS,
    Permissions.CREATE_SURVEYS,
    Permissions.UPDATE_SURVEYS,

    Permissions.VIEW_SURVEYS_RESULTS,

    Permissions.VIEW_CAMPAIGNS,
    Permissions.CREATE_CAMPAIGNS,
    Permissions.UPDATE_CAMPAIGNS,

    Permissions.START_CAMPAIGNS,

    Permissions.VIEW_CHAT_TAGS,
    Permissions.CREATE_CHAT_TAGS,
    Permissions.UPDATE_CHAT_TAGS,

    Permissions.VIEW_CONTACT_TAGS,
    Permissions.CREATE_CONTACT_TAGS,
    Permissions.UPDATE_CONTACT_TAGS,

    Permissions.VIEW_INTEGRATIONS,

    Permissions.VIEW_INTEGRATIONS_AGIBOT,

    Permissions.VIEW_INTEGRATIONS_VTEX,

    Permissions.VIEW_INTEGRATIONS_WHATSAPP,

    Permissions.VIEW_INTEGRATIONS_AGIDESK,
  ],
  AGENT: [
    Permissions.VIEW_CHATS,

    Permissions.VIEW_WAITING_LINE,
    Permissions.UPDATE_WAITING_LINE,
    Permissions.START_SERVICE_FROM_WAITING_LINE,

    Permissions.VIEW_MY_CHATS,
    Permissions.CREATE_MY_CHATS,
    Permissions.UPDATE_MY_CHATS,
    Permissions.TRANSFER_CHATS,
    Permissions.CREATE_CHAT_GROUP,

    Permissions.VIEW_TEAM_CHATS,
    Permissions.UPDATE_TEAM_CHATS,
    Permissions.TRANSFER_TEAM_CHATS,

    Permissions.VIEW_CHATS_HISTORY,
    Permissions.UPDATE_CHATS_HISTORY,

    Permissions.VIEW_AGENTS,

    Permissions.VIEW_TEAMS,

    Permissions.VIEW_CONTACTS,

    Permissions.VIEW_CUSTOMERS,

    Permissions.VIEW_SETTINGS,

    Permissions.VIEW_COMMENT_TEMPLATES,

    Permissions.VIEW_CHAT_TAGS,

    Permissions.VIEW_CONTACT_TAGS,
  ],
};

export interface IPermissionGroup {
  _id?: string;
  name: string;
  tenant?: string;
  role: RoleUser;
  permissions?: Permissions[];
  users?: IUser[];
  teams?: ITeam[];

  default?: boolean;
  active: boolean;
  created_at?: string;
  updated_at?: string;
  deleted?: boolean;
  deleted_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
}

export interface IFilterPermissionGroup {
  skip: number;
  limit: number;
  filter: string;
  deleted: boolean;
}

export const getSearchPermissiongroups = createAsyncThunk(
  "permissiongroups/search",
  async (filterPermissiongroup: IFilterPermissionGroup, thunkAPI) => {
    try {
      return await permissiongroupsService.search(filterPermissiongroup);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const selectPermissiongroup = createAsyncThunk(
  "permissiongroups/selectPermissiongroup",
  async ({ _id }: { _id: string }, thunkAPI) => {
    try {
      return await permissiongroupsService.permissiongroup({ _id });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updatePermissiongroup = createAsyncThunk(
  "permissiongroups/update",
  async (
    {
      _permissionGroup,
      noToast,
    }: { _permissionGroup: IPermissionGroup; noToast?: boolean },
    thunkAPI
  ) => {
    try {
      return await permissiongroupsService.update(_permissionGroup);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const attachUsersToPermissiongroup = createAsyncThunk(
  "permissiongroups/attach/users",
  async (
    {
      permissionGroupId,
      users,
      noToast,
    }: {
      permissionGroupId: string;
      users: string[];
      noToast?: boolean;
    },
    thunkAPI
  ) => {
    try {
      return await permissiongroupsService.attachToUsers(
        permissionGroupId,
        users
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const attachTeamsToPermissiongroup = createAsyncThunk(
  "permissiongroups/attach/teams",
  async (
    {
      permissionGroupId,
      teams,
      noToast,
    }: {
      permissionGroupId: string;
      teams: string[];
      noToast?: boolean;
    },
    thunkAPI
  ) => {
    try {
      return await permissiongroupsService.attachToTeams(
        permissionGroupId,
        teams
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const detachUsersToPermissiongroup = createAsyncThunk(
  "permissiongroups/detach/users",
  async (
    {
      permissionGroupId,
      users,
      noToast,
    }: {
      permissionGroupId: string;
      users: string[];
      noToast?: boolean;
    },
    thunkAPI
  ) => {
    try {
      return await permissiongroupsService.detachToUsers(
        permissionGroupId,
        users
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const detachTeamsToPermissiongroup = createAsyncThunk(
  "permissiongroups/detach/teams",
  async (
    {
      permissionGroupId,
      teams,
      noToast,
    }: {
      permissionGroupId: string;
      teams: string[];
      noToast?: boolean;
    },
    thunkAPI
  ) => {
    try {
      return await permissiongroupsService.detachToTeams(
        permissionGroupId,
        teams
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deletePermissiongroup = createAsyncThunk(
  "permissiongroups/delete",
  async (ids: string[], thunkAPI) => {
    try {
      return await permissiongroupsService.deletePermissiongroup({ ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createPermissionGroup = createAsyncThunk(
  "permissiongroups/create",
  async (newPermissionGroup: IPermissionGroup, thunkAPI) => {
    try {
      return await permissiongroupsService.create(newPermissionGroup);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

interface PermissiongroupsState extends AsyncState {
  isLoadingPermissiongroups: boolean;
  isLoadingDropdownPermissiongroups: boolean;
  isCreatingPermissiongroups: boolean;
  isRemoving: boolean;
  permissiongroups: IPermissionGroup[];
  totalPermissiongroups: number;
  selectedPermissiongroups: IPermissionGroup | null;
  selectedPermissiongroup: IPermissionGroup | null;
  selectedLocalPermissiongroup: IPermissionGroup | null;
}

const initialState: PermissiongroupsState = {
  permissiongroups: [],
  isLoadingPermissiongroups: false,
  isRemoving: false,
  isLoadingDropdownPermissiongroups: false,
  isCreatingPermissiongroups: false,
  totalPermissiongroups: 0,
  selectedPermissiongroups: null,
  selectedPermissiongroup: null,
  selectedLocalPermissiongroup: null,
  isSuccess: false,
  isError: false,
};

export const permissiongroupsSlice = createSlice({
  name: "permissiongroups",
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
    reselectPermissiongroups(
      state,
      action: PayloadAction<IPermissionGroup | null>
    ) {
      state.selectedPermissiongroups = action.payload;
    },
    reselectPermissiongroup(
      state,
      action: PayloadAction<IPermissionGroup | null>
    ) {
      state.selectedPermissiongroup = action.payload;
    },
    reselectLocalPermissiongroup(
      state,
      action: PayloadAction<IPermissionGroup | null>
    ) {
      state.selectedLocalPermissiongroup = action.payload;
    },
    selectPermissiongroups(state, action: PayloadAction<IPermissionGroup[]>) {
      state.permissiongroups = action.payload || [];
    },
    selectTotalPermissiongroups(state, action: PayloadAction<number>) {
      state.totalPermissiongroups = action.payload || 0;
    },
  },
  extraReducers: (builder) => {
    builder
      // OFFICEHOURS
      .addCase(getSearchPermissiongroups.pending, (state, action) => {
        if (action.meta.arg.limit === 10) {
          state.isLoadingPermissiongroups = true;
        }
        state.isLoadingDropdownPermissiongroups = true;
      })
      .addCase(getSearchPermissiongroups.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.permissiongroups = action.payload.results || [];
        state.totalPermissiongroups = action.payload.count;
        state.isLoadingPermissiongroups = false;
        state.isLoadingDropdownPermissiongroups = false;
      })
      .addCase(getSearchPermissiongroups.rejected, (state) => {
        state.isError = true;
        state.permissiongroups = [];
        state.isLoadingPermissiongroups = false;
        state.isLoadingDropdownPermissiongroups = false;
      })
      // SHOW CUSTOMER
      .addCase(selectPermissiongroup.pending, (state) => {
        state.isLoadingPermissiongroups = true;
      })
      .addCase(selectPermissiongroup.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (action.payload?._id) {
          state.selectedPermissiongroup = action.payload;
        }
        state.isLoadingPermissiongroups = false;
      })
      .addCase(selectPermissiongroup.rejected, (state) => {
        state.isError = true;
        state.selectedPermissiongroups = null;
        state.isLoadingPermissiongroups = false;
      })
      // UPDATE CUSTOMER
      .addCase(updatePermissiongroup.pending, (/* state */) => {
        // state.isLoadingPermissiongroups = true;
      })
      .addCase(updatePermissiongroup.fulfilled, (state, action) => {
        if (
          action?.payload !== null &&
          typeof action?.payload?._id !== "undefined"
        ) {
          state.isSuccess = true;
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.SUCCESS,
              message: `Alteração salva com sucesso!`,
            });
          }
        } else {
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado!`,
            });
          }
          state.isError = true;
        }
        state.isLoadingPermissiongroups = false;
      })
      .addCase(updatePermissiongroup.rejected, (state) => {
        state.isError = true;
        state.selectedPermissiongroups = null;
        state.isLoadingPermissiongroups = false;
      })
      .addCase(createPermissionGroup.pending, (state) => {
        state.isCreatingPermissiongroups = true;
      })
      .addCase(createPermissionGroup.fulfilled, (state, action) => {
        if (typeof action?.payload?._id !== "undefined") {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Grupo de permissão criado com sucesso!`,
          });
          const newState = state.permissiongroups;
          newState.push(action.payload);
          state.permissiongroups = newState;
        } else {
          state.isError = true;
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado! Tente novamente.`,
          });
        }
        state.isCreatingPermissiongroups = false;
      })
      .addCase(createPermissionGroup.rejected, (state) => {
        state.isError = true;
        state.isCreatingPermissiongroups = false;
      })
      .addCase(deletePermissiongroup.pending, (state) => {
        state.isRemoving = true;
      })
      .addCase(deletePermissiongroup.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          typeof action.payload !== "undefined" &&
          typeof action.payload?.message !== "string"
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Remoção realizada com sucesso!`,
          });
        } else if (
          typeof action.payload?.message === "string" &&
          action.payload.message.includes("default to the role")
        ) {
          customToast({
            type: IToastType.WARNING,
            message:
              "Opa! Este grupo de permissões está definido como padrão. Defina outro grupo como padrão antes de remover.",
          });
        } else {
          state.isError = true;
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado!`,
          });
        }
        state.isRemoving = false;
      })
      .addCase(deletePermissiongroup.rejected, (state) => {
        state.isError = true;
        state.isRemoving = false;
      });
  },
});

export default permissiongroupsSlice.reducer;
export const {
  logout,
  reselectPermissiongroup,
  reselectPermissiongroups,
  reselectLocalPermissiongroup,
  selectPermissiongroups,
  selectTotalPermissiongroups,
} = permissiongroupsSlice.actions;
