import React from "react";
import { Form } from "semantic-ui-react";
import InputSelectAccount from "./InputSelectAccount.component";
import LanguageDropdown from "./Language.dropdown.component";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import { reselectLocalTemplate } from "../../../slices/templates.slice";
import {
  capitalizeFirstLetter,
  removeAccents,
} from "../../../hooks/useFormatText";

const ConfigFormGroup = () => {
  const dispatch = useAppDispatch();
  const { selectedLocalTemplate } = useAppSelector((state) => state.templates);

  return (
    <Form
      id="ConfigFormGroup"
      className="flex flex-col items-start gap-9 w-full"
    >
      <Form.Group className="flex !flex-col self-stretch items-start pl-2 gap-2.5 !mb-0">
        <label className="">Nome do template</label>
        <Form.Input
          className="w-full !p-0 !pb-0"
          defaultValue={capitalizeFirstLetter(
            selectedLocalTemplate?.selectedConfig?.displayName?.replace(
              /_/g,
              " "
            ) || ""
          )}
          maxLength={70}
          onChange={(e, { value }) => {
            dispatch(
              reselectLocalTemplate({
                ...selectedLocalTemplate,
                selectedConfig: {
                  ...selectedLocalTemplate?.selectedConfig,
                  name: removeAccents(value)
                    .replace(/[^a-zA-Z0-9 ]/g, "")
                    .replace(/ /g, "_")
                    .toLocaleLowerCase(),
                  displayName: capitalizeFirstLetter(value?.replace(/_/g, " ")),
                },
              })
            );
          }}
          variant="outlined"
          placeholder="Digite um nome"
        />
        <span className="leading-trim-both text-edge-cap text-50 leading-5 fg-subtle truncate max-w-[500px]">
          {selectedLocalTemplate?.selectedConfig?.name
            ? `Nome cadastrado: ${selectedLocalTemplate?.selectedConfig?.name}`
            : "Dê um nome que descreva o propósito da mensagem."}
        </span>
      </Form.Group>
      <Form.Group className="flex items-start self-stretch gap-4 !mb-0">
        <InputSelectAccount />
        <LanguageDropdown
          defaultValue={
            selectedLocalTemplate?.selectedConfig?.language ?? "pt_BR"
          }
          onChange={(e, { value }) => {
            dispatch(
              reselectLocalTemplate({
                ...selectedLocalTemplate,
                selectedConfig: {
                  ...selectedLocalTemplate?.selectedConfig,
                  language: value?.toString() || "pt_BR",
                },
              })
            );
          }}
          label
        />
      </Form.Group>
    </Form>
  );
};

export default ConfigFormGroup;
