export const DEFAULT_COLUMNS = [
  {
    Header: "Nome",
    accessor: "name",
  },
  {
    Header: "Atualização",
    accessor: "updated_at",
  },
];

export const TARGET_COLUMNS = [
  {
    Header: "Alvo",
    accessor: "target",
  },
  {
    Header: "Telefone",
    accessor: "phone",
  },
];

export const DEFAULT_WITH_TEAMS_COLUMNS = [
  {
    Header: "Nome",
    accessor: "name",
  },
  {
    Header: "Equipes",
    accessor: "teams",
  },
  {
    Header: "Atualização",
    accessor: "updated_at",
  },
];

export const CONTACTS_COLUMNS = [
  {
    Header: "Nome",
    accessor: "name",
  },
  {
    Header: "Clientes",
    accessor: "customers",
  },
  {
    Header: "Atualização",
    accessor: "updated_at",
  },
];

export const OFFICEHOURS_COLUMNS = [
  {
    Header: "Dia da semana",
    accessor: "weekday",
  },
  {
    Header: "Horário inicial",
    accessor: "start",
  },
  {
    Header: "Horário final",
    accessor: "finish",
  },
];

export const WHATSAPP_COLUMNS = [
  {
    Header: "Nome",
    accessor: "name",
  },
  {
    Header: "Número",
    accessor: "phone",
  },
  {
    Header: "Equipes",
    accessor: "teams",
  },
  {
    Header: "Atualização",
    accessor: "updated_at",
  },
];

export const SURVEYRATING_COLUMNS = [
  {
    Header: "Situação",
    accessor: "status",
  },
  {
    Header: "Pesquisa",
    accessor: "created_at",
  },
  {
    Header: "Dados do contato",
    accessor: "user.name",
  },
  {
    Header: "Agente/Equipe",
    accessor: "chat.users.name",
  },
  {
    Header: "Avaliação",
  },
];

export const CAMPAIGNBATCHTARGET_COLUMNS = [
  {
    Header: "Situação",
    accessor: "status",
  },
  {
    Header: "Contato",
    accessor: "target",
  },
  {
    Header: "Agente",
    accessor: "chat.users.name",
  },
  {
    Header: "Mensagem",
    accessor: "statusmessage",
  },
  {
    Header: "Envio",
    accessor: "created_at",
  },
];

export const CAMPAIGNBATCHTARGET_NOCHATS_COLUMNS = [
  {
    Header: "Situação",
    accessor: "status",
  },
  {
    Header: "Contato",
    accessor: "target",
  },
  {
    Header: "Mensagem",
    accessor: "statusmessage",
  },
  {
    Header: "Envio",
    accessor: "created_at",
  },
];

export const TEMPLATES_COLUMNS = [
  {
    Header: "Nome do modelo",
    accessor: "templatename",
  },
  {
    Header: "Situação",
    accessor: "status",
  },
  {
    Header: "Categoria",
    // accessor: "teams",
  },
  {
    Header: "Integração",
    accessor: "integration",
  },
  {
    Header: "Atualização",
    // accessor: "updated_at",
  },
];
