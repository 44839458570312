/* eslint-disable react/require-default-props */
import React from "react";

export interface CategoryCardProps {
  onClick?: VoidFunction;
  variant: "marketing" | "utility" | "auth";
  state: "default" | "selected";
}

export interface CategoryTypesObject {
  [key: string]: {
    icon: string;
    title: string;
    description: string;
  };
}

const CategoryCard = ({
  onClick,
  variant,
  state = "default",
}: CategoryCardProps) => {
  const types: CategoryTypesObject = {
    marketing: {
      icon: "bullhorn",
      title: "Marketing",
      description:
        "Promoções ou informações sobre sua empresa, produto ou serviços.",
    },
    utility: {
      icon: "bell",
      title: "Utilidades",
      description:
        "Mensagens sobre transações, pedidos, solicitações ou lembretes específicos.",
    },
    auth: {
      icon: "unlock",
      title: "Autenticação",
      description:
        "Permite autenticar usuários com senhas descartáveis para logins ou transações.",
    },
  };

  return (
    <button
      type="button"
      onClick={onClick}
      id="CategoryCard"
      disabled={types[variant].icon === "unlock"}
      className={`group w-full max-w-[260px] cursor-pointer flex flex-col p-5 !text-left items-start gap-5 rounded-2 border
         ${
           types[variant].icon === "unlock"
             ? "!border-subtle !hover:!border-subtle !cursor-not-allowed !bg-default"
             : ""
         }
        ${
          state === "default"
            ? "border-subtle hover:border-primary bg-default"
            : "border-primary bg-orange-50"
        }
     `}
    >
      <div
        className={`icon-box ${
          state === "selected" ? "bg-orange-200" : "bg-secondary"
        }`}
      >
        <i
          className={`las la-${types[variant].icon} text-200 text-center ${
            state === "selected" ? "fg-primary" : "fg-default"
          }`}
        />
      </div>
      <h3
        className={`self-stretch ${
          state === "selected"
            ? "fg-primary"
            : "fg-default group-hover:fg-primary"
        } leading-trim-both text-edge-cap text-150 font-bold leading-6`}
      >
        {types[variant].title}
        {types[variant].icon === "unlock" && (
          <span className="fg-weak">
            <br />
            (em breve)
          </span>
        )}
      </h3>
      <span className="self-stretch fg-default leading-trim-both text-edge-cap text-50 leading-5">
        {types[variant].description}
      </span>
    </button>
  );
};

export default CategoryCard;
