/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { parseISO, formatRelative } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import DropdownMenu from "./DropdownMenu.component";
import { readAll } from "../../../slices/messages.slice";
import {
  addToRecents,
  changeChat,
  ChatGroupRole,
  ChatStatus,
  ChatType,
  checkingMessages,
  clearNotifications,
  closeChat,
  IChat,
  selectHistoryContact,
  // updateChat,
  updateReadChat,
} from "../../../slices/chats.slice";
import { RoleUser, UserStatus, UserType } from "../../../slices/users.slice";
import { ChatHeaderProps } from "../models/ChatHeaderProps.type";
import Tag from "../../chats/components/Tag.component";
import CloseButton from "../../../shared/components/Buttons/CloseButton.component";
import TagArea from "../../chats/components/TagArea.component";
import {
  IExternalAppAgidesk,
  reselectExternalappAgidesk,
} from "../../../slices/externalapps.slice";
import NoAvatar from "../../../shared/components/NoAvatar.component";
import Avatar from "../../../shared/components/Avatar.component";
import { ITeam } from "../../../slices/teams.slice";
import { teamsService } from "../../../services";
import useContactLogout from "../../../hooks/useContactLogout";
import { IToastType } from "../../../shared/models/types/Toast.type";
import customToast from "../../../shared/utils/customToast";
import AgideskHeaderSVG from "../../../shared/components/Agidesk/AgideskHeader.icon";
import useUserIsAdmin from "../../../hooks/useUserIsAdmin";
import { storeUserChatPreferences } from "../../../slices/preferences.slice";
import {
  checkChatReadInfo,
  getChatReadArray,
} from "../../chats/components/utils";
import LinkButton from "../../../shared/components/Buttons/LinkButton.component";
import { selectWidget } from "../../../slices/auth.slice";
import useUserIsSupervisor from "../../../hooks/useUserIsSupervisor";
import useAccessControl from "../../../hooks/useAccessControl";
import { Permissions } from "../../../slices/permissiongroups.slice";
import removeDuplicates from "../../../shared/utils/removeDuplicates";

const ChatHeader = ({
  isTyping,
  isRecording,
  setThumbs,
  setVisible,
  setShowDeleteModal,
  setShowCancelModal,
  setShowLeaveModal,
  setShowTagsModal,
  chatContact,
  widgetType,
  toggleList,
  setShowHistory,
  historyMode,
  whoIsTalking,
  setSkip,
}: ChatHeaderProps) => {
  const { canAccess } = useAccessControl();
  const { user } = useAppSelector((state) => state.auth);
  const { selectedChat, scrolling, checkingChatMessages } = useAppSelector(
    (state) => state.chats
  );
  const { isAdmin } = useUserIsAdmin();
  const { isSupervisor } = useUserIsSupervisor();
  const { externalAppAgidesk } = useAppSelector((state) => state.externalapps);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { logoutContact } = useContactLogout();

  const functions = {
    leave: () => {
      const _groupUsersWithoutMe = selectedChat?.groupsettings?.filter((_g) =>
        typeof _g?.user?._id !== "undefined"
          ? _g?.user?._id !== user?._id
          : _g?.user !== user?._id
      );
      let hasOthersAdmins = false;
      _groupUsersWithoutMe?.forEach((_usergroup) => {
        if (_usergroup.role?.includes(ChatGroupRole.ADMIN)) {
          hasOthersAdmins = true;
        }
      });
      if (!hasOthersAdmins) {
        customToast({
          message:
            "O grupo não pode ficar sem um administrador! Promova alguém antes de sair.",
          type: IToastType.WARNING,
        });
      } else if (setShowLeaveModal) setShowLeaveModal(true);
    },
    group: () => {
      navigate(`${location.pathname}#group`);
      setVisible(true);
    },
    infos: () => {
      navigate(`${location.pathname}#infos`);
      setVisible(true);
    },
    transfer: () => {
      navigate(`${location.pathname}#transfer`);
      setVisible(true);
    },
    agidesk: (externalappAgidesk: IExternalAppAgidesk) => {
      navigate(`${location.pathname}#tickets`);
      dispatch(reselectExternalappAgidesk(externalappAgidesk));
      setVisible(true);
    },
    tags: () => {
      if (setShowTagsModal) setShowTagsModal(true);
    },
    finish: () => {
      if (setShowDeleteModal) setShowDeleteModal(true);
    },
    cancel: () => {
      if (setShowCancelModal) setShowCancelModal(true);
    },
    notread: async () => {
      setThumbs([]);
      if (
        typeof selectedChat?._id !== "undefined" &&
        typeof user?._id !== "undefined"
      ) {
        const _read = !checkChatReadInfo({
          chat: selectedChat as IChat,
          userId: user?._id as string,
          read: true,
        });

        const _readResults = getChatReadArray({
          chat: selectedChat,
          userId: user?._id,
          read: _read,
        });
        await dispatch(
          updateReadChat({
            _id: selectedChat._id,
            read: _readResults,
          })
        );
        dispatch(
          changeChat({
            chat: {
              _id: selectedChat?._id,
              read: _readResults,
            },
          })
        );
        dispatch(
          storeUserChatPreferences({
            user: user?._id,
            _id: selectedChat?._id,
            chat: selectedChat || undefined,
            read: false,
          })
        );
        if (!_read) {
          setSkip(0);
          if (selectedChat?.lastmessage?._id) {
            dispatch(addToRecents(selectedChat));
          }
          dispatch(closeChat());
          navigate("/chat");
        }
      }
    },
    close: async () => {
      if (widgetType !== UserType.CONTACT) {
        setThumbs([]);
        if (!scrolling) {
          await dispatch(
            readAll({
              chatId: selectedChat?._id || "",
              userId: user?._id as string,
            })
          );
          if (
            typeof selectedChat?._id !== "undefined" &&
            typeof user?._id !== "undefined"
          ) {
            const _readResults = getChatReadArray({
              chat: selectedChat,
              userId: user?._id,
              read: true,
            });
            await dispatch(
              updateReadChat({
                _id: selectedChat._id,
                read: _readResults,
              })
            );
            dispatch(
              changeChat({
                chat: {
                  _id: selectedChat?._id,
                  read: _readResults,
                },
              })
            );
            dispatch(
              storeUserChatPreferences({
                user: user?._id,
                _id: selectedChat?._id,
                chat: selectedChat || undefined,
                read: true,
              })
            );
          }
          dispatch(
            clearNotifications({
              type: toggleList === "backlog" ? "backlog" : "mine",
              userId: user?._id as string,
            })
          );
        }
        setSkip(0);
        if (selectedChat?.lastmessage?._id) {
          dispatch(addToRecents(selectedChat));
        }
        dispatch(closeChat());
        navigate("/chat");
      } else {
        await logoutContact({});
      }
    },
  };

  const icons = {
    infos: <i className="las la-info-circle text-[16px] mr-[10px]" />,
    transfer: <i className="las la-share text-[16px] mr-[10px]" />,
    agidesk: <i className="las la-life-ring text-[16px] mr-[10px]" />,
    tags: <i className="las la-tags text-[16px] mr-[10px]" />,
    notread: <i className="las la-comment-medical text-[16px] mr-[10px]" />,
    finish: <i className="las la-check text-[16px] text-red mr-[10px]" />,
    cancel: (
      <i className="las la-minus-circle text-[16px] text-red mr-[10px]" />
    ),
    close: <i className="las la-times text-[16px] mr-[10px]" />,
    leave: <i className="las la-sign-out-alt text-[16px] text-red mr-[10px]" />,
  };

  interface headerOptionObject {
    [key: string]: {
      _id: number;
      icon?: JSX.Element;
      text: React.ReactNode;
      description?: string | null;
      content?: string | null;
      onClick(externalappAgidesk?: IExternalAppAgidesk): Promise<void> | void;
      hasDiv?: boolean;
    };
  }

  const [chatTeam, setChatTeam] = useState<string>("");

  const getChatTeam = async () => {
    let _team: ITeam | null = null;
    if (
      typeof selectedChat?.team !== "string" &&
      typeof selectedChat?.team?.name !== "undefined"
    ) {
      _team = selectedChat.team;
    } else if (
      typeof selectedChat?.team !== "undefined" &&
      typeof selectedChat?.team === "string" &&
      (selectedChat.team as string).length > 0
    ) {
      _team = await teamsService.team({ _id: selectedChat?.team });
    }
    if (typeof _team?.name !== "undefined") {
      setChatTeam(_team?.name);
    }
  };

  useEffect(() => {
    (async () => {
      await getChatTeam();
    })();
  }, []);

  const userInGroup = selectedChat?.groupsettings?.find((_gs) =>
    typeof _gs.user?._id !== "undefined"
      ? _gs.user?._id === user?._id
      : _gs.user === user?._id
  );

  const getInternalOptions = () => {
    const _headerOptions: headerOptionObject = !selectedChat?.groupchat
      ? {
          infos: {
            _id: 0,
            icon: icons.infos,
            text: "Detalhes do agente",
            description: null,
            content: "Informações",
            onClick: functions.infos,
            hasDiv: true,
          },
          notread: {
            _id: 1,
            icon: icons.notread,
            text: checkChatReadInfo({
              chat: selectedChat as IChat,
              userId: user?._id as string,
              read: true,
            })
              ? "Marcar como não lido"
              : "Marcar como lido",
            description: null,
            onClick: functions.notread,
            hasDiv: true,
          },
          close: {
            _id: 2,
            icon: icons.close,
            text: "Fechar",
            description: null,
            onClick: functions.close,
          },
        }
      : !userInGroup?.role?.includes(ChatGroupRole.ADMIN)
      ? {
          infos: {
            _id: 0,
            icon: icons.infos,
            text: "Detalhes do grupo",
            description: null,
            content: "Informações",
            onClick: functions.group,
            hasDiv: true,
          },
          notread: {
            _id: 1,
            icon: icons.notread,
            text: checkChatReadInfo({
              chat: selectedChat as IChat,
              userId: user?._id as string,
              read: true,
            })
              ? "Marcar como não lido"
              : "Marcar como lido",
            description: null,
            onClick: functions.notread,
            hasDiv: true,
          },
          leave: {
            _id: 2,
            icon: icons.leave,
            text: <span className="text-red">Sair do grupo</span>,
            description: null,
            onClick: functions.leave,
            hasDiv: true,
          },
          close: {
            _id: 3,
            icon: icons.close,
            text: "Fechar",
            description: null,
            onClick: functions.close,
          },
        }
      : {
          infos: {
            _id: 0,
            icon: icons.infos,
            text: "Detalhes do grupo",
            description: null,
            content: "Informações",
            onClick: functions.group,
            hasDiv: true,
          },
          tags: {
            _id: 1,
            icon: icons.tags,
            text: "Marcadores",
            description: null,
            onClick: functions.tags,
            hasDiv: true,
          },
          notread: {
            _id: 2,
            icon: icons.notread,
            text: checkChatReadInfo({
              chat: selectedChat as IChat,
              userId: user?._id as string,
              read: true,
            })
              ? "Marcar como não lido"
              : "Marcar como lido",
            description: null,
            onClick: functions.notread,
            hasDiv: true,
          },
          leave: {
            _id: 3,
            icon: icons.leave,
            text: <span className="text-red">Sair do grupo</span>,
            description: null,
            onClick: functions.leave,
            hasDiv: true,
          },
          close: {
            _id: 4,
            icon: icons.close,
            text: "Fechar",
            description: null,
            onClick: functions.close,
          },
        };
    return _headerOptions;
  };

  const getDropdownItems = () => {
    let _headerOptions: headerOptionObject =
      selectedChat?.type === ChatType.INTERNAL
        ? getInternalOptions()
        : widgetType !== UserType.EMBEDDED
        ? {
            infos: {
              _id: 0,
              icon: icons.infos,
              text: "Detalhes do contato",
              description: null,
              content: "Informações",
              onClick: functions.infos,
              hasDiv: true,
            },
            tags: {
              _id: 1,
              icon: icons.tags,
              text: "Marcadores",
              description: null,
              onClick: functions.tags,
              hasDiv: true,
            },
            transfer: {
              _id: 2,
              icon: icons.transfer,
              text: "Transferir",
              description: null,
              content: "Equipe e responsável",
              onClick: functions.transfer,
              hasDiv: true,
            },
            finish: {
              _id: 3,
              icon: icons.finish,
              text: <span className="text-red">Encerrar</span>,
              description: null,
              onClick: functions.finish,
              hasDiv: true,
            },
            cancel: {
              _id: 4,
              icon: icons.cancel,
              text: <span className="text-red">Cancelar</span>,
              description: null,
              onClick: functions.cancel,
              hasDiv: true,
            },
            notread: {
              _id: 5,
              icon: icons.notread,
              text: checkChatReadInfo({
                chat: selectedChat as IChat,
                userId: user?._id as string,
                read: true,
              })
                ? "Marcar como não lido"
                : "Marcar como lido",
              description: null,
              onClick: functions.notread,
              hasDiv: true,
            },
            close: {
              _id: 6,
              icon: icons.close,
              text: "Fechar",
              description: null,
              onClick: functions.close,
            },
          }
        : {
            infos: {
              _id: 0,
              icon: icons.infos,
              text: "Detalhes do contato",
              description: null,
              content: "Informações",
              onClick: functions.infos,
              hasDiv: true,
            },
            tags: {
              _id: 1,
              icon: icons.tags,
              text: "Marcadores",
              description: null,
              onClick: functions.tags,
              hasDiv: true,
            },
            transfer: {
              _id: 2,
              icon: icons.transfer,
              text: "Transferir",
              description: null,
              content: "Equipe e responsável",
              onClick: functions.transfer,
              hasDiv: true,
            },
            finish: {
              _id: 3,
              icon: icons.finish,
              text: <span className="text-red">Encerrar</span>,
              description: null,
              onClick: functions.finish,
              hasDiv: true,
            },
            cancel: {
              _id: 4,
              icon: icons.cancel,
              text: <span className="text-red">Cancelar</span>,
              description: null,
              onClick: functions.cancel,
            },
          };
    // RETURN GROUP ACTIONS
    if (selectedChat?.type === ChatType.INTERNAL && selectedChat.groupchat) {
      // USER IS ACTIVE ON GROUP
      const activeUser = selectedChat?.groupsettings?.find((_g) =>
        typeof _g?.user?._id !== "undefined"
          ? _g?.user?._id === user?._id
          : _g?.user === user?._id
      );
      if (activeUser && activeUser.active) {
        if (!activeUser?.role?.includes(ChatGroupRole.ADMIN)) {
          return [
            _headerOptions.infos,
            _headerOptions.notread,
            _headerOptions.leave,
            _headerOptions.close,
          ];
        }
        return [
          _headerOptions.infos,
          _headerOptions.tags,
          _headerOptions.notread,
          _headerOptions.leave,
          _headerOptions.close,
        ];
      }
      // USER ISN'T ACTIVE ON GROUP
      return [_headerOptions.infos, _headerOptions.close];
    }
    // RETURN USER NOT BELONGS TO CHAT && CHAT IS NOT HISTORY
    if (
      typeof selectedChat?.users !== "undefined" &&
      typeof selectedChat?.users?.find((_user) => _user._id === user?._id) ===
        "undefined" &&
      !historyMode
      // &&
      // selectedChat?.status &&
      // ![ChatStatus.WAITCAMPAIGN].includes(selectedChat?.status)
    ) {
      if (selectedChat?.users.length > 1) {
        return [_headerOptions.infos, _headerOptions.close];
      }
      return [
        _headerOptions.infos,
        _headerOptions.tags,
        _headerOptions.notread,
        canAccess(Permissions.CANCEL_CHAT) && _headerOptions.cancel,
        _headerOptions.close,
      ].filter((i) => !!i);
    }
    if (
      selectedChat?.status &&
      [ChatStatus.WAITREPLY, ChatStatus.WAITCAMPAIGN].includes(
        selectedChat?.status
      )
    ) {
      return [
        _headerOptions.infos,
        _headerOptions.tags,
        _headerOptions.notread,
        canAccess(Permissions.UPDATE_WAITING_LINE) && _headerOptions.finish,
        canAccess(Permissions.CANCEL_WAITING_LINE) && _headerOptions.cancel,
        _headerOptions.close,
      ].filter((i) => !!i);
    }
    // ADD AGIDESK EXTERNALAPPS
    if (widgetType !== UserType.EMBEDDED) {
      for (const _agidesk of externalAppAgidesk) {
        if (
          typeof _agidesk._id !== "undefined" &&
          _agidesk?.active &&
          _agidesk?.fields?.enablechat
        ) {
          const _title =
            _agidesk?.fields?.displaytitlechat || _agidesk?.fields?.name;
          const newOption = {
            [_title]: {
              _id: Object.keys(_headerOptions).length,
              text: _title,
              description: null,
              onClick: () => functions.agidesk(_agidesk),
            },
          };
          _headerOptions = {
            ..._headerOptions,
            ...newOption,
          };
        }
      }
    }

    if (
      selectedChat?.type === ChatType.INTERNAL ||
      (selectedChat?.status &&
        [
          ChatStatus.ACTIVE,
          ChatStatus.CANCELED,
          ChatStatus.SUSPEND,
          ChatStatus.FINISH,
        ].includes(selectedChat?.status))
    ) {
      let _arr =
        typeof selectedChat?.users !== "undefined" &&
        ((selectedChat?.status &&
          [ChatStatus.CANCELED, ChatStatus.SUSPEND, ChatStatus.FINISH].includes(
            selectedChat?.status
          )) ||
          typeof selectedChat?.users?.find(
            (_user) => _user._id === user?._id
          ) === "undefined")
          ? selectedChat?.type === ChatType.INTERNAL
            ? [_headerOptions.infos]
            : [_headerOptions.infos, _headerOptions.tags]
          : [
              _headerOptions.infos,
              _headerOptions.tags,
              canAccess(Permissions.TRANSFER_CHATS) && _headerOptions.transfer,
            ].filter((i) => !!i);

      for (const _headerOption of Object.values(_headerOptions)) {
        if (
          (selectedChat?.type === ChatType.INTERNAL &&
            _headerOption._id === 3) ||
          (selectedChat?.type !== ChatType.INTERNAL &&
            _headerOption._id === 7 &&
            typeof selectedChat?.users?.find(
              (_user) => _user._id === user?._id
            ) !== "undefined")
        ) {
          const _newHeader = {
            ..._headerOption,
            content: "Integrações",
            hasDiv:
              _headerOption._id === Object.keys(_headerOptions).length - 1,
          };
          _arr = [..._arr, _newHeader];
          _arr.push(_newHeader);
        } else if (
          selectedChat?.type === ChatType.INTERNAL
            ? _headerOption._id > 2
            : selectedChat?.status &&
              [
                ChatStatus.CANCELED,
                ChatStatus.SUSPEND,
                ChatStatus.FINISH,
              ].includes(selectedChat?.status)
            ? _headerOption._id > 6
            : _headerOption._id > 7
        ) {
          _arr.push({
            ..._headerOption,
            hasDiv:
              _headerOption._id === Object.keys(_headerOptions).length - 1,
          });
        }
      }
      if (
        (selectedChat?.type !== ChatType.INTERNAL &&
          selectedChat?.status &&
          ![
            ChatStatus.CANCELED,
            ChatStatus.SUSPEND,
            ChatStatus.FINISH,
          ].includes(selectedChat?.status) &&
          typeof selectedChat?.users?.find(
            (_user) => _user._id === user?._id
          ) !== "undefined") ||
        (selectedChat?.status &&
          [ChatStatus.WAITREPLY, ChatStatus.WAITCAMPAIGN].includes(
            selectedChat?.status
          ))
      ) {
        if (canAccess(Permissions.UPDATE_MY_CHATS)) {
          _arr.push(_headerOptions.finish);
        }
        if (canAccess(Permissions.CANCEL_CHAT)) {
          _arr.push(_headerOptions.cancel);
        }
      }
      _arr.push(_headerOptions.notread);
      _arr.push(_headerOptions.close);
      if (
        selectedChat?.type === ChatType.INTERNAL &&
        !selectedChat?.groupchat
      ) {
        _arr.splice(1, 1);
      }
      return removeDuplicates(_arr, "_id").filter(
        (item: any) => item !== undefined
      );
      // return _arr;
    }
    return [_headerOptions.infos, _headerOptions.notread, _headerOptions.close];
  };

  const getCheckingIcons = () => {
    const jsx = [];
    if (
      !checkingChatMessages.checking // ||
      // checkingChatMessages.selectedMessageIDs.length < 1
    ) {
      return null;
    }

    for (const _agidesk of externalAppAgidesk) {
      if (
        typeof _agidesk._id !== "undefined" &&
        _agidesk?.active &&
        _agidesk?.fields?.enablechat
      ) {
        const _title =
          _agidesk?.fields?.displaytitlechat || _agidesk?.fields?.name;
        jsx.unshift(
          <div
            key={_agidesk._id}
            role="presentation"
            className="cursor-pointer mb-1"
            title={_title}
            onClick={() => functions.agidesk(_agidesk)}
          >
            <AgideskHeaderSVG />
          </div>
        );
      }
    }
    // if (checkingChatMessages.selectedMessageIDs.length > 0) {
    const _value = checkingChatMessages.selectedMessageIDs.length;
    jsx.unshift(
      <div key="0" className="flex place-items-center">
        <p>
          <b>{_value}</b> {_value === 1 ? "selecionada" : "selecionadas"}
        </p>
        <i
          role="presentation"
          className="las la-backspace text-[30px] mx-2"
          title="Remover seleção"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            dispatch(
              checkingMessages({
                checking: false,
                selectedMessages: [],
              })
            );
          }}
        />
      </div>
    );
    // }
    return jsx;
  };

  const getHistoryIcon = () => {
    if (
      selectedChat?.type !== ChatType.INTERNAL &&
      !chatContact?.spam &&
      toggleList !== "search" &&
      !historyMode &&
      !checkingChatMessages.checking &&
      typeof setShowHistory !== "undefined"
    )
      return (
        <i
          role="presentation"
          className="las la-history text-[30px] mr-2"
          title="Exibir histórico"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            setShowHistory(true);
            if (selectedChat?._id) {
              dispatch(closeChat());
            }
            dispatch(
              selectHistoryContact({
                key: chatContact?._id,
                text: chatContact?.name,
              })
            );
          }}
        />
      );
    return null;
  };

  const getExternalIcon = () => {
    const URL = process.env.REACT_APP_FRONTEND_URL;
    return (
      <a
        href={`${URL}/chat/${selectedChat?._id}&embedded`}
        target="_blank"
        title="Ir para o Agitalks"
        rel="noopener noreferrer"
        onClick={() => {
          dispatch(selectWidget());
        }}
      >
        <i
          role="presentation"
          className="las la-external-link-alt text-[30px] mr-2"
        />
      </a>
    );
  };

  const getWidgetCloseButton = () => {
    return (
      <CloseButton
        extraClass="text-[20px] mr-2"
        onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          if (
            widgetType === UserType.CONTACT &&
            typeof selectedChat?.status !== "undefined" &&
            ![ChatStatus.FINISH, ChatStatus.CANCELED].includes(
              selectedChat.status
            )
          ) {
            if (setShowDeleteModal) setShowDeleteModal(true);
          } else {
            functions.close();
          }
        }}
      />
    );
  };

  const getOptionsCloseComponent = () => {
    if (widgetType !== UserType.CONTACT)
      return (
        <div className="flex items-center">
          {widgetType === UserType.EMBEDDED
            ? getExternalIcon()
            : getHistoryIcon()}
          {widgetType === UserType.EMBEDDED ? null : getCheckingIcons()}
          {selectedChat?.type === ChatType.INTERNAL &&
          widgetType === UserType.EMBEDDED ? null : (
            <DropdownMenu
              id="headerchat"
              icon={
                <i
                  role="presentation"
                  className="las la-ellipsis-h text-[30px] mr-2"
                  title="Opções da conversa"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                  }}
                />
              }
              items={getDropdownItems() as any}
            />
          )}
        </div>
      );
    return getWidgetCloseButton();
  };

  const includedInGroup = selectedChat?.groupsettings
    ?.filter((_gs) => _gs.active === true)
    ?.map((_u) => (typeof _u.user?._id !== "undefined" ? _u.user._id : _u.user))
    .includes(user?._id);
  // const userInGroup = selectedChat?.groupsettings?.find((_gs) =>
  //   typeof _gs.user?._id !== "undefined"
  //     ? _gs.user?._id === user?._id
  //     : _gs.user === user?._id
  // );
  const hasAvatar = () => {
    if (selectedChat?.groupchat) {
      if (includedInGroup) {
        if (
          selectedChat?.avatar &&
          selectedChat.avatar?.path &&
          selectedChat?.avatar?.name
        ) {
          return true;
        }
        return false;
      }
      if (!includedInGroup) {
        if (
          userInGroup?.chatavatar &&
          userInGroup.chatavatar?.path &&
          userInGroup?.chatavatar?.name
        ) {
          return true;
        }
        return false;
      }
    }
    if (
      chatContact &&
      chatContact?.avatar &&
      chatContact.avatar?.path &&
      chatContact?.avatar?.name
    ) {
      return true;
    }
    return false;
  };

  const getPath = () => {
    if (selectedChat?.groupchat) {
      if (includedInGroup) {
        return selectedChat?.avatar?.path;
      }
      if (!includedInGroup) {
        return userInGroup?.chatavatar?.path;
      }
    }
    return chatContact?.avatar?.path;
  };
  const getName = () => {
    if (selectedChat?.groupchat) {
      if (includedInGroup) {
        return selectedChat?.avatar?.name;
      }
      if (!includedInGroup) {
        return userInGroup?.chatavatar?.name;
      }
    }
    return chatContact?.avatar?.name;
  };
  const getTitle = () => {
    if (selectedChat?.groupchat) {
      if (includedInGroup) {
        return selectedChat?.name;
      }
      if (!includedInGroup) {
        return userInGroup?.chatname;
      }
    }
    return chatContact?.name;
  };

  const getCustomTags = () => {
    const jsx = [];
    if (
      typeof selectedChat?.tags !== "undefined" &&
      selectedChat?.tags.length > 0
    ) {
      if (selectedChat?.tags.length > 4) {
        const _tags = selectedChat?.tags;
        const _labels = selectedChat?.tags.map((_tag) => _tag.name).toString();
        jsx.push(
          <>
            <Tag
              key={_tags[0]._id}
              header
              tagType="custom"
              customTag={_tags[0]}
            />
            <Tag
              key={_tags[1]._id}
              header
              tagType="custom"
              customTag={_tags[1]}
            />
            <Tag
              key={_tags[2]._id}
              header
              tagType="custom"
              customTag={_tags[2]}
            />
            <Tag
              key={_tags[3]._id}
              header
              tagType="custom"
              customTag={_tags[3]}
            />
            <h2
              title={_labels}
              className="text-[11px] text-gray-333 font-semibold mr-2"
            >{`e mais ${_tags.length - 4}`}</h2>
          </>
        );
      } else {
        jsx.push(
          selectedChat?.tags.map((_tag) => (
            <Tag key={_tag._id} header tagType="custom" customTag={_tag} />
          ))
        );
      }
    }
    if (
      (selectedChat?.groupchat &&
        userInGroup?.role?.includes(ChatGroupRole.ADMIN)) ||
      ((isAdmin || isSupervisor) &&
        !selectedChat?.groupchat &&
        selectedChat?.type !== ChatType.INTERNAL) ||
      user?.teams?.map((_t) => _t._id).includes(selectedChat?.team?._id)
    ) {
      jsx.push(
        <Tag
          key="add"
          header
          tagType="add-custom"
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault();
            e.stopPropagation();
            functions.tags();
          }}
        />
      );
    }
    return jsx;
  };

  const chatContactTitle = () => {
    const _getName = () => {
      if (selectedChat?.groupchat) {
        return includedInGroup ? selectedChat?.name : userInGroup?.chatname;
      }
      const _name =
        typeof chatContact?.name !== "undefined" && chatContact?.name
          ? chatContact?.name
          : "Aguardando agente...";
      return _name;
    };

    return (
      <div className="flex items-center mt-1">
        <h2 className="text-[16px] mr-2 font-semibold">{_getName()}</h2>
      </div>
    );
  };

  const chatTags = () => {
    if (widgetType === UserType.CONTACT) return null;
    return (
      <TagArea>
        {selectedChat?.type !== ChatType.INTERNAL ? (
          <Tag
            header
            tagType={selectedChat?.status ? "chatstatus" : undefined}
            status={selectedChat?.status}
          />
        ) : null}
        {selectedChat?.type !== ChatType.WHATSAPP &&
        selectedChat?.type !== ChatType.BOTMAKER ? (
          <Tag
            header
            tagType={selectedChat?.type ? "chattype" : undefined}
            type={selectedChat?.type}
          />
        ) : null}
        {typeof selectedChat?.type !== "undefined" &&
        [ChatType.WHATSAPP, ChatType.BOTMAKER].includes(selectedChat?.type) &&
        typeof selectedChat?.campaign?.campaign !== "undefined" ? (
          <Tag
            header
            tagType={selectedChat?.campaign?.campaign ? "campaign" : undefined}
            campaign={selectedChat?.campaign?.campaign}
          />
        ) : null}
        {selectedChat?.type !== ChatType.INTERNAL && !selectedChat?.campaign ? (
          <Tag
            header
            tagType={selectedChat?.externalapp ? "externalapp" : undefined}
            type={selectedChat?.type}
            externalapp={selectedChat?.externalapp}
          />
        ) : null}
        {selectedChat?.type !== ChatType.INTERNAL ? (
          <Tag
            header
            tagType={selectedChat?.transferred ? "transferred" : undefined}
            transferred={selectedChat?.transferred}
          />
        ) : null}
        {selectedChat?.type === ChatType.INTERNAL ? (
          <Tag
            header
            tagType={selectedChat?.groupchat ? "groupchat" : undefined}
            groupchat={selectedChat?.groupchat}
          />
        ) : null}
        {selectedChat?.type !== ChatType.INTERNAL ? (
          <Tag
            header
            tagType={selectedChat?.offline ? "offline" : undefined}
            offline={selectedChat?.offline}
          />
        ) : null}
        {getCustomTags()}
        {/* {typeof selectedChat?.tags !== "undefined" &&
        selectedChat.tags.length > 0
          ? selectedChat.tags.map((_tag) => (
              <Tag
                key={_tag._id}
                header
                tagType="custom"
                customTag={_tag}
              />
            ))
          : null} */}
      </TagArea>
    );
  };

  const teamCustomerTitle = () => {
    const getTeamCustomerSubtitle = () => {
      if (selectedChat?.type !== ChatType.INTERNAL) {
        if (
          typeof chatContact?.customers !== "undefined" &&
          chatContact?.customers?.length > 0 &&
          typeof chatContact?.customers[0]?.name !== "undefined"
        ) {
          return `${chatContact?.customers[0]?.name}${
            chatContact?.customers.length > 1
              ? ` (e mais ${chatContact.customers.length - 1})`
              : ""
          }`;
        }
        return "";
      }
      if (
        typeof chatContact?.teams !== "undefined" &&
        chatContact?.teams?.length > 0 &&
        typeof chatContact?.teams[0]?.name !== "undefined"
      ) {
        return `${chatContact?.teams[0]?.name}${
          chatContact?.teams.length > 1
            ? ` (e mais ${chatContact.teams.length - 1})`
            : ""
        }`;
      }
      return "";
    };

    let icon = "";
    let title = "";
    if (widgetType === UserType.CONTACT && chatTeam.length > 0) {
      icon = "la-user-friends";
      title = chatTeam;
    } else {
      title = getTeamCustomerSubtitle();
      if (!chatContact?.roles?.includes(RoleUser.CONTACT)) {
        icon = "la-user-friends";
      } else {
        icon = "la-building";
      }
    }

    return (
      <div className="flex items-center relative">
        <div className="flex flex-col items-center -mr-10">
          {title.length > 0 ? (
            <div className="flex w-[300px] overflow-hidden whitespace-nowrap text-ellipsis normal">
              <i
                className={`las ${icon} mt-[2px] text-[12px] font-semibold"`}
              />
              <h2 className="text-[12px] font-semibold block w-[300px] overflow-hidden whitespace-nowrap text-ellipsis ml-2">
                {title}
              </h2>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const statusTitle = () => {
    const getSubtitle = () => {
      if (isRecording && !historyMode) {
        if (!selectedChat?.groupchat) return "está gravando áudio...";
        if (typeof whoIsTalking !== "undefined")
          return `${whoIsTalking} está gravando áudio...`;
      }
      if (isTyping && !historyMode) {
        if (!selectedChat?.groupchat) return "está digitando...";
        if (typeof whoIsTalking !== "undefined") {
          return `${whoIsTalking} está digitando...`;
        }
      }
      if (
        widgetType === UserType.CONTACT ||
        chatContact?.roles?.includes(RoleUser.CONTACT) ||
        selectedChat?.groupchat
      ) {
        return "";
      }
      if (chatContact?.status !== UserStatus.OFFLINE) {
        return `Online`;
      }
      if (chatContact?.updated_at) {
        return `visto por último ${formatRelative(
          parseISO(chatContact?.updated_at || ""),
          new Date(),
          {
            locale: ptBR,
          }
        )}`.replace(" às", ", às");
      }
      return "";
    };

    return (
      <div className="flex items-center relative h-[16px]">
        <div
          className={`flex flex-col items-center -mr-10 ${
            isTyping || isRecording ? "italic" : "normal"
          }`}
        >
          <span className="text-[12px] block w-[300px] overflow-hidden whitespace-nowrap text-ellipsis">
            {getSubtitle()}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="flex relative h-[110px] md:[h-100px] cursor-pointer border-b-[1px] border-b-gray-ddd justify-between">
      <div className="flex items-center">
        <div
          role="presentation"
          onClick={
            widgetType !== UserType.CONTACT && !selectedChat?.groupchat
              ? functions.infos
              : () => null
          }
          className="pl-4 my-auto mx-0"
        >
          <div className="w-[49px] h-[49px] relative">
            {hasAvatar() &&
            getPath() &&
            getName() &&
            getTitle() &&
            typeof getPath() !== "undefined" &&
            getName() !== "undefined" &&
            typeof getTitle() !== "undefined" ? (
              <Avatar
                path={getPath() as string}
                name={getName() as string}
                title={getTitle() as string}
              />
            ) : (
              <NoAvatar
                displaytext={getTitle() || "--"}
                elementClass="p-1"
                labelClass="text-[15px]"
              />
            )}
            {selectedChat?.type === ChatType.INTERNAL &&
            !selectedChat?.groupchat ? (
              <div
                title="Status"
                className={`flex absolute w-[15px] h-[15px] rounded-full bottom-[0px] right-[0px] ${
                  chatContact?.status === UserStatus.ONLINE
                    ? "bg-green"
                    : "bg-red"
                }`}
              />
            ) : null}
          </div>
        </div>
        <div
          role="presentation"
          onClick={
            widgetType !== UserType.CONTACT &&
            // !historyMode &&
            !selectedChat?.groupchat
              ? functions.infos
              : () => null
          }
          className="mx-0 my-auto pl-3 relative flex items-center"
        >
          <div className="ml-1">
            {chatContactTitle()}
            {chatTags()}
            {!selectedChat?.groupchat ? teamCustomerTitle() : null}
            {statusTitle()}
          </div>
        </div>
      </div>

      <div className="flex items-center mr-1">{getOptionsCloseComponent()}</div>
    </div>
  );
};

export default ChatHeader;
