/* eslint-disable react/require-default-props */
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import { ITag } from "../../../slices/tags.slice";
import { ICustomer } from "../../../slices/customers.slice";
import { ITeam } from "../../../slices/teams.slice";
import { update, userdata } from "../../../slices/auth.slice";

interface IChatSort {
  selectedSort: number;
  setSelectedSort: React.Dispatch<React.SetStateAction<number>>;
  disabled: boolean;
  toggleList: "mine" | "teams" | "backlog" | "search" | "history";
}

const ChatSortComponent = ({
  selectedSort,
  setSelectedSort,
  disabled,
  toggleList,
}: IChatSort) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);

  return (
    <div
      className={`chat-sort ${disabled ? "disabled" : ""}`}
      title={`Ordenando por conversas mais ${
        selectedSort === 1 ? "antigas" : "recentes"
      }`}
      role="presentation"
      onClick={() => {
        if (!disabled) {
          setSelectedSort((prevState) => {
            const newSort = prevState === 1 ? -1 : 1;
            (async () => {
              await dispatch(
                update({
                  _updateUser: {
                    _id: user?._id,
                    chatfilters: {
                      ...user?.chatfilters,
                      sort: {
                        ...user?.chatfilters?.sort,
                        [toggleList]: newSort,
                      },
                    },
                  },
                  noToast: true,
                })
              );
              await dispatch(userdata());
            })();

            return newSort;
          });
        }
      }}
    >
      <i className="las la-exchange-alt rotate-90" />
      <span>{selectedSort === 1 ? "Mais antigas" : "Mais recentes"}</span>
    </div>
  );
};

export default ChatSortComponent;
