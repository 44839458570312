/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { useLocation } from "react-router-dom";
import LoadingDots from "./LoadingDots.component";
import { useAppSelector } from "../../hooks/redux/hooks";
import NoAvatar from "./NoAvatar.component";
import PopupComponent from "./Popup.component";
import useUserIsAdmin from "../../hooks/useUserIsAdmin";
import useUserIsSupervisor from "../../hooks/useUserIsSupervisor";

const FormAvatar = ({
  instance,
  onChange,
  onRemove,
}: {
  instance: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  onRemove?: () => Promise<void>;
}) => {
  const { isAdmin } = useUserIsAdmin();
  const { isSupervisor } = useUserIsSupervisor();
  const location = useLocation();
  const URL = process.env.REACT_APP_API_BASE_URL;
  const { isUploading } = useAppSelector((state) => state.auth);
  return (
    <div>
      <PopupComponent
        className="flex items-center"
        label="Foto"
        content={
          <div>
            <h1 className="font-semibold text-[12px]">
              - Tamanho máximo do arquivo: 20MB
            </h1>
            <h1 className="font-semibold text-[12px]">
              - Dimensões sugeridas: 150px x 150px
            </h1>
          </div>
        }
      />
      {isUploading ? (
        <LoadingDots className="flex justify-center items-center h-[120px] mb-3" />
      ) : instance && instance?.avatar?.path && instance?.avatar?.name ? (
        <div className="relative">
          {onRemove ? (
            <div
              role="presentation"
              title="Remover imagem"
              onClick={onRemove}
              className="absolute w-[25px] h-[25px] z-[199] bottom-[calc(50%+15px)] right-[calc(50%-60px)] rounded-full  text-red hover:text-red-alphaC0 cursor-pointer"
            >
              <i className="las la-times-circle text-[20px] bg-white rounded-full p-0" />
            </div>
          ) : null}
          <div
            className="w-[120px] h-[120px] relative border-[5px] border-white rounded-full bg-cover mb-3 mt-0 mx-auto overflow-hidden"
            style={{
              // Temporary solution to avoid the x-tenant-id problem with the url file
              backgroundImage: `url(${`${URL}/api/public-files/${instance?.avatar?.path}/${instance?.avatar?.name}`})`,
            }}
          >
            {onChange ? (
              <input
                readOnly={
                  !isAdmin &&
                  !isSupervisor &&
                  !location.pathname.includes("profile") &&
                  !location.pathname.includes("contact")
                }
                type="file"
                className="my_file"
                title="Trocar foto"
                onChange={onChange}
              />
            ) : null}
          </div>
        </div>
      ) : (
        <div className="w-[120px] h-[120px] relative border-[5px] border-white rounded-full bg-cover mb-3 mt-0 mx-auto overflow-hidden">
          <NoAvatar
            elementClass="p-0"
            labelClass="text-[30px]"
            displaytext={instance?.name || "--"}
          />
          {onChange ? (
            <input
              readOnly={
                !isAdmin &&
                !isSupervisor &&
                !location.pathname.includes("profile") &&
                !location.pathname.includes("contact")
              }
              type="file"
              className="my_file"
              title="Trocar foto"
              onChange={onChange}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

FormAvatar.defaultProps = {
  onChange: undefined,
  onRemove: undefined,
};

export default FormAvatar;
