import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CategoryCard from "./CategoryCard.component";
import LinkButton from "../Buttons/LinkButton.component";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import { reselectLocalTemplate } from "../../../slices/templates.slice";

export type CategoryCardItemType = "marketing" | "utility" | "auth";

export interface CategoryCardItemProps {
  type: CategoryCardItemType;
  onClick: VoidFunction;
}

const CategoryCardGroup = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedLocalTemplate, selectedTemplate } = useAppSelector(
    (state) => state.templates
  );
  // const navigate = useNavigate();
  const [selected, setSelected] = useState<CategoryCardItemType | "">(
    (selectedLocalTemplate?.selectedCategory as CategoryCardItemType) ?? ""
  );

  const onClickSelected = (type: CategoryCardItemType) => {
    setSelected(type);
    dispatch(
      reselectLocalTemplate({
        ...selectedLocalTemplate,
        selectedStep: "config",
        selectedCategory: type,
        selectedHeader:
          type === "auth"
            ? {
                type: "none",
              }
            : selectedLocalTemplate?.selectedHeader,
      })
    );
    navigate("#config");
  };

  useEffect(() => {
    if (selectedTemplate?.selectedCategory) {
      onClickSelected(
        selectedTemplate.selectedCategory as CategoryCardItemType
      );
    }
  }, [selectedTemplate?.selectedCategory]);

  const cards: CategoryCardItemProps[] = [
    {
      type: "marketing",
      onClick: () => {
        onClickSelected("marketing");
      },
    },
    {
      type: "utility",
      onClick: () => {
        onClickSelected("utility");
      },
    },
    {
      type: "auth",
      onClick: () => {
        onClickSelected("auth");
      },
    },
  ];

  return (
    <div id="CategoryCardGroup" className="flex flex-col items-start gap-9">
      <div className="relative w-fit p-3 items-start gap-3 rounded-4 bg-secondary">
        <div className="flex gap-3">
          {cards.map((_card) => (
            <CategoryCard
              key={_card.type}
              variant={_card.type}
              state={selected === _card.type ? "selected" : "default"}
              onClick={_card.onClick}
            />
          ))}
        </div>
      </div>
      <a
        href="https://developers.facebook.com/docs/whatsapp/updates-to-pricing/new-template-guidelines"
        target="_blank"
        rel="noreferrer"
      >
        <LinkButton
          onClick={() => null}
          leftIcon="las la-info-circle"
          label="Saiba mais sobre categorias"
          extraClass="leading-trim-both text-edge-cap text-100 leading-5 !text-orange-500 hover:!text-orange-600"
        />
      </a>
    </div>
  );
};

export default CategoryCardGroup;
