import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import SideMenu from "./SideMenu.component";
import NotificationBadge from "../../../features/chats/components/NotificationBadge.component";
import {
  ChatStatus,
  closeChat,
  selectHistoryContact,
} from "../../../slices/chats.slice";
import CompanyProfile from "../CompanyProfile.component";
import { IUser } from "../../../slices/users.slice";
import { getNotificationsAmount } from "../../utils/getChatNotificationsByUser";
import UserProfileStatus from "../UserProfileStatus/index.component";

interface INavBar {
  toggleSidebar: boolean;
  setToggleSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  setToggleList: React.Dispatch<React.SetStateAction<string>>;
  setShowHistory: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setShowUsersList: React.Dispatch<React.SetStateAction<boolean>>;
}

const Navbar = ({
  toggleSidebar,
  setToggleSidebar,
  setToggleList,
  setShowHistory,
  setShowFilter,
  setShowUsersList,
}: INavBar) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.auth);
  const { backlogChats, mineChats, selectedChat } = useAppSelector(
    (state) => state.chats
  );

  const [badge, setBadge] = useState<number>(0);

  useEffect(() => {
    const backlogNotifications = backlogChats.reduce((accumulator, chat) => {
      if (
        chat?.notifications?.length &&
        getNotificationsAmount(chat, user as IUser) > 0 &&
        chat?.status &&
        ![ChatStatus.CANCELED, ChatStatus.SUSPEND, ChatStatus.FINISH].includes(
          chat.status
        )
      ) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);
    const mineChatsNotifications = mineChats.reduce((accumulator, chat) => {
      if (
        chat?.notifications?.length &&
        getNotificationsAmount(chat, user as IUser) > 0 &&
        chat?.status &&
        ![ChatStatus.CANCELED, ChatStatus.FINISH].includes(chat.status)
      ) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);

    setBadge(backlogNotifications + mineChatsNotifications);
  }, [backlogChats, mineChats]);

  const gotoChats = () => {
    setToggleSidebar(false);
    if (selectedChat?._id) {
      dispatch(closeChat());
    }
    setToggleList("mine");
    setShowHistory(false);
    setShowFilter(false);
    setShowUsersList(false);
    dispatch(selectHistoryContact(null));
    navigate("/chat");
  };

  return (
    <div className="flex md:hidden flex-row">
      <div className="p-2 w-full flex flex-row justify-between items-center shadow-xl px-5 bg-white">
        <div className="flex items-center w-1/2 sm:w-4/5">
          <i
            title="Exibir menu"
            role="presentation"
            className="las la-bars cursor-pointer text-3xl mr-2 sm:mr-7"
            onClick={() => {
              setToggleSidebar(true);
            }}
          />
          {!toggleSidebar ? (
            <CompanyProfile toggleSidebar={toggleSidebar} navbar />
          ) : null}
        </div>
        <div className="flex items-center w-1/5 justify-end">
          <div className="relative mr-2">
            <i
              title="Conversas"
              role="presentation"
              className="las la-comments cursor-pointer text-3xl"
              onClick={gotoChats}
            />
            <div className="absolute bottom-0 right-0">
              {badge > 0 ? <NotificationBadge badge={badge} /> : null}
            </div>
          </div>
          <UserProfileStatus />
        </div>
      </div>
      {toggleSidebar ? (
        <div
          id="sidemenu"
          className="overflow-x-hidden fixed w-[255px] max-w-3/5 bg-white h-screen overflow-y-scroll shadow-md z-[200] animate-slide-in"
        >
          <SideMenu
            navbar
            toggleSidebar={toggleSidebar}
            setToggleSidebar={setToggleSidebar}
            setToggleList={setToggleList}
            setShowHistory={setShowHistory}
            setShowFilter={setShowFilter}
            setShowUsersList={setShowUsersList}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Navbar;
