import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import appkeyService from "../services/appkey.service";
import customToast from "../shared/utils/customToast";
import { IToastType } from "../shared/models/types/Toast.type";

interface ssokeyState extends AsyncState {
  isLoadingSsokeys: boolean;
  ssoKey: string | undefined;
  lastSsoKey: string | undefined;
  isSuccess: boolean;
  isError: boolean;
}

const initialState: ssokeyState = {
  ssoKey: undefined,
  lastSsoKey: undefined,
  isLoadingSsokeys: false,
  isSuccess: false,
  isError: false,
};

export const getSsoKeys = createAsyncThunk(
  "appkey/sso",
  async ({ tenant }: { tenant: string }, thunkAPI) => {
    try {
      if (typeof tenant !== "undefined") {
        return await appkeyService.ssokeys(tenant);
      }
      return undefined;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const refreshSsoKey = createAsyncThunk(
  "appkey/updateSso",
  async ({ tenant }: { tenant: string }, thunkAPI) => {
    try {
      return await appkeyService.refreshsso(tenant);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const appSsoSlice = createSlice({
  name: "ssokey",
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      // TEAMS
      .addCase(getSsoKeys.pending, (state) => {
        state.isLoadingSsokeys = true;
      })

      // UPDATE SETTINGS
      .addCase(refreshSsoKey.pending, (state) => {
        state.isLoadingSsokeys = true;
      })
      .addCase(refreshSsoKey.fulfilled, (state, action) => {
        if (action.payload !== null) {    
          state.isSuccess = true;
          state.ssoKey = action.payload.ssokey;
          state.lastSsoKey = action.payload.lastssokey;
          // customToast({
          //   type: IToastType.SUCCESS,
          //   message: `Alteração salva com sucesso!`,
          // });
        } else {
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado!`,
          });
          state.isError = true;
        }
        state.isLoadingSsokeys = false;
      })
      .addCase(refreshSsoKey.rejected, (state) => {
        state.isError = true;
        state.isLoadingSsokeys = false;
      });
  },
});

export default appSsoSlice.reducer;
export const { logout } = appSsoSlice.actions;
