/* eslint-disable no-nested-ternary */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authService } from "../services";
import { updateTenant, updateTenantForgot } from "../services/instances";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import {
  ILoginAgideskUser,
  ILoginUser,
  IOnboardingUser,
  IUpdateUser,
  IUser,
  RegisterUser,
  UserStatus,
  // RoleUser,
} from "./users.slice";
import notificationService from "../services/notification.service";
import customToast from "../shared/utils/customToast";
import { IToastType } from "../shared/models/types/Toast.type";

export interface ILoginMethod {
  _id: string;
  fields: {
    name: string;
    displaytitlelogin: string;
    url: string;
  };
}

interface AuthState extends AsyncState {
  isLoadingAuth: boolean;
  isUploading: boolean;
  user?: IUser | null;
  loginMethods?: ILoginMethod[] | null;
  isAuthenticated?: boolean;
  tenantID?: string;
  externalapp?: string;
  selectedLoginMethod?: ILoginMethod | null;
  isForgot: boolean;
  isWidgetAgent: boolean;
  isWidgetContact: boolean;
  isWidgetEmbedded: boolean;
  isErrorPass: boolean;
  isTenantError: boolean;
  isAgideskError: boolean;
  isStatusManualChanged: boolean;
}

const initialState: AuthState = {
  user: null,
  loginMethods: null,
  selectedLoginMethod: null,
  tenantID: undefined,
  externalapp: undefined,
  isAuthenticated: false,
  isLoadingAuth: false,
  isUploading: false,
  isSuccess: false,
  isError: false,
  isTenantError: false,
  isErrorPass: false,
  isWidgetAgent: false,
  isWidgetEmbedded: false,
  isWidgetContact: false,
  isForgot: false,
  isAgideskError: false,
  isStatusManualChanged: false,
};

export const register = createAsyncThunk(
  "auth/register",
  async (newUser: RegisterUser, thunkAPI) => {
    try {
      updateTenant(newUser.tenant);
      return await authService.register(newUser);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (_user: ILoginUser, thunkAPI) => {
    try {
      updateTenant(_user.tenant);
      return await authService.login(_user);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const signin = createAsyncThunk(
  "auth/signin",
  async ({ tenant }: { tenant: string }, thunkAPI) => {
    try {
      updateTenant(tenant);
      return await authService.signin();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const agidesklogin = createAsyncThunk(
  "auth/agidesklogin",
  async (_user: ILoginAgideskUser, thunkAPI) => {
    try {
      updateTenant(_user.tenant);
      return await authService.agideskLogin(_user);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const onboarding = createAsyncThunk(
  "auth/onboarding",
  async (_user: IOnboardingUser, thunkAPI) => {
    try {
      updateTenant(_user.tenant);
      if (_user._id.length > 0) {
        const finishOnboardingUser: IUpdateUser = {
          _id: _user._id,
          name: _user.name,
          onboarding: "completed",
          roles: _user.roles,
          status: UserStatus.ONLINE,
          active: true,
          deleted: false,
        };
        await authService.changePassword({
          tenant: _user.tenant,
          email: _user.email,
          password: _user.password,
        });
        return await authService.update(finishOnboardingUser);
      }
      const userRegistered = await authService.registerFromAgidesk({
        tenant: _user.tenant,
        email: _user.email,
        name: _user.name,
        password: _user.password,
      });
      const userRegisterUpdated: IUpdateUser = {
        _id: userRegistered?._id,
        roles: _user.roles,
        onboarding: "completed",
        status: UserStatus.ONLINE,
        active: true,
        deleted: false,
      };
      return await authService.update(userRegisterUpdated);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const userdata = createAsyncThunk(
  "auth/userdata",
  async (_, thunkAPI) => {
    try {
      return await authService.userdata();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const changeStatus = createAsyncThunk(
  "auth/changeStatus",
  async (
    userStatus: { status: UserStatus; origin?: string; manual?: boolean },
    thunkAPI
  ) => {
    try {
      return await authService.changeStatus(userStatus);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassord",
  async (_pass: ILoginUser, thunkAPI) => {
    try {
      return await authService.changePassword(_pass);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const recoveryPassword = createAsyncThunk(
  "auth/recoveryPassord",
  async (
    {
      code,
      password,
    }: {
      code: string;
      password: string;
    },
    thunkAPI
  ) => {
    try {
      return await authService.recoveryPassword({ code, password });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async ({ email, tenant }: { email: string; tenant: string }, thunkAPI) => {
    try {
      updateTenantForgot(tenant);
      return await authService.forgotPassword({ email, tenant });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const update = createAsyncThunk(
  "auth/update",
  async (
    { _updateUser, noToast }: { _updateUser: any; noToast?: boolean },
    thunkAPI
  ) => {
    try {
      return await authService.update(_updateUser);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const uploadAvatar = createAsyncThunk(
  "auth/uploadAvatar",
  async (_files: File[], thunkAPI) => {
    const maxSize = 20 * 1024 * 1024; // 20MB
    try {
      if (!_files[0].type.includes("image/")) {
        return "Unsupported type file";
      }
      if (_files[0].size > maxSize) {
        return "too large";
      }
      return await authService.uploadFile(_files);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    await notificationService.disable(); // DISABLE NOTIFICATIONS ON LOGOUT
    return await authService.logout();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isSuccess = false;
      state.isError = false;
      state.isErrorPass = false;
      state.isAgideskError = false;
      state.isTenantError = false;
      state.isForgot = false;
      state.isLoadingAuth = false;
    },
    selectTenant(state, action: PayloadAction<string>) {
      state.tenantID = action.payload;
      updateTenant(action.payload);
      updateTenantForgot(action.payload);
    },
    selectWidget(state, action: PayloadAction<string | undefined>) {
      if (
        typeof action.payload !== "undefined" &&
        typeof action.payload === "string"
      ) {
        if (action.payload === "agent") {
          state.isWidgetAgent = true;
          state.isWidgetContact = false;
        } else if (action.payload === "contact") {
          state.isWidgetContact = true;
          state.isWidgetAgent = false;
        } else if (action.payload === "embedded") {
          state.isWidgetEmbedded = true;
          state.isWidgetAgent = false;
          state.isWidgetContact = false;
        }
      } else {
        state.isWidgetEmbedded = false;
        state.isWidgetAgent = false;
        state.isWidgetContact = false;
      }
    },
    selectMethod(state, action: PayloadAction<ILoginMethod | null>) {
      state.selectedLoginMethod = action.payload;
      state.externalapp =
        action.payload !== null ? action.payload._id : undefined;
    },
    authenticationWidgetContact(state) {
      if (!state.isAuthenticated) state.isAuthenticated = true;
    },
    authenticationWidgetEmbedded(state) {
      if (!state.isAuthenticated) state.isAuthenticated = true;
    },
    reselectUser(state, action: PayloadAction<IUser | null>) {
      state.user = action.payload;
    },
    refreshLogout() {
      return initialState;
    },
    refreshContactLogout(state) {
      return {
        ...initialState,
        isWidgetContact: state.isWidgetContact,
        tenantID: state.tenantID,
        tenant: state.tenant,
      };
    },
    setupsocket(state, action: PayloadAction<{ _id: string }>) {
      //
    },
  },
  extraReducers: (builder) => {
    builder
      // REGISTER
      .addCase(register.pending, (state) => {
        state.isLoadingAuth = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          !["undefined", "string"].includes(typeof action.payload)
        ) {
          const _payload = action.payload as IUser;
          state.isSuccess = true;
          state.isError = false;
          state.user = _payload;
          if (state.user) state.user.onboarding = "completed";
          state.isAuthenticated = true;
        } else {
          if (
            typeof action.payload === "string" &&
            action.payload.includes("duplicate")
          ) {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado! Tente novamente.`,
            });
          }
          state.isError = true;
          state.user = null;
          state.isAuthenticated = false;
        }
        state.isLoadingAuth = false;
      })
      .addCase(register.rejected, (state) => {
        state.isError = true;
        state.user = null;
        state.isAuthenticated = false;
        state.isLoadingAuth = false;
      })
      .addCase(signin.pending, (state) => {
        state.isLoadingAuth = true;
      })
      .addCase(signin.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          typeof action.payload !== "undefined" &&
          !action.payload.error
        ) {
          state.isSuccess = true;
          state.tenantID = action.meta.arg.tenant;
          if (action.payload.length > 0) {
            state.loginMethods = action.payload;
          }
          state.isTenantError = false;
        } else {
          state.isTenantError = true;
          // state.loginMethods = null;
        }
        state.isLoadingAuth = false;
      })
      .addCase(signin.rejected, (state) => {
        state.isTenantError = true;
        state.user = null;
        state.isAuthenticated = false;
        state.isLoadingAuth = false;
      })
      // LOGIN
      .addCase(login.pending, (state) => {
        state.isLoadingAuth = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload !== null) {
          state.isSuccess = true;
          state.user = action.payload;
          state.isAuthenticated = true;
          state.isError = false;
        } else {
          state.isError = true;
          state.user = null;
          state.isAuthenticated = false;
        }
        state.isLoadingAuth = false;
      })
      .addCase(login.rejected, (state) => {
        state.isError = true;
        state.user = null;
        state.isAuthenticated = false;
        state.isLoadingAuth = false;
      })
      // AGIDESK LOGIN
      .addCase(agidesklogin.pending, (state) => {
        state.isLoadingAuth = true;
      })
      .addCase(agidesklogin.fulfilled, (state, action) => {
        if (action.payload !== null) {
          state.isSuccess = true;
          state.user = action.payload;
          state.isAuthenticated = true;
          state.isAgideskError = false;
        } else {
          state.isError = true;
          state.isAgideskError = true;
          state.user = null;
          state.isAuthenticated = false;
        }
        state.isLoadingAuth = false;
      })
      .addCase(agidesklogin.rejected, (state) => {
        state.isAgideskError = true;
        state.user = null;
        state.isAuthenticated = false;
        state.isLoadingAuth = false;
      })
      // ONBOARDING
      .addCase(onboarding.pending, (state) => {
        state.isLoadingAuth = true;
      })
      .addCase(onboarding.fulfilled, (state, action) => {
        if (action.payload !== null) {
          state.isSuccess = true;
          state.user = {
            ...state.user,
            name: action.payload.name,
            onboarding: action.payload.onboarding,
          };
          state.isAuthenticated = true;
          state.isError = false;
        } else {
          state.isError = true;
          state.user = null;
          state.isAuthenticated = false;
        }
        state.isLoadingAuth = false;
      })
      .addCase(onboarding.rejected, (state) => {
        state.isError = true;
        state.user = null;
        state.isAuthenticated = false;
        state.isLoadingAuth = false;
      })
      // CHANGE PASSWORD
      .addCase(changePassword.pending, (state) => {
        state.isLoadingAuth = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        if (action.payload !== null) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: "Senha alterada com sucesso!",
          });
          state.user = action.payload;
          state.isAuthenticated = true;
          state.isError = false;
        } else {
          state.isError = true;
          state.user = null;
          state.isAuthenticated = false;
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado!`,
          });
        }
        state.isLoadingAuth = false;
      })
      .addCase(changePassword.rejected, (state) => {
        state.isError = true;
        state.user = null;
        state.isAuthenticated = false;
        state.isLoadingAuth = false;
      })
      // FORGOT PASSWORD
      .addCase(forgotPassword.pending, (state) => {
        state.isLoadingAuth = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        if (action.payload !== null) {
          state.isSuccess = true;
          state.isErrorPass = false;
          state.isForgot = true;
        } else {
          state.isErrorPass = true;
          state.isForgot = false;
        }
        state.isLoadingAuth = false;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.isErrorPass = true;
        state.isLoadingAuth = false;
      })
      .addCase(recoveryPassword.pending, (state) => {
        state.isLoadingAuth = true;
      })
      .addCase(recoveryPassword.fulfilled, (state, action) => {
        if (action.payload !== null) {
          state.isSuccess = true;
          state.isErrorPass = false;
          state.isForgot = true;
        } else {
          state.isErrorPass = true;
          state.isForgot = false;
        }
        state.isLoadingAuth = false;
      })
      .addCase(recoveryPassword.rejected, (state) => {
        state.isErrorPass = true;
        state.isLoadingAuth = false;
      })
      // USERDATA
      .addCase(userdata.pending, () => {
        // state.isLoadingAuth = true;
      })
      .addCase(userdata.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.user = action.payload;
        state.isAuthenticated = true;
        // state.isLoadingAuth = false;
      })
      .addCase(userdata.rejected, (state) => {
        state.isError = true;
        state.user = null;
        state.isAuthenticated = false;
        state.isLoadingAuth = false;
      })
      // CHANGESTATUS
      .addCase(changeStatus.pending, () => {
        // state.isLoadingAuth = true;
      })
      .addCase(changeStatus.fulfilled, (state, action) => {
        state.isStatusManualChanged =
          typeof action.meta.arg?.manual !== "undefined" &&
          action.meta.arg?.manual === true &&
          action.meta.arg.status === UserStatus.OFFLINE;
        state.isSuccess = true;
        const _user = { ...state.user, status: action?.payload?.status };
        state.user = _user;
        state.isAuthenticated = true;
        // state.isLoadingAuth = false;
      })
      .addCase(changeStatus.rejected, (state) => {
        state.isError = true;
        state.user = null;
        state.isAuthenticated = false;
        // state.isLoadingAuth = false;
      })
      // CHANGESTATUS
      .addCase(uploadAvatar.pending, (state) => {
        state.isUploading = true;
      })
      .addCase(uploadAvatar.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          !["undefined", "string"].includes(typeof action.payload)
        ) {
          state.isSuccess = true;
          state.isError = false;
        } else {
          if (typeof action.payload === "string") {
            const _message = action.payload
              .toLowerCase()
              .includes("unsupported")
              ? "Formato de arquivo não suportado!"
              : action.payload.toLowerCase().includes("too large")
              ? "Tamanho máximo por arquivo: 20MB"
              : "Algo deu errado no upload da imagem!";
            customToast({
              type: IToastType.ERROR,
              message: _message,
            });
          }
          state.isError = true;
        }
        state.isUploading = false;
      })
      .addCase(uploadAvatar.rejected, (state) => {
        state.isError = true;
        state.isUploading = false;
      })
      // UPDATE
      .addCase(update.pending, () => {
        // state.isLoadingAuth = true;
      })
      .addCase(update.fulfilled, (state, action) => {
        if (action.payload !== null) {
          state.isSuccess = true;
          state.isAuthenticated = true;
          // state.user = action.payload;
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.SUCCESS,
              message: `Alteração salva com sucesso!`,
            });
          }
        } else {
          state.isError = true;
          // state.user = null;
          state.isAuthenticated = false;
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado!`,
            });
          }
        }
        state.isLoadingAuth = false;
      })
      .addCase(update.rejected, (state) => {
        state.isError = true;
        state.user = null;
        state.isAuthenticated = false;
        state.isLoadingAuth = false;
      })
      // LOGOUT
      .addCase(logout.pending, (state) => {
        state.isLoadingAuth = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isSuccess = true;
        state.user = null;
        state.isAuthenticated = false;
        state.isLoadingAuth = false;
      })
      .addCase(logout.rejected, (state) => {
        state.isError = true;
        state.isLoadingAuth = false;
      });
  },
});

export const {
  reset,
  refreshLogout,
  refreshContactLogout,
  reselectUser,
  selectTenant,
  selectWidget,
  authenticationWidgetContact,
  authenticationWidgetEmbedded,
  selectMethod,
  setupsocket,
} = authSlice.actions;
export default authSlice.reducer;
