/* eslint-disable import/prefer-default-export */
import { IChat } from "../../slices/chats.slice";
import { IUser } from "../../slices/users.slice";

const getNotificationsAmount = (chat: IChat, user: IUser) => {
  const notification = chat.notifications?.find(
    (_notification) => _notification?.user === user?._id
  );

  return notification?.amount || 0;
};

export { getNotificationsAmount };
