/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  reselectLocalTemplate,
  reselectTemplate,
  sendTemplate,
  // reselectLocalTemplate,
  TemplateStepsType,
  updateTemplate,
} from "../../slices/templates.slice";
import { WhatsAppMobilePreviewBodyType } from "../../shared/components/Templates/WhatsAppMobilePreview.component";
import { WizardItemsState } from "../../shared/components/Templates/WizardStepNumber.component";
import { authService } from "../../services";
import { getFileInfoFromURL } from "../../shared/utils/getFilesFromURL";
import { convertHtmlToText } from "../useFormatText";
import { validNumberLength } from "../../shared/utils/handleMaskLength";
import validateUrl from "../../shared/utils/validation/url";

const useTemplates = ({
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar?: boolean;
  disable?: boolean;
  setDisable?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { selectedTemplate, selectedLocalTemplate } = useAppSelector(
    (state) => state.templates
  );
  const [showReactiveModal, setShowReactiveModal] = useState<boolean>(false);

  const getInitialFilesImage = useCallback(() => {
    try {
      if (
        selectedLocalTemplate?.selectedHeader?.type === "image" &&
        selectedLocalTemplate?.selectedHeader?.image
      ) {
        return [
          typeof selectedLocalTemplate?.selectedHeader?.image === "string"
            ? getFileInfoFromURL(selectedLocalTemplate?.selectedHeader?.image)
            : selectedLocalTemplate?.selectedHeader?.image,
        ];
      }
      return [];
    } catch (error) {
      return [];
    }
  }, []);

  const getInitialFilesVideo = useCallback(() => {
    try {
      if (
        selectedLocalTemplate?.selectedHeader?.type === "video" &&
        selectedLocalTemplate?.selectedHeader?.video
      ) {
        return [
          typeof selectedLocalTemplate?.selectedHeader?.video === "string"
            ? getFileInfoFromURL(selectedLocalTemplate?.selectedHeader?.video)
            : selectedLocalTemplate?.selectedHeader?.video,
        ];
      }
      return [];
    } catch (error) {
      return [];
    }
  }, []);

  const [filesImage, setFilesImage] = useState<any>(getInitialFilesImage());
  const [filesVideo, setFilesVideo] = useState<any>(getInitialFilesVideo());

  const validateButtons = (
    buttons?: {
      type?: string;
      text?: string;
      phone?: string;
      url?: string;
    }[]
  ): boolean => {
    if (!buttons) {
      return true;
    }

    for (const item of buttons) {
      if (!item.type || !item.text) {
        return false;
      }
      if (
        item.type === "PHONE_NUMBER" &&
        (!item?.phone || !validNumberLength({ value: item?.phone || "" }))
      ) {
        return false;
      }
      if (
        item.type === "URL" &&
        (!item?.url || !validateUrl(item?.url || ""))
      ) {
        return false;
      }
    }
    return true;
  };

  const validateContent = (content?: {
    content?: string;
    vars?: {
      name?: string;
      example?: string;
    }[];
  }): boolean => {
    if (!content?.content) {
      return false;
    }

    if (
      !selectedLocalTemplate?.selectedHeader?.type ||
      (selectedLocalTemplate?.selectedHeader?.type !== "none" &&
        !selectedLocalTemplate?.selectedHeader?.text &&
        !selectedLocalTemplate?.selectedHeader?.image &&
        !selectedLocalTemplate?.selectedHeader?.video)
    ) {
      return false;
    }

    if (!content.vars) {
      return true;
    }

    for (const item of content.vars) {
      if (!item.name || !item.example) {
        return false;
      }
    }
    return true;
  };

  const getStateWizardItem = (step: TemplateStepsType): WizardItemsState => {
    switch (step) {
      case "category":
        // if (
        //   !selectedLocalTemplate?.selectedStep ||
        //   selectedLocalTemplate?.selectedStep === "category"
        // ) {
        //   return "selected";
        // }
        if (selectedLocalTemplate?.selectedCategory) {
          return "success";
        }
        return "enabled";
      case "config":
        // if (selectedLocalTemplate?.selectedStep === "config") {
        //   return "selected";
        // }
        if (!selectedLocalTemplate?.selectedCategory) {
          return "disabled";
        }
        if (
          selectedLocalTemplate?.selectedConfig?.name &&
          selectedLocalTemplate?.selectedConfig?.language &&
          selectedLocalTemplate?.selectedConfig?.externalappType &&
          selectedLocalTemplate?.selectedConfig?.externalappNumber
        ) {
          return "success";
        }
        return "enabled";
      case "header":
        // if (selectedLocalTemplate?.selectedStep === "header") {
        //   return "selected";
        // }
        if (
          !selectedLocalTemplate?.selectedConfig?.name ||
          !selectedLocalTemplate?.selectedConfig?.language ||
          !selectedLocalTemplate?.selectedConfig?.externalappType ||
          !selectedLocalTemplate?.selectedConfig?.externalappNumber
        ) {
          return "disabled";
        }
        if (
          selectedLocalTemplate?.selectedHeader?.type === "none" ||
          (selectedLocalTemplate?.selectedHeader?.type &&
            (selectedLocalTemplate?.selectedHeader?.image ||
              selectedLocalTemplate?.selectedHeader?.video ||
              selectedLocalTemplate?.selectedHeader?.text))
        ) {
          return "success";
        }
        return "enabled";
      case "content":
        // if (selectedLocalTemplate?.selectedStep === "content") {
        //   return "selected";
        // }
        if (
          !selectedLocalTemplate?.selectedHeader?.type ||
          (selectedLocalTemplate?.selectedHeader?.type !== "none" &&
            !selectedLocalTemplate?.selectedHeader?.text &&
            !selectedLocalTemplate?.selectedHeader?.image &&
            !selectedLocalTemplate?.selectedHeader?.video)
        ) {
          return "disabled";
        }
        if (validateContent(selectedLocalTemplate?.selectedContent)) {
          return "success";
        }
        return "enabled";
      case "footer":
        // if (selectedLocalTemplate?.selectedStep === "footer") {
        //   return "selected";
        // }
        if (!validateContent(selectedLocalTemplate?.selectedContent)) {
          return "disabled";
        }
        // if (selectedLocalTemplate?.selectedFooter) {
        //   return "success";
        // }
        return "success";
      case "buttons":
        // if (selectedLocalTemplate?.selectedStep === "buttons") {
        //   return "selected";
        // }
        if (!validateContent(selectedLocalTemplate?.selectedContent)) {
          return "disabled";
        }
        if (validateButtons(selectedLocalTemplate?.selectedButtons)) {
          return "success";
        }
        return "enabled";
      default:
        return "disabled";
    }
  };

  const getPreviewType = () => {
    if (selectedLocalTemplate?.selectedCategory === "auth") {
      return selectedLocalTemplate?.selectedCategory as WhatsAppMobilePreviewBodyType;
    }
    if (selectedLocalTemplate?.selectedHeader?.type) {
      return selectedLocalTemplate?.selectedHeader
        ?.type as WhatsAppMobilePreviewBodyType;
    }
    return "none";
  };

  const header: {
    icon?: string;
    title?: string;
    created?: string;
    updated?: string;
    deleted?: string;
  } = {
    created: selectedLocalTemplate?.created_at,
    updated: selectedLocalTemplate?.updated_at,
    deleted: selectedLocalTemplate?.deleted_at,
    title: selectedTemplate?.name,
    icon: "las la-envelope-square",
  };

  const back = () => {
    navigate("/settings/templates");
    dispatch(reselectTemplate(null));
    dispatch(reselectLocalTemplate(null));
  };

  const draft = async (noToast?: boolean) => {
    const _image = selectedLocalTemplate?.selectedHeader?.image
      ? (selectedLocalTemplate?.selectedHeader?.image as any)?._id
        ? [selectedLocalTemplate?.selectedHeader?.image]
        : await authService.uploadFile(
            [selectedLocalTemplate?.selectedHeader?.image as File],
            true
          )
      : undefined;
    const _video = selectedLocalTemplate?.selectedHeader?.video
      ? (selectedLocalTemplate?.selectedHeader?.video as any)?._id
        ? [selectedLocalTemplate?.selectedHeader?.video]
        : await authService.uploadFile(
            [selectedLocalTemplate?.selectedHeader?.video as File],
            true
          )
      : undefined;

    // dispatch(
    //   reselectLocalTemplate({
    //     ...selectedLocalTemplate,
    //     selectedStep: "header",
    //     selectedHeader: _image
    //       ? {
    //           ...selectedLocalTemplate?.selectedHeader,
    //           type: "image",
    //           image: _image?.[0],
    //         }
    //       : _video
    //       ? {
    //           ...selectedLocalTemplate?.selectedHeader,
    //           type: "video",
    //           video: _video?.[0],
    //         }
    //       : selectedLocalTemplate?.selectedHeader,
    //   })
    // );
    const _template: any = { ...selectedLocalTemplate };
    delete _template.selectedStep;
    _template.step = selectedLocalTemplate?.selectedStep;
    delete _template.selectedCategory;
    _template.category = selectedLocalTemplate?.selectedCategory;
    delete _template.selectedConfig;
    _template.config = {
      ...selectedLocalTemplate?.selectedConfig,
      language: "pt_BR",
    };
    delete _template.selectedHeader;
    _template.header = {
      ...selectedLocalTemplate?.selectedHeader,
      image: _image?.[0],
      video: _video?.[0],
    };
    delete _template.selectedContent;
    _template.content = {
      ...selectedLocalTemplate?.selectedContent,
      contentExample: convertHtmlToText(
        selectedLocalTemplate?.selectedContent?.contentExample || ""
      ),
    };
    delete _template.selectedFooter;
    _template.footer = selectedLocalTemplate?.selectedFooter;
    delete _template.selectedButtons;
    _template.buttons = selectedLocalTemplate?.selectedButtons?.filter(
      (_button) => _button?.type !== "empty"
    );
    const { payload } = await dispatch(updateTemplate({ _template, noToast }));
    dispatch(
      reselectTemplate({
        ...payload,
        selectedStep: payload?.step,
        selectedCategory: payload?.category,
        selectedConfig: payload?.config,
        selectedHeader: payload?.header,
        selectedContent: payload?.content,
        selectedFooter: payload?.footer,
        selectedButtons: payload?.buttons,
      })
    );
  };

  const save = async () => {
    if (setDisable) setDisable(true);
    await draft(true);
    if (selectedLocalTemplate?._id) {
      const { payload } = await dispatch(
        sendTemplate({ _id: selectedLocalTemplate?._id })
      );
      if (payload?._id) {
        back();
      }
    }
    if (setDisable) setDisable(false);
  };

  const _disabled =
    disable ||
    !validateContent(selectedLocalTemplate?.selectedContent) ||
    !validateButtons(selectedLocalTemplate?.selectedButtons);

  const footer = {
    disabled: _disabled,
    deleted: selectedLocalTemplate?.deleted,
    back,
    save,
    draft,
    toggleSidebar: toggleSidebar || false,
    modaltitle: selectedLocalTemplate?.deleted
      ? "Deseja reativar este modelo de resposta?"
      : "Deseja remover este modelo de resposta?",
    showReactiveModal,
    setShowReactiveModal,
  };

  return {
    getStateWizardItem,
    getPreviewType,
    header,
    footer,
    filesImage,
    setFilesImage,
    filesVideo,
    setFilesVideo,
  };
};

export default useTemplates;
