/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
/* eslint-disable no-cond-assign */
/* eslint-disable no-plusplus */
import React, { useState, useEffect, useCallback } from "react";
import { Form } from "semantic-ui-react";
import RtfEditor from "../RtfEditor.component";
import FormHeader from "./FormHeader.component";
import LinkButton from "../Buttons/LinkButton.component";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import { reselectLocalTemplate } from "../../../slices/templates.slice";
import { convertHtmlToText, removeAccents } from "../../../hooks/useFormatText";
import ErrorLabel from "../../../features/auth/components/Forms/ErrorLabel.component";
import { ExternalAppTypeTemplates } from "../../../slices/externalapps.slice";

const ContentFormGroup = () => {
  const dispatch = useAppDispatch();
  const { selectedLocalTemplate } = useAppSelector((state) => state.templates);

  const getStartContent = useCallback(() => {
    if (selectedLocalTemplate?.selectedContent?.content?.length) {
      return selectedLocalTemplate?.selectedContent?.content;
    }
    if (selectedLocalTemplate?.selectedCategory === "auth") {
      dispatch(
        reselectLocalTemplate({
          ...selectedLocalTemplate,
          selectedContent: {
            ...selectedLocalTemplate?.selectedContent,
            content:
              "<b>{{1}}</b> é o seu código de verificação. Para sua seguração, não compartilhe este código.",
            contentName:
              "<b>${code_id}</b> é o seu código de verificação. Para sua seguração, não compartilhe este código.",
            contentExample:
              "<b>${code_id}</b> é o seu código de verificação. Para sua seguração, não compartilhe este código.",
          },
        })
      );
      return "<b>{{1}}</b> é o seu código de verificação. Para sua seguração, não compartilhe este código.";
    }
    return "";
  }, []);

  const getStartVars = useCallback(() => {
    if (selectedLocalTemplate?.selectedContent?.vars?.length) {
      return selectedLocalTemplate?.selectedContent?.vars;
    }
    if (
      selectedLocalTemplate?.selectedCategory === "auth" &&
      selectedLocalTemplate?.status === "draft"
    ) {
      dispatch(
        reselectLocalTemplate({
          ...selectedLocalTemplate,
          selectedContent: {
            ...selectedLocalTemplate?.selectedContent,
            vars: [
              {
                name: "${code_id}",
                oldName: "{{1}}",
                id: 0,
                example: "${code_id}",
              },
            ],
          },
        })
      );
      return [
        {
          name: "${code_id}",
          oldName: "{{1}}",
          id: 0,
          example: "${code_id}",
        },
      ];
    }
    return [];
  }, []);

  const [content, setContent] = useState<string>("");
  const [contentName, setContentName] = useState<string>("");
  const [contentExample, setContentExample] = useState<string>("");
  const [vars, setVars] = useState<
    { name: string; oldName?: string; example: string; id: number }[]
  >([]);

  useEffect(() => {
    setContent(getStartContent());
  }, [getStartContent]);

  useEffect(() => {
    setVars(getStartVars());
  }, [getStartVars]);

  const [repeatedName, setRepeatedName] = useState<boolean>(false);

  const handlerContent = useCallback(() => {
    const regex = /\{\{([\wÀ-ÿ\s]+)\}\}/g;
    let updatedContentName = content;
    let updatedContentExample = content;
    let match: any;

    while ((match = regex.exec(content)) !== null) {
      const variable = vars.find((v) => v.oldName === match[0]);
      if (variable) {
        updatedContentName = updatedContentName.replace(
          match[0],
          selectedLocalTemplate?.selectedCategory === "auth"
            ? `${variable.name}`
            : `{{${variable.name}}}`
        );
        updatedContentExample = updatedContentExample.replace(
          match[0],
          selectedLocalTemplate?.selectedCategory === "auth"
            ? `${variable.example}`
            : `{{${variable.example}}}`
        );
      }
    }

    setContentName(updatedContentName);
    setContentExample(
      selectedLocalTemplate?.selectedConfig?.externalappType ===
        ExternalAppTypeTemplates.BOTMAKER
        ? updatedContentExample?.replace(/\{\{([^{}]+)\}\}/g, "${$1}")
        : updatedContentExample
    );

    dispatch(
      reselectLocalTemplate({
        ...selectedLocalTemplate,
        selectedContent: {
          ...selectedLocalTemplate?.selectedContent,
          content,
          contentName: updatedContentName,
          contentExample:
            selectedLocalTemplate?.selectedConfig?.externalappType ===
            ExternalAppTypeTemplates.BOTMAKER
              ? updatedContentExample?.replace(/\{\{([^{}]+)\}\}/g, "${$1}")
              : updatedContentExample,
        },
      })
    );
  }, [content, vars]);

  useEffect(() => {
    handlerContent();
  }, [handlerContent]);

  const removeVarGroup = (index: number) => {
    const variableToRemove = vars[index];
    const newVars = vars.filter((_var, i) => i !== index);
    const updatedContent = content.replace(variableToRemove.oldName || "", "");
    const updatedContentName = contentName.replace(
      variableToRemove.oldName || "",
      ""
    );
    const updatedContentExample = contentExample.replace(
      variableToRemove.oldName || "",
      ""
    );

    setVars(newVars);
    setContent(updatedContent);
    setContentName(updatedContentName);
    setContentExample(
      selectedLocalTemplate?.selectedConfig?.externalappType ===
        ExternalAppTypeTemplates.BOTMAKER
        ? updatedContentExample?.replace(/\{\{([^{}]+)\}\}/g, "${$1}")
        : updatedContentExample
    );

    dispatch(
      reselectLocalTemplate({
        ...selectedLocalTemplate,
        selectedContent: {
          ...selectedLocalTemplate?.selectedContent,
          content: updatedContent,
          contentName: updatedContentName,
          contentExample:
            selectedLocalTemplate?.selectedConfig?.externalappType ===
            ExternalAppTypeTemplates.BOTMAKER
              ? updatedContentExample?.replace(/\{\{([^{}]+)\}\}/g, "${$1}")
              : updatedContentExample,
          vars: newVars,
        },
      })
    );
  };

  const isUniqueName = (name: string, index: number) => {
    return !vars.some((v, i) => v.name === name && i !== index);
  };

  const handleEditorChange = (value: string) => {
    if (convertHtmlToText(value || "")?.length <= 1024) {
      setContent(value);
    }
  };

  const handleNameChange = (index: number, value: string) => {
    const _value = value
      ? removeAccents(value.replace(" ", "").toLocaleLowerCase())
      : "";

    setRepeatedName(!isUniqueName(_value, index));

    const newVars = vars.map((_var, i) => {
      if (i === index) {
        return {
          ..._var,
          name: _value,
          example: _value ? _var.example : `${index + 1}`,
        };
      }
      return _var;
    });

    setVars(newVars);
  };

  const handleExampleChange = (index: number, value: string) => {
    const _value = value;
    const newVars = vars.map((_var, i) => {
      if (i === index) {
        return { ..._var, example: _value || `${index + 1}` };
      }
      return _var;
    });

    setVars(newVars);
  };

  return (
    <Form
      id="ContentFormGroup"
      className="flex flex-col items-start gap-9 w-full"
    >
      <RtfEditor
        disabled={false}
        height="300"
        templates
        id="template-content"
        initialValue={content}
        onChange={(e: any, value: any) =>
          handleEditorChange(value.getContent())
        }
        vars={vars}
        setVars={setVars}
      />
      {vars.length > 0 && (
        <FormHeader
          title="{  } Variáveis"
          description={
            <span>
              Adicione um exemplo para cada variável inserida, mas evite
              informações reais de clientes. Os parâmetros serão avaliados pela
              Meta.
              <br />
              <b>Atenção: </b>O controle das variáveis deve ser feito apenas
              pelo botão &quot;Adicionar variável&quot; e na lista abaixo.
            </span>
          }
        />
      )}
      {vars.map((variable, index) => (
        <Form.Group
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className="flex relative w-full p-5 items-end gap-4 rounded-2 bg-secondary !m-0"
        >
          <Form.Input
            label={`Nome da variável {{${index + 1}}}`}
            error={repeatedName}
            value={variable.name?.replace(" ", "")}
            placeholder="Digite um nome para a variável"
            onChange={(e, { value }) => handleNameChange(index, value)}
            onBlur={() => {
              dispatch(
                reselectLocalTemplate({
                  ...selectedLocalTemplate,
                  selectedContent: {
                    ...selectedLocalTemplate?.selectedContent,
                    vars,
                  },
                })
              );
            }}
          />
          <Form.Input
            fluid
            disabled={
              selectedLocalTemplate?.selectedContent?.vars?.[index]?.name
                ?.length === 0
            }
            className="w-full"
            label="Exemplo"
            placeholder="Digite um valor de exemplo"
            value={variable.example}
            onChange={(e, { value }) => handleExampleChange(index, value)}
            onBlur={() => {
              dispatch(
                reselectLocalTemplate({
                  ...selectedLocalTemplate,
                  selectedContent: {
                    ...selectedLocalTemplate?.selectedContent,
                    vars,
                  },
                })
              );
            }}
          />
          {selectedLocalTemplate?.selectedCategory !== "auth" && (
            <i
              role="presentation"
              className="w-[34px] h-[38px] gap-3 py-2 px-4 flex items-center justify-center rounded-2 las la-trash fg-subtle text-200"
              onClick={() => removeVarGroup(index)}
            />
          )}
        </Form.Group>
      ))}
      {repeatedName && (
        <div className="-mt-6">
          <ErrorLabel
            error={repeatedName}
            pointing
            label="Variáveis não podem ter o mesmo nome"
          />
        </div>
      )}
      {vars.length > 0 && (
        <a
          href="https://developers.facebook.com/docs/whatsapp/cloud-api/guides/send-message-templates/template-library/"
          target="_blank"
          rel="noreferrer"
        >
          <LinkButton
            onClick={() => null}
            leftIcon="las la-info-circle"
            label="Saiba mais sobre variáveis"
            extraClass="leading-trim-both text-edge-cap text-100 leading-5 !text-orange-500 hover:!text-orange-600"
          />
        </a>
      )}
    </Form>
  );
};

export default ContentFormGroup;
