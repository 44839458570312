/* eslint-disable react/require-default-props */
import React from "react";

export type WizardItemsState =
  | "enabled"
  | "disabled"
  | "selected"
  | "success"
  | "warning"
  | "error";

export type WizardStepNumberProps =
  | {
      state: WizardItemsState;
      number: true;
      value: string;
      icon?: string;
    }
  | {
      state: WizardItemsState;
      number: false;
      value?: string;
      icon: string;
    };

export interface WizardStepNumberStylesObject {
  [key: string]: {
    border: string;
    bg: string;
    fg: string;
  };
}

const WizardStepNumber = ({
  state,
  number,
  value,
  icon,
}: WizardStepNumberProps) => {
  const styles: WizardStepNumberStylesObject = {
    enabled: {
      border: "border-default",
      bg: "bg-default",
      fg: "fg-subtle",
    },
    disabled: {
      border: "border-default",
      bg: "bg-default",
      fg: "fg-weak",
    },
    selected: {
      border: "border-orange-500",
      bg: "bg-orange-500",
      fg: "text-base-white",
    },
    success: {
      border: "border-emerald-600",
      bg: "bg-emerald-600",
      fg: "text-base-white",
    },
    warning: {
      border: "border-amber-200",
      bg: "bg-amber-200",
      fg: "text-amber-800",
    },
    error: {
      border: "border-red-500",
      bg: "bg-red-500",
      fg: "text-base-white",
    },
  };

  return (
    <div
      id="WizardStepNumber"
      className={`flex w-6 h-6 p-0 flex-col justify-center items-center gap-2 ${styles[state].bg} ${styles[state].border} rounded-full border`}
    >
      {number ? (
        <span
          className={`${styles[state].fg} text-100-to-fix overflow-ellipsis leading-5 line-clamp-1 font-bold leading-trim-both text-edge-cap`}
        >
          {value}
        </span>
      ) : (
        <i
          className={`${styles[state].fg} las la-${icon} text-25 justify-center items-center flex shrink-0`}
        />
      )}
    </div>
  );
};

export default WizardStepNumber;
