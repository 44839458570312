import {
  IPermissionGroup,
  IFilterPermissionGroup,
} from "../slices/permissiongroups.slice";
import instances from "./instances";

const search = async ({
  skip,
  limit,
  filter,
  deleted,
}: IFilterPermissionGroup) => {
  try {
    const _filters =
      typeof filter === "object" ? JSON.parse(JSON.stringify(filter)) : {};

    if (typeof filter === "string" && typeof _filters?.content === "undefined")
      _filters.content = filter;
    if (deleted) _filters.deleted = true;

    const response = await instances.instancePermissionGroups.get("", {
      params: {
        skip: skip || 0,
        limit: limit || 10,
        filter: _filters,
        fields:
          "name,active,role,permissions,default,created_at,created_by,deleted,updated_at,updated_by",
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const permissiongroup = async ({ _id }: { _id: string }) => {
  try {
    const response = await instances.instancePermissionGroups.get(`/${_id}`);
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const update = async (permissionGroup: IPermissionGroup) => {
  try {
    const data = permissionGroup;
    const response = await instances.instancePermissionGroups.patch(
      `${permissionGroup._id}`,
      data
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const attachToUsers = async (permissionGroupId: string, users: string[]) => {
  try {
    const response = await instances.instancePermissionGroups.patch(
      `${permissionGroupId}/attach/users`,
      { users }
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const attachToTeams = async (permissionGroupId: string, teams: string[]) => {
  try {
    const response = await instances.instancePermissionGroups.patch(
      `${permissionGroupId}/attach/teams`,
      { teams }
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const detachToUsers = async (permissionGroupId: string, users: string[]) => {
  try {
    const response = await instances.instancePermissionGroups.patch(
      `${permissionGroupId}/detach/users`,
      { users }
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const detachToTeams = async (permissionGroupId: string, teams: string[]) => {
  try {
    const response = await instances.instancePermissionGroups.patch(
      `${permissionGroupId}/detach/teams`,
      { teams }
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const deletePermissiongroup = async ({ ids }: { ids: string[] }) => {
  try {
    const data = { id: ids };
    const response = await instances.instancePermissionGroups.delete("", {
      data,
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const create = async (permissiongroup: IPermissionGroup) => {
  try {
    const response = await instances.instancePermissionGroups.post("", permissiongroup);
    if (response && response.data && !response.data.message) {
      return response.data;
    }
    return response.data.message;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const permissiongroupsService = {
  search,
  permissiongroup,
  update,
  create,
  deletePermissiongroup,
  attachToUsers,
  attachToTeams,
  detachToUsers,
  detachToTeams,
};

export default permissiongroupsService;
