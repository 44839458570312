/* eslint-disable react/require-default-props */
/* eslint-disable import/prefer-default-export */
import { twMerge } from "tailwind-merge";

import { useNavigate } from "react-router-dom";
import CustomToggle from "../Buttons/CustomToggle.component";
import useUserProfile from "./controller";
import { ProfileAvatar } from "./Avatar.component";

export const ProfileModalContent = ({
  onClose,
  type,
}: {
  onClose: () => void;
  type?: string;
}) => {
  const { user, isOnline, hasAvatar, updateStatus, handleLogout } =
    useUserProfile({ type });

  const navigate = useNavigate();

  function handleTogglePopUp() {
    onClose();
    navigate("/settings/profile");
  }

  const name =
    user && user.name
      ? user.name.split(" ").length > 2
        ? `${user.name.split(" ")[0]} ${user.name.split(" ").at(-1)}`
        : user.name
      : "Unknown";

  return (
    <main>
      <section
        className="flex items-center gap-2 p-3 border-b-[1px]"
        title="Perfil"
      >
        <div className="w-8 h-8">
          <ProfileAvatar
            hasAvatar={hasAvatar}
            user={user}
            fontSize="text-[10px]"
          />
        </div>
        <aside className="flex justify-center flex-col">
          <strong
            className="text-sm leading-[14px] font-semibold"
            title={user?.name || "Usuário"}
          >
            {name || "Usuário"}
          </strong>
          <p
            className="flex items-center row"
            title={isOnline ? "Online" : "Offline"}
          >
            <div
              className={twMerge(
                "flex w-3 h-3 rounded-full mr-1",
                isOnline ? "bg-green" : "bg-red"
              )}
            />
            <span className="text-xs leading-[14px] text-gray-777">
              {isOnline ? "Online" : "Offline"}
            </span>
          </p>
        </aside>
      </section>

      <section
        className="flex items-center gap-2  p-3 px-4 border-b-[1px]"
        title="Status"
      >
        <CustomToggle onChange={updateStatus} checked={isOnline} />
        <span>{isOnline ? "Online" : "Offline"}</span>
      </section>

      <section
        className="flex items-center gap-2 p-3 px-4 cursor-pointer border-b-[1px]"
        title="Perfil"
        onClick={handleTogglePopUp}
      >
        <i className="las la-user text-xl" />
        <span>Meu perfil</span>
      </section>

      <section
        className="flex items-center gap-2 p-3 px-4 cursor-pointer"
        title="Sair"
        onClick={handleLogout}
      >
        <i
          role="presentation"
          className="las la-sign-out-alt text-red text-xl"
          title="Sair"
        />
        <span className="text-red">Sair</span>
      </section>
    </main>
  );
};
