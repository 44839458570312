/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, memo } from "react";
import NotificationBadge from "../features/chats/components/NotificationBadge.component";
import { useAppSelector } from "../hooks/redux/hooks";
import { UserType } from "../slices/users.slice";
import { getNotificationsAmount } from "../shared/utils/getChatNotificationsByUser";

const WidgetButton = ({
  widgetType,
  toggleOpenClose,
  onToggleOpenClose,
}: {
  widgetType: UserType;
  toggleOpenClose: boolean;
  onToggleOpenClose: React.MouseEventHandler<HTMLDivElement>;
}) => {
  const { mineChats, backlogChats, selectedChat } = useAppSelector(
    (state) => state.chats
  );

  const { user } = useAppSelector((state) => state.auth);

  const [badge, setBadge] = useState<number>(0);

  useEffect(() => {
    let value = 0;
    const notificationMineArray = mineChats?.map((chat) =>
      getNotificationsAmount(chat, user!)
    );
    const notificationBacklogArray = backlogChats?.map((chat) =>
      getNotificationsAmount(chat, user!)
    );

    if (notificationMineArray && notificationMineArray.length > 0) {
      notificationMineArray.forEach((_notify) => {
        if (_notify) value += _notify;
      });
    }

    if (
      notificationBacklogArray &&
      notificationBacklogArray.length > 0 &&
      widgetType !== UserType.CONTACT
    ) {
      notificationBacklogArray.forEach((_notify) => {
        if (_notify) value += _notify;
      });
    }

    setBadge(value);
  }, [mineChats.length, backlogChats.length, selectedChat, widgetType]);

  useEffect(() => {
    const widget = document.getElementById("widget-button");
    document.body.style.backgroundColor = "transparent";
    const container = document.body;

    let offsetY = 0;

    if (widget) {
      widget.addEventListener("dragstart", (event) => {
        const rect = widget.getBoundingClientRect();
        offsetY = event.clientY - rect.top;
      });

      widget.addEventListener("dragend", (event) => {
        const containerRect = container.getBoundingClientRect();
        const widgetRect = widget.getBoundingClientRect();

        let newTop = event.clientY - offsetY;

        if (newTop < containerRect.top) {
          newTop = containerRect.top;
        }

        if (newTop + widgetRect.height > containerRect.bottom) {
          newTop = containerRect.bottom - widgetRect.height;
        }

        widget.style.top = `${newTop}px`;
      });
    }
  }, []);

  return (
    <div
      id="widget-button"
      role="presentation"
      draggable
      className={`absolute flex flex-col items-center justify-center right-0 top-0 w-[32px] ${
        widgetType === UserType.AGENT || widgetType === UserType.EMBEDDED
          ? "h-[130px]"
          : "h-[178px]"
      } cursor-pointer ${
        toggleOpenClose
          ? "bg-red-500"
          : "rounded-tl-[8px] rounded-bl-[8px] bg-orange-500"
      }`}
      onClick={onToggleOpenClose}
      title={
        !toggleOpenClose
          ? widgetType === UserType.AGENT || widgetType === UserType.EMBEDDED
            ? "Abrir chat"
            : "Precisa de ajuda?"
          : "Recolher"
      }
    >
      {toggleOpenClose ? (
        <i className="las la-times mb-[5px] text-[18px] text-white" />
      ) : null}
      <span
        style={{
          writingMode: "vertical-rl",
          msWritingMode: "vertical-rl",
        }}
        className={`text-white text-[16px] ${
          toggleOpenClose ? "-rotate-1" : "-rotate-180"
        }`}
      >
        {!toggleOpenClose
          ? widgetType === UserType.AGENT || widgetType === UserType.EMBEDDED
            ? "Abrir chat"
            : "Precisa de ajuda?"
          : "Recolher"}
      </span>
      {!toggleOpenClose ? (
        <i className="las la-headset mt-[5px] text-[18px] text-white -rotate-90" />
      ) : null}
      {badge > 0 && !toggleOpenClose ? (
        <NotificationBadge badge={badge} />
      ) : null}
    </div>
  );
};

export default memo(WidgetButton);
