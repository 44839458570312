import React from "react";
import { useAppSelector } from "../../../hooks/redux/hooks";
import Avatar from "../Avatar.component";
import NoAvatar from "../NoAvatar.component";

const WhatsAppMobileHeaderPreview = () => {
  const { user } = useAppSelector((state) => state.auth);
  return (
    <div id="WhatsAppMobileHeaderPreview" className="w-full">
      <div id="BusinessDetails">
        {/* LOGO CIRCLE */}
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
        >
          <circle opacity="0.2" cx="14" cy="14" r="14" fill="#BBF7D0" />
        </svg> */}
        <div className="w-[28px] h-[28px]">
          {user &&
          user?.company?.avatar &&
          user?.company?.avatar?.path &&
          user?.company?.avatar?.name ? (
            <Avatar
              path={user?.company?.avatar?.path}
              name={user?.company?.avatar?.name}
              title={user?.company?.name || "Imagem da empresa"}
              extraClass="border border-white"
            />
          ) : (
            <NoAvatar
              displaytext={user?.company?.name || "--"}
              elementClass="p-0"
              labelClass="text-[11px]"
            />
          )}
        </div>
        {/* BUSINESS NAME */}
        <div id="BusinessName" className="flex items-center gap-1 flex-1">
          <span className="text-base-white text-50 overflow-ellipsis leading-5 font-bold leading-trim-both text-edge-cap">
            {user?.company?.name ?? "Nome empresa"}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clipPath="url(#clip0_4398_5428)">
              <path
                d="M15.6667 8L14.04 6.14L14.2667 3.68L11.86 3.13333L10.6 1L8.33333 1.97333L6.06667 1L4.80667 3.12667L2.4 3.66667L2.62667 6.13333L1 8L2.62667 9.86L2.4 12.3267L4.80667 12.8733L6.06667 15L8.33333 14.02L10.6 14.9933L11.86 12.8667L14.2667 12.32L14.04 9.86L15.6667 8ZM7.06 11.1467L4.52667 8.60667L5.51333 7.62L7.06 9.17333L10.96 5.26L11.9467 6.24667L7.06 11.1467Z"
                fill="#86EFAC"
              />
            </g>
            <defs>
              <clipPath id="clip0_4398_5428">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        {/* ACTION BUTTONS */}
        <div id="ActionButtons" className="flex items-start gap-2 opacity-20">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clipPath="url(#clip0_4398_5452)">
              <path
                d="M20.01 15.38C18.78 15.38 17.59 15.18 16.48 14.82C16.13 14.7 15.74 14.79 15.47 15.06L13.9 17.03C11.07 15.68 8.42 13.13 7.01 10.2L8.96 8.54C9.23 8.26 9.31 7.87 9.2 7.52C8.83 6.41 8.64 5.22 8.64 3.99C8.64 3.45 8.19 3 7.65 3H4.19C3.65 3 3 3.24 3 3.99C3 13.28 10.73 21 20.01 21C20.72 21 21 20.37 21 19.82V16.37C21 15.83 20.55 15.38 20.01 15.38Z"
                fill="#BBF7D0"
              />
            </g>
            <defs>
              <clipPath id="clip0_4398_5452">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clipPath="url(#clip0_4398_5455)">
              <path
                d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z"
                fill="#BBF7D0"
              />
            </g>
            <defs>
              <clipPath id="clip0_4398_5455">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default WhatsAppMobileHeaderPreview;
