/* eslint-disable react/no-array-index-key */
import React, { Children, isValidElement, ReactElement } from "react";
import WizardListItem from "./WizardListItem.component";

export interface WizardMenuProps {
  children:
    | ReactElement<typeof WizardListItem>
    | ReactElement<typeof WizardListItem>[];
}

const WizardMenu = ({ children }: WizardMenuProps) => {
  const validChildren = Children.toArray(children).filter(
    (child) =>
      isValidElement(child) && (child as ReactElement).type === WizardListItem
  );
  return (
    <div id="WizardMenu" className="flex w-full flex-col items-start gap-y-1">
      {validChildren}
    </div>
  );
};

export default WizardMenu;
