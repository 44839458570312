import React from "react";
import { Sidebar } from "semantic-ui-react";
import LoadingDots from "../shared/components/LoadingDots.component";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";

type PageProps = {
  isLoading: boolean;
  header?: {
    icon?: string;
    title?: string;
    created?: string;
    updated?: string;
    deleted?: string;
    imported?: boolean;
    spam?: boolean;
  };
  footer?: {
    disabled?: boolean;
    deleted?: boolean;
    modaltitle?: string;
    toggleSidebar?: boolean;
    showDeleteModal?: boolean;
    setShowDeleteModal?: React.Dispatch<React.SetStateAction<boolean>>;
    showReactiveModal?: boolean;
    setShowReactiveModal?: React.Dispatch<React.SetStateAction<boolean>>;
    showSpamModal?: boolean;
    setShowSpamModal?: React.Dispatch<React.SetStateAction<boolean>>;
    back?: VoidFunction;
    draft?: VoidFunction;
    reactive?: VoidFunction;
    remove?: VoidFunction;
    save?: VoidFunction;
    spamAction?: VoidFunction;
  };
  content: React.ReactNode;
  modals?: React.ReactNode;
};

export type SectionPageProps = {
  id: string;
  icon: string;
  title: string;
  description: string;
};

const DefaultPage = ({
  isLoading,
  header,
  content,
  footer,
  modals,
}: PageProps) => {
  return isLoading ? (
    <LoadingDots centerPage large />
  ) : (
    <Sidebar.Pusher>
      {typeof header !== "undefined" ? <HeaderPageInfo {...header} /> : null}
      {content}
      {header?.icon !== "las la-envelope-square" && <div className="mb-20" />}
      {typeof footer !== "undefined" ? <FooterPageInfo {...footer} /> : null}
      {typeof modals !== "undefined" ? modals : null}
    </Sidebar.Pusher>
  );
};

DefaultPage.defaultProps = {
  header: undefined,
  footer: undefined,
  modals: undefined,
};

export default DefaultPage;
