import React from "react";
import WizardStepNumber, {
  WizardStepNumberProps,
  WizardStepNumberStylesObject,
} from "./WizardStepNumber.component";

export type WizardListItemProps =
  | (WizardStepNumberProps & {
      onClick: VoidFunction;
      label?: string;
    })
  | (WizardStepNumberProps & {
      state: "disabled";
      onClick?: never;
      label?: string;
    });

export interface WizardListItemStylesObject
  extends WizardStepNumberStylesObject {
  [key: string]: {
    border: string;
    bg: string;
    fg: string;
    "hover-border": string;
    "hover-bg": string;
    "hover-fg": string;
  };
}

const WizardListItem = ({
  state,
  onClick,
  label,
  number,
  icon,
  value,
}: WizardListItemProps) => {
  return (
    <div
      id="WizardListItem"
      role="presentation"
      onClick={state !== "disabled" ? onClick : undefined}
      className={` 
        group flex py-2.5 px-4 items-center gap-4 shrink-0 w-full max-h-10 rounded-2 justify-between
        ${state === "disabled" ? "cursor-default" : "cursor-pointer"}
        ${state === "enabled" ? "hover:bg-secondary" : ""} 
        ${state === "selected" ? "bg-orange-50 hover:bg-orange-100" : ""} 
        `}
    >
      <div className="flex items-center gap-4">
        <WizardStepNumber
          state={state}
          number={number as never}
          icon={icon as never}
          value={value}
        />
        {label && (
          <span
            className={`
              text-100-to-fix overflow-ellipsis leading-5 line-clamp-1 leading-trim-both text-edge-cap
              ${
                state === "selected"
                  ? "text-orange-500 group-hover:text-orange-600"
                  : ""
              }
            ${
              state === "enabled"
                ? "text-slate-500 group-hover:text-slate-700"
                : ""
            }
            ${state === "disabled" ? "text-slate-400" : ""}
            `}
          >
            {label}
          </span>
        )}
      </div>
      {state !== "disabled" && (
        <i
          className={`
            las la-angle-right text-100-to-fix justify-center items-center shrink-0 
            ${
              state !== "selected"
                ? "group-hover:flex hidden text-slate-500"
                : "flex text-orange-300"
            }`}
        />
      )}
    </div>
  );
};

export default WizardListItem;
