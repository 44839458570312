import React from "react";

export interface ViewFilterProps {
  state: "grid" | "table";
  onClick: VoidFunction;
}

const ViewFilter = ({ state, onClick }: ViewFilterProps) => {
  return (
    <div id="ViewFilter" className="flex">
      <div
        role="presentation"
        onClick={state === "table" ? onClick : undefined}
        id="grid"
        className={`${
          state === "grid"
            ? "!border border-orange-300 bg-orange-50"
            : "border-slate-200 bg-base-white"
        } cursor-pointer flex h-[34px] w-[34px] py-2 px-4 gap-3 justify-center items-center rounded-s-1 border-y border-l`}
      >
        <i
          className={`${
            state === "grid" ? "text-orange-500" : "fg-subtle"
          } las la-th-large flex justify-center items-center text-100`}
        />
      </div>
      <div
        role="presentation"
        onClick={state === "grid" ? onClick : undefined}
        id="table"
        className={`${
          state === "table"
            ? "!border border-orange-300 bg-orange-50"
            : "border-slate-200 bg-base-white"
        } cursor-pointer flex h-[34px] w-[34px] py-2 px-4 gap-3 justify-center items-center rounded-e-1 border-y border-r`}
      >
        <i
          className={`${
            state === "table" ? "text-orange-500" : "fg-subtle"
          } las la-list-ul flex justify-center items-center text-100`}
        />
      </div>
    </div>
  );
};

export default ViewFilter;
