/* eslint-disable react/require-default-props */
import React, { useState, useRef } from "react";
import { twMerge } from "tailwind-merge";

import useUserProfile from "./controller";

import PopUpMenu from "../Modals/PopUpMenuModal.component";
import { ProfileModalContent } from "./ModalContent.component";
import { ProfileAvatar } from "./Avatar.component";

const UserProfileStatus = ({ type }: { type?: string }) => {
  const { user, hasAvatar, isOnline } = useUserProfile({});

  const [open, setOpen] = useState(false);

  const contextRef = useRef(null);

  const handleTogglePopUp = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <div
        role="presentation"
        title={user?.name}
        onClick={handleTogglePopUp}
        className="w-10 h-12 flex justify-center items-center relative cursor-pointer"
        ref={contextRef}
      >
        <div className="w-[32px] h-[32px]">
          <ProfileAvatar hasAvatar={hasAvatar} user={user} />
        </div>

        <div
          title="Trocar status"
          role="presentation"
          className={twMerge(
            "flex absolute w-[13px] h-[13px] rounded-full bottom-0 right-0 cursor-pointer",
            isOnline ? "bg-green" : "bg-red"
          )}
        />
      </div>

      <PopUpMenu ref={contextRef} open={open}>
        <ProfileModalContent onClose={handleTogglePopUp} type={type} />
      </PopUpMenu>
    </>
  );
};

export default UserProfileStatus;
