/* eslint-disable react/require-default-props */
import React from "react";
import { twMerge } from "tailwind-merge";
import { useLocation } from "react-router-dom";
import HeaderCardGroup from "./HeaderCardGroup.component";
import FormHeader from "./FormHeader.component";
import CategoryCardGroup from "./CategoryCardGroup.component";
import ConfigFormGroup from "./ConfigFormGroup.component";
import ButtonFormGroup from "./ButtonFormGroup.component";
import ContentFormGroup from "./ContentFormGroup.component";
import { TemplateStepsType } from "../../../slices/templates.slice";
import FooterFormGroup from "./FooterFormGroup.component";
import { useAppSelector } from "../../../hooks/redux/hooks";

export interface StepFormTypesObject {
  [key: string]: {
    title: string | JSX.Element;
    description?: string;
    content?: JSX.Element;
  };
}

type StepFormProps =
  | {
      type: TemplateStepsType;
      title?: string;
    }
  | { type: "disabled"; title: string };

const StepForm = ({ type, title }: StepFormProps) => {
  const location = useLocation();
  const { selectedLocalTemplate } = useAppSelector((state) => state.templates);

  const forms: StepFormTypesObject = {
    header: {
      title: "Cabeçalho",
      description: "Selecione o tipo de cabeçalho que você usará na mensagem.",
      content: <HeaderCardGroup />,
    },
    config: {
      title: "Configuração",
      description: "Configure os principais dados da conta do template.",
      content: <ConfigFormGroup />,
    },
    content: {
      title: "Conteúdo",
      description:
        "Insira o texto da sua mensagem no idioma selecionado. (Máximo: 1024 caracteres)",
      content: <ContentFormGroup />,
    },
    footer: {
      title: (
        <span>
          Rodapé <span className="fg-weak">(opcional)</span>
        </span>
      ),
      description:
        "Insira o texto do rodapé da sua mensagem. (Máximo: 60 caracteres)",
      content: <FooterFormGroup />,
    },
    buttons: {
      title:
        selectedLocalTemplate?.selectedCategory !== "auth" ? (
          <span>
            Botões de ação <span className="fg-weak">(opcional)</span>
          </span>
        ) : (
          <span>Botões de ação</span>
        ),
      description: "Adicione botões de respostas rápidas ou ações específicas.",
      content: <ButtonFormGroup />,
    },
    category: {
      title: "Categoria do template",
      description:
        "Primeiro selecione uma categoria que descreva o seu template de mensagem.",
      content: <CategoryCardGroup />,
    },
    disabled: { title: title || "Nome do passo" },
  };

  return (
    <div
      id={`${type}-StepForm`}
      className={twMerge(
        "relative min-w-[457px] w-full flex p-8 flex-col items-start gap-9 rounded-2 border border-subtle bg-default",
        type === "disabled" && "bg-default--hover !text-slate-400",
        location.pathname === "/settings/template/agibot" && "non-selectable"
      )}
    >
      <FormHeader
        type={type}
        title={forms[type]?.title}
        description={forms[type]?.description}
      />
      {forms[type].content}
    </div>
  );
};

export default StepForm;
