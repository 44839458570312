/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";

import {
  LayoutPage,
  LoginPage,
  PasswordPage,
  RecoveryPage,
  PrivacyPage,
  SurveyratingPage,
  LoginTenantPage,
  PublichoursPage,
} from "../pages";
import {
  PrivateRoute,
  PublicRoute,
  WidgetAgentRoute,
  WidgetContactRoute,
} from "../routes";

import WidgetButton from "../widget/WidgetButton.component";
import RecentChatsList from "../features/chats/components/RecentChats/List.component";
import {
  authenticationWidgetEmbedded,
  changeStatus,
  selectTenant,
  selectWidget,
  signin,
  userdata,
} from "../slices/auth.slice";
import {
  ChatStatus,
  clearRecents,
  closeWidget,
  selectChat,
} from "../slices/chats.slice";
import { IUser, UserStatus, UserType } from "../slices/users.slice";
import instances, { updateTenant } from "../services/instances";
import { getSettings } from "../slices/settings.slice";
import favicon from "../shared/utils/favicon";
import { teamsChatsAssignment } from "../slices/teams.slice";
import { loadUserPreferences } from "../slices/preferences.slice";
import { getNotificationsAmount } from "../shared/utils/getChatNotificationsByUser";
import { resetWaitingMessages } from "../slices/messages.slice";
import WidgetEmbeddedRoute from "../routes/WidgetEmbedded.route";

const App = () => {
  const {
    user,
    isAuthenticated,
    isStatusManualChanged,
    isWidgetAgent,
    isWidgetContact,
    isWidgetEmbedded,
  } = useAppSelector((state) => state.auth);
  const { recentChats, backlogChats, mineChats } = useAppSelector(
    (state) => state.chats
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const getType = () => {
    if (isWidgetAgent) {
      return UserType.AGENT;
    }
    if (isWidgetContact) {
      return UserType.CONTACT;
    }
    if (isWidgetEmbedded) {
      return UserType.EMBEDDED;
    }
    return UserType.NONE;
  };

  const [toggleOpenClose, setToggleOpenClose] = useState<boolean>(false);
  const widgetTypeRef = useRef<UserType>(getType());
  // const [widgetType, setWidgetType] = useState<UserType>(getType());
  const [disable, setDisable] = useState(false);
  const [src, setSrc] = useState("/favicon.ico");
  const [code, setCode] = useState("");
  const [badge, setBadge] = useState<number>(0);

  useEffect(() => {
    if (
      typeof user?._id !== "undefined" &&
      isAuthenticated &&
      !user?.permissiongroups?.length
    ) {
      (async () => {
        await dispatch(userdata());
      })();
    }
  }, []);

  useEffect(() => {
    if (
      typeof user?._id !== "undefined" &&
      isAuthenticated &&
      widgetTypeRef.current !== UserType.EMBEDDED
    ) {
      // (async () => {
      //   await dispatch(userdata());
      // })();
      if (user.status === UserStatus.OFFLINE && !isStatusManualChanged) {
        (async () => {
          await dispatch(
            changeStatus({ status: UserStatus.ONLINE, origin: user?._id })
          );
          await dispatch(teamsChatsAssignment());
        })();
      }
      dispatch(loadUserPreferences(user._id));
    }
  }, []);

  useEffect(() => {
    // console.log(widgetTypeRef.current);
    // widgetTypeRef.current = getType();
  }, [widgetTypeRef.current]);

  useEffect(() => {
    if (widgetTypeRef.current !== UserType.EMBEDDED) {
      const backlogNotifications = backlogChats.reduce((accumulator, chat) => {
        if (
          chat?.notifications?.length &&
          getNotificationsAmount(chat, user as IUser) > 0 &&
          chat?.status &&
          ![
            ChatStatus.CANCELED,
            ChatStatus.SUSPEND,
            ChatStatus.FINISH,
          ].includes(chat.status)
        ) {
          return accumulator + 1;
        }
        return accumulator;
      }, 0);
      const mineChatsNotifications = mineChats.reduce((accumulator, chat) => {
        if (
          chat?.notifications?.length &&
          getNotificationsAmount(chat, user as IUser) > 0 &&
          chat?.status &&
          ![ChatStatus.CANCELED, ChatStatus.FINISH].includes(chat.status)
        ) {
          return accumulator + 1;
        }
        return accumulator;
      }, 0);
      let _total = mineChatsNotifications;
      if (widgetTypeRef.current !== UserType.CONTACT) {
        _total += backlogNotifications;
      }
      setBadge(_total);
    }
  }, [backlogChats, mineChats, widgetTypeRef.current]);

  const GenerateBlob = () => {
    const blob = new Blob([favicon({ notifications: badge })], {
      type: "image/svg+xml;utf8",
    });
    const img = URL.createObjectURL(blob);
    setSrc(img);
  };

  useEffect(() => {
    GenerateBlob();
  }, [badge, widgetTypeRef.current]);

  const getInfos = async ({ widgetcode }: { widgetcode?: string }) => {
    const response = await instances.instanceWidget.post("", {
      widgetcode: widgetcode || code,
    });
    if (
      typeof response?.status !== "undefined" &&
      [200, 201].includes(response.status)
    ) {
      await dispatch(getSettings({ widget: "widget" }));
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    window.parent.postMessage("toggleOpenClose", "*");
    if (user && widgetTypeRef.current === UserType.AGENT) {
      dispatch(
        changeStatus({
          status: !toggleOpenClose ? UserStatus.ONLINE : UserStatus.OFFLINE,
          origin: user._id,
        })
      );
    }
    if (recentChats.length > 0 && widgetTypeRef.current === UserType.AGENT) {
      dispatch(clearRecents());
    }
    dispatch(closeWidget(toggleOpenClose));
    setToggleOpenClose((prevState) => !prevState);
  };

  const sholdShowXBtnToCloseWidget = toggleOpenClose && window.outerWidth < 800;

  useEffect(() => {
    try {
      window.addEventListener("message", async (event) => {
        if (typeof event.data === "string" && event.data.length > 0) {
          const widgetInfo = event.data.split("|");
          if (widgetInfo.length > 1) {
            if (
              widgetInfo[1] === UserType.AGENT ||
              widgetInfo[1] === UserType.CONTACT ||
              widgetInfo[1] === UserType.EMBEDDED
            ) {
              localStorage.clear();
              // eslint-disable-next-line prefer-destructuring
              widgetTypeRef.current = widgetInfo[1];
              const slug = widgetInfo[0];
              if (widgetTypeRef.current === UserType.CONTACT) {
                // dispatch(refreshLogout());
                updateTenant(slug, "contact");
                dispatch(selectTenant(slug));
                dispatch(selectWidget("contact"));
              } else if (widgetTypeRef.current === UserType.AGENT) {
                updateTenant(slug, "agent");
                dispatch(selectTenant(slug));
                dispatch(selectWidget(widgetInfo[1]));
                await dispatch(signin({ tenant: slug }));
              } else if (
                widgetTypeRef.current === UserType.EMBEDDED &&
                typeof widgetInfo[2] !== "undefined" &&
                widgetInfo[2]
              ) {
                // TODO
                dispatch(authenticationWidgetEmbedded());
                updateTenant(slug, "embedded");
                dispatch(selectTenant(slug));
                dispatch(selectWidget(widgetInfo[1]));
                await dispatch(userdata());
                await dispatch(selectChat({ _id: widgetInfo[2] }));
                window.history.pushState({}, "", `/chat/${widgetInfo[2]}`);
                dispatch(resetWaitingMessages());
              }
            }
            if (
              widgetInfo[1] === UserType.CONTACT &&
              widgetInfo[2] &&
              widgetInfo[2].length > 0
            ) {
              setCode(widgetInfo[2]);
              await getInfos({ widgetcode: widgetInfo[2] });
            }
          }
        }
      });
    } catch (error) {
      // console.log(error);
    }
  }, []);

  const getWidgetRoutes = () => {
    switch (widgetTypeRef.current) {
      case UserType.CONTACT:
        return (
          <WidgetContactRoute
            disable={disable}
            setDisable={setDisable}
            toggleOpenClose={toggleOpenClose}
            widgetType={widgetTypeRef.current}
            onClick={() => getInfos({ widgetcode: undefined })}
          />
        );
      case UserType.EMBEDDED:
        return (
          <WidgetEmbeddedRoute
            disable={disable}
            setDisable={setDisable}
            toggleOpenClose={toggleOpenClose}
            widgetType={widgetTypeRef.current}
          />
        );
      case UserType.AGENT:
      default:
        return (
          <WidgetAgentRoute
            disable={disable}
            setDisable={setDisable}
            toggleOpenClose={toggleOpenClose}
            widgetType={widgetTypeRef.current}
          />
        );
    }
  };

  return (
    <HelmetProvider>
      <Router>
        <Helmet
          defer={false}
          title={`${badge > 0 ? `(${badge})` : ""} ${t("agitalks")}`}
          prioritizeSeoTags
        >
          <meta
            name="description"
            content="Plataforma omnichannel para comunicação interna e atendimento ao cliente"
          />
          <link rel="icon" href={`${src}`} type="image/x-icon" />
        </Helmet>
        {widgetTypeRef.current === UserType.NONE ? <ToastContainer /> : null}
        {widgetTypeRef.current !== UserType.NONE ? (
          <div className="flex flex-col">
            {recentChats.length > 0 &&
            widgetTypeRef.current === UserType.AGENT &&
            toggleOpenClose ? (
              <RecentChatsList />
            ) : null}
            {getWidgetRoutes()}
            {/* eslint-disable-next-line no-nested-ternary */}
            {widgetTypeRef.current !== UserType.EMBEDDED ? (
              !sholdShowXBtnToCloseWidget ? (
                <WidgetButton
                  widgetType={widgetTypeRef.current}
                  toggleOpenClose={toggleOpenClose}
                  onToggleOpenClose={handleClick}
                />
              ) : (
                <span
                  className={twMerge(
                    "absolute top-2 cursor-pointer font-black p-4 text-xl",
                    widgetTypeRef.current === UserType.AGENT
                      ? "right-7"
                      : "right-2"
                  )}
                  onClick={handleClick}
                >
                  X
                </span>
              )
            ) : null}
          </div>
        ) : (
          <Routes>
            <Route element={<PublicRoute />}>
              <Route path="/company" element={<LoginTenantPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/login-agidesk" element={<LoginPage />} />
              <Route path="/recovery" element={<RecoveryPage />} />
              <Route path="/password" element={<PasswordPage />} />
              <Route path="/privacy" element={<PrivacyPage />} />
              <Route path="/surveyrating" element={<SurveyratingPage />}>
                <Route
                  path="/surveyrating/:_id"
                  element={<SurveyratingPage />}
                />
              </Route>
              <Route
                path="/schedule/officehours/:tenant"
                element={<PublichoursPage />}
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/*"
                element={
                  <LayoutPage
                    widgetType={widgetTypeRef.current}
                    disable={disable}
                    setDisable={setDisable}
                  />
                }
              />
            </Route>
          </Routes>
        )}
      </Router>
    </HelmetProvider>
  );
};

export default App;
