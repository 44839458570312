/* eslint-disable react/no-children-prop */
import React from "react";
import PhoneInput from "react-phone-input-2";
import { Form, SemanticWIDTHS } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { validNumberLength } from "../utils/handleMaskLength";
import "react-phone-input-2/lib/semantic-ui.css";
import pt from "react-phone-input-2/lang/pt.json";

const PhoneFlagInput = ({
  defaultValue,
  readOnly,
  className,
  onChange,
  required,
  onBlur,
  error,
  label,
  width,
}: {
  label?: string;
  className?: string;
  defaultValue: string | undefined;
  readOnly?: boolean;
  required?: boolean;
  onBlur?: VoidFunction;
  error?: boolean;
  width?: SemanticWIDTHS;
  onChange: (value: string) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Form.Input
      width={width}
      className={className}
      required={required || false}
      readOnly={readOnly}
      label={label || t("form.label.phone")}
      onBlur={onBlur}
      error={error}
      children={
        <PhoneInput
          disabled={readOnly}
          localization={pt}
          containerClass="!w-full !h-full"
          country="br"
          inputProps={{
            id: "phone",
            name: "phone",
            required: true,
            autoComplete: "none",
            "data-testid": "input-id",
          }}
          value={defaultValue}
          // specialLabel={t("form.label.phone") || ""}
          searchNotFound="Não encontrado"
          searchPlaceholder="Pesquisar"
          disableSearchIcon
          placeholder="Telefone"
          enableSearch
          enableAreaCodeStretch
          countryCodeEditable={false}
          onChange={onChange}
          isValid={(inputNumber) => {
            return validNumberLength({ value: inputNumber });
          }}
        />
        // <InputMask
        //   min={12}
        //   max={13}
        //   mask={handleMaskLength({ value: defaultValue, ddi })}
        //   readOnly={readOnly}
        //   placeholder={t("form.placeholder.phone") || ""}
        //   defaultValue={defaultValue}
        //   type="phone"
        //   onChange={onChange}
        //   maskPlaceholder={null}
        // />
      }
      autoComplete="off"
    />
  );
};

PhoneFlagInput.defaultProps = {
  readOnly: false,
  required: false,
  onBlur: () => null,
  error: false,
  label: "",
  className: "",
  width: undefined,
};

export default PhoneFlagInput;
