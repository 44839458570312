import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import {
  reselectLocalPermissiongroup,
  reselectPermissiongroup,
  selectPermissiongroup,
} from "../slices/permissiongroups.slice";
import useGetIdPage from "../hooks/useGetIdPage";
import usePermissiongroups from "../hooks/features/usePermissiongroups";
import DividerInfoPages from "../shared/components/DividerInfoPages.component";
import Section from "../shared/components/Section.component";
import DefaultPage from "./Default.page";
import Infos from "../features/permissiongroups/components/Infos.section";
import Permissions from "../features/permissiongroups/components/Permissions.section";
import SectionElement from "../shared/components/SectionElement.component";
import { SidebarComponent } from "../shared/components/Sidebars";
import CreateModal from "../shared/components/Modals/CreateModal.component";
import RelationModal from "../shared/components/Modals/RelationModal.component";
import ConfirmationModal from "../shared/components/Modals/ConfirmationModal.component";

const RELATION_PROPS = {
  USER: {
    CREATE_MODAL: {
      title: "Novo agente",
      icon: "las la-user"
    },
    RELATION_MODAL: {
      title: "Relacionar agentes",
      icon: "las la-user"
    }
  },
  TEAM: {
    CREATE_MODAL: {
      title: "Nova equipe",
      icon: "las la-user-friends"
    },
    RELATION_MODAL: {
      title: "Relacionar equipes",
      icon: "las la-user-friends"
    }
  }
}

const PermissiongroupPage = ({
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [showRelationModal, setShowRelationModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [relation, setRelation] = useState<keyof typeof RELATION_PROPS>('USER');
  const { getId } = useGetIdPage();
  // const { isAdmin } = useUserIsAdmin();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isLoadingPermissiongroups, selectedPermissiongroup } = useAppSelector(
    (state) => state.permissiongroups
  );
  const { sectionDetails, header, footer } = usePermissiongroups({
    disable,
    setDisable,
    toggleSidebar,
  });

  useEffect(() => {
    const _id = getId();
    if (location.pathname === `/settings/permissiongroup/${_id}`) {
      (async () => {
        dispatch(reselectPermissiongroup(null));
        dispatch(reselectLocalPermissiongroup(null));
        await dispatch(selectPermissiongroup({ _id }));
      })();
    }
  }, []);

  return (
    <div className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-4 sm:px-8">
      <SidebarComponent
        visible={visible}
        setVisible={setVisible}
        content={
          <CreateModal
            title={RELATION_PROPS[relation].CREATE_MODAL.title}
            icon={RELATION_PROPS[relation].CREATE_MODAL.icon}
            setShowModal={setShowRelationModal}
            setVisible={setVisible}
          />
        }
      />
      <DefaultPage
        isLoading={
          isLoadingPermissiongroups && selectedPermissiongroup === null
        }
        header={header}
        content={
          <>
            <Section
              icon={sectionDetails.infos.icon}
              title={sectionDetails.infos.title}
              description={sectionDetails.infos.description}
              component={<Infos />}
            />
            <DividerInfoPages />
            <Section
              icon="las la-user"
              title="Agentes que pertencem ao grupo"
              description="Relacione os agentes desejados"
              component={
                <SectionElement
                  instance={selectedPermissiongroup}
                  type="team-users"
                  setShowModal={(s) => {
                    setRelation('USER');
                    setShowRelationModal(s)
                  }}
                  setVisible={(v) => {
                    setRelation('USER');
                    setVisible(v)
                  }}
                />
              }
            />
            <DividerInfoPages />
            <Section
              icon="las la-user-friends"
              title="Equipes vinculadas ao grupo"
              description="Relacione as equipes desejadas"
              component={
                <SectionElement
                  instance={selectedPermissiongroup}
                  type="user-teams"
                  setShowModal={(s) => {
                    setRelation('TEAM');
                    setShowRelationModal(s)
                  }}
                  setVisible={(v) => {
                    setRelation('TEAM');
                    setVisible(v)
                  }}
                />
              }
            />
            <DividerInfoPages />
            <Section
              icon={sectionDetails.content.icon}
              title={sectionDetails.content.title}
              description={sectionDetails.content.description}
              fullarea
              component={<Permissions />}
            />
          </>
        }
        footer={{
          ...footer,
          save: (selectedPermissiongroup?.users?.length! > 0 || selectedPermissiongroup?.teams?.length! > 0)
            ? () => setShowConfirmationModal(true) : footer.save
        }}
      />
      {showRelationModal && (
        <RelationModal
          title={RELATION_PROPS[relation].RELATION_MODAL.title}
          icon={RELATION_PROPS[relation].RELATION_MODAL.icon}
          setShowModal={setShowRelationModal}
        />
      )}
      {showConfirmationModal && footer.save && (
        <ConfirmationModal
          disabled={false}
          action={() => {
            footer.save?.()
            setShowConfirmationModal(false)
          }}
          setShowModal={setShowConfirmationModal}
          title={[
            selectedPermissiongroup?.users?.length && `${selectedPermissiongroup?.users?.length} agente${selectedPermissiongroup?.users?.length > 1 ? "s" : ""}`,
            selectedPermissiongroup?.users?.length && selectedPermissiongroup?.teams?.length && "e",
            selectedPermissiongroup?.teams?.length && `${selectedPermissiongroup?.teams?.length} equipe${selectedPermissiongroup?.teams?.length > 1 ? "s" : ""}`,
            ((selectedPermissiongroup?.users?.length ?? 0) + (selectedPermissiongroup?.teams?.length ?? 0)) > 1 ? "serão afetados." : "será afetado."
          ].filter(s => !!s).join(" ")}
        />
      )}
    </div>
  );
};

export default PermissiongroupPage;
