/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  messagesService,
  whatsappService,
  botmakerService,
  externalappsService,
} from "../services";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import { Files, IFile } from "../shared/models/interfaces/file.interface";
import { IChat } from "./chats.slice";
import { IChangeUser, ISender, IUser } from "./users.slice";
import { IAcceptedFiles } from "../shared/models/interfaces/acceptedfile.interface";
import { IToastType } from "../shared/models/types/Toast.type";
import customToast from "../shared/utils/customToast";
import { ExternalAppType } from "./externalapps.slice";

export enum InternalMessageType {
  WIDGET_CONTACT = "WIDGET_CONTACT",
  SUSPEND_CHAT = "SUSPEND_CHAT",
  START_CHAT = "START_CHAT",
  TEAM_TRANSFER = "TEAM_TRANSFER",
  USER_TRANSFER = "USER_TRANSFER",
  CREATE_GROUP = "CREATE_GROUP",
  ADD_TO_GROUP = "ADD_TO_GROUP",
  REMOVE_FROM_GROUP = "REMOVE_FROM_GROUP",
  LEAVE_GROUP = "LEAVE_GROUP",
  REMOVE_GROUP = "REMOVE_GROUP",
  REMOVE_ADM_GROUP = "REMOVE_ADM_GROUP",
  ADD_ADM_GROUP = "ADD_ADM_GROUP",
  EDIT_GROUP = "REMOVE_GROUP",
}

export enum MessageType {
  ALL = "ALL",
  CONTACT = "CONTACT",
  USER = "USER",
}

export enum MessageStatus {
  WAIT = "WAIT",
  SENT = "SENT",
  DELIVERED = "DELIVERED",
  READ = "READ",
  FAILED = "FAILED",
}

export enum SenderType {
  USER = "USER",
  CONTACT = "CONTACT",
}

export interface IReadMessage {
  _id: string;
  read: boolean;
}

export interface ITypingMessage {
  chat: IChat;
  sender: string;
  message: string;
  type: string;
  action?: string;
  origin?: string;
}

export interface IMessage {
  _id: string;
  tenant?: string;
  deleted?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
  sender: ISender;
  sendertype?: SenderType;
  content?: string;
  chat: IChat;
  read: boolean;
  files: Files;
  parent?: IMessage | null;
  origin?: string;
  internal?: boolean;
  referenceid?: string;
  externalapp?: string;
  type?: MessageType;
  status?: MessageStatus;
  order?: number;
  commenttemplate?: string;
  statusmessage?: string;
}

export type Messages = IMessage[];

export interface UpdateStatusPayload {
  _id: string;
  chat: IChat;
  status: MessageStatus;
}

export interface INewMessage {
  chat: IChat;
  content: string;
  parent: string | null;
  files?: string[] | IFile[];
  preFiles?: Files;
  preSender?: IUser;
  preParent?: IMessage | null;
  origin?: string;
  internal?: boolean;
  referenceid?: string;
  read?: boolean;
  inactivetime?: string;
  type?: MessageType;
  sendertype?: SenderType;
  commenttemplate?: string;
}

export interface CurrencyWpp {
  fallback_value: string;
  code: string;
  ammount_1000: number;
}
export interface ButtonWpp {
  type?: string;
  text?: string;
  url?: string;
  example?: string[];
  phone_number?: string;
}
export interface ImageWpp {
  id?: string;
  link?: string;
}
export interface VideoWpp {
  id?: string;
  link?: string;
}
export interface DocumentWpp {
  id?: string;
  link?: string;
  filename?: string;
}
export interface LanguageWpp {
  code: string;
}

export interface DateTimeWpp {
  fallback_value: string;
  day_of_month: number;
  year: number;
  month: number;
  hour: number;
  minute: number;
}
export interface Parameter {
  key?: string;
  type?: string;
  image?: ImageWpp;
  document?: DocumentWpp;
  video?: VideoWpp;
  text?: string;
  dateTime?: DateTimeWpp;
  currency?: CurrencyWpp;
  coupon_code?: string;
  url?: string;
  sub_type?: string;
  index?: number;
}

export type ImgTemplateVars = {
  key?: string;
  type: string;
  text?: string;
  file?: IFile;
  image:
    | ImageWpp
    | {
        id: string;
      };
};
export type VideoTemplateVars = {
  key?: string;
  type: string;
  text?: string;
  file?: IFile;
  video:
    | VideoWpp
    | {
        id: string;
      };
};
export type DocumentTemplateVars = {
  key?: string;
  type: string;
  text?: string;
  file?: IFile;
  document:
    | DocumentWpp
    | {
        id: string;
        filename: string;
      };
};
export type CodeTemplateVars = {
  key?: string;
  text?: string;
  type: string;
  coupon_code: string;
  file?: IFile;
};
export type TextTemplateVars = {
  key?: string;
  type: string;
  text: string;
  file?: IFile;
};
export type FileTemplateVars = {
  key: string;
  text?: string;
  type: string;
  file: IFile;
  url: string;
};

export type ITemplateVarsArray = (
  | ImgTemplateVars
  | VideoTemplateVars
  | DocumentTemplateVars
  | CodeTemplateVars
  | TextTemplateVars
  | FileTemplateVars
)[];
export type ITemplateVars =
  | ImgTemplateVars
  | VideoTemplateVars
  | DocumentTemplateVars
  | CodeTemplateVars
  | TextTemplateVars
  | FileTemplateVars;

export interface ExampleComponent {
  header_text?: Array<string>[];
  body_text?: Array<string>[];
  header_handle?: string[];
}
export interface Component {
  format?: string;
  text?: string;
  buttons?: ButtonWpp[];
  type: string;
  example?: ExampleComponent;
  parameters?: Parameter[];
  sub_type?: string;
  index?: number;
}

export interface ITemplateVariables {
  header_text?: string[];
  body_text?: string[];
}

export interface ITemplate {
  category?: string;
  id?: string;
  name: string;
  status?: string;
  templateOrigin?: string;
  language: LanguageWpp | any;
  phone?: string | any;
  components: Component[];
  variables?: ITemplateVariables;
}

export interface IContext {
  message_id: string;
}

export type Templates = ITemplate[];
export interface INewMessageWpp {
  message_id: string;
  messaging_product: string;
  to: string;
  type: string;
  recipient_type?: string;
  text?: any;
  audio?: any;
  template?: ITemplate;
  context?: IContext;
  externalapp?: string;
}
export interface INewMessageExternalappWpp {
  chat_id?: string;
  message_id: string;
  messaging_product: string;
  to: string;
  type: string;
  recipient_type?: string;
  text?: any;
  audio?: any;
  template?: ITemplate;
  context?: IContext;
  externalapp?: string;
  contact?: IUser;
  team?: string;
}
export interface INewMessageBotmaker {
  message_id: string;
  messaging_product: string;
  to: string;
  type: string;
  recipient_type?: string;
  externalapp?: string;
  externalcode?: string;
  message?: object;
  text?: any;
  audio?: any;
  template?: ITemplate;
  context?: IContext;
  files?: IAcceptedFiles;
  externalcodephone?: string;
  phone?: string;
}

export interface IFinishChatBotmaker {
  externalapp?: string;
  externalcode?: string;
  externalcodephone?: string;
  phone?: string;
}

export interface IFilterMessage {
  _id: string;
  skip: number;
  limit: number;
  filter: string;
  history: boolean;
}

interface MessagesState extends AsyncState {
  isLoadingMessages: boolean;
  messages: Messages;
  whatsappTemplates: Templates;
  selectedTemplate: ITemplate | null;
  selectedTemplateMessage: string;
  selectedTemplateFiles: IAcceptedFiles;
  selectedTemplateFilesChanged: boolean;
  selectedTemplateButtons: {
    url: string;
    code: string;
  };
  selectedTemplateParameters:
    | {
        sub_type?: string;
        index?: number;
        type: string;
        parameters: Parameter[];
      }[]
    | null;
  totalMessages: number;
  isSending: boolean;
  isReading: boolean;
  chatdisable: boolean;
  waitingMessages: string[];
}

interface ITriggerChatInactive {
  chat: IChat;
  inactivetime: string;
}

const initialState: MessagesState = {
  messages: [],
  totalMessages: 0,
  isLoadingMessages: false,
  whatsappTemplates: [],
  selectedTemplate: null,
  selectedTemplateParameters: null,
  selectedTemplateMessage: "",
  selectedTemplateFiles: [],
  selectedTemplateFilesChanged: false,
  selectedTemplateButtons: {
    url: "",
    code: "",
  },
  isSending: false,
  isReading: false,
  isSuccess: false,
  isError: false,
  chatdisable: false,
  waitingMessages: [],
};

export const getSearchMessages = createAsyncThunk(
  "messages/search",
  async (filterMessage: IFilterMessage, thunkAPI) => {
    try {
      return await messagesService.search(filterMessage);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const getMessages = createAsyncThunk(
//   "message/list",
//   async (chat: string, thunkAPI) => {
//     try {
//       return await messagesService.getMessages(chat);
//     } catch (error) {
//     }
//   }
// );

export const sendMessageBotmaker = createAsyncThunk(
  "botmaker/create",
  async (newMessage: INewMessageBotmaker, thunkAPI) => {
    try {
      return await botmakerService.createBotmaker(newMessage);
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to create new message!");
    }
  }
);

export const finishChatBotmaker = createAsyncThunk(
  "botmaker/finish",
  async (botmakerPars: IFinishChatBotmaker, thunkAPI) => {
    try {
      return await botmakerService.finishBotmaker(botmakerPars);
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to create new message!");
    }
  }
);

export const sendMessageWpp = createAsyncThunk(
  "whatsapp/create",
  async (newMessage: INewMessageWpp, thunkAPI) => {
    try {
      return await whatsappService.createWpp(newMessage);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTemplatesWpp = createAsyncThunk(
  "whatsapp/templates",
  async (
    {
      externalappid,
    }: { externalappid: string; _validate?: boolean; _validateError?: boolean },
    thunkAPI
  ) => {
    try {
      return await whatsappService.templateWpp({ externalappid });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getExternalappTemplatesWpp = createAsyncThunk(
  "externalapp/wppTemplates",
  async (
    {
      externalappid,
      phone,
    }: {
      externalappid: string;
      _validate?: boolean;
      _validateError?: boolean;
      phone?: string;
    },
    thunkAPI
  ) => {
    try {
      const filter = { _id: externalappid };
      const externalapp = await externalappsService.externalapps(filter);

      switch (externalapp.type) {
        case ExternalAppType.WHATSAPP:
          return await whatsappService.templateWpp({ externalappid });
        case ExternalAppType.BOTMAKER:
          // eslint-disable-next-line no-case-declarations
          const _payload = await botmakerService.templateWpp({ externalappid });
          if (typeof phone === "undefined") {
            return _payload;
          }
          return _payload.filter((_temp: any) => _temp.phone === phone);
        default:
          return await whatsappService.templateWpp({ externalappid });
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createMessage = createAsyncThunk(
  "messages/create",
  async (newMessage: INewMessage, thunkAPI) => {
    try {
      return await messagesService.create(newMessage);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const read = createAsyncThunk(
  "messages/read",
  async (readMessage: IReadMessage, thunkAPI) => {
    try {
      return await messagesService.read(readMessage);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const readAll = createAsyncThunk(
  "messages/readall",
  async ({ chatId, userId }: { chatId: string; userId: string }, thunkAPI) => {
    try {
      return await messagesService.readAll({ chatId, userId });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const replyTime = createAsyncThunk(
  "messages/replytime",
  async (
    {
      contactId,
      externalapp,
    }: {
      contactId: string;
      externalapp: string | undefined;
    },
    thunkAPI
  ) => {
    try {
      if (externalapp)
        return await messagesService.replyTime({ contactId, externalapp });
      return null;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAll = createAsyncThunk(
  "chats/deleteall",
  async (_, thunkAPI) => {
    try {
      return await messagesService.deleteAll();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    selectTotalMessages(state, action: PayloadAction<number>) {
      state.totalMessages = action.payload || 0;
    },
    resetWaitingMessages(state) {
      state.waitingMessages = [];
    },
    selectMessages(state, action: PayloadAction<IMessage[]>) {
      if (action.payload.length > 0) {
        state.chatdisable = true;
      }
      state.messages = action.payload || [];
    },
    selectTemplate(
      state,
      action: PayloadAction<{
        template: ITemplate | null;
        campaignMode?: boolean;
        campaignVars?: string | null;
      }>
    ) {
      if (action.payload.template !== null) {
        let _payload = action.payload.template;
        const _campaignMode = action?.payload?.campaignMode || false;
        const _campaignVars =
          typeof action?.payload?.campaignVars !== "undefined" &&
          action?.payload?.campaignVars
            ? JSON.parse(action?.payload?.campaignVars)
            : null;

        const getTextParamValue = ({
          value,
          index,
          header,
          body,
        }: {
          value: string;
          index?: number;
          header?: boolean;
          body?: boolean;
        }) => {
          const _buttons = {
            code: "",
            url: "",
          };
          state.selectedTemplateButtons = _buttons;
          state.selectedTemplateFiles = [];
          const defaultVars = [
            "contact.name",
            "contact.tenant",
            "contact.phone",
            "contact.email",
            `\${_contact_name}`,
            `\${_contact_phone}`,
            `\${_contact_email}`,
            // "user.name",
            // "user.email",
          ];
          if (defaultVars.includes(value) && typeof index !== "undefined") {
            return `{{${value}}}`;
          }
          if (defaultVars.includes(value)) {
            return value
              ?.replaceAll("{{", `</>{{${value}`)
              .replaceAll("}}", "}}</>");
          }
          if (header && typeof index !== "undefined") {
            return `{{H-${index + 1}}}`;
          }
          if (header) {
            return value?.replaceAll("{{", "</>{{H-").replaceAll("}}", "}}</>");
          }
          if (body && typeof index !== "undefined") {
            return `{{B-${index + 1}}}`;
          }
          return value?.replaceAll("{{", "</>{{B-").replaceAll("}}", "}}</>");
        };

        if (typeof _payload.language === "string") {
          _payload = {
            ..._payload,
            language: {
              code: _payload.language,
            },
          };
        }

        let hasHeader = false;
        let hasFooter = false;
        state.selectedTemplateParameters = null;

        const getMessageFormat = (_payload: ITemplate) => {
          return `${hasHeader ? `${_payload.components[0]?.text}\n\n` : ""}${
            !hasHeader
              ? _payload.components[0]?.text
              : _payload.components[1]?.text
          }\n\n${
            hasFooter
              ? `${
                  !hasHeader
                    ? _payload.components[1]?.text
                    : _payload.components[2]?.text
                }\n\n`
              : ""
          }`;
        };

        const getTextReplace = (
          message: string,
          parameters: Parameter[],
          type: string
        ) => {
          let _message = "";
          parameters.forEach((_p, index) => {
            const messageData = _message.length > 0 ? _message : message;
            if (_p.key !== _p.text) {
              _message = messageData?.replaceAll(
                `{{${+index + 1}}}`,
                _p.text as string
              );
            } else {
              _message = messageData?.replaceAll(
                `{{${+index + 1}}}`,
                `</>{{${type}-${+index + 1}}}</>`
              );
            }
          });
          return _message;
        };

        for (const key of Object.keys(_payload.components)) {
          const curComponent = _payload.components[+key];
          switch (curComponent.type) {
            case "HEADER":
              hasHeader = true;
              if (
                typeof curComponent.format !== "undefined" &&
                typeof curComponent.example?.header_handle !== "undefined"
              ) {
                const _format = curComponent.format;
                const _parameters: Parameter[] = [];
                const headerVars = curComponent.example?.header_handle;
                switch (_format) {
                  case "IMAGE":
                  case "DOCUMENT":
                  case "VIDEO":
                    if (
                      typeof headerVars[0] === "string" &&
                      headerVars.length === 1
                    ) {
                      const _arrayH: string[] = [];
                      _arrayH.push(headerVars[0]);
                      _arrayH.forEach((_, index) =>
                        _parameters.push({
                          key: `{{H-${index + 1}}}`,
                          type: _format.toLowerCase(),
                          [`${_format.toLowerCase()}`]: {
                            link: headerVars[0],
                          },
                        })
                      );
                      state.selectedTemplateParameters = [
                        {
                          type: "HEADER",
                          parameters: _parameters,
                        },
                      ];
                      _payload = {
                        ..._payload,
                        components: {
                          ..._payload.components,
                          "0": {
                            ...curComponent,
                            parameters: _parameters,
                            example: {
                              ...curComponent.example,
                              header_handle: _arrayH,
                            },
                          },
                        },
                      };
                    }
                    break;
                  default:
                    break;
                }
              } else if (curComponent.example?.header_text) {
                const _parameters: Parameter[] = [];
                const headerVars = curComponent.example.header_text;
                if (
                  typeof headerVars[0] === "string" &&
                  headerVars.length === 1
                ) {
                  const _arrayH: string[] = [];
                  _arrayH.push(headerVars[0]);
                  _arrayH.forEach((value, index) =>
                    _parameters.push({
                      key: `{{H-${index + 1}}}`,
                      type: "text",
                      text: _campaignMode
                        ? getTextParamValue({ value, index, header: true })
                        : `{{H-${index + 1}}}`,
                    })
                  );
                  state.selectedTemplateParameters = [
                    {
                      type: "HEADER",
                      parameters: _parameters,
                    },
                  ];
                  _payload = {
                    ..._payload,
                    components: {
                      ..._payload.components,
                      "0": {
                        ...curComponent,
                        parameters: _parameters,
                        text: getTextReplace(
                          curComponent.text as string,
                          _parameters,
                          "H"
                        ),
                        example: {
                          ...curComponent.example,
                          header_text: [_arrayH],
                        },
                      },
                    },
                  };
                } else {
                  headerVars[0].forEach((value, index) =>
                    _parameters.push({
                      key: `{{H-${index + 1}}}`,
                      type: "text",
                      text: _campaignMode
                        ? getTextParamValue({ value, index, header: true })
                        : `{{H-${index + 1}}}`,
                    })
                  );
                  state.selectedTemplateParameters = [
                    {
                      type: "HEADER",
                      parameters: _parameters,
                    },
                  ];
                  _payload = {
                    ..._payload,
                    components: {
                      ..._payload.components,
                      "0": {
                        ...curComponent,
                        parameters: _parameters,
                        text: getTextReplace(
                          curComponent.text as string,
                          _parameters,
                          "H"
                        ),
                      },
                    },
                  };
                }
              }
              break;
            case "BODY":
              if (curComponent.example?.body_text) {
                const _parameters: Parameter[] = [];
                const bodyVars = curComponent.example.body_text;
                if (typeof bodyVars[0] === "string" && bodyVars.length === 1) {
                  const _arrayB: string[] = [];
                  _arrayB.push(bodyVars[0]);
                  _arrayB.forEach((value, index) =>
                    _parameters.push({
                      key: `{{B-${index + 1}}}`,
                      type: "text",
                      text: _campaignMode
                        ? getTextParamValue({ value, index, body: true })
                        : `{{B-${index + 1}}}`,
                    })
                  );
                  if (key === "0") {
                    state.selectedTemplateParameters = [
                      {
                        type: "BODY",
                        parameters: _parameters,
                      },
                    ];

                    _payload = {
                      ..._payload,
                      components: {
                        ..._payload.components,
                        "0": {
                          ...curComponent,
                          parameters: _parameters,
                          text: getTextReplace(
                            curComponent.text as string,
                            _parameters,
                            "B"
                          ),
                          example: {
                            ...curComponent.example,
                            body_text: [_arrayB],
                          },
                        },
                      },
                    };
                  } else {
                    const _selectedTemplateParameters =
                      state.selectedTemplateParameters || [];
                    _selectedTemplateParameters?.push({
                      type: "BODY",
                      parameters: _parameters,
                    });
                    _payload = {
                      ..._payload,
                      components: {
                        ..._payload.components,
                        "1": {
                          ...curComponent,
                          parameters: _parameters,
                          text: getTextReplace(
                            curComponent.text as string,
                            _parameters,
                            "B"
                          ),
                          example: {
                            ...curComponent.example,
                            body_text: [_arrayB],
                          },
                        },
                      },
                    };
                    state.selectedTemplateParameters =
                      _selectedTemplateParameters;
                  }
                } else {
                  bodyVars[0].forEach((value, index) =>
                    _parameters.push({
                      key: `{{B-${index + 1}}}`,
                      type: "text",
                      text: _campaignMode
                        ? getTextParamValue({ value, index, body: true })
                        : `{{B-${index + 1}}}`,
                    })
                  );
                  if (key === "0") {
                    state.selectedTemplateParameters = [
                      {
                        type: "BODY",
                        parameters: _parameters,
                      },
                    ];
                    _payload = {
                      ..._payload,
                      components: {
                        ..._payload.components,
                        "0": {
                          ...curComponent,
                          parameters: _parameters,
                          text: getTextReplace(
                            curComponent.text as string,
                            _parameters,
                            "B"
                          ),
                        },
                      },
                    };
                  } else {
                    const _selectedTemplateParameters =
                      state.selectedTemplateParameters || [];
                    _selectedTemplateParameters?.push({
                      type: "BODY",
                      parameters: _parameters,
                    });

                    _payload = {
                      ..._payload,
                      components: {
                        ..._payload.components,
                        "1": {
                          ...curComponent,
                          parameters: _parameters,
                          text: getTextReplace(
                            curComponent.text as string,
                            _parameters,
                            "B"
                          ),
                        },
                      },
                    };
                    state.selectedTemplateParameters =
                      _selectedTemplateParameters;
                  }
                }
              }
              break;
            case "FOOTER":
              hasFooter = true;
              break;
            case "BUTTONS":
              if (typeof curComponent.buttons !== "undefined") {
                const getButtonValue = (key: string, defaultValue: string) => {
                  const _value = {
                    code: "",
                    url: "",
                    key: "",
                  };
                  if (_campaignMode && _campaignVars) {
                    for (const _templatevar of _campaignVars) {
                      if (
                        _templatevar.key === "{{CODE-1}}" &&
                        _templatevar.type === "coupon_code" &&
                        typeof _templatevar.coupon_code !== "undefined"
                      ) {
                        _value.code = _templatevar.coupon_code;
                        _value.key = _templatevar.key;
                      } else if (
                        _templatevar.key === "{{URL-1}}" &&
                        _templatevar.type === "text" &&
                        typeof _templatevar.text !== "undefined"
                      ) {
                        _value.url = _templatevar.text;
                      }
                    }
                    if (_value.code.length > 0 || _value.url.length > 0) {
                      let _newState = state.selectedTemplateButtons;
                      _newState = {
                        ..._newState,
                        code: _value.code,
                        url: _value.url,
                      };
                      state.selectedTemplateButtons = _newState;
                      if (state.selectedTemplateParameters !== null) {
                        const newState = state.selectedTemplateParameters;
                        for (
                          let gIndex = 0;
                          gIndex < newState.length;
                          // eslint-disable-next-line no-plusplus
                          gIndex++
                        ) {
                          const parameter = newState[gIndex].parameters[0];
                          if (parameter.key === key && newState && gIndex) {
                            newState[gIndex].parameters[0].text = _value.code;
                          }
                          state.selectedTemplateParameters = newState;
                        }
                      }
                      if (key === "{{URL-1}}") {
                        return _value.url;
                      }
                      return _value.code;
                    }
                  }
                  return defaultValue;
                };
                let _tempPayload = _payload;
                curComponent.buttons.forEach((button, index) => {
                  const _components: any = {};
                  if (
                    (button.type === "URL" &&
                      typeof button.example !== "undefined" &&
                      button.url?.includes("{{1}}")) ||
                    button.type === "COPY_CODE"
                  ) {
                    const _parameters: Parameter[] = [];
                    const buttonVars = button.example || [];
                    buttonVars.forEach((_, index) => {
                      if (
                        button.type === "URL" &&
                        typeof button.example !== "undefined"
                      ) {
                        _parameters.push({
                          key: `{{URL-${index + 1}}}`,
                          type: "text",
                          url: button.url?.replace("{{1}}", "{{URL-1}}"),
                          text: getButtonValue(
                            `{{URL-${index + 1}}}`,
                            buttonVars[0]
                          ),
                        });
                      } else if (
                        button.type === "COPY_CODE" &&
                        typeof buttonVars !== "undefined"
                      ) {
                        _parameters.push({
                          key: `{{CODE-${index + 1}}}`,
                          type: "coupon_code",
                          coupon_code: getButtonValue(
                            `{{CODE-${index + 1}}}`,
                            buttonVars[0]
                          ),
                          text: _campaignMode
                            ? getButtonValue(
                                `{{CODE-${index + 1}}}`,
                                buttonVars[0]
                              )
                            : undefined,
                          sub_type: button.type.toLowerCase(),
                          index,
                        });
                        let _buttons = state.selectedTemplateButtons;
                        _buttons = {
                          ..._buttons,
                          code: getButtonValue(
                            `{{CODE-${index + 1}}}`,
                            buttonVars[0]
                          ),
                        };
                        state.selectedTemplateButtons = _buttons;
                      }
                    });
                    const _selectedTemplateParameters =
                      state.selectedTemplateParameters || [];
                    _selectedTemplateParameters?.push({
                      type: "BUTTONS",
                      index,
                      sub_type: button.type.toLowerCase(),
                      parameters: _parameters,
                    });
                    for (const _index in _tempPayload.components) {
                      if (
                        Object.prototype.hasOwnProperty.call(
                          _tempPayload.components,
                          _index
                        )
                      ) {
                        const _component = _tempPayload.components[_index];
                        _components[
                          Object.keys(_components).length.toString()
                        ] = _component;
                      }
                    }
                    _components[Object.keys(_components).length.toString()] = {
                      type: "button",
                      sub_type: button.type.toLowerCase(),
                      index,
                      parameters: _parameters,
                    };
                    _tempPayload = {
                      ..._tempPayload,
                      components: {
                        ..._tempPayload.components,
                        ..._components,
                      },
                    };
                    state.selectedTemplateParameters =
                      _selectedTemplateParameters;
                  }
                  return button;
                });
                _payload = _tempPayload;
              }
              break;
            default:
              break;
          }
        }
        state.selectedTemplate = _payload;
        // console.log(_payload);
        state.selectedTemplateMessage = getMessageFormat(_payload);
      } else {
        state.selectedTemplate = null;
        state.selectedTemplateMessage = "";
        state.selectedTemplateButtons = { code: "", url: "" };
        state.selectedTemplateFiles = [];
        state.selectedTemplateFilesChanged = false;
        state.selectedTemplateParameters = null;
      }
    },
    reselectTemplate(state, action: PayloadAction<ITemplate | null>) {
      state.selectedTemplate = action.payload;
    },
    reselectTemplateParameters(
      state,
      action: PayloadAction<{
        gIndex: number;
        pIndex: number;
        newValue: string;
        type?: string;
        file?: boolean;
      } | null>
    ) {
      const _gIndex = action.payload?.gIndex.toString();
      const _pIndex = action.payload?.pIndex.toString();

      const _file = action.payload?.file;
      const _type = action.payload?.type;
      const _newValue = action.payload?.newValue;
      if (state.selectedTemplateParameters && _gIndex && _pIndex) {
        const newState = state.selectedTemplateParameters;
        if (_file && _newValue) {
          switch (_type) {
            case "IMAGE":
              if (
                typeof newState[+_gIndex].parameters[+_pIndex]?.image !==
                "undefined"
              ) {
                newState[+_gIndex].parameters[+_pIndex].image = {
                  link: _newValue,
                };
              }
              break;
            case "VIDEO":
              if (
                typeof newState[+_gIndex].parameters[+_pIndex]?.video !==
                "undefined"
              ) {
                newState[+_gIndex].parameters[+_pIndex].video = {
                  link: _newValue,
                };
              }
              break;
            case "DOCUMENT":
              if (
                typeof newState[+_gIndex].parameters[+_pIndex]?.document !==
                "undefined"
              ) {
                newState[+_gIndex].parameters[+_pIndex].document = {
                  link: _newValue,
                  filename: "",
                };
              }
              break;
            default:
              break;
          }
        } else {
          newState[+_gIndex].parameters[+_pIndex].text = _newValue;
        }
        state.selectedTemplateParameters = newState;
      }
    },
    sendsocket(
      state,
      action: PayloadAction<{ chat: IChat; message: IMessage }>
    ) {
      //
    },
    reselectTemplateMessage(
      state,
      action: PayloadAction<{
        index: string;
        newValue: string;
      }>
    ) {
      const _index = action.payload.index;
      const _newValue = action.payload.newValue;
      const newState = state.selectedTemplateMessage.replaceAll(
        _index,
        `</>${_newValue}</>`
      );
      state.selectedTemplateMessage = newState;
    },
    reselectTemplateFiles(
      state,
      action: PayloadAction<{ files: IAcceptedFiles; currentChanged?: boolean }>
    ) {
      state.selectedTemplateFiles = action.payload.files;
      if (typeof action?.payload?.currentChanged !== "undefined") {
        state.selectedTemplateFilesChanged = action.payload.currentChanged;
      }
    },
    reselectTemplateButtons(state, action) {
      state.selectedTemplateButtons = action.payload;
    },
    triggerChatInactive(state, action: PayloadAction<ITriggerChatInactive>) {
      //
    },
    logout() {
      return initialState;
    },
    disableChatCard(
      state,
      action: PayloadAction<{ disable: boolean; chat?: string }>
    ) {
      try {
        state.chatdisable = action.payload.disable;
      } catch (error) {
        state.chatdisable = false;
      }
    },
    changeStatus(state, action: PayloadAction<UpdateStatusPayload>) {
      const _messageToBeUpdated = action.payload._id;
      const newStateMessages = state.messages.map((message) => {
        if (message?._id === _messageToBeUpdated) {
          const newMessage = {
            ...message,
            status: action.payload.status,
          };
          return newMessage;
        }
        return message;
      });
      state.messages = newStateMessages;
    },
    changeUserMessage(state, action: PayloadAction<IChangeUser>) {
      const _userToBeUpdated: IChangeUser = action.payload;
      const newStateMessages = state.messages.map((message) => {
        const _sender = message.sender;
        if (_sender && _sender._id === _userToBeUpdated._id) {
          const newMessage = {
            ...message,
            sender: {
              ..._sender,
              ..._userToBeUpdated,
            },
          };
          return newMessage;
        }
        return message;
      });
      state.messages = newStateMessages;
    },
    addMessage(state, action: PayloadAction<IMessage>) {
      const _newMessage: IMessage = action.payload;
      const newState = state.messages;
      if (newState.length <= 0) {
        newState.push(_newMessage);
        state.messages = newState;
      } else if (
        newState.length > 0 &&
        _newMessage.chat?._id === newState[0].chat?._id
      ) {
        newState.push(_newMessage);
        // state.messages = removeDuplicates(newState, "_id");
        state.messages = newState;
        state.totalMessages += 1;
      }
    },
    typingMessage(state, action: PayloadAction<ITypingMessage>) {
      const _state = state;
      const _action = action;
      _state.isSuccess = !!_action.payload.action;
    },
  },
  extraReducers: (builder) => {
    builder
      // MESSAGES
      .addCase(getSearchMessages.pending, (state, action) => {
        if (
          action.meta.arg.limit === 10 &&
          !action.meta.arg.filter.includes("content")
        ) {
          state.isLoadingMessages = true;
        }
        // state.isLoadingMessages = true;
      })
      .addCase(getSearchMessages.fulfilled, (state, action) => {
        try {
          state.isSuccess = true;
          if (action.meta.arg.skip > 0) {
            state.messages = action?.payload?.results
              ?.reverse()
              .concat(state.messages);
          } else {
            state.messages = action.meta.arg.history
              ? action?.payload?.results?.sort((a: IMessage, b: IMessage) =>
                  // eslint-disable-next-line no-nested-ternary
                  (b?.created_at || "") > (a?.created_at || "")
                    ? -1
                    : (a?.created_at || "") > (b?.created_at || "")
                    ? 1
                    : 0
                ) || state.messages
              : action?.payload?.results?.reverse() || state.messages;
          }
          // state.messages = action?.payload?.results?.reverse() || state.messages;
          state.totalMessages = action?.payload?.count || state.totalMessages;
          state.isLoadingMessages = false;
        } catch (error) {
          // console.log(error);
        }
      })
      .addCase(getSearchMessages.rejected, (state) => {
        state.isError = true;
        // state.messages = [];
        // state.totalMessages = 0;
        state.isLoadingMessages = false;
      })
      // MESSAGES
      .addCase(getTemplatesWpp.pending, () => {
        //
      })
      .addCase(getTemplatesWpp.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (
          action?.payload !== null &&
          typeof action?.payload?.error === "undefined"
        ) {
          state.whatsappTemplates = action.payload;
          if (action.meta.arg._validate && !action.meta.arg._validateError) {
            customToast({
              type: IToastType.SUCCESS,
              message: `Validado com sucesso!`,
            });
          }
        } else if (action.meta.arg._validate) {
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado na validação!`,
          });
        }
      })
      .addCase(getTemplatesWpp.rejected, (state) => {
        state.isError = true;
      })
      .addCase(getExternalappTemplatesWpp.pending, () => {
        //
      })
      .addCase(getExternalappTemplatesWpp.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (
          action?.payload !== null &&
          typeof action?.payload?.error === "undefined"
        ) {
          state.whatsappTemplates = action.payload;
          if (action.meta.arg._validate && !action.meta.arg._validateError) {
            customToast({
              type: IToastType.SUCCESS,
              message: `Validado com sucesso!`,
            });
          }
        } else if (action.meta.arg._validate) {
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado na validação!`,
          });
        }
      })
      .addCase(getExternalappTemplatesWpp.rejected, (state) => {
        state.isError = true;
      })
      // CREATE
      .addCase(createMessage.pending, (state, action) => {
        if (typeof action.meta.arg.preSender !== "undefined") {
          const newState = state.messages;
          const now = new Date(Date.now());
          const createdAt = now.toISOString();
          const _stateMsgs = state.waitingMessages;
          _stateMsgs.push(createdAt);
          state.waitingMessages = _stateMsgs;
          const _newPreMsg: any = {
            ...action.meta.arg,
            _id: createdAt,
            parent: action.meta.arg.preParent || null,
            files: action.meta.arg.preFiles || [],
            created_at: createdAt,
            sender: action.meta.arg.preSender as IUser,
            read: action.meta.arg.read || false,
          };
          newState.push(_newPreMsg);
          state.totalMessages += 1;
        }
        state.chatdisable = true;
        state.isSending = true;
      })
      .addCase(createMessage.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (action.payload !== null) {
          if (typeof action.meta.arg.preSender === "undefined") {
            const newState = state.messages;
            newState.push(action.payload);
            state.messages = newState;
            state.totalMessages += 1;
          } else {
            const newState = [];
            let replaced = false;
            for (const _message of state.messages) {
              if (
                !state.waitingMessages.includes(_message._id) ||
                replaced === true
              ) {
                newState.push(_message);
              } else {
                replaced = true;
                newState.push(action.payload);
              }
            }
            state.messages = newState;
          }
          state.isSending = false;
        } else {
          state.isError = true;
          if (typeof action.meta.arg.preSender !== "undefined") {
            const newState = [];
            let replaced = false;
            for (const _message of state.messages) {
              if (
                !state.waitingMessages.includes(_message._id) ||
                replaced === true
              ) {
                newState.push(_message);
              } else {
                replaced = true;
                state.totalMessages -= 1;
              }
            }
            state.messages = newState;
          }
          state.isSending = false;
        }
      })
      .addCase(createMessage.rejected, (state) => {
        state.isError = true;
        state.isSending = false;
      })
      // SEND WPP
      .addCase(sendMessageWpp.pending, (state) => {
        state.isSending = true;
      })
      .addCase(sendMessageWpp.fulfilled, (state, action) => {
        state.isSending = false;
        if (action.payload !== null) {
          state.isSuccess = true;
        } else {
          state.isError = true;
        }
        state.isSending = false;
      })
      .addCase(sendMessageWpp.rejected, (state) => {
        state.isSending = false;
        state.isError = true;
      })
      // READ
      .addCase(read.pending, (state) => {
        state.isReading = true;
      })
      .addCase(read.fulfilled, (state, action) => {
        state.isSuccess = true;
        const _messages = state.messages;
        if (action.payload !== null) {
          _messages.map((message) => {
            if (message._id === action.payload._id) {
              message.read = false;
              return message;
            }
            return message;
          });
        }
        state.messages = _messages;
        state.isReading = false;
      })
      .addCase(read.rejected, (state) => {
        state.isError = true;
        state.isReading = false;
      })
      .addCase(readAll.pending, () => {
        // state.isReading = true;
      })
      .addCase(readAll.fulfilled, (state, action) => {
        // state.isSuccess = true;
        const _messages = state.messages;
        if (action.payload !== null) {
          _messages.map((message) => {
            message.read = true;
            return message;
          });
        }
        state.messages = _messages;
        // state.isReading = false;
      })
      .addCase(readAll.rejected, (state) => {
        state.isReading = false;
        state.isError = true;
      })
      .addCase(replyTime.pending, () => {
        // state.isReading = true;
      })
      .addCase(replyTime.fulfilled, (state) => {
        state.isSuccess = true;
      })
      .addCase(replyTime.rejected, (state) => {
        state.isError = true;
      })
      // DELETE
      .addCase(deleteAll.pending, (state) => {
        state.isLoadingMessages = true;
      })
      .addCase(deleteAll.fulfilled, (state) => {
        state.isSuccess = true;
        state.messages = [];
        state.isLoadingMessages = false;
      })
      .addCase(deleteAll.rejected, (state) => {
        state.isError = true;
        state.isLoadingMessages = false;
      });
  },
});

export default messagesSlice.reducer;
export const {
  sendsocket,
  changeStatus,
  addMessage,
  triggerChatInactive,
  typingMessage,
  logout,
  selectTemplate,
  changeUserMessage,
  reselectTemplate,
  reselectTemplateParameters,
  reselectTemplateMessage,
  reselectTemplateFiles,
  reselectTemplateButtons,
  selectMessages,
  selectTotalMessages,
  disableChatCard,
  resetWaitingMessages,
} = messagesSlice.actions;
