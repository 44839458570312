/* eslint-disable react/require-default-props */
import React from "react";
import { Form } from "semantic-ui-react";
import ActionButtonsModal from "./ActionButtonsModal.component";

type ModalProps = {
  header: {
    title: string;
    icon?: string;
  };
  content: React.ReactNode;
  buttons: {
    cancelAction?: VoidFunction;
    submitAction?: VoidFunction;
    disabled?: boolean | undefined;
    submitLabel?: string | undefined;
    submitIcon?: string | undefined;
    cancelLabel?: string | undefined;
    cancelIcon?: string | undefined;
    sidebar?: boolean | undefined;
    noIcon?: boolean | undefined;
  };
  noForm?: boolean;
};

const DefaultModal = ({ header, content, buttons, noForm }: ModalProps) => {
  return (
    <div id="modal" className="fixed inset-0 z-[200]">
      <div
        role="presentation"
        className="fixed inset-0 w-full h-full bg-black opacity-80"
        // onClick={() => {
        //   setShowModal(false);
        // }}
      />
      <div className="flex items-center justify-center min-h-screen">
        <div
          className={`relative flex-container bg-white flex flex-col justify-center rounded-md max-h-[675px] shadow-lg py-8 px-10 ${
            noForm
              ? "w-[450px] sm:w-3/4 md:max-w-[450px] rounded-6"
              : "w-4/5 sm:w-3/4 md:max-w-[600px]"
          } `}
        >
          <h1 className="header flex items-center justify-center font-semibold">
            {header.icon && <i className={`${header.icon} text-3xl`} />}
            <span className="text-xl ml-3">{header.title}</span>
          </h1>
          {noForm ? (
            <div
              id="modal-relation"
              className="content overflow-y-scroll mx-auto w-[400px] overflow-x-hidden px-4"
            >
              {content}
            </div>
          ) : (
            <Form
              id="modal-relation"
              className="content overflow-y-scroll mx-auto w-[530px] overflow-x-hidden px-4"
            >
              {content}
            </Form>
          )}
          <Form className="footer mx-auto w-[530px] px-4">
            <ActionButtonsModal
              cancelAction={buttons.cancelAction}
              disabled={buttons.disabled}
              submitAction={buttons.submitAction}
              submitLabel={buttons.submitLabel}
              submitIcon={buttons.submitIcon}
              cancelLabel={buttons.cancelLabel}
              cancelIcon={buttons.cancelIcon}
              noIcon={buttons.noIcon}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default DefaultModal;
