import React, { useEffect } from "react";
import { DropdownProps, SemanticWIDTHS } from "semantic-ui-react";
import Dropdown from "../Dropdown.component";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import { getSearchPermissiongroups } from "../../../slices/permissiongroups.slice";

const PermissionGroupsDropdown = ({
  id,
  className,
  disabled,
  defaultValue,
  onChange,
  width,
  fluid,
  error,
}: {
  id?: string;
  width?: SemanticWIDTHS;
  className?: string;
  disabled?: boolean;
  defaultValue?: string[];
  error?: boolean;
  onChange?:
  | ((
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => void)
  | undefined;
  fluid?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { permissiongroups } = useAppSelector(
    (state) => state.permissiongroups
  );

  useEffect(() => {
    dispatch(
      getSearchPermissiongroups({
        skip: 0,
        limit: 100,
        filter: "",
        deleted: false,
      })
    );
  }, []);

  if (!permissiongroups) {
    return null;
  }

  return (
    <Dropdown
      id={id || "dropdown-permission-groups"}
      className={className || "w-full flex flex-col gap-2"}
      disabled={disabled || false}
      fluid={fluid}
      width={width}
      error={error}
      required
      placeholder="Grupos de Permissão"
      label="Grupos de Permissão"
      selection
      multiple={typeof defaultValue === "object"}
      defaultValue={defaultValue}
      onChange={onChange}
      options={permissiongroups.map((permissiongroup) => ({
        key: permissiongroup._id,
        text: permissiongroup.name,
        value: permissiongroup._id,
      }))}
    />
  );
};

PermissionGroupsDropdown.defaultProps = {
  id: undefined,
  className: undefined,
  disabled: undefined,
  defaultValue: undefined,
  onChange: undefined,
  fluid: undefined,
  width: undefined,
  error: undefined,
};

export default PermissionGroupsDropdown;
